import {JacksonType} from "@sonner/jackson-service-v2";
import {Atividade} from "../atividade";

@JacksonType("ConsultaSimplesNacionalResumoAtividadeTO")
export class ConsultaSimplesNacionalResumoAtividadeTO {

  @JacksonType("Atividade")
  atividade: Atividade;

  valor: number;

}
