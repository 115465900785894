import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {ProcessoExportacaoSimples} from "./processo.exportacao.simples";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {ExclusaoSimplesCodigosEnum} from "../enums/exclusao.simples.codigos.enum";
import {ProcessoExportacaoSimplesTipoRegistroEnum} from "../enums/processo.exportacao.simples.tipo.registro.enum";

@JacksonType("ProcessoExportacaoSimplesDetalhe")
@JsonIdentityInfo()
export class ProcessoExportacaoSimplesDetalhe extends SonnerBaseEntity {

  id: number;

  cnpj: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @EnumType(ExclusaoSimplesCodigosEnum)
  codigoEventoEnvio: ExclusaoSimplesCodigosEnum;

  @EnumType(ExclusaoSimplesCodigosEnum)
  codigoEventoRetorno: ExclusaoSimplesCodigosEnum;

  @DateType()
  dataFatoMotivador: Date;

  @DateType()
  dataNotificacao: Date;

  exclusaoRealizada: boolean;

  idOriginal: string;

  marcadoExportar: boolean;

  numeroProcessoProtocoloAto: string;

  @JacksonType("ProcessoExportacaoSimples")
  processo: ProcessoExportacaoSimples;

  observacao: string;

  registroExportado: boolean;

  @EnumType(ProcessoExportacaoSimplesTipoRegistroEnum)
  tipoRegistro: ProcessoExportacaoSimplesTipoRegistroEnum;
}
