import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {GuiaAvulsa} from "./guia.avulsa";
import {MultaInfracao} from "./multa.infracao";
import {TermoAutoInfracao} from "./termo.auto.infracao";

@JacksonType("OcorrenciaTermoAutoInfracao")
@JsonIdentityInfo()
export class OcorrenciaTermoAutoInfracao extends SonnerBaseEntity {

  id: number;

  baseCalculo: number;

  descricao: string;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  @JacksonType("MultaInfracao")
  multa: MultaInfracao;

  quantidade: number;

  @JacksonType("TermoAutoInfracao")
  termoAutoInfracao: TermoAutoInfracao;

  valor: number;

  @DateType()
  vencimento: Date;
}
