import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Atividade} from "./atividade";
import {TipoEstabelecimentoCartorio} from "./tipo.estabelecimento.cartorio";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("TipoServicoCartorio")
@JsonIdentityInfo()
export class TipoServicoCartorio extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  descricao: string;

  codigo: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("TipoEstabelecimentoCartorio")
  tipoCartorio: TipoEstabelecimentoCartorio;

  @JacksonType("Atividade")
  atividade: Atividade;

  getDisplayValue(): string {
    return this.codigo + " - " + this.descricao;
  }



}
