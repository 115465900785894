import {EnumUtils} from "../modules/core/utils/enum.utils";

export enum TipoRegimeISSEnum {

  Estimativa = "Estimativa",
  Faturamento = "Faturamento",
  FixoAnual = "Fixo Anual",
  Imune = "Imune",
  Isento = "Isento",
  MEI = "MEI",
  NaoIncide = "Não Incidência",
  Simples = "Simples",
  SociedadeProfissional = "Sociedade Profissional"
}

export class TipoRegimeISSEnumUtils {
  id: number;
  nome: string;

  constructor(id: number, nome: string) {
    this.id = id;
    this.nome = nome;
  }
}

export function convertTipoRegimeISSEnumUtils(): TipoRegimeISSEnumUtils[] {
  let list: TipoRegimeISSEnumUtils[] = [];

  EnumUtils.values(TipoRegimeISSEnum).forEach((value, index) => {
    list.push(new TipoRegimeISSEnumUtils(index, value))
  });

  return list;
}
