import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Serie} from "../../../classes/serie";
import {SerieFilter} from "../../commons/filters/serie.filter";

@Injectable()
export class SerieService {

  constructor(private restService: RestService) {
  }

  buscar(serieFilter: SerieFilter): Observable<Serie> {
    return this.restService.post("/serie/buscar", {data: serieFilter, responseType: Serie});
  }

  buscaListaSeriePorContribuinte(): Observable<Serie> {
    return this.restService.post("/serie/buscaListaSeriePorContribuinte", {responseType: Serie});
  }

  remover(serieFilter: SerieFilter): Observable<any> {
    return this.restService.post("/serie/remover", {data: serieFilter});
  }

  salvar(serie: Serie): Observable<any> {
    return this.restService.post("/serie/salvar", {data: serie})
  }
}
