import {JacksonType} from "@sonner/jackson-service-v2";
import {NotaFiscalSimplificadaTO} from "./nota.fiscal.simplificada.to";

@JacksonType("CancelaNotaLoteTO")
export class CancelaNotaLoteTO {


  @JacksonType("NotaFiscalSimplificadaTO")
  notas: NotaFiscalSimplificadaTO[];

  notasCancelar: number[];

  motivoCancelamento: string;

  documento: string;

  senhaUsuario: string;

}
