import {Component, Injector} from "@angular/core";
import {LoginService} from "../../../core/services/login.service";
import {Municipio} from "../../../../classes/municipio";
import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {LoginFilter} from "../../../core/filters/login.filter";
import {AlertService} from "../../../ui/services/alert.service";
import {CognitoService} from "../../../core/services/cognito.service";
import {RemoveInscricaoEmailService} from "../../../core/services/remove.inscricao.email.service";

@Component({
  selector: 'remove-inscricao-email',
  templateUrl: './remove.inscricao.email.page.component.html',
  styleUrls: ['./remove.inscricao.email.page.component.scss']
})
export class RemoveInscricaoEmailPageComponent extends BaseComponent {

  constructor(private activatedRoute: ActivatedRoute,
              private removeInscricaoEmailService: RemoveInscricaoEmailService,
              private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(map => {
      if(map.get("email")){
          this.of(this.removeInscricaoEmailService.remover(map.get("email")),()=>{
            this.sucess = true;
            this.disableLoading();
          });
      }
    });
  }
}
