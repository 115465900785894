import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {DenunciaTipoEnum} from "../enums/denuncia.tipo.enum";

@JacksonType("Denuncia")
@JsonIdentityInfo()
export class Denuncia extends SonnerBaseEntity {

  id: number;

  conteudoResposta: string;

  @DateType()
  dataDenuncia: Date;

  @DateType()
  dataEncerramento: Date;

  @DateType()
  dataOcorrencia: Date;

  @DateType()
  dataResposta: Date;

  documentoDenunciado: string;

  documentoDenunciante: string;

  emailDenunciado: string;

  emailDenunciante: string;

  enderecoDenunciado: string;

  nomeDenunciado: string;

  nomeDenunciante: string;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  @JacksonType("Municipio")
  municipio: Municipio;

  obs: string;

  obsEncerramento: string;

  rps: string;

  @EnumType(DenunciaTipoEnum)
  tipo: DenunciaTipoEnum;
}
