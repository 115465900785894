import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Currency} from "./currency";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("RegimeISS")
@JsonIdentityInfo()
export class RegimeISS extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @EnumType(TipoRegimeISSEnum)
  tipo: TipoRegimeISSEnum = TipoRegimeISSEnum.Faturamento;

  valor: number;

  descricao() {
    if (this.tipo != null && this.tipo == TipoRegimeISSEnum.Simples) {
      return "Simples " + (this.aliquota * 100).toFixed(2) + " %";
    }

    if (this.tipo != null && this.tipo == TipoRegimeISSEnum.Estimativa) {
      let curreny: Currency = new Currency();

      curreny.value = this.valor;

      return "Estimativa  R$ " + curreny.valueString;
    }

    return this.tipo;
  }
}
