import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {DateUtilsService} from "../../../core/services/date.utils.service";

@Component({
  selector: "competencia-selector",
  templateUrl: './competencia.selector.component.html',
  styleUrls: ['./competencia.selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompetenciaSelectorComponent extends BaseComponent {

  competenciaList: string[];

  competenciaSelecionada: string;

  private _competencia: Date;

  @Output()
  competenciaChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input()
  readOnly: boolean = false;

  @Input()
  set competencia(value: Date) {
    this._competencia = value;
    if (value) {
      this.competenciaSelecionada = this.dateUtilsService.getMesAno(value.getMonth(), value.getFullYear());
    }
  }

  constructor(private injector: Injector,
              private dateUtilsService: DateUtilsService) {
    super(injector);
  }

  ngOnInit() {
    if (!this._competencia) {
      this._competencia = new Date();
    }
    this.competenciaList = this.dateUtilsService.mesesAnosString();
    this.competenciaSelecionada = this.dateUtilsService.getMesAno(this._competencia.getMonth(), this._competencia.getFullYear());
  }

  alteraCompetencia(event: string) {
    if (event) {
      this.competenciaSelecionada = event;
      let competencia: string[] = event.split("/");
      let mes: number = this.dateUtilsService.getMesNumber(competencia[0].trim());
      let ano: number = +competencia[1].trim();
      this.competenciaChange.emit(new Date(ano, mes, 1));
    }
  }

}
