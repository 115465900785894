import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("ConfiguracaoMigradorTO")
export class ConfiguracaoMigradorTO {

  blkPessoaFisica: boolean = false;

  blkOverEnds: boolean = false;

  blkRegimes: boolean = false;

  chgRegEstValor: boolean = false;

  comando: string;

  comandoPostgre: string;

  idMunicipio: number;

  migrTodosSubstTrib: boolean = false;

  onlyPrts: boolean = false;

  sobrepoemCadastroAtivo: boolean = false;

  naoCalculaAliquotaSimplesNacional: boolean = false;

  migraCBO: boolean = false;

  processaEncargosLegado: boolean = false;
}
