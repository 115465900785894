import {JacksonType} from "@sonner/jackson-service-v2";
import {PGDASDDASTO} from "./pgdasd.das.to";

@JacksonType("ReciboTO")
export class ReciboTO {

  erros: string[];

  urlRelatorio: URL;


}
