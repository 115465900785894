import {BaseComponent} from "../../../ui/components/base/base.component";
import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {AppStateService} from "../../../app/services/app.state.service";
import {NotaDeducaoService} from "../../services/nota.deducao.service";
import {NotaDeducaoFilter} from "../../filters/nota.deducao.filter";
import {EncerramentoService} from "../../../commons/services/encerramento.service";
import {EncerramentoFilter} from "../../../commons/filters/encerramento.filter";
import {PapelServicoEnum} from "../../../../enums/papel.servico.enum";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {forkJoin} from "rxjs";
import {NotaDeducaoDocumentoTO} from "../../../../classes/to/nota.deducao.documento.to";
import {NotaDeducaoTO} from "../../../../classes/to/nota.deducao.to";

@Component({
  selector: 'nota-deducao-menu',
  templateUrl: './nota.deducao.menu.page.component.html',
  styleUrls: ['./nota.deducao.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class NotaDeducaoMenuPageComponent extends BaseComponent {

  constructor(
              private appStateService: AppStateService,
              private encerramentoService: EncerramentoService,
              private notaDeducaoService: NotaDeducaoService,
              private injector: Injector
             )
  {
    super(injector);

  }

  filter: NotaDeducaoFilter = new NotaDeducaoFilter();

  notasSemVinculo: NotaDeducaoDocumentoTO[];

  notasVinculadas: NotaDeducaoDocumentoTO[];

  idSelecionada: number;

  competenciaEncerrada: boolean;

  notasAdicionadasTemp: number[] =[];

  notasRemovidasTemp: number[] =[];

  notasAdicionadas: number[] =[];

  notasRemovidas: number[] =[];

  notasVinculadasCache: Set<number> = new Set<number>();

  notasSemVinculoCache: Set<number> = new Set<number>();

  competenciaSelecionada: Date = new Date();

  visible: boolean = false;

  valorVinculadoCancelado: number;

  ngOnInit(): void {
    this.appStateService.userInfoSubject.subscribe(user => {
      if(user){
        this.idSelecionada = user.idPessoaSelecionada;
        this.competenciaEncerrada = false;
        this.disableLoading()
      }
    });
  }

  @GlobalLoadingIndicator()
  competenciaChange(evento: Date) {
    if (evento && evento.getTime() != this.competenciaSelecionada.getTime()) {
      this.clear();
      this.competenciaSelecionada = evento;
      this.filter.ano = evento.getFullYear();
      this.filter.mes = evento.getMonth();
      let encerramentoFilter: EncerramentoFilter = new EncerramentoFilter();
      encerramentoFilter.ano = this.filter.ano;
      encerramentoFilter.mes = this.filter.mes;
      encerramentoFilter.papelServico = PapelServicoEnum.prestador;
      encerramentoFilter.idPessoa = this.idSelecionada;
      return this.of(forkJoin(this.encerramentoService.verificarPorAnoEAtivoEMesEPapelServicoEPessoa(encerramentoFilter), this.notaDeducaoService.buscar(this.filter)), data => {
        if (data) {
          this.competenciaEncerrada = data[0];
          this.notasSemVinculo = data[1].notasDeducaoDocumentoSemVinculo;
          this.notasVinculadas = data[1].notasDeducaoDocumento;
          this.valorVinculadoCancelado = data[1].valorVinculadoCancelado;
        }
        this.visible = true;
      });
    }
  }

  addClick() {
    if(this.notasAdicionadasTemp && this.notasAdicionadasTemp.length > 0){
      this.notasAdicionadasTemp.forEach(i =>{
        let index = this.notasSemVinculo.findIndex( a => a.id == i);
        this.notasVinculadas.push(this.notasSemVinculo[index]);
        this.notasSemVinculo.splice(index,1);
        if(!this.notasVinculadasCache.has(i)){
          this.notasAdicionadas.push(i);
        }
        this.removeFromList(true, i);
      });
    }
  }

  removeClick(){
    if(this.notasRemovidasTemp && this.notasRemovidasTemp.length > 0){
      this.notasRemovidasTemp.forEach(i =>{
        let index = this.notasVinculadas.findIndex( a => a.id == i);
        this.notasSemVinculo.push(this.notasVinculadas[index]);
        this.notasVinculadas.splice(index,1);
        if(!this.notasSemVinculoCache.has(i)){
          this.notasRemovidas.push(i);
        }
        this.removeFromList(false, i);
      });
    }
  }

  removeFromList(removeList: boolean, id: number){
    if(removeList){
      this.notasRemovidas.splice(this.notasRemovidas.findIndex(n => n == id ),1);
    }else{
      this.notasAdicionadas.splice(this.notasAdicionadas.findIndex(n => n == id ),1);
    }
  }

  clear(){
    this.notasVinculadas = [];
    this.notasSemVinculo = [];
    this.visible = false;
    this.notasSemVinculoCache = new Set<number>();
    this.notasVinculadasCache = new Set<number>();
    this.notasAdicionadas = [];
    this.notasRemovidas = [];
    this.notasRemovidasTemp = [];
    this.notasAdicionadasTemp = [];
  }

  @GlobalLoadingIndicator()
  acionaSalvar() {
    let to: NotaDeducaoTO = new NotaDeducaoTO();
    to.notasDeducaoDocumentoAdicionadas = this.notasAdicionadas;
    to.notasDeducaoDocumentoRemovidas = this.notasRemovidas;
    to.mes = this.competenciaSelecionada.getMonth();
    to.ano = this.competenciaSelecionada.getFullYear();
    return this.of(this.notaDeducaoService.salvar(to), () => this.sucess = true);
  }

  url(senha: string) {
    if(senha){
      window.open("/home/actions/documentos?senha="+ senha);
    }
  }

  verificaCancelamento(data: any): string {
    if(data.cancelado){
      return "cancelado";
    }
    return;
  }

  summaryProcess(options) {
    switch(options.summaryProcess) {
      case "start":
        options.totalValue = 0;
        break;
      case "calculate":
        if (options.value && !options.value.cancelado) {
          if(options.name == "valorTotalSummary"){
            options.totalValue = options.totalValue + options.value.valor;
          }
        }
        break;
    }
  }

}
