import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "./rest.service";
import {Municipio} from "../../../classes/municipio";
import {UsuarioTO} from "../../../classes/to/usuario.to";
import {Atividade} from "../../../classes/atividade";
import {CEP} from "../../../classes/c.e.p";
import {Usuario} from "../../../classes/usuario";
import {LoginFilter} from "../filters/login.filter";
import {PrimeiroAcessoTO} from '../../../classes/to/primeiro.acesso.to';

@Injectable()
export class RemoveInscricaoEmailService {

  constructor(private restService: RestService) {
  }

  remover(email: string): Observable<any> {
    return this.restService.post("/public/removeinscricaoemail/remover", {data: email});
  }
}
