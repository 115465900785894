import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AtividadeDimensao} from "./atividade.dimensao";
import {CompetenciaDimensao} from "./competencia.dimensao";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {ReceitaRealizada} from "./receita.realizada";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("ReceitaRealizadaAtividade")
@JsonIdentityInfo()
export class ReceitaRealizadaAtividade extends SonnerBaseEntity {

  id: number;

  @JacksonType("AtividadeDimensao")
  atividade: AtividadeDimensao;

  @JacksonType("CompetenciaDimensao")
  competencia: CompetenciaDimensao;

  @JacksonType("Pessoa")
  contribuinteResponsavel: Pessoa;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("ReceitaRealizada")
  receita: ReceitaRealizada;

  valor: number;
}
