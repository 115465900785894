import {JacksonType} from "@sonner/jackson-service-v2";
import {UnidadeItem} from "../unidade.item";

@JacksonType("ConfiguracaoUnidadeTO")
export class ConfiguracaoUnidadeTO {

  @JacksonType("UnidadeItem")
  unidadeItemList: UnidadeItem[] = [];

  utilizaUnidades: boolean = false;
}
