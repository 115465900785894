import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifConta} from "./desif.conta";
import {DesifPlanoConta} from "./desif.plano.conta";
import {DesifServicoVariavel} from "./desif.servico.variavel";

@JacksonType("DesifTarifaVariavel")
@JsonIdentityInfo()
export class DesifTarifaVariavel extends SonnerBaseEntity {

  id: number;

  @JacksonType("DesifConta")
  conta: DesifConta;

  descricaoAdicional: string;

  desdobramentoContaMista: string;

  @JacksonType("DesifPlanoConta")
  planoConta: DesifPlanoConta;

  @JacksonType("DesifServicoVariavel")
  servico: DesifServicoVariavel;
}
