import {EnumUtils} from "../modules/core/utils/enum.utils";

export enum NaturezaCadastroEnum {

  PERMANENTE = "Permanente",
  EVENTUAL = "Eventual",
  PROVISORIO = "Provisório"
}

export class NaturezaCadastroEnumUtils {
  id: number;
  nome: string;

  constructor(id: number, nome: string) {
    this.id = id;
    this.nome = nome;
  }
}

export function convertNaturezaCadastroEnumUtils(): NaturezaCadastroEnumUtils[] {
  let list: NaturezaCadastroEnumUtils[] = [];

  EnumUtils.values(NaturezaCadastroEnum).forEach((value, index) => {
    list.push(new NaturezaCadastroEnumUtils(index, value))
  });

  return list;
}
