import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Comunicado")
@JsonIdentityInfo()
export class Comunicado extends SonnerBaseEntity {

  id: number;

  assunto: string;

  corpo: string;

  @DateType()
  fim: Date;

  icone: string;

  @DateType()
  inicio: Date;

  @JacksonType("Pessoa")
  emissor: Pessoa;

  @JacksonType("Municipio")
  municipio: Municipio;

  @EnumType(PerfilEnum)
  perfil: PerfilEnum;

  @DateType()
  publicadoEm: Date;

  @JacksonType("Pessoa")
  destinatarios: Pessoa[];
}
