import {JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Solicitacao} from "./solicitacao";
import {Topico} from "./topico";

@JacksonType("SolicitacaoGenerica")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoGenerica', type: 'Solicitacao'})
@JsonIdentityInfo()
export class SolicitacaoGenerica extends Solicitacao {

  @JacksonType("Topico")
  topico: Topico;
}
