import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";

@Component({
  selector: 'ensino-menu',
  templateUrl: './ensino.menu.page.component.html',
  styleUrls: ['./ensino.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnsinoMenuPageComponent extends BaseComponent {

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.disableLoading();
  }
}
