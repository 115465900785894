import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {Atividade} from "../atividade";
import {GrupoAtividade} from "../grupo.atividade";
import {AtividadeHistorico} from "../atividade.historico";

@JacksonType("AtividadeTO")
export class AtividadeTO {

  id:number;

  abateObra: boolean;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("AtividadeHistorico")
  atividadeHistorico: AtividadeHistorico;

  @JacksonType("AtividadeHistorico")
  atividadeHistoricoList: AtividadeHistorico[];

  bloqueiaRetencaoMunicipio: boolean = false;

  @DateType()
  competencia: Date;

  @JacksonType("GrupoAtividade")
  grupoAtividadeList: GrupoAtividade[];

  municipioExibeOutrasDeducoes: boolean = false;

  prestadorPermiteRentencao: boolean = true;

  quantidadeVinculosAtivos: number;

  retida: boolean;

  simples: boolean = false;

  tomadorPermiteRentencao: boolean = false;

  descricao: string;

  codigoGrupo: string;

  grupoDescricao: string;

  codigoAtividade: string;
}
