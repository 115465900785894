import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {ControleExportacaoTO} from "../../../classes/to/controle.exportacao.to";
import {ControleIntegracaoTO} from "../../../classes/to/controle.integracao.to";
import {ControleIntegracaoFilter} from "../filters/controle.integracao.filter";

@Injectable()
export class ControleIntegracaoService {

  constructor(private restService: RestService) {
  }

  porMunicipioReceitaFederal(): Observable<ControleIntegracaoTO[]> {
    return this.restService.get("/controleintegracao/porMunicipioReceitaFederal", {responseType: ControleIntegracaoTO});
  }

  porChaveOperacaoReceitaFederal(controleIntegracaoFilter: ControleIntegracaoFilter): Observable<ControleIntegracaoTO[]> {
    return this.restService.post("/controleintegracao/porChaveOperacaoReceitaFederal", {data: controleIntegracaoFilter, responseType: ControleIntegracaoTO});
  }

  report(controleIntegracaoFilter: ControleIntegracaoFilter): Observable<any> {
    return this.restService.post("/controleintegracao/report", {data: controleIntegracaoFilter});
  }

}
