import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";
import {AppErrorService} from "../services/app.error.service";
import {AppErrorTypeEnum} from "../../../enums/app.error.type.enum";
import {LoginService} from "../services/login.service";
import {CognitoService} from "../services/cognito.service";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(private appErrorService: AppErrorService,
              private cognitoService: CognitoService) {

    cognitoService.cognitoUser.subscribe(token => this.token = token);
  }

  private token: any;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.token && this.token.signInUserSession) {
      req = req.clone({setHeaders: {Authorization: `Bearer ${this.token.signInUserSession.idToken.jwtToken}`}});
    }

    return next.handle(req).pipe(catchError(response => {
        if (response instanceof HttpErrorResponse) {
          if (response.status == 401) {
            this.appErrorService.setError(AppErrorTypeEnum.AUTHENTICATION_ERROR);
          } else if (response.status == 403) {
            this.appErrorService.setError(AppErrorTypeEnum.AUTHORIZATION_ERROR);
          }
        }

        return throwError(response);
      })
    );
  }
}
