import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("ConsolidacaoDwLog")
@JsonIdentityInfo()
export class ConsolidacaoDwLog extends SonnerBaseEntity {

  id: number;

  ano: number;

  inicioCnpj: string;

  mes: number;

  municipioId: number;

  timestampExecutado: number;
}
