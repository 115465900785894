import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";

@JacksonType("ConfiguracaoBackupTO")
@JsonIdentityInfo()
export class ConfiguracaoBackupTO {

  id: string;

  @DateType()
  dataAgendamento: Date;

  @DateType()
  inicioProcessamento: Date;

  @DateType()
  fimProcessamento: Date;

  erro: boolean;

  url: string;

  nomeUsuario: string;

  email: string;

  senha: boolean = false;

  nomeBackup: string;

}
