import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Relatorios} from "../../../classes/relatorios";

@Injectable()
export class RelatorioAssincronoService {

  constructor(private restService: RestService) {
  }

  busca(tipoRelatorio: string, maxResults: number ): Observable<Relatorios> {
    return this.restService.get("/relatorioAssincrono?tipoRelatorio=" + tipoRelatorio + "&maxResults=" + maxResults,{responseType: Relatorios});
  }
}
