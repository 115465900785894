import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Cartorio")
@JsonSubType({key: 'br.com.sonner.iss.entity.Cartorio', type: "Papel"})
export class Cartorio extends Papel {

  constructor() {
    super(PerfilEnum.cartorio);
  }
}
