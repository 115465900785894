import {JacksonType} from "@sonner/jackson-service-v2";
import {ParcelamentoSimplesConsolidacao} from "../parcelamento.simples.consolidacao";
import {ParcelamentoSimplesDebito} from "../parcelamento.simples.debito";

@JacksonType("ParcelamentoConsolidacaoTO")
export class ParcelamentoConsolidacaoTO {

  @JacksonType("ParcelamentoSimplesConsolidacao")
  consolidacao: ParcelamentoSimplesConsolidacao;

  @JacksonType("ParcelamentoSimplesDebito")
  debitos: ParcelamentoSimplesDebito;
}
