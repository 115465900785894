import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {OrdemServico} from "../../../../classes/ordem.servico";
import {TipoOrdemServicoEnum} from "../../../../enums/tipo.ordem.servico.enum";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {Arquivo} from "../../../../classes/arquivo";
import {ArquivoService} from "../../../commons/services/arquivo.service";
import {AppStateService} from "../../../app/services/app.state.service";
import {OrdemServicoFilter} from "../../filters/ordem.servico.filter";
import {PerfilEnum} from "../../../../enums/perfil.enum";
import {OrdemServicoService} from "../../services/ordem.servico.service";
import {OrdemServicoTO} from "../../../../classes/to/ordem.servico.to";
import {SituacaoOrdemServicoEnum} from "../../../../enums/situacao.ordem.servico.enum";
import {mergeMap} from "rxjs/operators";
import {OrdemServicoItem} from "../../../../classes/ordem.servico.item";
import {Pessoa} from "../../../../classes/pessoa";
import {DomHandlerService} from "../../../ui/services/dom.handler.service";
import {OrdemServicoArquivo} from "../../../../classes/ordem.servico.arquivo";
import {OrdemServicoArquivoFilter} from "../../filters/ordem.servico.arquivo.filter";
import {OrdemServicoArquivoService} from "../../services/ordem.servico.arquivo.service";

@Component({
  selector: 'ordem-servico-page',
  templateUrl: './ordem.servico.page.component.html',
  styleUrls: ['./ordem.servico.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrdemServicoPageComponent extends BaseComponent {

  constructor(private appStateService: AppStateService,
              private arquivoService: ArquivoService,
              private domHandler: DomHandlerService,
              private injector: Injector,
              private ordemServicoService: OrdemServicoService,
              private ordemServicoArquivoService: OrdemServicoArquivoService) {
    super(injector);

    this.onRowClick = this.onRowClick.bind(this);
  }

  tabOption: any;

  anexoList: Arquivo[] = [];

  conteudo: string;

  ordemServico: OrdemServico = new OrdemServico();

  ordemServicoTO: OrdemServicoTO = new OrdemServicoTO();

  ordemServicoFilter: OrdemServicoFilter = new OrdemServicoFilter();

  pessoa: Pessoa;

  situacaoOrdemServicoEnumType: any = SituacaoOrdemServicoEnum;

  suporte: boolean = false;

  tipoOrdemServicoEnumType: any = TipoOrdemServicoEnum;

  ngOnInit() {
    this.tabOption = {
      selectedIndex: 0
    };
    this.appStateService.userInfoSubject.subscribe(user => {
      if (user) {
        this.pessoa = user.pessoaSelecionada;

        if (user.perfis.includes(PerfilEnum.suporte)) {
          this.suporte = true;
          this.ordemServicoFilter.idMunicipio = user.pessoaSelecionada.responsavel.id;
        } else if (user.perfis.includes(PerfilEnum.fiscal) || user.perfis.includes(PerfilEnum.gestor)) {
          this.ordemServicoFilter.idPessoa = user.pessoaSelecionada.id;
        } else {
          this.navigate("/home/access-denied")
        }

        this.carrega();
      }
    });
  }

  acionaAdicionar() {
    let ordermServicoItem: OrdemServicoItem = new OrdemServicoItem();

    ordermServicoItem.conteudo = this._.clone(this.conteudo);
    ordermServicoItem.data = new Date();
    ordermServicoItem.pessoa = this.pessoa;

    this.ordemServico.ordemServicoItemList.push(ordermServicoItem);

    this.conteudo = "";
  }

  acionaLimpar() {
    this.ordemServico = new OrdemServico();
    this.ordemServico.ordemServicoArquivoList = [];
    this.ordemServico.ordemServicoItemList = [];
  }

  @GlobalLoadingIndicator()
  acionaSalvar() {
    this.clearErrorMessages();

    if (!this.ordemServico.titulo) {
      this.addErrorMessage("O título da ordem de serviço é obrigatório.")
    }

    if (!this.ordemServico.tipoOrdemServico) {
      this.addErrorMessage("O tipo da ordem de serviço é obrigatório.")
    }

    if (!this.ordemServico.descricao) {
      this.addErrorMessage("A descrição da ordem de serviço é obrigatório.")
    }

    if (this.suporte) {
      if (this.ordemServico.situacaoOrdemServico == SituacaoOrdemServicoEnum.FECHADO) {
        this.addErrorMessage("Apenas o solicitante pode fechar a ordem de serviço.")
      }
    } else {
      if (this.ordemServico.situacaoOrdemServico == SituacaoOrdemServicoEnum.CONCLUIDO) {
        this.addErrorMessage("Apenas o suporte pode concluir a ordem de serviço.")
      }
    }

    if (!this.hasErrorMessages()) {
      this.anexoList.forEach(anexo => {
        let ordemServicoArquivo: OrdemServicoArquivo = new OrdemServicoArquivo();

        ordemServicoArquivo.anexo = new Arquivo();
        ordemServicoArquivo.anexo.id = anexo.id;

        if (!this.ordemServico.ordemServicoArquivoList) {
          this.ordemServico.ordemServicoArquivoList = [];
        }

        this.ordemServico.ordemServicoArquivoList.push(ordemServicoArquivo);
      })

      return this.of(this.ordemServicoService.salvar(this.ordemServico).pipe(mergeMap(() => {
        return this.carrega();
      })), () => {
        this.sucess = true;

        this.acionaLimpar();
      });
    }
  }

  adicionarAnexo(arquivo: Arquivo) {
    this.anexoList.push(arquivo);
  }

  carrega() {
    return this.of(this.ordemServicoService.buscarTO(this.ordemServicoFilter), ordemServicoTO => {
      this.ordemServicoTO = ordemServicoTO;

      this.disableLoading();
    });
  }

  @GlobalLoadingIndicator()
  removerAnexo(arquivo: Arquivo) {
    let i = this.anexoList.indexOf(arquivo);

    this.anexoList.splice(i, 1);

    let ordemServicoArquivoFilter: OrdemServicoArquivoFilter = new OrdemServicoArquivoFilter();

    ordemServicoArquivoFilter.idArquivo = arquivo.id;

    return this.of(this.ordemServicoArquivoService.remover(ordemServicoArquivoFilter), () => {});
  }

  onRowClick(event: any) {
    this.ordemServico = event.data;
    this.anexoList = [];
    this.ordemServico.ordemServicoArquivoList.forEach(ordemServicoArquivo => this.anexoList.push(ordemServicoArquivo.anexo));
    this.tabOption = {
      selectedIndex: 1
    };

  }

  url(arquivo: Arquivo, event: any) {
    event.preventDefault();

    return this.of(this.arquivoService.download("v2/rest/arquivo/download/" + arquivo.id), response => this.saveAs(response), () => this.addErrorMessage('Erro ao efetuar o download.'))
  }

}
