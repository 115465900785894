import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {convertNaturezaCadastroEnumUtils, NaturezaCadastroEnum} from "../../../../enums/natureza.cadastro.enum";

@Component({
  selector: "natureza-cadastro-drop-down",
  templateUrl: './natureza.cadastro.drop.down.component.html',
  styleUrls: ['./natureza.cadastro.drop.down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NaturezaCadastroDropDownComponent extends BaseComponent {

  private _itensSelecionados: NaturezaCadastroEnum[];

  naturezaCadastroEnumUtils: any = convertNaturezaCadastroEnumUtils();

  @Input()
  readOnly: boolean = false;

  @Output()
  naturezaChange:EventEmitter<NaturezaCadastroEnum[]> = new EventEmitter<NaturezaCadastroEnum[]>();


  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }


  get itensSelecionados(): NaturezaCadastroEnum[] {
    return this._itensSelecionados;
  }

  set itensSelecionados(value: NaturezaCadastroEnum[]) {
    this._itensSelecionados = value;
    this.naturezaChange.emit(this._itensSelecionados);
  }
}
