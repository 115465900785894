import {Component, EventEmitter, Injector, Input, Output} from "@angular/core";
import {BaseComponent} from "../base/base.component";
import {DateUtilsService} from "../../../core/services/date.utils.service";
import {getMesEnum, MesEnum, mesNumero} from "../../../../enums/mes.enum";

@Component({
  selector: 'competencia',
  templateUrl: './competencia.component.html',
  styleUrls: ['./competencia.component.scss'],
})
export class CompetenciaComponent extends BaseComponent {

  get ano(): number {
    return this._ano;
  }
  @Input()
  set ano(value: number) {
    this._ano = value;
    if(value){
      this.anoSelecionado = value.toString();
      if(this.mes || this.mes == 0){
        if((this.date && this.date.getFullYear()!=value) || !this.date){
          this.date = new Date(value,this.mes,1);
        }
      }
    }else{
      this.date = null;
    }
  }

  get date(): Date {
    return this._date;
  }

  @Input()
  set date(value: Date) {
    this._date = value;
    if (value && (!this.ano || !this.mes || this.ano != value.getFullYear() || this.mes != value.getMonth())) {
      this.ano = value.getFullYear();
      this.mes = value.getMonth();
      this.mesSelecionado = getMesEnum(this.mes);
      this.anoSelecionado = this.ano.toString();
    }
    this.dateChange.emit(value);
  }
  // @Input()
  // set date(value: Date) {
  //   this._date = value;
  //   if (value) {
  //     this.dateChange.emit(value);
  //   }
  // }

  get mes(): number {
    return this._mes;
  }

  @Input()
  set mes(value: number) {
    this._mes = value;
    if(value || value == 0){
      this.mesSelecionado = getMesEnum(value);
      if(this.ano){
        if((this.date && this.date.getMonth()!=value) || !this.date){
          this.date = new Date(this.ano,value,1);
        }
      }
    }else if (!value){
      this.date = null;
    }
  }

  private _ano: number;

  private _date: Date;

  private _mes: number;

  @Output()
  anoChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  mesChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private dateUtils: DateUtilsService,
              private inject: Injector) {
    super(inject);
  }

  anosList: string [] = this.dateUtils.anosString();

  anoSelecionado: string;

  mesEnumType: any = MesEnum;

  mesSelecionado: MesEnum;

  @Input()
  readOnly: boolean = false;

  @Input()
  showClearButton: boolean = false;


  alteraAno(ano: string) {
    if(ano){
      this.ano = +ano;
    }else{
      this.ano = null;
    }
    this.anoChange.emit(this.ano);
  }

  alterarMes(mes: MesEnum) {
    if(mes){
      if(this.mes != mesNumero(mes)){
        this.mes = mesNumero(mes);
        this.mesChange.emit(this.mes);
      }
    }else{
      this.mes = null;
      this.mesChange.emit(this.mes);
    }
  }

}
