import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {BancoDependencia} from "./banco.dependencia";
import {DesifApuracaoMensal} from "./desif.apuracao.mensal";

@JacksonType("DesifApuracaoMensalDependencia")
@JsonIdentityInfo()
export class DesifApuracaoMensalDependencia extends SonnerBaseEntity {

  id: number;

  @JacksonType("DesifApuracaoMensal")
  apuracaoMensal: DesifApuracaoMensal;

  contabilidadePropria: boolean;

  @DateType()
  dataFimParalizacao: Date;

  @DateType()
  dataInicioParalizacao: Date;

  @JacksonType("BancoDependencia")
  dependencia: BancoDependencia;

  enderecoDependencia: string;
}
