export enum ProcessoAssincronoFilaEnum {

  ENCERRAMENTO = "filaEncerramento",
  ENCERRAMENTO_LOTE = "filaEncerramentoLote",
  ENCERRAMENTO_AUTOMATICO = "filaEncerramentoAutomatico",
  EMAILS = "filaEmails",
  RELATORIOS = "filaRelatorio",
  ATIVIDADEDUPLICADA = "filaAtividadeDuplicada",
  GERA_AUTORIZACAO_NFE = "filaGeraAutorizacaoNfe",
  REPROCESSAMENTO_NOTAESCRIT = "filaReprocessamentoNotasEscrit",
  PROCESSA_CMD = "processaCmd",
  PROCESSA_DW = "processaDw",
  PROCESSA_CMD_PRIORIDADE = "processaCmdPrioridade",
  PROCESSA_CMD_BAIXA_PRIORIDADE = "processaCmdBaixaPrioridade",
  PROCESSA_CMD_IMPORTACAO_PGDAS = "processaCmdImportacaoPgdasd",
  IMPORTA_NOTAS = "filaImportaNotas",
  IMPORTA_EMPRESA_GISS = "filaImportaEmpresasGiss",
  IMPORTA_DESIF = "filaImportaDesif",
  RELATORIO_LIVRO_ANUAL = "filaLivroAnual"
}
