import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Atividade} from "../../../classes/atividade";
import {VinculoAtividade} from "../../../classes/vinculo.atividade";
import {VinculoAtividadeFilter} from "../filters/vinculo.atividade.filter";
import {AtividadeCTISS} from "../../../classes/atividade.c.t.i.s.s";

@Injectable()
export class VinculoAtividadeService {

  constructor(private restService: RestService) {
  }

  buscar(vinculoAtividadeFilter: VinculoAtividadeFilter): Observable<VinculoAtividade> {
    return this.restService.post("/vinculoatividade/buscar", {data: vinculoAtividadeFilter, responseType: VinculoAtividade});
  }

  buscarListaAtividadePorPessoa(vinculoAtividadeFilter?: VinculoAtividadeFilter): Observable<Atividade[]> {
    return this.restService.post("/vinculoatividade/buscarListaAtividadePorPessoa", {data:vinculoAtividadeFilter,responseType: Atividade});
  }

  quantidadePorAtivoEAtividade(vinculoAtividadeFilter: VinculoAtividadeFilter): Observable<number> {
    return this.restService.post("/vinculoatividade/quantidadePorAtivoEAtividade", {data: vinculoAtividadeFilter});
  }

  contribuintesAtividadeEAtivo(vinculoAtividadeFilter: VinculoAtividadeFilter): Observable<string[]> {
    return this.restService.post("/vinculoatividade/contribuintesAtividadeEAtivo", {data: vinculoAtividadeFilter});
  }

  atividadeCTISSPorPessoa(): Observable<AtividadeCTISS[]> {
    return this.restService.post("/vinculoatividade/atividadeCTISSPorPessoa", {responseType: AtividadeCTISS});
  }
}
