import {JacksonType} from "@sonner/jackson-service-v2";
import {Serie} from "../serie";
import {AutorizacaoNFe} from "../autorizacao.n.fe";
import {SolicitacaoNFe} from '../solicitacao.n.fe';
import {SolicitacaoArquivoTO} from "./solicitacao.arquivo.to";

@JacksonType("SolicitacaoNFeTO")
export class SolicitacaoNFeTO {

  id: string;

  @JacksonType("Serie")
  serie: Serie;

  @JacksonType("AutorizacaoNFe")
  autorizacao: AutorizacaoNFe;

  @JacksonType("SolicitacaoNFe")
  anteriores: SolicitacaoNFe[];

  @JacksonType("SolicitacaoArquivoTO")
  arquivos: SolicitacaoArquivoTO[];

  declarada: number;

  emitida: number;

  naoPermiteEditarDataFim: boolean = false;

  observacao: string;

}
