import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("PessoaSimplesTO")
export class PessoaSimplesTO {

  idPessoa: number;

  nome: string[];

  documento: string[];

  inscricaoMunicipal: string[];

  mobiliario: string[];

  inconsistenciasList: string[];

}
