import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("MonitorNotaPremiadaPrestadoresTO")
export class MonitorNotaPremiadaPrestadoresTO {

  nome: string;

  documento: string;

  quantidade: number;

}
