import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("DadosEndereco")
export class DadosEndereco {

  bairro: string;

  cep: string;

  complemento: string;

  endereco: string;

  estado: string;

  municipio: string;

  numero: string;

  pais: string = "Brasil";

  telefone: string;

  enderecoFormatado() {
    let str = this.endereco + ", " + this.numero;

    if (this.complemento != null && this.complemento.length > 0) {
      str = str + ", " + this.complemento;
    }

    return str;
  }
}
