import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {EscritPapel} from "./escrit.papel";
import {Municipio} from "./municipio";
import {NotaFiscal} from "./nota.fiscal";
import {Pessoa} from "./pessoa";

@JacksonType("PagamentoSIAFI")
@JsonIdentityInfo()
export class PagamentoSIAFI extends SonnerBaseEntity {

  id: number;

  aliquotaNota: number;

  ano: number;

  arquivoRemssa: string;

  @DateType()
  dataEmissaoNota: Date;

  @DateType()
  dataPrestacaoNota: Date;

  @DateType()
  dataProcessamento: Date;

  @DateType()
  emissao: Date;

  @JacksonType("EscritPapel")
  escritPapel: EscritPapel;

  idGrp: number;

  juros: number;

  @JacksonType("NotaFiscal")
  notaFiscal: NotaFiscal;

  numeroDocumento: string;

  numeroNota: string;

  mes: number;

  multa: number;

  @JacksonType("Municipio")
  municipioEmissaoNota: Municipio;

  obsNota: string;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @JacksonType("Pessoa")
  prestador: Pessoa;

  valorBaseNota: number;

  valorNota: number;

  valorPrincipal: number;

  @DateType()
  vencimento: Date;

  serieNota: string;
}
