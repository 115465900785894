import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {ConfigMunicipio} from "../../../classes/config.municipio";
import {ConfigBoletoFilter} from "../filters/config.boleto.filter";
import {ConfigBoleto} from "../../../classes/config.boleto";

@Injectable()
export class ConfigBoletoService {

  constructor(private restService: RestService){
  }

  buscar(configBoletoFilter: ConfigBoletoFilter): Observable<ConfigBoleto> {
    return this.restService.post("/configboleto/buscar", {data: configBoletoFilter, responseType: ConfigMunicipio});
  }

  verificarPorMunicipio(configMunicipioFilter: ConfigBoletoFilter): Observable<boolean> {
    return this.restService.post("/configboleto/verificarPorMunicipio", {data: configMunicipioFilter});
  }
}
