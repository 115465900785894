import {Municipio} from "./municipio";
import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("LoteTre")
@JsonIdentityInfo()
export class LoteTre extends SonnerBaseEntity {

  id: number;

  lote: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  remessa: string;
}
