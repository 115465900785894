import { Injectable } from "@angular/core";

@Injectable()
export class CookieService {

  public getCookie(name: string) {
    const cookie: Array<string> = document.cookie.split(";");
    const lenght: number = cookie.length;
    const cookieName = `${name}=`;
    let temp: string;

    for (let i = 0; i < lenght; i += 1) {
      temp = cookie[i].replace(/^\s+/g, "");

      if (temp.indexOf(cookieName) === 0) {
        return temp.substring(cookieName.length, temp.length);
      }
    }

    return "";
  }

  public removeCookie(cookieName, path) {
    this.expiresNow(cookieName, path);
  }

  public setCookie(name: string, value: any, expires: Date, path: string) {
    const expiresOn = `expires=${expires.toUTCString()}`;

    const cpath = path ? `; path=${path}` : "";

    document.cookie = `${name}=${value}; ${expiresOn}${cpath}`;
  }

  public expiresNow(cookieName: string, path: string) {
    const expiresOn = `expires=Thu, 01 Oct 2020 03:00:01 GMT`;

    const cpath = path ? `; path=${path}` : "";

    document.cookie = `${cookieName}=""; ${expiresOn}${cpath}`;
  }
}
