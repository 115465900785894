import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("ExigibilidadePadrao")
@JsonIdentityInfo()
export class ExigibilidadePadrao extends SonnerBaseEntity {

  id: number;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  @JacksonType("Municipio")
  municipio: Municipio;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;
}
