import {JacksonType, MapType} from "@sonner/jackson-service-v2";
import {MonitorMunicipioTabelaTO} from "./monitor.municipio.tabela.to";

@JacksonType("MonitorMunicipioTO")
export class MonitorMunicipioTO {

  titulo: string;

  competenciaList: string[];

  @MapType()
  competenciaMap: Map<string, number[]>;

  hintList: string[];

  @JacksonType("MonitorMunicipioTabelaTO")
  tabelaList: MonitorMunicipioTabelaTO[];
}
