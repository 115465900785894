export enum TipoDocumentoEnum {

  NF = "Nota Fiscal Eletrônica de Serviços",
  ESCRITPAPEL = "Escrituração de Nota Fiscal",
  AIDF = "Autorização de Impressão de Documentos Fiscais",
  GUIAAVULSA = "Guia de Pagamento",
  ANFE = "Autorização de Emissão de Nota Fiscal Eletrônica",
  AIMPORTNFE = "Autorização de Emissão de Recibo Provisório de Serviço",
  ENCERRAMENTO = "Encerramento de Escrituração",
  CREDITO = "Crédito Tributário",
  DEBITO = "Débito Tributário",
  Convite = "Convite de Acesso",
  Livro = "Livro Fiscal",
  Pagamento = "Pagamento",
  RECIBO = "Recibo",
  RECIBOLOCACAO = "Recibo de Locação"
}

export function abreviacao(tipoDocumento: TipoDocumentoEnum): string {

  if (tipoDocumento == TipoDocumentoEnum.NF) {
    return "NFS-e";
  } else if (tipoDocumento == TipoDocumentoEnum.ESCRITPAPEL) {
    return "Escrituração";
  } else if (tipoDocumento == TipoDocumentoEnum.AIDF) {
    return "A.I.D.F.";
  } else if (tipoDocumento == TipoDocumentoEnum.ANFE) {
    return "Aut. NFS-e";
  } else if (tipoDocumento == TipoDocumentoEnum.ENCERRAMENTO) {
    return "Encerramento";
  }else {
    return tipoDocumento.toString();
  }
}

export function interno(tipoDocumento: TipoDocumentoEnum): boolean {

  if (tipoDocumento == TipoDocumentoEnum.Convite) {
    return true;
  } else  {
    return false;
  }
}
