import {JacksonType} from "@sonner/jackson-service-v2";
import {MonitorNotaPremiadaPrestadoresTO} from "./monitor.nota.premiada.prestadores.to";

@JacksonType("MonitorNotaPremiadaTO")
export class MonitorNotaPremiadaTO {

  id: number;

  quantidadeNotas: number;

  quantidadeBilhetes: number;

  quantidadeContribuintes: number;

  descricao: string;

  @JacksonType("MonitorNotaPremiadaPrestadoresTO")
  listPrestadores: MonitorNotaPremiadaPrestadoresTO[];

}
