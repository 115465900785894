import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Competencia} from "./competencia";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";

@JacksonType("DeclaracaoIncorretaSimples")
@JsonIdentityInfo()
export class DeclaracaoIncorretaSimples extends SonnerBaseEntity {

  id: number;

  baseCalculoNaoRetidoGov: number;

  baseCalculoNaoRetidoSimples: number;

  baseCalculoRetidoGov: number;

  baseCalculoRetidoSimples: number;

  baseCalculoNaoServicoSimples: number;

  @JacksonType("Competencia")
  competencia: Competencia;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  valorNaoRetido: number;

  valorRetido: number;
}
