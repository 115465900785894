import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("NotaEnsinoAlunoTO")
export class NotaEnsinoAlunoTO{

  nome: string;

  nomeResponsavel: string;

  senhaNota: string;

  erro: string;

}
