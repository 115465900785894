import {JacksonType, JsonIdentityInfo, JsonIgnore} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Currency} from "./currency";
import {NotaFiscal} from "./nota.fiscal";
import {SituacaoItem} from "./situacao.item";
import {UnidadeItem} from "./unidade.item";
import {ReciboLocacao} from "./recibo.locacao";

@JacksonType("ItemReciboLocacao")
@JsonIdentityInfo()
export class ItemReciboLocacao extends SonnerBaseEntity {

  id: number;

  descricao: string;

  @JacksonType("ReciboLocacao")
  reciboLocacao: ReciboLocacao;

  valor: number;

}
