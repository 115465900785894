import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {NotaFiscal} from "./nota.fiscal";
import {Pessoa} from "./pessoa";
import {TipoCreditoTomadorConfigEnum} from "../enums/tipo.credito.tomador.config.enum";

@JacksonType("CreditoTomador")
@JsonIdentityInfo()
export class CreditoTomador extends SonnerBaseEntity {

  id: number;

  anoCompetencia: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  emissao: Date;

  mesCompetencia: number;

  @JacksonType("NotaFiscal")
  notaOrigem: NotaFiscal;

  @EnumType(TipoCreditoTomadorConfigEnum)
  tipoCredito: TipoCreditoTomadorConfigEnum;

  valor: number;
}
