import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";

@JacksonType("AtividadeHistorico")
@JsonIdentityInfo()
export class AtividadeHistorico extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @DateType()
  dataAlteracao: Date;

  @DateType()
  dataValidade: Date;
}
