import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("ConfiguracaoPessoal")
@JsonIdentityInfo()
export class ConfiguracaoPessoal extends SonnerBaseEntity {

  id: number;

  aliquotaTomador: number;

  bloqueiaEmissaoGuiaAvulsa: boolean = false;

  bloqueiaEmissaoGuiaValorFixo: boolean = false;

  bloqueioTomadorNfe: boolean = false;

  denunciaEspCompetenciasAutorizadas: string = "";

  destacaEmailNota: boolean = false;

  escrituracaoEspecial: boolean = false;

  escrituracaoEspecialDesconto: boolean = false;

  escrituracaoEspecialIndividual: boolean = false;

  escrituracaoEspecialQuantidade: boolean = false;

  exibeCondicaoPagamentoNota: boolean = false;

  exibirValorUnitarioQuantidade: boolean = true;

  ignoraAlertaFaltaPagamento: boolean = false;

  ignoraConfiguracaoSubstitutoTributario: boolean = false;

  isentaGuiaTomador: boolean = false;

  naoEncerraAutomaticamente: boolean = false;

  percentualDescontoEncerramento: number;

  permiteAlterarRetencaoSubstNFe: boolean = false;

  permiteEditarDataPrestacaoNFe: boolean = false;

  permiteEmissaoGuiaAvulsa: boolean = false;

  permiteEmissaoGuiaValorFixo: boolean = false;

  permiteEmissaoNFSemTomador: boolean = false;

  permiteEmissaoNFTomadorIgualPrestador: boolean = false;

  permiteEmissaoNotaAvulsa: boolean = false;

  permiteEmissaoNotasCompetenciaForaOrdem: boolean = false;

  permiteEscriturarPrestadorOutroMunicipio: boolean = false;

  permiteRetencaoContribuinteEventual: boolean = false;

  permiteRetencaoProprioMunicipio: boolean = false;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  possuiImpressoraFiscal: boolean = false;

  quantidadeNotaAvulsaAno: number;

  recebeEmailPrestadorNotaFiscal: boolean = true;

  recebeEmailTomadorNotaFiscal: boolean = true;

  permiteGuiaAvulsaCompetenciaEnc: boolean = false;

  naoCarregarNotasNaSubstituicaoDeNota: boolean = false;

  diaEncerramento: number;

  utilizaDataLiquidacao: boolean;
}
