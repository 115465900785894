import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {TipoTributacao} from "../../../classes/tipo.tributacao";
import {TipoTributacaoFilter} from "../filters/tipo.tributacao.filter";

@Injectable()
export class TipoTributacaoService {

  constructor(private restService: RestService){
  }

  buscar(tipoTributacaoFilter: TipoTributacaoFilter): Observable<TipoTributacao> {
    return this.restService.post("/tipotributacao/buscar", {data: tipoTributacaoFilter, responseType: TipoTributacao});
  }

  buscarListaTipoTributacaoPorMunicipio(): Observable<TipoTributacao> {
    return this.restService.post("/tipotributacao/buscarListaTipoTributacaoPorMunicipio", {responseType: TipoTributacao});
  }

  salvar(tipoTributacaoList: TipoTributacao[]): Observable<any> {
    return this.restService.post("/tipotributacao/salvar", {data: tipoTributacaoList})
  }
}
