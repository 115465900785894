import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Competencia} from "./competencia";
import {Municipio} from "./municipio";
import {NotaFiscal} from "./nota.fiscal";

@JacksonType("NotaPremiada")
@JsonIdentityInfo()
export class NotaPremiada extends SonnerBaseEntity {

  id: number;

  @JacksonType("Competencia")
  competencia: Competencia;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("NotaFiscal")
  notaFiscal: NotaFiscal;

  numeroPremiacao: number;
}
