import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("ConfigLivro")
@JsonIdentityInfo()
export class ConfigLivro extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  prestadorCapa: string = "Contém esta encadernação {{ pageCountFmt }} folhas numeradas sequencialmente pelo processo eletrônico de dados totalmente escrituradas, de 1 a {{ pageCountFmt }} e que servirá de Livro de Registro de Serviços Prestados {{ refLeiLivroPrestador }}, onde estão registradas todas as Notas Fiscais de Serviços Prestados pela Empresa abaixo qualificada.";

  prestadorFundo: string = "Contém esta encadernação {{ pageCountFmt }} folhas numeradas sequencialmente pelo processo eletrônico de dados totalmente escrituradas, de 1 a {{ pageCountFmt }} e serviu para o lançamento de todas as Notas Fiscais de Serviços Prestados pela Empresa abaixo qualificada.";

  rotulo1: string = "Empresa:";

  rotulo2: string = "Endereço:";

  rotulo3: string = "Bairro:";

  rotulo4: string = "Cidade:";

  rotulo5: string = "CNPJ:";

  rotulo6: string = "Insc. Mun.:";

  tomadorCapa: string = "Contém esta encadernação {{ pageCountFmt }} folhas numeradas sequencialmente pelo processo eletrônico de dados totalmente escrituradas, de 1 a {{ pageCountFmt }} e que servirá de Livro de Registro de Serviços Tomados {{ refLeiLivroTomador }}, onde estão registradas todas as Notas Fiscais de Serviços Tomados pela Empresa abaixo qualificada.";

  tomadorFundo: string = "Contém esta encadernação {{ pageCountFmt }} folhas numeradas sequencialmente pelo processo eletrônico de dados totalmente escrituradas, de 1 a {{ pageCountFmt }} e serviu para o lançamento de todas as notas fiscais de serviços tomados pela empresa abaixo qualificada.";

  valor1: string = "{{ contribuinte }}";

  valor2: string = "{{ endereco }}";

  valor3: string = "{{ bairro }}";

  valor4: string = "{{ cidade }}";

  valor5: string = "{{ documento }}";

  valor6: string = "{{ inscMun }}";
}
