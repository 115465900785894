import {EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {EscritPapel} from "./escrit.papel";
import {GuiaAvulsa} from "./guia.avulsa";
import {NotaFiscal} from "./nota.fiscal";
import {Pessoa} from "./pessoa";
import {TipoReciboEnum} from "../enums/tipo.recibo.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";

@JacksonType("Recibo")
@JsonSubType({key: 'br.com.sonner.iss.entity.Recibo', type: "Documento"})
export class Recibo extends Documento {

  constructor() {
    super(TipoDocumentoEnum.RECIBO);
  }

  @JacksonType("EscritPapel")
  escritPapel: EscritPapel;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  @JacksonType("NotaFiscal")
  notaFiscal: NotaFiscal;

  observacao: string;

  @JacksonType("Pessoa")
  prestador: Pessoa;

  @JacksonType("Pessoa")
  tomador: Pessoa;

  @EnumType(TipoReciboEnum)
  tipo: TipoReciboEnum;
}
