import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {Serie} from "../serie";
import {AutorizacaoNFe} from "../autorizacao.n.fe";
import {SolicitacaoNFe} from '../solicitacao.n.fe';
import {SolicitacaoArquivoTO} from "./solicitacao.arquivo.to";
import {TipoEncerramentoEnum} from "../../enums/tipo.encerramento.enum";
import {PapelServicoEnum} from "../../enums/papel.servico.enum";
import {Papel} from "../papel";

@JacksonType("EncerramentoSimplificadoTO")
export class EncerramentoSimplificadoTO {

  id: string;

  @DateType()
  data: Date;

  competencia: string;

  @EnumType(TipoEncerramentoEnum)
  tipo: TipoEncerramentoEnum;

  valor: number;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  senha: string;

  senhaGuia: string;

  senhaGuiaFmt: string;

  guiaBaixada: boolean;

  guiaInscrita: boolean;

}
