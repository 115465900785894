import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {FiscalMenuFilter} from "../filters/fiscal.menu.filter";
import {FiscalMenuTO} from "../../../classes/to/fiscal.menu.to";

@Injectable()
export class FiscalMenuService {

  constructor(private restService: RestService) {
  }

  buscar(fiscalMenuFilter: FiscalMenuFilter): Observable<FiscalMenuTO> {
    return this.restService.post("/fiscalmenu/buscar", {data: fiscalMenuFilter, responseType: FiscalMenuTO});
  }
}
