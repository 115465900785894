import {EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {PapelServicoEnum} from "../../enums/papel.servico.enum";
import {TipoCredito} from "../tipo.credito";

@JacksonType("CreditoTO")
export class CreditoTO {

  id: number;

  senha:string;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  @JacksonType("TipoCredito")
  tipoCredito: TipoCredito;

  ano: number;

  mes: number;

  valor: number;

  obs:string;

  emissor:string;

  encerrado: boolean;

  competencia() {
    return (this.mes + 1) + "/" + this.ano;
  }

}
