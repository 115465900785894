import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("Arquivo")
@JsonIdentityInfo()
export class Arquivo extends SonnerBaseEntity {

  id: number;

  bucketKey: string;

  bucketName: string;

  contentType: string;

  @DateType()
  ctime: Date;

  fileName: string;
}
