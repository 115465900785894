import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";

@JacksonType("TNCPropriedade")
@JsonIdentityInfo()
export class TNCPropriedade {

  id: number;

  nome: string;

  valor: string;
}
