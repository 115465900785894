import {Injectable} from "@angular/core";
import {RestService} from "../../core/services/rest.service";
import {Observable} from "rxjs";
import {GrupoAtividade} from "../../../classes/grupo.atividade";
import {GrupoAtividadeFilter} from "../filters/grupo.atividade.filter";

@Injectable()
export class GrupoAtividadeService {

  constructor(private restService: RestService) {
  }

  buscar(grupoAtividadeFilter: GrupoAtividadeFilter): Observable<GrupoAtividade> {
    return this.restService.post("/grupoatividade-v2/buscar", {data: grupoAtividadeFilter, responseType: GrupoAtividade});
  }

  buscarListaPorAtivaEMunicipio(grupoAtividadeFilter?: GrupoAtividadeFilter): Observable<GrupoAtividade[]> {
    return this.restService.post("/grupoatividade-v2/buscarListaPorAtivaEMunicipio", {data: grupoAtividadeFilter, responseType: GrupoAtividade});
  }

  salvar(grupoAtividade: GrupoAtividade): Observable<any> {
    return this.restService.post("/grupoatividade-v2/salvar", {data: grupoAtividade});
  }

  remover(grupoAtividadeFilter: GrupoAtividadeFilter): Observable<any> {
    return this.restService.post("/grupoatividade-v2/remover", {data: grupoAtividadeFilter});
  }
}
