import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("Duvida")
@JsonIdentityInfo()
export class Duvida extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  autor: Pessoa;

  @DateType()
  dataPergunta: Date;

  @DateType()
  dataResposta: Date;

  pergunta: string;

  resposta: string;
}
