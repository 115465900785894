import {JacksonType} from "@sonner/jackson-service-v2";
import {CompetenciaDimensao} from "../competencia.dimensao";
import {AlertaFiscal} from "../alerta.fiscal";

@JacksonType("ConfigNotaPremiadaPeriodoTO")
export class ConfigNotaPremiadaPeriodoTO {

  id: number;

  descricao: string;

  quantidadeGanhador: number;

}
