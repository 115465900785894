import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("Tarefa")
@JsonIdentityInfo()
export class Tarefa extends SonnerBaseEntity {

  id: number;

  nome: string;

  progresso: number;
}
