import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {DesifVersaoEnum} from "../enums/desif.versao.enum";

@JacksonType("DesifCodigoTributacao")
@JsonIdentityInfo()
export class DesifCodigoTributacao extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  codigo: string;

  codigoLC116: string;

  descricao: string;

  @EnumType(DesifVersaoEnum)
  versaoDesif: DesifVersaoEnum;

  codigoFormatado() {
    if (this.codigo == null) {
      return null;
    }

    if (this.codigo.length < 8) {
      return this.codigo;
    }

    return this.codigo.substring(0, 2) + "." + this.codigo.substring(2, 4) + "." + this.codigo.substring(4, 7) + "-" + this.codigo.substring(8, 9);
  }

  getDisplayValue(): string {
    return this.codigoFormatado() + " " + this.descricao + " (" + this.codigoLC116 + ")";
  }
}
