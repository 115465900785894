import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ImportacaoPGDASD} from "./importacao.p.g.d.a.sd";
import {ParcelamentoSimplesConsolidacao} from "./parcelamento.simples.consolidacao";

@JacksonType("ParcelamentoSimplesDebito")
@JsonIdentityInfo()
export class ParcelamentoSimplesDebito extends SonnerBaseEntity {

  id: number;

  anoCompetencia: number;

  @JacksonType("ParcelamentoSimplesConsolidacao")
  consolidacao: ParcelamentoSimplesConsolidacao;

  cnpj: string;

  @JacksonType("ImportacaoPGDASD")
  importacao: ImportacaoPGDASD;

  mesCompetencia: number;

  numeroProcesso: string;

  parcelamento: number;

  @DateType()
  periodoApuracao: Date;

  valorAtualizado: number;

  valorOriginal: number;

  @DateType()
  vencimento: Date;
}
