import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {PessoaSimplificadaTO} from "../../../classes/to/pessoa.simplificada.to";
import {SituacaoSolicitacaoEnum} from "../../../enums/situacao.solicitacao.enum";
import {TipoSolicitacaoEnum} from "../../../enums/tipo.solicitacao.enum";

@JacksonType("AtendimentoFilter")
export class AtendimentoFilter {

  @DateType()
  dataInicial: Date;

  @DateType()
  dataFinal: Date;

  nome: string;

  documento: string;

  grpMobiliario: string;

  @JacksonType("PessoaSimplificadaTO")
  fiscal: PessoaSimplificadaTO;

  @EnumType(SituacaoSolicitacaoEnum)
  situacao: SituacaoSolicitacaoEnum;

  @EnumType(TipoSolicitacaoEnum)
  tipos: TipoSolicitacaoEnum[];

}
