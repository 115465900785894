import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "simNao"})
export class SimNaoPipe implements PipeTransform {

  transform(booleanValue: boolean): any {
    if (booleanValue) {
      return 'Sim';
    }

    return 'Não';
  }
}
