import {JacksonType} from "@sonner/jackson-service-v2";
import {TipoAbatimentoObra} from "../tipo.abatimento.obra";

@JacksonType("ConfiguracaoConstrucaoCivilTO")
export class ConfiguracaoConstrucaoCivilTO {

  idMunicipio: number;

  necessitaAuthParaAbaterObra: boolean = false;

  chaveAcessoObrigatorio: boolean = false;

  permiteAdicionarNotas: boolean = false;

  naoControlaSaldoAbatimentoPadrao: boolean = false;

  @JacksonType("TipoAbatimentoObra")
  tipoAbatimentoObraList: TipoAbatimentoObra[] = [];
}
