import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {PerfilEnum} from "../../../../enums/perfil.enum";
import {Municipio} from "../../../../classes/municipio";
import {Pessoa} from "../../../../classes/pessoa";
import {Fiscal} from "../../../../classes/fiscal";
import {Contador} from "../../../../classes/contador";
import {Funcionario} from "../../../../classes/funcionario";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {SessionFilter} from "../../../core/filters/session.filter";
import {SessionService} from "../../../core/services/session.service";
import {AppShellService} from "../../services/app.shell.service";
import {InicialMenuTO} from "../../../../classes/to/inicial.menu.to";
import {InicialMenuFilter} from "../../filters/inicial.menu.filter";
import {InicialMenuService} from "../../services/inicial.menu.service";
import {mergeMap} from "rxjs/operators";
import {AppStateService} from "../../../app/services/app.state.service";
import {ExportService} from "../../../commons/services/export.service";
import {Comunicado} from "../../../../classes/comunicado";

@Component({
  selector: 'inicial-menu-page',
  templateUrl: './inicial.menu.page.component.html',
  styleUrls: ['./inicial.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InicialMenuPageComponent extends BaseComponent {

  constructor(private appShellService: AppShellService,
              private appStateService: AppStateService,
              private exportService: ExportService,
              private inicialMenuService: InicialMenuService,
              private injector: Injector,
              private sessionService: SessionService) {
    super(injector);

    this.onRowClick = this.onRowClick.bind(this);
  }

  contador: boolean = false;

  fiscal: boolean = false;

  funcionario: boolean = false;

  inicial_exibeContatoMunicipio: boolean = false;

  inicialMenuTO: InicialMenuTO = new InicialMenuTO();

  mensagem: boolean = false;

  municipio: Municipio = new Municipio();

  pessoa: Pessoa = new Pessoa();

  pessoaSelecionada: Pessoa = new Pessoa();

  search: string;

  ngOnInit(): void {
    this.appStateService.userInfoSubject.subscribe(user => {
      if (user) {
        this.municipio = user.pessoaUsuario.responsavel;
        this.pessoa = user.pessoaUsuario;
        this.pessoaSelecionada = user.pessoaSelecionada;
      }
    });

    this.sessionService.buscarUserInfo().subscribe(user => {
      if (this.municipio) {
        this.municipio = user.pessoaUsuario.responsavel;
      }

      if (this.pessoa) {
        this.pessoa = user.pessoaUsuario;
      }

      if (this.pessoaSelecionada) {
        this.pessoaSelecionada = user.pessoaSelecionada;
      }

      if (user) {
        if (user.perfis) {
          if (user.perfis.includes(PerfilEnum.contador)) {
            this.contador = true;
          }

          if (user.perfis.includes(PerfilEnum.fiscal)) {
            this.fiscal = true;
          }

          if (user.perfis.includes(PerfilEnum.funcionario)) {
            this.funcionario = true;
          }
        }

        if (user.authRegex.includes("iss:inicial:exibeContatoMunicipio")) {
          this.inicial_exibeContatoMunicipio = true;
        }

        this.carrega();
      }
    });
  }

  carrega() {
    this.of(this.inicialMenuService.buscar(this.configuraFiltro()), inicialMenuTO => {
      this.inicialMenuTO = inicialMenuTO;

      if ((this.inicialMenuTO.alertaList && this.inicialMenuTO.alertaList.length > 0) || (this.inicialMenuTO.aliquotaSimples && this.inicialMenuTO.aliquotaSimples.length > 0) || (this.inicialMenuTO.autorizacaoList && this.inicialMenuTO.autorizacaoList.length > 0) || (this.inicialMenuTO.bloqueioList && this.inicialMenuTO.bloqueioList.length > 0) || (this.inicialMenuTO.comunicadoList && this.inicialMenuTO.comunicadoList.length > 0)) {
        this.mensagem = true;
      }

      this.disableLoading();
    });
  }

  competencia(ano: number, mes: number) {
    return new Date(ano, mes);
  }

  configuraFiltro() {
    let inicialMenuFilter: InicialMenuFilter = new InicialMenuFilter();

    this.pessoa.papeis.forEach(papel => {
      if (papel instanceof Contador) {
        inicialMenuFilter.idContador = papel.id;
      }

      if (papel instanceof Fiscal) {
        inicialMenuFilter.idFiscal = papel.id;
      }

      if (papel instanceof Funcionario) {
        inicialMenuFilter.idFuncionario = papel.ator.id;
      }
    })

    return inicialMenuFilter;
  }

  @GlobalLoadingIndicator()
  onRowClick(evento: any) {
    if (evento && evento.data.id != this.pessoaSelecionada.id) {
      this.mensagem = false;

      let sessionFilter: SessionFilter = new SessionFilter();

      sessionFilter.idPessoa = evento.data.id;

      return this.of(this.sessionService.alterarPessoaSelecionada(sessionFilter).pipe(mergeMap(() => {
        this.appShellService.refreshUserInfo(false);

        return this.inicialMenuService.buscar(this.configuraFiltro());
      })), inicialMenuTO => {
        this.inicialMenuTO = inicialMenuTO;

        if ((this.inicialMenuTO.alertaList && this.inicialMenuTO.alertaList.length > 0) || (this.inicialMenuTO.aliquotaSimples && this.inicialMenuTO.aliquotaSimples.length > 0) || (this.inicialMenuTO.autorizacaoList && this.inicialMenuTO.autorizacaoList.length > 0) || (this.inicialMenuTO.bloqueioList && this.inicialMenuTO.bloqueioList.length > 0) || (this.inicialMenuTO.comunicadoList && this.inicialMenuTO.comunicadoList.length > 0)) {
          this.mensagem = true;
        }
      });
    }
  }

  url(data: any) {
    return '/home/actions/pessoa?id=' + data.id;
  }

  alteraSearch(evento: any) {
    let search = evento.replace(/[.\-\/]/g, "");

    if ((/^[0-9]+$/).test(search)) {
      this.search = search;
    } else {
      this.search = evento;
    }
  }

  imprimirSelecaoContribuinte(tipo: string) {
    this.exportService.relatorio("/app/inicial!imprimir.action?tipo="+tipo+"&relatorio=selecao-contribuinte").subscribe(response => this.saveAs(response));
  }

  @GlobalLoadingIndicator()
  confirmaComunicado(index: any) {
    if(this.inicialMenuTO.comunicadoList){
      let comunicado: Comunicado = this.inicialMenuTO.comunicadoList[index];
      if(!this.inicialMenuTO.comunicadoLido.has(comunicado.id)){
        return this.of(this.inicialMenuService.confirmarLeitura(comunicado.id), () => this.inicialMenuTO.comunicadoLido.add(comunicado.id));
      }
    }
  }
}
