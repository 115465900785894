import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TNCSyncScript} from "./t.n.c.sync.script";

@JacksonType("TNCAgendamento")
@JsonIdentityInfo()
export class TNCAgendamento extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("TNCSyncScript")
  script: TNCSyncScript;

  ativo: boolean = false;

  apenasUmaVez: boolean = false;

  frequencia: number;

  email: string;

}
