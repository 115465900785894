import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {BancoDependencia} from "./banco.dependencia";
import {DesifApuracaoMensal} from "./desif.apuracao.mensal";
import {DesifCodigoTributacao} from "./desif.codigo.tributacao";
import {DesifConta} from "./desif.conta";

@JacksonType("DesifApuracaoMensalSubtitulo")
@JsonIdentityInfo()
export class DesifApuracaoMensalSubtitulo extends SonnerBaseEntity {

  id: number;

  @JacksonType("DesifApuracaoMensal")
  apuracao: DesifApuracaoMensal;

  @JacksonType("DesifConta")
  conta: DesifConta;

  @JacksonType("DesifCodigoTributacao")
  codigoTributacao: DesifCodigoTributacao;

  descricaoDeducao: string;

  @JacksonType("BancoDependencia")
  dependencia: BancoDependencia;

  descricaoIncentivoFiscal: string;

  linhaOrigem: number;

  motivoExigibilidade: string;

  processoExigibilidade: string;

  valorAliquota: number;

  valorBaseCalculo: number;

  valorCreditoMensal: number;

  valorDebitoMensal: number;

  valorDeducao: number;

  valorIncentivoFiscal: number;

  valorReceitaTributavel: number;
}
