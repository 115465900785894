import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {AtividadeService} from "../../services/atividade.service";
import {GrupoAtividadeService} from "../../services/grupo.atividade.service";
import {VinculoAtividadeService} from "../../services/vinculo.atividade.service";

@Component({
  selector: "anexo-simples-selector",
  templateUrl: './anexo.simples.selector.component.html',
  styleUrls: ['./anexo.simples.selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnexoSimplesSelectorComponent extends BaseComponent {

  @Input()
  valor: number;

  @Output()
  anexoChange:EventEmitter<number> = new EventEmitter<number>();

  anexosSimples: any[] = [{label:'III', valor: 3}, {label:'IV', valor: 4}, {label:'V', valor: 5}];

  constructor(private injector: Injector,
              private atividadeService: AtividadeService,
              private vinculoAtividadeService: VinculoAtividadeService,
              private grupoAtividadeService: GrupoAtividadeService) {
    super(injector);
  }


  alteraAnexo(anexo: number) {
    this.anexoChange.emit(anexo);
  }
}
