import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Arquivo} from "./arquivo";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {OrigemEnum} from "../enums/origem.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";

@JacksonType("Documento")
@JsonIdentityInfo()
export class Documento extends SonnerBaseEntity {

  id: number;

  @JacksonType("Arquivo")
  arquivo: Arquivo;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataAlteracao: Date;

  @DateType()
  dataCancelamento: Date;

  @DateType()
  dataValidade: Date;

  @DateType()
  emissao: Date;

  emissaoMillis: number;

  @JacksonType("Pessoa")
  emissor: Pessoa;

  erroExp: string;

  erroImp: string;

  grpProcessado: boolean = false;

  interno: boolean = false;

  motivoCancelamento: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  @EnumType(OrigemEnum)
  origem: OrigemEnum;

  readOnly: boolean = false;

  senha: string;

  @EnumType(TipoDocumentoEnum)
  tipoDocumento: TipoDocumentoEnum;

  @DateType()
  ultimaImp: Date;

  @DateType()
  ultimaExp: Date;

  @DateType()
  ultimaVerificacao: Date;

  constructor(tipoDocumento?: TipoDocumentoEnum) {
    super();

    this.tipoDocumento = tipoDocumento;
  }

  descricao(): string {
    return this.tipoDocumento;
  }

  senhaFormatada() {
    if (!this.senha) {
      return "";
    }

    if (this.senha.length < 16) {
      return this.senha;
    }

    if (this.senha.length == 24 && this.senha.match("[^-]{4}-[^-]{4}-[^-]{4}-[^-]{4}-[^-]{4}")) {
      return this.senha;
    }

    let senha = this.senha.substring(0, 16);

    return (senha.substring(0, 4) + '.' + senha.substring(4, 8) + '.' + senha.substring(8, 12) + '.' + senha.substring(12)).toUpperCase();
  }
}
