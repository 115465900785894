import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {DesifCodigoTributacao} from "./desif.codigo.tributacao";
import {Municipio} from "./municipio";

@JacksonType("DesifCodigoTributacaoAtividade")
@JsonIdentityInfo()
export class DesifCodigoTributacaoAtividade extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("DesifCodigoTributacao")
  codigoTributacao: DesifCodigoTributacao;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  @JacksonType("Municipio")
  municipio: Municipio;
}
