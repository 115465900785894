import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AtividadeDimensao} from "./atividade.dimensao";
import {DiaDimensao} from "./dia.dimensao";
import {CompetenciaDimensao} from "./competencia.dimensao";
import {Municipio} from "./municipio";
import {MunicipioDimensao} from "./municipio.dimensao";
import {Pessoa} from "./pessoa";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";
import {TipoFatoGeradorEnum} from "../enums/tipo.fato.gerador.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("FatoGeradorCreditoAReceber")
@JsonIdentityInfo()
export class FatoGeradorCreditoAReceber extends SonnerBaseEntity {

  id: number;

  @JacksonType("AtividadeDimensao")
  atividade: AtividadeDimensao;

  aliquota: number;

  @JacksonType("CompetenciaDimensao")
  competencia: CompetenciaDimensao;

  @JacksonType("DiaDimensao")
  diaCancelamento: DiaDimensao;

  @JacksonType("DiaDimensao")
  diaEmissao: DiaDimensao;

  @JacksonType("DiaDimensao")
  diaPrestacao: DiaDimensao;

  @JacksonType("Pessoa")
  contribuinteGerador: Pessoa;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("MunicipioDimensao")
  municipioIncidencia: MunicipioDimensao;

  @EnumType(TipoRegimeISSEnum)
  regimeContribuinteGerador: TipoRegimeISSEnum;

  @EnumType(TipoRegimeISSEnum)
  regimeContribuinteResponsavelRecolhimento: TipoRegimeISSEnum;

  @JacksonType("Pessoa")
  responsavelRecolhimento: Pessoa;

  retido: boolean;

  senha: string;

  @EnumType(TipoFatoGeradorEnum)
  tipoFatoGerador: TipoFatoGeradorEnum;

  valorBrutoFaturamento: number;

  valorDeducaoBaseCalculo: number;

  valorDescontoImpostoNF: number;

  valorTributo: number;

  valorTributoForaMunicipio: number;
}
