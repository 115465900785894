import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {WebServiceServicoEnum} from "../enums/web.service.servico.enum";

@JacksonType("WebServiceArquivos")
@JsonIdentityInfo()
export class WebServiceArquivo extends SonnerBaseEntity {

  id: number;

  bucketArquivoEntrada: string;

  bucketArquivoSaida: string;

  @JacksonType('Pessoa')
  contribuinte: Pessoa;

  docUsuario: string;

  @JacksonType('Pessoa')
  emissor: Pessoa;

  @DateType()
  horarioAcesso: Date;

  keyArquivoEntrada: string;

  keyArquivoSaida: string;

  @JacksonType('Municipio')
  municipio: Municipio;

  nomeArquivoEntrada: string;

  nomeArquivoSaida: string;

  @EnumType(WebServiceServicoEnum)
  servico: WebServiceServicoEnum;

  urlArquivoEntrada: string;

  urlArquivoSaida: string;
}
