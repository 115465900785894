import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TNCAgendamento} from "./t.n.c.agendamento";

@JacksonType("TNCAlertaMigracao")
@JsonIdentityInfo()
export class TNCAlertaMigracao extends SonnerBaseEntity {

  id: number;

  @JacksonType("TNCAgendamento")
  agendamento: TNCAgendamento;

  @JacksonType("Municipio")
  municipio: Municipio;

  ultimoStatus: boolean;

  @DateType()
  horario: Date;
}
