import {Injectable} from "@angular/core";
import {BehaviorSubject, from, Observable, of, Subject} from "rxjs";
import {AmplifyService} from "aws-amplify-angular";
import {CookieService} from "./cookie.service";
import {DateUtilsService} from "./date.utils.service";
import {mergeMap} from "rxjs/operators";
import {Router} from "@angular/router";
import {LogoutService} from "./logout.service";

@Injectable()
export class CognitoService {

  cognitoUser: Subject<any> = new BehaviorSubject(null);

  constructor(private amplifyService: AmplifyService,
              private cookieService: CookieService,
              private dateUtilsService: DateUtilsService,
              private router: Router) {
  }

  auth(observable) {
    return this.currentAuthenticatedUser().pipe(
      mergeMap(resp => {
        if (!resp) {
          this.router.navigate(['/landing-page']);

          return of(resp);
        } else {

          return observable;
        }
      })
    )
  }

  currentAuthenticatedUser(atualiza: boolean = false): Observable<any> {
    return from(this.amplifyService.auth().currentAuthenticatedUser({bypassCache: atualiza})
      .then(user => {
        this.updateAllCookie(`Bearer ${user.getSignInUserSession().getIdToken().getJwtToken()}`, `Bearer ${user.getSignInUserSession().getAccessToken().getJwtToken()}`);

        return true;
      })
      .catch(exception => {
        this.exceptionTranslator(exception);

        return false;
      })
    );
  }

  completeNewPassword(user: any, novaSenha: string): Observable<any> {
    return from(this.amplifyService.auth().completeNewPassword(user, novaSenha, null))
  }

  currentSession() {
    return from(this.amplifyService.auth().currentSession()
      .then(resp => {
        return resp;
      })
      .catch(exception => {
        return this.exceptionTranslator(exception);
      }))
  }

  exceptionTranslator(exception: any): any {
    let message: string = "";

    if (exception) {
      if (exception.code == "LimitExceededException") {
        message = "Limite de tentativa excedido. Tente depois de algum tempo.";
      } else if (exception == "Username cannot be empty") {
        message = "Por favor, informe o usuário.";
      } else if (exception == "Password cannot be empty") {
        message = "Por favor, informe a senha.";
      } else if (exception == "Code cannot be empty") {
        message = "Por favor, informe o código.";
      } else if (exception.code == "CodeMismatchException") {
        message = "Código ou estado de autenticação inválido para o usuário.";
      } else if (exception.code == "UserNotFoundException") {
        message = "'Combinação de nome de usuário e/ou id não encontrada.';"
      } else if (exception.code == "InvalidParameterException") {
        if (exception.message == "Cannot reset password for the user as there is no registered/verified email or phone_number.") {
          message = "Não é possivel redefinir a senha se o usuário não possuir um e-mail cadastrado e verificado.";
        } else {
          message = "A senha deve possuir pelo menos seis caracteres.";
        }
      } else if (exception.code == "ExpiredCodeException") {
        message = "Código inválido fornecido, solicite um código novamente.";
      } else if (exception.code == "NotAuthorizedException") {
        if (exception.message == "User password cannot be reset in the current state.") {
          message = "Não é possível redefinir a sua senha no momento, por favor entre em contato com o suporte.";
        } else {
          message = "Usuário ou senha incorreto.";
        }
      } else if (exception == "not authenticated") {
        this.signOut();
      } else {
        console.log(exception);
        message = 'Erro não tratado, por favor entre em contato com o suporte.';
      }
    }

    return message;
  }

  forgotPassword(username: string) {
    return from(this.amplifyService.auth().forgotPassword(username)
      .then(resp => {
        return resp.CodeDeliveryDetails.DeliveryMedium + " - " + resp.CodeDeliveryDetails.Destination;
      })
      .catch(exception => {
        return this.exceptionTranslator(exception);
      })
    )
  }

  forgotPasswordSubmit(username: string, code: string, password: string) {
    return from(this.amplifyService.auth().forgotPasswordSubmit(username, code, password)
      .then(resp => {
        return resp;
      })
      .catch(exception => {
        return this.exceptionTranslator(exception);
      }))
  }

  removeAllCookie() {
    this.removeCookie("Authentication", "/");
    this.removeCookie("Authorization", "/");
  }

  removeCookie(name: string, path: string) {
    this.cookieService.removeCookie(name, path);
  }

  signIn(usuario: string, senha: string): Observable<any> {
    return from(this.amplifyService.auth().signIn(usuario, senha)
      .then(user => {
        this.cognitoUser.next(user);

        return this.cognitoUser;
      })
    );
  }

  signOut(): Observable<any> {
    return from(this.amplifyService.auth().signOut()
      .then(() => {
        this.removeAllCookie();
        localStorage.clear();

        return true;
      }))
  }

  updateAllCookie(idToken, accessToken?) {
    this.updateCookie("Authentication", idToken, '/');

    if (accessToken) {
      this.updateCookie("Authorization", accessToken, '/');
    }
  }

  updateCookie(name, value, path) {
    let expiresStorage = localStorage.getItem("expires");

    if (expiresStorage && new Date() > new Date(expiresStorage)) {
      this.removeAllCookie();
    } else {
      const expires = this.dateUtilsService.addMinutes(new Date(), 120);

      if (window.localStorage) {
        localStorage.setItem("expires", expires.toString());
      }

      this.cookieService.setCookie(name, value, expires, path);
    }
  }
}
