import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {RotinaAgendamentoParametro} from "./rotina.agendamento.parametro";
import {RotinaAgendamento} from "./rotina.agendamento";
import {RotinaAgendamentoParametroTipoEnum} from "../enums/rotina.agendamento.parametro.tipo.enum";

@JacksonType("RotinaAgendamentoLog")
@JsonIdentityInfo()
export class RotinaAgendamentoLog extends SonnerBaseEntity {

  id: number;

  @DateType()
  horario: Date;

  observacao: string;

  @JacksonType("RotinaAgendamento")
  rotina: RotinaAgendamento;
}
