export enum SituacaoProcessoSimplesEnum {

  ARQUIVO_GERADO,
  ARQUIVO_RETORNO_IMPORTADO,
  EM_PROCESSAMENTO,
  INICIADO,
  NOTIFICACAO_EXPORTADA,
  NOTIFICACAO_GERADA,
  PROCESSO_CONCLUIDO,
  PROCESSO_CANCELADO,
  PROCESSAMENTO_CONCLUIDO
}
