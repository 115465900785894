import {JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";
import {Solicitacao} from "./solicitacao";

@JacksonType("SolicitacaoImportacaoNFE")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoImportacaoNFE', type: 'Solicitacao'})
@JsonIdentityInfo()
export class SolicitacaoImportacaoNFE extends Solicitacao {

  controleNumeracao: boolean;

  @JacksonType("Pessoa")
  grafica: Pessoa;

  justificativa: string;

  nomeGrafica: string;

  numeroFinal: number;

  numeroInicial: number;

  quantidadePorBloco: number;

  vias: number;
}
