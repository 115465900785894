import {Component, Injector, Input, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {AppStateService} from "../../../app/services/app.state.service";
import {UserInfoTO} from "../../../core/classes/user.info.to";
import {Pessoa} from "../../../../classes/pessoa";
import {Empresa} from "../../../../classes/empresa";

@Component({
  selector: 'pessoa',
  templateUrl: './pessoa.component.html',
  styleUrls: ['./pessoa.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PessoaComponent extends BaseComponent {

  @Input()
  pessoa: Pessoa = new Pessoa();

  @Input()
  titulo: string = null;

  cadastro_inscricaoEstadual: boolean = false;

  labelNome: string = "Nome";

  logo: string = null;

  nomeFantasia: string;

  userInfoTO: UserInfoTO = new UserInfoTO();

  constructor(private appStateService: AppStateService,
              private injector: Injector) {
    super(injector);
  }

  doOnInit() {
    this.appStateService.userInfoSubject.subscribe(userInfoTO => {
      if (userInfoTO) {
        this.userInfoTO = userInfoTO;

        if (userInfoTO.authRegex.includes("iss:cadastro:inscricaoEstadual")) {
          this.cadastro_inscricaoEstadual = true;
        }
      }
    });

    if (this.pessoa) {
      let possuiNomeFantasia:boolean = false;
      if (this.pessoa.papeis) {
        this.pessoa.papeis.forEach(papel => {
          if (papel instanceof Empresa) {
            this.labelNome = "Razão social";
            this.nomeFantasia = papel.nomeFantasia;
            possuiNomeFantasia = true;
          }
        })
      }
      if(!possuiNomeFantasia){
        this.nomeFantasia = null;
      }

      if (this.pessoa.imagen && this.pessoa.imagen.id) {
        this.logo = "v2/rest/arquivo/buscarLogoPorPessoa/" + this.pessoa.id + "/" + Math.random();
      }
    }
  }
}
