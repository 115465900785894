import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("ConfigNotaPremiadaDocumentosExcluidos")
@JsonIdentityInfo()
export class ConfigNotaPremiadaDocumentosExcluidos extends SonnerBaseEntity {

  id: number;

  documento: string;

  motivo: string;

  @JacksonType("Municipio")
  municipio: Municipio;

}
