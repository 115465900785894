import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("TNCStatus")
@JsonIdentityInfo()
export class TNCStatus extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  @DateType()
  pingDate: Date;

  versao: string;
}
