import {AsyncSubject, Observable, Subject} from "rxjs";

export class ProxyObservable {

  private __observable: Subject<any> = new AsyncSubject();

  private __output: Subject<any> = new AsyncSubject();

  constructor(observable: Observable<any>) {
    const self = this;

    const sub = observable.subscribe(
      data => {
        self.__observable.next(data);
        self.__observable.complete();

        if (sub) {
          sub.unsubscribe();
        }
      },
      err => {
        console.error(err);
        self.__observable.error(err);
        self.__observable.complete();

        if (sub) {
          sub.unsubscribe();
        }
      }
    );
  }

  of(successFn?: Function, errorFn?: Function): Observable<any> {
    this.__observable.subscribe(
      result => {
        if (successFn) {
          successFn(result);
        }

        this.__output.next(result);
        this.__output.complete();
      },
      error => {
        console.error(error);

        if (errorFn) {
          errorFn(error);
        }

        this.__output.next(error);
        this.__output.complete();
      }
    );

    return this.__output;
  }
}
