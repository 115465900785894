import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {SolicitacaoAlteracaoNFe} from "./solicitacao.alteracao.n.fe";
import {TipoDeducao} from "./tipo.deducao";

@JacksonType("DeducaoSolicitacaoAlteracaoNFe")
@JsonIdentityInfo()
export class DeducaoSolicitacaoAlteracaoNFe extends SonnerBaseEntity {

  id: number;

  @JacksonType("SolicitacaoAlteracaoNFe")
  solicitacao: SolicitacaoAlteracaoNFe;

  @JacksonType("TipoDeducao")
  tipo: TipoDeducao;

  valor: number;
}
