import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("InicialMenuFilter")
export class InicialMenuFilter {

  idContador: number;

  idFiscal: number;

  idFuncionario: number;

  idMunicipio: number;
}
