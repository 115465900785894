import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Encerramento} from "./encerramento";
import {TipoTributacao} from "./tipo.tributacao";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";

@JacksonType("EncerramentoMemoria")
@JsonIdentityInfo()
export class EncerramentoMemoria extends SonnerBaseEntity {

  id: number;

  @JacksonType("Encerramento")
  encerramento: Encerramento;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  cancelada: boolean;

  descricao: string;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  idDocumento: number;

  idEscritEspecial: number;

  idEscritBancario: number;

  idEscritBancarioDesif: number;

  incidenciaMunicipio: boolean;

  numero: number;

  retida: boolean;

  serie: string;

  @EnumType(TipoDocumentoEnum)
  tipoDocumento: TipoDocumentoEnum;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;

  valorBaseCalculo: number;

  valorImposto: number;
}
