import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("Feriado")
@JsonIdentityInfo()
export class Feriado extends SonnerBaseEntity {

  id: number;

  descricao: string;

  @DateType()
  dia: Date;

  @JacksonType("Municipio")
  municipio: Municipio;
}
