import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EncerramentoOficioDetalheTO")
export class EncerramentoOficioDetalheTO {

  competencia: string;

  @DateType()
  dataInicioProcessamento: Date;

  @DateType()
  dataFimProcessamento: Date;

  senhaEncerramentoPrestador: string;

  senhaEncerramentoTomador: string;

  observacaoPrestador: string;

  observacaoTomador: string;

}
