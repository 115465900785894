import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {RegimeISS} from "./regime.i.s.s";

@JacksonType("RegimeCompetencia")
@JsonIdentityInfo()
export class RegimeCompetencia extends SonnerBaseEntity {

  id: number;

  adicionadoMigracao: boolean;

  ativo: boolean;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataAlteracao: Date;

  @DateType()
  dataInicio: Date;

  @JacksonType("RegimeISS")
  regime: RegimeISS;
}
