import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {EscritTransporteTarifaValor} from "./escrit.transporte.tarifa.valor";

@JacksonType("EscritTransporteTarifa")
@JsonIdentityInfo()
export class EscritTransporteTarifa extends SonnerBaseEntity {

  id: number;

  descricao: string;

  codigo:number;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("EscritTransporteTarifaValor")
  valores: EscritTransporteTarifaValor[];



}
