import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoSolicitacaoEnum} from "../enums/tipo.solicitacao.enum";

@JacksonType("JustificativaIndeferimento")
@JsonIdentityInfo()
export class JustificativaIndeferimento extends SonnerBaseEntity {

  id: number;

  descricao: string;

  @JacksonType('Municipio')
  responsavel: Municipio;

  @EnumType(TipoSolicitacaoEnum)
  tipo: TipoSolicitacaoEnum;

  constructor(tipo?: TipoSolicitacaoEnum) {
    super();

    this.tipo = tipo;
  }
}
