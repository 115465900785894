import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {FormatoRelatorioEnum} from "../../../../enums/formato.relatorio.enum";
import {EnumUtils} from "../../../core/utils/enum.utils";

@Component({
  selector: 'formato-relatorio',
  templateUrl: './formato.relatorio.component.html',
  styleUrls: ['./formato.relatorio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormatoRelatorioComponent extends BaseComponent {



  constructor(private injector: Injector) {
    super(injector);
  }

  formatosRelatorio: string[] = EnumUtils.values(FormatoRelatorioEnum);

  @Input()
  formatoRelatorio: string;

  @Output()
  formatoChange: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    if(!this.formatoRelatorio){
      this.formatoRelatorio = this.formatosRelatorio[0];
    }
    this.formatoChange.emit(this.formatoRelatorio);
  }

  alteraFormato(event: any){
    this.formatoChange.emit(event.value);
  }

}
