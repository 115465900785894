import {EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {ServiceErrorMessage} from "./service.error.message";
import {TipoExceptionEnum} from "../../../enums/tipo.exception.enum";

@JacksonType("ExceptionInfo")
export class ExceptionInfo {

  alreadyPrepared: boolean = false;

  className: string;

  exceptionMessage: string;

  stackTrace: string;

  @EnumType(TipoExceptionEnum)
  tipo: TipoExceptionEnum;

  @JacksonType("ServiceErrorMessage")
  mensagens: ServiceErrorMessage[];

  toString(): string {
    let message = "";

    if (this.mensagens && this.mensagens.length > 0) {
      this.mensagens.forEach(m => {
        message += "\n" + m.message;
      });
    }

    if (message == "" && this.exceptionMessage) {
      message += "\n" + this.exceptionMessage;
    }

    return message;
  }
}
