import {JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {PerfilDescontoImpostoNfe} from "./perfil.desconto.imposto.nfe";
import {Solicitacao} from "./solicitacao";

@JacksonType("SolicitacaoPerfilDescontoNfe")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoPerfilDescontoNfe', type: 'Solicitacao'})
@JsonIdentityInfo()
export class SolicitacaoPerfilDescontoNfe extends Solicitacao {

  justificativa: string;

  @JacksonType("PerfilDescontoImpostoNfe")
  perfil: PerfilDescontoImpostoNfe;
}
