import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("TNCDocumentoPendentesTO")
export class TNCDocumentoPendentesTO {

  @DateType()
  dataExportacao: Date;

  @DateType()
  dataEmissao: Date;

  descricao: string;

  motivoCancelamento: string;

  senha: string;

  @DateType()
  ultimaAlteracao: Date;

  urlRelatorio: string;
}
