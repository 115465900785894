export enum ExigibilidadeSimplesNacionalEnum {

  NAO_INFORMADO = "Não informado",
  ANTECIPACAO_ENCERRAMENTO = "Antecipação com encerramento de tributação",
  EXIGIBILIDADE_SUSPENSA = "Exigibilidade suspensa",
  IMUNIDADE = "Imunidade",
  RETENCAO_TRIBUTARIA = "Retenção tributária",
  SUBSTITUICAO_TRIBUTARIA = "Substituição tributária",
  TRIBUTACAO_MONOFASICA = "Tributação monofásica",
  ISENCAO = "Isenção/redução",
  LANCAMENTO_OFICIO = "Lançamento de ofício"
}

export function codigo(exigibilidadeSimplesNacional: ExigibilidadeSimplesNacionalEnum): number {

  if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.NAO_INFORMADO) {
    return 0;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.ANTECIPACAO_ENCERRAMENTO) {
    return 1;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.EXIGIBILIDADE_SUSPENSA) {
    return 2;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.IMUNIDADE) {
    return 3;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.RETENCAO_TRIBUTARIA) {
    return 4;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.SUBSTITUICAO_TRIBUTARIA) {
    return 5;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.TRIBUTACAO_MONOFASICA) {
    return 6;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.ISENCAO) {
    return 7;
  } else if (exigibilidadeSimplesNacional == ExigibilidadeSimplesNacionalEnum.LANCAMENTO_OFICIO) {
    return 8;
  }
}
