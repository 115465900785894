export enum TipoEventoEnum {

  login = "Login",
  logout = "Logout",
  emitirNfe = "Emiss�o Nota Fiscal Elet�nica",
  escrituracaoPrestador = "Escritura��o de Prestador",
  escrituracaoPrestador_remove = "Remo��o de Escritura��o de Prestador",
  escrituracaoTomador = "Escritura��o de Tomador",
  escrituracaoTomador_remove = "Remo��o de Escritura��o de Tomador",
  emissaoGuia = "Emiss�o de Guia",
  encerramento = "Encerramento",
  escrituracaoBanco = "Escritura��o banc�ria",
  credito = "Cr�dito de ISS",
  cancelaDocumento = "Cancelamento de Documento",
  publicaComunicado = "Publica��o de comunicados",
  aprovaSolicitacao = "Aprova��o de Solicita��es",
  aprovaSolicitacaoAIDF = "Aprova��o de AIDF",
  aprovaSolicitacaoAutorizacaoNFe = "Aprova��o de Autoriza��o para NFe",
  autorizacaoAutomaticaNFeAvulsa = "Autoriza��o autom�tica de Nota Fiscal Avulsa",
  aprovaSolicitacaoAlteracaoNFe = "Aprova��o de Altera��o de NFe",
  aprovaSolicitacaoAutorizacaoRPS = "Aprova��o de Autoriza��o RPS",
  aprovaSolicitacaoCadastroContador = "Aprova��o de Cadastro de Contador",
  aprovaSolicitacaoCancelamentoEncerramento = "Aprova��o de Cancelamento de Encerramento",
  aprovaSolicitacaoLivroFiscal = "Aprova��o de Livro Fiscal",
  indefeSolicitacao = "Indefere solicita��o",
  conviteAcesso = "Convite de Acesso ao gov",
  respondeTopico = "Resposta ao t�pico",
  encerramentoTopico = "Encerramento do t�pico",
  indeferirTopico = "Indeferir o t�pico",
  alteraCadastroGeral = "Altera Cadastro: Geral",
  alteraCadastroContato = "Altera Cadastro: Contato",
  alteraCadastroEmpresa = "Altera Cadastro: Empresa",
  alteraCadastroAtividades = "Altera Cadastro: Atividades",
  alteraCadastroVinculoAtividades = "Altera Cadastro: Al�quota no Vinculo",
  alteraCadastroUsuario = "Altera Cadastro: Usuario",
  alteraCadastroBanco = "Altera Cadastro: Banco",
  alteraCadastroConstrutora = "Altera Cadastro: Construtora",
  alteraCadastroConfPessoal = "Altera Cadastro: Conf. Pessoal",
  alteraCadastroAdicionaIgnoraBloqueioEmissaoNf = "Altera Cadastro: Adiciona Bloqueio Emissao NF",
  alteraCadastroEscritEspecial = "Altera Cadastro: Escrit Especial",
  aprovaSolicitacaoCadastroEventual = "Aprova solicita��o de cadastro Eventual",
  alteraAtividade = "Edita Atividade",
  adicionaObra = "Adiciona Obra",
  alteraAliquotaSimples = "Altera al�quota do Simples",
  alteraRegimeSimples = "Altera Regime do Contribuinte",
  alteraHistoricoRegime = "Altera Historico de regimes - Migrador",
  fiscalizacaoObra = "Gerada guia ap�s fiscaliza��o na Dedu��o de Obras",
  alteraConfiguracaoCreditoTomador = "Altera configura��es de cr�dito de tomadores",
  removeContador = "Remove o contador configurado como contador pr�prio",
  escrituracaoEspecial = "Escritura��o Especial",
  solicitaNfSe = "Solicita��o de NFSe",
  solicitaAIDF = "Solicita��o de AIDF",
  inciaChat = "Iniciou conversa via Chat",
  baixaManualGuia = "Baixa Manual de Guia de Pagamento",
  alteraPerfilDescontoImpostoNfe = "Altera o perfil de desconto da base de c�lculo da NFe",
  aprovaSolcitacaoDescontoImpostoNfe = "Aprova a solicita��o para perfil de desconto da base de c�lculo da NFe",
  alteraPessoaPerfilDescontoImpostoNfe = "Altera o perfil de desconto da base de c�lculo da NFe de um contribuinte",
  reprocessaAliquotaSimples = "Recalcula as al�quotas do simples a partir do cadastro",
  bloqueioDeMovimentacoes = "Bloqueia Movimenta��es NFSe e/ou Encerramento e/ou Escrituracoes",
  alteraSituacaoObraUnica = "Altera Situa��o de Obra"
}
