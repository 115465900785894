import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {AppStateService} from "../../../app/services/app.state.service";
import {Pessoa} from "../../../../classes/pessoa";

@Component({
  selector: 'bloqueio-movimentacao-page',
  templateUrl: './bloqueio.movimentacao.page.component.html',
  styleUrls: ['./bloqueio.movimentacao.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BloqueioMovimentacaoPageComponent extends BaseComponent {

  constructor(private appStateService: AppStateService,
              private injector: Injector) {
    super(injector);
  }

  pessoa: Pessoa;

  exibeContatoMunicipio: boolean = false;

  ngOnInit() {
    this.appStateService.userInfoSubject.subscribe(user => {
      if(user){
        this.pessoa = user.pessoaSelecionada;
        if (user.authRegex.includes("iss:inicial:exibeContatoMunicipio")) {
          this.exibeContatoMunicipio = true;
        }
      }
    });

    this.disableLoading();
  }
}
