import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Serie} from "../serie";
import {DesifTipoConsolidacaoEnum} from "../../enums/desif.tipo.consolidacao.enum";
import {TipoRegimeISSEnum} from "../../enums/tipo.regime.iss.enum";

@JacksonType("DeclaracaoIncorretaSimplesTO")
@JsonIdentityInfo()
export class DeclaracaoIncorretaSimplesTO {

  documento: string;

  docNome: string;

  baseCalculoNaoRetidoGov: number;

  baseCalculoNaoRetidoSimples: number;

  valorNaoRetido: number;

  baseCalculoRetidoGov: number;

  baseCalculoRetidoSimples: number;

  valorRetido: number;

  baseCalculoNaoServicoSimples: number;

  baseCalculoNaoRetidoForaGov: number;

  baseCalculoNaoRetidoForaSimples: number;

  valorNaoRetidoFora: number;

}
