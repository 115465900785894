import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {PGDASDApuracaoFilialAtividade} from "./p.g.d.a.s.d.apuracao.filial.atividade";
import {FaixaSimplesNacionalEnum} from "../enums/faixa.simples.nacional.enum";
import {ExigibilidadeSimplesNacionalEnum} from "../enums/exigibilidade.simples.nacional.enum";

@JacksonType("PGDASDApuracaoFilialAtividadeDetalhe")
@JsonIdentityInfo()
export class PGDASDApuracaoFilialAtividadeDetalhe extends SonnerBaseEntity {

  id: number;

  aliquotaISS: number;

  aliquotaISSIsento: number;

  aliquotaISSReducao: number;

  baseCalculoPrestador: number;

  diferencaPGDASBetweenNF: number;

  @EnumType(ExigibilidadeSimplesNacionalEnum)
  exigibilidade: ExigibilidadeSimplesNacionalEnum;

  @EnumType(FaixaSimplesNacionalEnum)
  faixa: FaixaSimplesNacionalEnum;

  @JacksonType("PGDASDApuracaoFilialAtividade")
  filialAtividade: PGDASDApuracaoFilialAtividade;

  @JacksonType("Municipio")
  municipioIncidencia: Municipio;

  percentualReceitaReducao: number;

  retido: boolean;

  valorISS: number;

  valorISSIsento: number;

  valorReceita: number;

  valorReceitaIsenta: number;

  valorReceitaReducao: number;

  valorISSReducao: number;
}
