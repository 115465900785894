import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {EscritTransporteArquivoResumoTO} from "./escrit.transporte.arquivo.resumo.to";

@JacksonType("EscritTransporteArquivoTO")
export class EscritTransporteArquivoTO {

  id: number;

  competenciaEncerrada: boolean;

  idArquivo: number;

  @DateType()
  competencia: Date;

  @JacksonType("EscritTransporteArquivoResumoTO")
  escritTransporteArquivos: EscritTransporteArquivoResumoTO[];

  contribuinteAtivo: boolean;
}
