import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {TabelaSimples} from "./tabela.simples";

@JacksonType("TabelaSimplesItem")
@JsonIdentityInfo()
export class TabelaSimplesItem extends SonnerBaseEntity {

  id: number;

  aliquotaCofins: number;

  aliquotaCPP: number;

  aliquotaCSLL: number;

  aliquotaISS: number;

  aliquotaIR: number;

  aliquotaNominal: number;

  aliquotaPIS: number;

  @JacksonType("TabelaSimples")
  tabela: TabelaSimples;

  valorDeduzir: number;

  valorFinal: number;

  valorInicial: number;
}
