import {JacksonType, JsonIdentityInfo, JsonIgnore} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Currency} from "./currency";
import {NotaFiscal} from "./nota.fiscal";
import {SituacaoItem} from "./situacao.item";
import {UnidadeItem} from "./unidade.item";

@JacksonType("ItemNotaFiscal")
@JsonIdentityInfo()
export class ItemNotaFiscal extends SonnerBaseEntity {

  id: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  descricao: string;

  eventual: boolean = false;

  @JacksonType("NotaFiscal")
  notaFiscal: NotaFiscal;

  quantidade: number;

  @JacksonType("SituacaoItem")
  situacao: SituacaoItem;

  @JacksonType("UnidadeItem")
  unidade: UnidadeItem;

  valorUnitario: number;

  webISSValorDesconto: number = 0;

  webISSValorDescontoIncondicional: number = 0;

  calculaTotal() {
    return parseFloat((this.valorUnitario * this.quantidade).toFixed(2))
  }

  @JsonIgnore()
  private _valor: Currency;

  get valor(): Currency {
    if (this.valorUnitario) {
      let valor: Currency = new Currency();
      valor.value = this.valorUnitario;
      this._valor = valor;
    }

    return this._valor;
  }

  set valor(value: Currency) {
    this._valor = value;
    this.valorUnitario = value.value;
  }

  valorString(value: string) {
    let valor: Currency = new Currency();
    valor.valueString = value;

    this.valor = valor;
  }

  total(): Currency {
    let total: Currency = new Currency();
    total.value = this.calculaTotal();

    return total;
  }
}
