import {Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {VinculoAtividadeService} from "../../services/vinculo.atividade.service";
import {DxAutocompleteComponent} from "devextreme-angular";
import {AtividadeCTISS} from "../../../../classes/atividade.c.t.i.s.s";
import {AtividadeCTISSService} from "../../services/atividade.ctiss.service";

@Component({
  selector: "atividade-ctiss-selector",
  templateUrl: './atividade.ctiss.selector.component.html',
  styleUrls: ['./atividade.ctiss.selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AtividadeCtissSelectorComponent extends BaseComponent {

  @ViewChild("autoCompleteComponent")
  autoCompleteComponent: DxAutocompleteComponent;

  private _atividade: AtividadeCTISS;

  atividadeSelecionada: AtividadeCTISS;

  atividadeList: AtividadeCTISS[];

  grupoList: AtividadeCTISS[];

  hint: string;

  placeHolderAtividade: string = "Selecione uma atividade...";

  /** Habilita o autocomplet ao invés do select box, ideal para trazer todas as atividades do município*/
  @Input()
  atividadeAutoComplete: boolean = false;

  /** Lista somente as atividades da pessoa selecionada */
  @Input()
  atividadeContribuinte: boolean = false;

  /** Usado para os select-box */
  @Input()
  searchEnabled: boolean = true;

  /** Permitir limpar o conteúdo selecionado */
  @Input()
  showClearButton: boolean = true;

  /** Atributos utilizados para fazer a busca dentro do autoComplete */
  @Input()
  searchExpr: string[] = ['getDisplayValue'];

  /** Valor que será demonstrado ao selecionar um item dentro do autoComplete */
  @Input()
  valueExpr: string = "getDisplayValue";

  @Output()
  atividadeChange: EventEmitter<AtividadeCTISS> = new EventEmitter<AtividadeCTISS>();


  constructor(private injector: Injector,
              private atividadeCTISSService: AtividadeCTISSService,
              private vinculoAtividadeService: VinculoAtividadeService) {
    super(injector);
  }


  get atividade(): AtividadeCTISS {
    return this._atividade;
  }

  set atividade(value: AtividadeCTISS) {
    this._atividade = value;
  }

  ngOnInit() {
    if (!this.atividadeList) {
      this.atividadeList = [];
    }
    if (this.atividadeContribuinte) {
      this.carregaAtividadePorPessoa();
    } else {
      this.carregaTodasAtividadesMunicipio();
    }
  }

  alteraAtividade(event: any) {
    if (event instanceof AtividadeCTISS) {
      setTimeout(() => {
        if ((this.atividadeSelecionada && event.id != this.atividadeSelecionada.id) || !this.atividadeSelecionada) {
          this.atividadeChange.emit(event);
          this.hint = event.getDisplayValue();
        }
        this.atividadeSelecionada = event;
      });
    } else if (!event) {
      setTimeout(() => {
        this.limpaAtividade();
      });
    }
  }

  @GlobalLoadingIndicator()
  carregaAtividadePorPessoa() {
    return this.of(this.vinculoAtividadeService.atividadeCTISSPorPessoa(), atividadeList => this.setAtividadeList(atividadeList));
  }

  @GlobalLoadingIndicator()
  carregaTodasAtividadesMunicipio() {
    return this.of(this.atividadeCTISSService.buscarListaPorAtivaEMunicipio(), atividadeList => this.setAtividadeList(atividadeList));
  }

  setAtividadeList(atividadeList: AtividadeCTISS[]) {
    this.atividadeList = atividadeList;
    if (atividadeList && atividadeList.length == 0) {
      this.placeHolderAtividade = "Nenhuma atividade encontrada";
    }

    this.atividadeSelecionada = this.atividade;
    if (this.atividadeSelecionada) {
      this.autoCompleteComponent.value = this.atividadeSelecionada.getDisplayValue();
    }
  }

  limpaAtividade() {
    this.atividadeSelecionada = null;
    this.atividade = null;
    if(this.atividadeAutoComplete){
      this.autoCompleteComponent.value = null;
    }
    this.hint = "";
    this.atividadeChange.emit(null);
  }

}
