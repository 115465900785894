import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Arquivo} from "./arquivo";
import {Municipio} from "./municipio";
import {TipoCertificadoEnum} from "../enums/tipo.certificado.enum";

@JacksonType("MunicipioCertificado")
@JsonIdentityInfo()
export class MunicipioCertificado extends SonnerBaseEntity {

  id: number;

  ativo: boolean = true;

  @JacksonType('Arquivo')
  certificado: Arquivo;

  cnpj: string;

  @DateType()
  inicio: Date;

  @JacksonType('Municipio')
  municipio: Municipio;

  nomeCertificado: string;

  senhaCertificado: string;

  @EnumType(TipoCertificadoEnum)
  tipoCertificado: TipoCertificadoEnum;

  @DateType()
  validade: Date;
}
