import {JacksonType} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";
import {StringUtils} from "../modules/core/utils/string.utils";
import {DocumentoPipe} from "../modules/core/pipes/documento.pipe";

@JacksonType("DadosPessoa")
export class DadosPessoa {

  destacaEmail: boolean = false;

  documento: string = "";

  inscEst: string = "";

  email: string;

  nome: string = "";

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  // methods

  getDocumentoFmt(): string {
    if(this.documento){
      let documentoPipe = new DocumentoPipe();
      return documentoPipe.transform(this.documento);
    }
    return "";
  }

  getDocNomeTrunc(): string{
    if(this.getDocumentoFmt()){
      return this.getDocumentoFmt() + " - " + StringUtils.trunc(this.nome,35);
    }
  }
  
}
