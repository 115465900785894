import {Component, Injector} from "@angular/core";
import {LoginService} from "../../../core/services/login.service";
import {AuthResponseTO} from "../../../core/classes/auth.response.to";
import {VersionService} from "../../../core/services/version.service";
import {Usuario} from "../../../../classes/usuario";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {forkJoin} from "rxjs";
import {UsuarioService} from "../../../commons/services/usuario.service";
import {UsuarioFilter} from "../../../commons/filters/usuario.filter";
import {LoginFilter} from "../../../core/filters/login.filter";
import {CognitoService} from "../../../core/services/cognito.service";

@Component({
  selector: 'landing-page',
  templateUrl: './landing.page.component.html',
  styleUrls: ['./landing.page.component.scss']
})
export class LandingPageComponent extends BaseComponent {

  constructor(private injector: Injector,
              private cognitoService: CognitoService,
              private loginService: LoginService,
              private usuarioService: UsuarioService,
              private versionService: VersionService) {
    super(injector);
  }

  confirmaLogin: string;

  login: string;

  loginErrors: string[];

  loginIndicator: boolean = false;

  novoLogin: string;

  novaSenha: string;

  novaSenhaConfirmacao: string;

  nroDocumento: string;

  popupUsuario: boolean = false;

  senha: string;

  senhaInvalida : boolean = false;

  trocarSenha: boolean = false;

  usuario: Usuario = new Usuario();

  usuarioCognito: any;

  usuarioInvalido : boolean = false;

  usuarioNaoAtivoError: boolean = false;

  versao: string = "";

  ngOnInit(): void {
    if(window.location.href =='https://nfe-cidades.com.br/landing-page'){
      window.location.href ='https://www.nfe-cidades.com.br/landing-page';
    }else{
      this.carrega()
    }
  }

  acionaRedefinir() {
    this.clearErrorMessages();
    if (this.usuarioInvalido) {
      if (!this.novoLogin || !this.confirmaLogin) {
        this.addErrorMessage("Os campos usuário e confirmação devem ser preenchidos.");
      } else if (this.novoLogin == this.confirmaLogin) {
        let reg = /[^LM\S{NP}]/g;

        if (reg.test(this.novoLogin)) {
          this.addErrorMessage("Os campos usuário e confirmação não devem possuir nenhum espaço.");
        }
      } else {
        this.addErrorMessage("Os campos usuário e confirmação estão diferentes.");
      }
    }

    if(this.senhaInvalida){
      if (!this.novaSenha || !this.novaSenhaConfirmacao) {
        this.addErrorMessage("Os campos senha e confirmação de senha devem ser preenchidos.");
      } else if (this.novaSenha == this.novaSenhaConfirmacao) {

        if (this.novaSenha.length < 6) {
          this.addErrorMessage("Os campos senha e confirmação de senha devem possuir no minímo 6 caractéres");
        }
      } else {
        this.addErrorMessage("Os campos senha e confirmação de senha são diferentes");
      }
    }

    if(!this.hasErrorMessages()){
      this.atualizar();
    }
  }

  atualizar() {
    let loginFilter: LoginFilter = new LoginFilter();

    loginFilter.novoUsuario = this.novoLogin;
    loginFilter.usuario = this.login;
    loginFilter.senha = this.senha;
    loginFilter.novaSenha = this.novaSenha;

    return this.of(this.loginService.atualizar(loginFilter), usuario => {
      this.login = usuario.usuario;
      this.senha = this.novaSenha;

      this.popupUsuario = false;
    });
  }

  buscarAuthResponse() {
    let usuarioFilter: UsuarioFilter = new UsuarioFilter();

    usuarioFilter.usuario = this.login;
    usuarioFilter.senha = this.senha;

    this.usuarioService.buscarAuthResponsePorUsuario(usuarioFilter).subscribe(resp => {
      this.loginIndicator = false;

      if (!resp.success) {
        this.handleLoginError(resp);

        return;
      }

      this.navigate('/home');
    }, error => {
      this.loginIndicator = false;

      console.log(error);
      this.loginErrors.push("Falha ao processar requisição");
    });
  }

  buscarDocumento() {
    if (this.nroDocumento && this.nroDocumento.trim()) {
      this.navigate('/public/documentos',null, null, {senha: this.nroDocumento});
    }
  }

  carrega() {
    return this.of(forkJoin(this.cognitoService.currentAuthenticatedUser(), this.versionService.buscar()), data => {
      if (data[0]) {
        this.navigate('/home/inicial-menu');
      }else{
        this.cognitoService.signOut();
      }

      this.versao = data[1];
    })
  }

  handleLoginError(resp: AuthResponseTO) {
    this.cognitoService.signOut();

    if (!resp.errors || resp.errors.length == 0) {
      this.loginErrors.push('Falha ao processar login');
      return;
    }

    resp.errors.forEach(errorObj => {
      if (errorObj.type == 'info') {
        this.loginErrors.push(errorObj.info);
      } else if (errorObj.type == 'loginFailed') {
        if (errorObj.info == 'usuarioNaoAtivoFiscal') {
          this.loginErrors.push('Apenas usuários ativados são permitidos!');
        } else if (errorObj.info == 'usuarioNaoAtivo') {
          this.loginErrors.push('Apenas usuários ativados são permitidos!');
        } else if (errorObj.info == 'loginInvalido') {
          this.loginErrors.push('Usuário ou senha inválido.');
        } else if (errorObj.info == 'municipioBloqueado') {
          this.loginErrors.push('O acesso deste sistema está bloqueado em função do término do contrato da prefeitura com o fornecedor.');
        } else if (errorObj.info == 'municipioBloqueadoNaoPagamento') {
          this.loginErrors.push('Este sistema ficou indisponível. Favor contatar o suporte técnico da Prefeitura Municipal.');
        } else if (errorObj.info == 'empresaBloqueada') {
          this.loginErrors.push('O acesso dessa empresa foi bloqueado.');
        } else if (errorObj.info == 'custom') {
          this.loginErrors.push('Não foi possível realizar o login.');
        } else if (errorObj.info == 'usuarioNaoAtivoFiscal') {
          this.loginErrors.push('Aguarde a aprovação do fiscal.');
        } else if (errorObj.info == 'empresaBloqueada') {
          this.loginErrors.push(errorObj.info);
        } else if (errorObj.info == 'usuarioNaoAtivo') {
          this.usuarioNaoAtivoError = true;
        }
      }
    });
  }

  onLogin() {
    this.loginIndicator = true;
    this.usuarioInvalido = false;
    this.senhaInvalida = false;

    this.loginErrors = [];
    this.usuarioNaoAtivoError = false;

    if (!this.login || !this.senha) {
      this.loginIndicator = false;

      this.loginErrors.push("O campo usuário e a senha são obrigatórios.");

      return;
    }

    let reg = /[^LM\S{NP}]/g;

    if (reg.test(this.login) || this.senha.length < 6) {
      let loginFilter: LoginFilter = new LoginFilter();

      loginFilter.senha = this.senha;
      loginFilter.usuario = this.login;

      this.loginService.buscar(loginFilter).subscribe(usuario => {
        this.loginIndicator = false;

        if (usuario) {
          //Se tirar não funciona o teste.
          reg = /[^LM\S{NP}]/g;
          if(reg.test(usuario.usuario)){
            this.usuarioInvalido = true;
          }

          if(this.senha.length < 6){
            this.senhaInvalida = true;
          }
          this.usuario = usuario;
          this.novoLogin = "";
          this.confirmaLogin= "";
          this.novaSenha = "";
          this.novaSenhaConfirmacao = "";

          this.popupUsuario = true;
        } else {
          this.loginErrors.push("Campo(s) usuário e/ou senha inválido(s).");

        }
        return;
      });
    } else {
      if (this.trocarSenha) {
        if (this.novaSenha && this.novaSenhaConfirmacao) {
          if (this.novaSenha.length < 6) {
            this.loginIndicator = false;

            this.loginErrors.push("O campo nova senha deve possuir pelo menos seis caracteres.");

            return;
          }

          if (this.novaSenha != this.novaSenhaConfirmacao) {
            this.loginIndicator = false;

            this.loginErrors.push("O campo nova senha e sua confirmação deve ser iguais.");

            this.novaSenha = "";
            this.novaSenhaConfirmacao = "";

            return;
          }

          this.cognitoService.completeNewPassword(this.usuarioCognito, this.novaSenha).subscribe(() => {
            this.trocarSenha = false;

            this.navigate('/home');
          }, err => {
            this.loginIndicator = false;

            console.log(err);
            this.loginErrors.push(err);

            return;
          })
        } else {
          this.loginErrors.push("O campo nova senha e sua confirmação são obrigatórios.");

          return;
        }
      } else {
        this.signIn();
      }
    }
  }

  signIn() {
    this.cognitoService.signIn(this.login, this.senha).subscribe(resp => {
      if (resp.value && resp.value.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.loginIndicator = false;

        this.usuarioCognito = resp.value;
        this.trocarSenha = true;

        return;
      }

      this.cognitoService.cognitoUser.subscribe(u => {
        if (u && u.signInUserSession) {
          this.cognitoService.updateAllCookie(`Bearer ${u.signInUserSession.idToken.jwtToken}`)
        }

      });

      this.buscarAuthResponse();
    }, err => {
      this.loginIndicator = false;

      if (err.code === 'UserNotConfirmedException') {
        this.loginErrors.push("Usuário não foi confirmado.");
      } else if (err.code === 'NotAuthorizedException') {
        this.loginErrors.push("Usuário ou senha inválido.");
      } else if (err.code === 'UserNotFoundException') {
        if (err.message == "Invalid email address format." || err.message == "UserMigration failed with error Bad email.") {
          this.loginErrors.push("E-mail inválido, entre contato com o suporte.");
        } else {
          this.loginErrors.push("Usuário ou senha inválido.");
        }
      } else {
        console.log(err);
        this.loginErrors.push("Falha ao processar requisição.");
      }
    });
  }
}
