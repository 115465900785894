import {JacksonType} from "@sonner/jackson-service-v2";
import {PGDASDDASTO} from "./pgdasd.das.to";
import {Municipio} from "../municipio";

@JacksonType("CatalogoEnderecoTO")
export class CatalogoEnderecoTO {

  id: number;

  bairro: string;

  cep: string;

  complemento: string;

  documento: string;

  email: string;

  endereco: string;

  inscEst: string;

  inscMun: string;

  nome: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  numero: string;

  pais: string;

  telefone: string;

}
