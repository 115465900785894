import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {PGDASDTipoArquivoEnum} from "../enums/pgdas.d.tipo.arquivo.enum";

@JacksonType("ImportacaoPGDASD")
@JsonIdentityInfo()
export class ImportacaoPGDASD extends SonnerBaseEntity {

  id: number;

  bucketName: string;

  @DateType()
  dataArquivo: Date;

  @DateType()
  dataInicioProcessamento: Date;

  @DateType()
  dataFimProcessamento: Date;

  @DateType()
  dataUpload: Date;

  @JacksonType("Pessoa")
  gerador: Pessoa;

  importadoSucesso: boolean;

  keyBase: string;

  nomeBase: string;

  sequenciaArquivo: string;

  @EnumType(PGDASDTipoArquivoEnum)
  tipoArquivo: PGDASDTipoArquivoEnum;
}
