import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Endereco} from "./endereco";
import {Telefone} from "./telefone";
import {Ensino} from "./ensino";
import {Aluno} from "./aluno";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {Municipio} from "./municipio";

@JacksonType("Responsavel")
@JsonIdentityInfo()
export class Responsavel extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  cnh: string;

  documento: string;

  email: string;

  matricula: string;

  @DateType()
  nascimento: Date;

  nome: string;

  passaporte: string;

  residencial: boolean = false;

  rg: string;

  telefone: string;

  //Endereço
  bairro: string;

  cep: string;

  complemento: string;

  @JacksonType("Municipio")
  municipioLogradouro: Municipio;

  logradouro: string;

  numero: string;

  pais: string = "Brasil";

  exibeDoc(): string {
    if (this.documento) {
      return this.documento + " (CPF/CNPJ)";
    } else {
      if (this.rg) {
        return this.rg + " (RG)";
      } else {
        if (this.cnh) {
          return this.cnh + " (CNH)";
        } else {
          if (this.passaporte) {
            return this.passaporte + " (Passaporte)";
          }
        }
      }
    }

    return "Nenhum documento cadastrado.";
  }

  getDisplayValue(): string {
    return this.nome;
  }
}
