import {DateType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Usuario")
@JsonSubType({key: 'br.com.sonner.iss.entity.Usuario', type: "Papel"})
export class Usuario extends Papel {

  @DateType()
  ativadoEm: Date;

  ativador: string;

  motivoBloqueio: string;

  @DateType()
  ultimoLogin: Date;

  usuario: string;

  constructor() {
    super(PerfilEnum.usuario);
  }
}
