import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AlunoCurso} from "./aluno.curso";
import {Atividade} from "./atividade";
import {Ensino} from "./ensino";
import {TipoPeriodoEnum} from "../enums/tipo.periodo.enum";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {CursoValor} from "./curso.valor";

@JacksonType("Curso")
@JsonIdentityInfo()
export class Curso extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  codigo: string;

  @DateType()
  dataBaixa: Date;

  descricao: string;

  @JacksonType("Ensino")
  ensino: Ensino;

  nome: string;

  @EnumType(TipoPeriodoEnum)
  tipoPeriodo: TipoPeriodoEnum;

  valor: number;

  @JacksonType("AlunoCurso")
  alunoCursoList: AlunoCurso[]

  @JacksonType("CursoValor")
  valorCursoList: CursoValor[];


  getDisplayValue(): string {
    return this.nome;
  }
}
