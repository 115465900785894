export enum DesifTipoDependenciaEnum {

  AGENCIA_MATRIZ = "Agência (Matriz)",
  FILIAL = "Agência (filial)",
  UNIDADE_DESMEMBRADA = "Unidade Administrativa Desmembrada - UAD",
  POSTO_ATENDIMENTO = "Posto de Atendimento - PA",
  POSTO_ATENDIMENTO_ELETRONICO = "Posto de Atendimento Eletrônico - PAE",
  POSTO_ATENDIMENTO_COOPERATIVA = "Posto de Atendimento de Cooperativa de Crédito - PAC",
  OUTROS_TIPOS_POSTOS = "Outros tipos de postos "
}
