import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {TipoBloqueioMovimentacao} from "./tipo.bloqueio.movimentacao";

@JacksonType("BloqueioMovimentacao")
@JsonIdentityInfo()
export class BloqueioMovimentacao extends SonnerBaseEntity {

  id: number;

  anoCompetenciaFinal: number;

  anoCompetenciaInicial: number;

  bloqueiaEncerramento: boolean = false;

  bloqueiaEscrituracoes: boolean = false;

  bloqueiaNF: boolean = false;

  competenciaFinalOrdenacao: string;

  competenciaInicialOrdenacao: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataFinal: Date;

  @DateType()
  dataInicial: Date;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  motivo: string;

  mesCompetenciaInicial: number;

  mesCompetenciaFinal: number;

  numeroProcesso: number;

  @JacksonType("TipoBloqueioMovimentacao")
  tipoBloqueioMovimentacao: TipoBloqueioMovimentacao;
}
