import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {ConfiguracaoMenuTO} from "../../../classes/to/configuracao.menu.to";
import {ConfiguracaoMenuFilter} from "../filters/configuracao.menu.filter";

@Injectable()
export class ConfiguracaoMenuService {

  constructor(private restService: RestService){
  }

  buscar(configuracaoMenuFilter: ConfiguracaoMenuFilter): Observable<ConfiguracaoMenuTO> {
    return this.restService.post("/configuracaomenu/buscar", {data: configuracaoMenuFilter, responseType: ConfiguracaoMenuTO});
  }
}
