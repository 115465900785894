import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {convertTipoRegimeISSEnumUtils, TipoRegimeISSEnum} from "../../../../enums/tipo.regime.iss.enum";

@Component({
  selector: "regime-drop-down",
  templateUrl: './regime.drop.down.component.html',
  styleUrls: ['./regime.drop.down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegimeDropDownComponent extends BaseComponent {


  tipoRegimeEnumUtils: any = convertTipoRegimeISSEnumUtils();

  private _itensSelecionados: TipoRegimeISSEnum[];

  @Input()
  readOnly: boolean = false;

  @Output()
  regimeChange:EventEmitter<TipoRegimeISSEnum[]> = new EventEmitter<TipoRegimeISSEnum[]>();


  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }


  get itensSelecionados(): TipoRegimeISSEnum[] {
    return this._itensSelecionados;
  }

  set itensSelecionados(value: TipoRegimeISSEnum[]) {
    this._itensSelecionados = value;
    this.regimeChange.emit(this._itensSelecionados);
  }
}
