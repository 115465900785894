import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {MultaInfracao} from "./multa.infracao";
import {Municipio} from "./municipio";

@JacksonType("Infracao")
@JsonIdentityInfo()
export class Infracao extends SonnerBaseEntity {

  id: number;

  @DateType()
  cadastro: Date;

  descricao: string;

  fundamentoLegal: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  @JacksonType("MultaInfracao")
  multas: MultaInfracao[];
}
