import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {SituacaoTopicoEnum} from "../../enums/situacao.topico.enum";

@JacksonType("TopicoTO")
export class TopicoTO {

  @DateType()
  fim: Date;

  idMunicipio: number

  idTopico: number

  @DateType()
  inicio: Date;

  resposta: string;
}
