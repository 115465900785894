import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("CorrecaoStrategy")
@JsonIdentityInfo()
export class CorrecaoStrategy extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  usarTodosIndices: boolean;

  getDisplayValue(): string {
    return "";
  }
}
