import {DateType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Grafica")
@JsonSubType({key: 'br.com.sonner.iss.entity.Grafica', type: "Papel"})
export class Grafica extends Papel {

  @DateType()
  ate: Date;

  @DateType()
  de: Date;

  constructor() {
    super(PerfilEnum.grafica);
  }
}
