import {DateType, EnumType, JacksonType, JsonIgnore} from "@sonner/jackson-service-v2";
import {Atividade} from "../atividade";
import {AtividadeCTISS} from "../atividade.c.t.i.s.s";
import {AtividadeCNAE} from "../atividade.c.n.a.e";
import {CatalogoEndereco} from "../catalogo.endereco";
import {ConfiguracaoPessoal} from "../configuracao.pessoal";
import {Estado} from "../estado";
import {ExigibilidadeISSEnum} from "../../enums/exigibilidade.iss.enum";
import {Municipio} from "../municipio";
import {PessoaPerfilDescontoImpostoNfe} from "../pessoa.perfil.desconto.imposto.nfe";
import {ObraPrestador} from "../obra.prestador";
import {VinculoAtividade} from "../vinculo.atividade";
import {UnidadeItem} from "../unidade.item";
import {NotaFiscal} from "../nota.fiscal";
import {ObraUnica} from "../obra.unica";
import {AutorizacaoNFe} from "../autorizacao.n.fe";
import {Recibo} from "../recibo";
import {Currency} from "../currency";

@JacksonType("NotaFiscalTO")
export class NotaFiscalTO {

  alertas: string[];

  autorizado: boolean;

  @JacksonType("AutorizacaoNFe")
  autorizacaoNFe: AutorizacaoNFe;

  autorizadoRPS: boolean;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("AtividadeCNAE")
  atividadeCNAE: AtividadeCNAE;

  @JacksonType("AtividadeCTISS")
  atividadeCTISS: AtividadeCTISS;

  @JacksonType("Atividade")
  atividadeList: Atividade[];

  @JacksonType("AtividadeCNAE")
  atividadeCNAEList: AtividadeCNAE[];

  @JacksonType("AtividadeCTISS")
  atividadeCTISSList: AtividadeCTISS[];

  atualizaConfiguracao: boolean;

  bloqueiaTributosFederais: boolean;

  @JacksonType("CatalogoEndereco")
  catalogoEnderecoList: CatalogoEndereco[];

  @JacksonType("ConfiguracaoPessoal")
  configuracaoPessoal: ConfiguracaoPessoal;

  @DateType()
  dataEmissaoOriginal: Date;

  documentoTomadorOriginal: string;

  empresaBloqueada: boolean;

  @JacksonType("Estado")
  estado: Estado;

  @JacksonType("Estado")
  estadoList: Estado[];

  exibeDescontoCondicional: boolean;

  exibeDescontoIncondicional: boolean;

  exibeOutrasDeducoes: boolean;

  exibeUnidades: boolean;

  exibeValorLiquido: boolean;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidadeISSList: ExigibilidadeISSEnum[];

  ignoraSubstituicaoTributaria: boolean;

  movimentacaoBloqueada: boolean;

  @JacksonType("Municipio")
  municipio: Municipio;

  municipioBloqMovimentacao: boolean;

  @JacksonType("NotaFiscal")
  notaFiscal: NotaFiscal;

  @JacksonType("NotaFiscal")
  notaFiscalSubstituida: NotaFiscal;

  notaFiscalSubstituidaData: string;

  notaFiscalSubstituidaEmissao: string;

  notaFiscalSubstituidaNumero: number;

  numero: number;

  @JacksonType("PessoaPerfilDescontoImpostoNFe")
  pessoaPerfilDescontoImpostoNfe: PessoaPerfilDescontoImpostoNfe;

  permiteAlteraDocumentoTomador: boolean;

  permiteAlterarIncidencia: boolean;

  permiteEdicaoDataPrestacao: boolean;

  permiteEdicaoFormaRetencao: boolean;

  permiteRetencaoAutomaticamente: boolean;

  @JacksonType("ObraUnica")
  obraUnica: ObraUnica;

  @JacksonType("ObraPrestador")
  obraPrestadorList: ObraPrestador[];

  @JacksonType("ObraUnica")
  obraUnicaList: ObraUnica[];

  opcoesRetencao: string[];

  @JacksonType("Recibo")
  recibo: Recibo;

  RPS: number;

  salvarTomador: boolean;

  todasAtividadesCTISS: string;

  substituicao: boolean;

  @JacksonType("UnidadeItem")
  unidadeItemList: UnidadeItem[];

  valorGuiaSubstituicao: number;

  @JacksonType("VinculoAtividade")
  vinculoAtividadePrincipal: VinculoAtividade;

  @JacksonType("VinculoAtividade")
  vinculoAtividadeList: VinculoAtividade[];

  // auxiliares

  @JsonIgnore()
  descricaoUnica: string = "";

  @JsonIgnore()
  utilizaValorUnitario: boolean = false;

  @JsonIgnore()
  valorUnico: Currency = new Currency();
}
