import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Documento} from "./documento";
import {Encerramento} from "./encerramento";

@JacksonType("EncerramentoDocumento")
@JsonIdentityInfo()
export class EncerramentoDocumento extends SonnerBaseEntity {

  id: number;

  @JacksonType("Documento")
  documento: Documento;

  @JacksonType("Encerramento")
  encerramento: Encerramento;
}
