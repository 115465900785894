import {CompetenciaDimensao} from "./competencia.dimensao";
import {Municipio} from "./municipio";
import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {DiaDimensao} from "./dia.dimensao";
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {TipoReceitaEnum} from "../enums/tipo.receita.enum";

@JacksonType("ReceitaRealizada")
@JsonIdentityInfo()
export class ReceitaRealizada extends SonnerBaseEntity {

  id: number;

  @JacksonType("CompetenciaDimensao")
  competencia: CompetenciaDimensao;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  idDocumentoOriginal: number;

  identificacaoDocumento: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("DiaDimensao")
  pagamento: DiaDimensao;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  @EnumType(TipoReceitaEnum)
  tipoReceita: TipoReceitaEnum;

  valorJuros: number;

  valorMulta: number;

  valorPrincipal: number;

  @JacksonType("DiaDimensao")
  vencimento: DiaDimensao;
}
