import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {InicioContagemTempoEnum} from "../enums/inicio.contagem.tempo.enum";

@JacksonType("JurosStrategy")
@JsonIdentityInfo()
export class JurosStrategy extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  @EnumType(InicioContagemTempoEnum)
  inicioTempo: InicioContagemTempoEnum;

  getDisplayValue(): string {
    return "";
  }
}
