import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {EspecieAIDF} from "./especie.a.i.d.f";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("Serie")
@JsonIdentityInfo()
export class Serie extends SonnerBaseEntity implements ValueDisplayable{

  id: number;

  anoCompetenciaMaximaEscrit: number;

  avulsa: boolean = false;

  codigo: string;

  @DateType()
  dataCancelamento: Date;

  eletronica: boolean = false;

  @JacksonType("EspecieAIDF")
  especie: EspecieAIDF;

  mesCompetenciaMaximaEscrit: number;

  nome: string;

  requerAutEstadual: boolean = false;

  toString(): string {
    return this.especie.nome + '/' + this.codigo;
  }

  getDisplayValue(): string {
    return this.especie.nome + '/' + this.codigo;
  }


}
