import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {NotaDeducaoFilter} from "../filters/nota.deducao.filter";
import {NotaDeducaoTO} from "../../../classes/to/nota.deducao.to";

@Injectable()
export class NotaDeducaoService {

  constructor(private restService: RestService) {
  }

  buscar(filter: NotaDeducaoFilter): Observable<NotaDeducaoTO> {
    return this.restService.post("/notadeducao/buscar", {data: filter, responseType: NotaDeducaoTO});
  }

  salvar(notaDeducaoTO: NotaDeducaoTO): Observable<any> {
    return this.restService.post("/notadeducao/salvar", {data: notaDeducaoTO});
  }

}
