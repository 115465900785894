import {JacksonType} from "@sonner/jackson-service-v2";
import {ProcessoExportacaoSimples} from "../processo.exportacao.simples";
import {ProcessoExportacaoSimplesDetalhe} from "../processo.exportacao.simples.detalhe";

@JacksonType("ProcessoExportacaoSimplesTO")
export class ProcessoExportacaoSimplesTO {

  @JacksonType("ProcessoExportacaoSimples")
  processoExportacaoSimples: ProcessoExportacaoSimples;

  @JacksonType("ProcessoExportacaoSimplesDetalhe")
  processoExportacaoSimplesDetalheList: ProcessoExportacaoSimplesDetalhe[];

}
