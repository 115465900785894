export enum WebServiceServicoEnum {

  CancelarNfse,
  ConsultarLoteRps,
  ConsultarNfseServicoPrestado,
  ConsultarNfseServicoTomado,
  ConsultarNfsePorFaixa,
  ConsultarNfsePorRps,
  RecepcionarLoteRps,
  GerarNfse,
  SubstituirNfse,
  RecepcionarLoteRpsSincrono
}
