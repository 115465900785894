import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";

@JacksonType("Obra")
@JsonIdentityInfo()
export class Obra extends SonnerBaseEntity {

  id: number;

  alvara: string;

  ativa: boolean = true;

  bairro: string;

  cep: string;

  complemento: string;

  @DateType()
  criacao: Date;

  @JacksonType("Pessoa")
  criador: Pessoa;

  @DateType()
  dataFim: Date;

  @DateType()
  dataInicio: Date;

  descricao: string;

  documentoObra: string;

  documentoProprietario: string;

  isenta: number = 0;

  logradouro: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  numero: string;

  nomeProprietario: string;

  @JacksonType("Municipio")
  responsavel: Municipio;
}
