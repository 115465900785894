import {JacksonType} from "@sonner/jackson-service-v2";
import {Serie} from "../serie";
import {AutorizacaoNFe} from "../autorizacao.n.fe";
import {SolicitacaoNFe} from '../solicitacao.n.fe';
import {SolicitacaoArquivo} from '../solicitacao.arquivo';

@JacksonType("SolicitacaoArquivoTO")
export class SolicitacaoArquivoTO {

  id: string;

  idSolicitacao: string;

  nome: string;

}
