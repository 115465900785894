import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Aluno} from "./aluno";
import {Curso} from "./curso";

@JacksonType("AlunoCurso")
@JsonIdentityInfo()
export class AlunoCurso extends SonnerBaseEntity {

  id: number;

  @JacksonType("Aluno")
  aluno: Aluno;

  @JacksonType("Curso")
  curso: Curso;

  @DateType()
  inicio: Date;

  @DateType()
  fim: Date;

  descontoCondicional: number;

  descontoIncondicional: number;

  @DateType()
  ultimaAlteracao: Date;

}
