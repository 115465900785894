import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {NotaFiscalHistorico} from "./nota.fiscal.historico";
import {UnidadeItem} from "./unidade.item";

@JacksonType("ItemNotaFiscalHistorico")
@JsonIdentityInfo()
export class ItemNotaFiscalHistorico extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  base: number;

  descricao: string;

  @JacksonType("NotaFiscalHistorico")
  notaFiscalHistorico: NotaFiscalHistorico;

  quantidade: number;

  @JacksonType("UnidadeItem")
  unidade: UnidadeItem;

  valorUnitario: number;
}
