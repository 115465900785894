import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {EscritPapel} from "./escrit.papel";
import {GuiaAvulsa} from "./guia.avulsa";
import {Municipio} from "./municipio";
import {NotaFiscal} from "./nota.fiscal";
import {Pessoa} from "./pessoa";

@JacksonType("NotaFiscalGuiaAvulsa")
@JsonIdentityInfo()
export class NotaFiscalGuiaAvulsa extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("EscritPapel")
  escrit: EscritPapel;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("NotaFiscal")
  nota: NotaFiscal;
}
