import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Fiscal")
@JsonSubType({key: 'br.com.sonner.iss.entity.Fiscal', type: "Papel"})
export class Fiscal extends Papel {

  constructor() {
    super(PerfilEnum.fiscal);
  }

  get municipio() {
    return this.ator.responsavel;
  }
}
