import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {RelatorioAssincrono} from "./relatorio.assincrono";

@JacksonType("RelatorioMetadata")
@JsonIdentityInfo()
export class RelatorioMetadata extends SonnerBaseEntity {

  id: number;

  chave: string;

  @JacksonType("RelatorioAssincrono")
  relatorio: RelatorioAssincrono;

  valor: string;
}
