import {AfterViewInit, Directive} from "@angular/core";
import {DxiColumnComponent} from "devextreme-angular/ui/nested";

@Directive({
    selector: 'dxi-column[alignCenterHeader]'
})
export class DxColumHeaderAlignDirective implements AfterViewInit {

  private readonly dxiColumn: DxiColumnComponent;

    constructor(dxiColumn: DxiColumnComponent){
      this.dxiColumn = dxiColumn;
    }

    ngAfterViewInit(): void {
      this.dxiColumn.headerCellTemplate = this.headerCenterCellTemplate;
    }

  headerCenterCellTemplate(element, info) {
    element.closest("td").style["text-align"] = "center";
    return element.innerHTML = info.column.caption;
  }


}
