import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Infracao} from "./infracao";
import {TipoBaseCalculoEnum} from "../enums/tipo.base.calculo.enum";
import {TipoMoedaEnum} from "../enums/tipo.moeda.enum";

@JacksonType("MultaInfracao")
@JsonIdentityInfo()
export class MultaInfracao extends SonnerBaseEntity {

  id: number;

  descricao: string;

  @DateType()
  fimVigencia: Date;

  fundamentoLegal: string;

  @JacksonType("Infracao")
  infracao: Infracao;

  @DateType()
  inicioVigencia: Date;

  @EnumType(TipoBaseCalculoEnum)
  tipoBaseCalculo: TipoBaseCalculoEnum;

  @EnumType(TipoMoedaEnum)
  tipoMoeda: TipoMoedaEnum;

  valorFixo: number;
}
