export enum ReceitaEnum {

  IRPJ = 1001,
  CSLL = 1002,
  COFINS = 1004,
  PIS = 1005,
  INSS = 1006,
  ICMS = 1007,
  IPI = 1008,
  ISS = 1010,
  MULTAIRPJ = 1012,
  MULTACSLL = 1013,
  MULTACOFINS = 1014,
  MULTAPIS = 1015,
  MULTAINSS = 1017,
  MULTAICMS = 1018,
  MULTAIPI = 1019,
  MULTAISS = 1021,
  JUROSIRPJ = 1023,
  JUROSCSLL = 1025,
  JUROSCOFINS = 1026,
  JUROSPIS = 1027,
  JUROSINSS = 1028,
  JUROSICMS = 1029,
  JUROSIPI = 1030,
  JUROSISS = 1031,
  R_D_ATIVA_SIMPLES_NACIONAL = 1507,
  MULTA_SIMPLES_NACIONAL = 1508,
  JUROS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1509,
  R_D_ATIVA_IRPJ_SIMPLES_NACIONAL = 1469,
  R_D_ATIVA_CSLL_SIMPLES_NACIONAL = 1470,
  R_D_ATIVA_COFINS_SIMPLES_NACIONAL = 1471,
  R_D_ATIVA_PIS_SIMPLES_NACIONAL = 1472,
  R_D_ATIVA_IPI_SIMPLES_NACIONAL = 1474,
  R_D_ATIVA_CPP_SIMPLES_NACIONAL = 1475,
  R_D_ATIVA_ICMS_SIMPLES_NACIONAL = 1477,
  R_D_ATIVA_ISS_SIMPLES_NACIONAL = 1478,
  MULTA_IRPJ_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1479,
  MULTA_CSLL_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1481,
  MULTA_COFINS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1482,
  MULTA_PIS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1483,
  MULTA_IPI_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1485,
  MULTA_CPP_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1487,
  MULTA_ICMS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1488,
  MULTA_ISS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1489,
  JUROS_IRPJ_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1491,
  JUROS_CSLL_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1493,
  JUROS_COFINS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1494,
  JUROS_PIS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1495,
  JUROS_IPI_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1497,
  JUROS_CPP_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1498,
  JUROS_ICMS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1501,
  JUROS_ISS_SIMPLES_NACIONAL_DIVIDA_ATIVA = 1502,
  IRPJ_AINF = 1346,
  CSLL_AINF = 1354,
  COFINS_AINF = 1357,
  PIS_AINF = 1362,
  CPP_AINF = 1366,
  ICMS_AINF = 1343,
  IPI_AINF = 1369,
  ISS_AINF = 1344,
  MULTA_IRPJ_AINF = 1348,
  MULTA_CSLL_AINF = 1355,
  MULTA_COFINS_AINF = 1358,
  MULTA_PIS_AINF = 1363,
  MULTA_CPP_AINF = 1367,
  MULTA_ICMS_AINF = 1339,
  MULTA_IPI_AINF = 1371,
  MULTA_ISS_AINF = 1341,
  JUROS_IRPJ_AINF = 1352,
  JUROS_CSLL_AINF = 1356,
  JUROS_COFINS_AINF = 1359,
  JUROS_PIS_AINF = 1365,
  JUROS_CPP_AINF = 1368,
  JUROS_ICMS_AINF = 1340,
  JUROS_IPI_AINF = 1372,
  JUROS_ISS_AINF = 1342,
  SIMPLES = 55,
  MULTA_SIMPLES = 68,
  JUROS_SIMPLES = 71,
  ICMS_SIMPLES = 83,
  ICMS_MULTA_SIMPLES = 96,
  ICMS_JUROS_SIMPLES = 112,
  ISS_SIMPLES = 125,
  ISS_MULTA_SIMPLES = 136,
  ISS_JUROS_SIMPLES = 149,
  INSS_SIMPLES = 151,
  INSS_MULTA_SIMPLES = 164,
  INSS_JUROS_SIMPLES = 177
}
