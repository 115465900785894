import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("SituacaoItem")
@JsonIdentityInfo()
export class SituacaoItem extends SonnerBaseEntity {

  id: number;

  contaBase: boolean = false;

  contaImposto: boolean = false;

  nome: string;

  @EnumType(PapelServicoEnum)
  papelServico: PapelServicoEnum;
}
