import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {GuiaAvulsa} from "./guia.avulsa";

@JacksonType("ItemGuiaAvulsa")
@JsonIdentityInfo()
export class ItemGuiaAvulsa extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  baseCalculo: number = 0;

  descricao: string;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  valor: number;
}
