import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {TipoRelacionamentoEnum} from "../enums/tipo.relacionamento.enum";

@JacksonType("Relacionamento")
@JsonIdentityInfo()
export class Relacionamento extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  destino: Pessoa;

  @JacksonType("Pessoa")
  origem: Pessoa;

  @EnumType(TipoRelacionamentoEnum)
  tipoRelacionamento: TipoRelacionamentoEnum;
}
