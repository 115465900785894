import {Component, Input} from "@angular/core";
import {UserInfoTO} from "../../../core/classes/user.info.to";

@Component({
  selector: 'menu-lateral',
  templateUrl: './menu.lateral.component.html',
  styleUrls: ['./menu.lateral.component.scss']
})
export class MenuLateralComponent {

  @Input()
  userInfo: UserInfoTO;

  @Input()
  activeViewId: string;
}
