import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("TipoEstabelecimentoCartorio")
@JsonIdentityInfo()
export class TipoEstabelecimentoCartorio extends SonnerBaseEntity implements ValueDisplayable{

  id: number;

  descricao: string;

  codigo: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  getDisplayValue(): string {
    return this.codigo +" - "+ this.descricao;
  }

}
