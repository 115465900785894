import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {Atividade} from "../atividade";
import {GrupoAtividade} from "../grupo.atividade";
import {AtividadeHistorico} from "../atividade.historico";
import {AtividadeTO} from "./atividade.to";

@JacksonType("VinculoAtividadeAnexoSimplesTO")
export class VinculoAtividadeAnexoSimplesTO {

  @JacksonType("AtividadeTO")
  atividadesSemVinculo: AtividadeTO[];

  @JacksonType("AtividadeTO")
  atividadesVinculadas: AtividadeTO[];

  atividadesAdicionadas: number[];

  atividadesRemovidas: number[];

  anexo: number;

}
