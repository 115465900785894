import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("InstituicaoBancaria")
@JsonIdentityInfo()
export class InstituicaoBancaria extends SonnerBaseEntity {

  id: number;

  inicioCnpj: string;

  nome: string;
}
