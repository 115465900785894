import {DateType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Solicitacao} from "./solicitacao";

@JacksonType("SolicitacaoNFe")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoNFe', type: 'Solicitacao'})
@JsonIdentityInfo()
export class SolicitacaoNFe extends Solicitacao {

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;
}
