import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("MunicipioFilter")
export class MunicipioFilter {

  codAtvSep: string;

  id: number;

  idEstado: number;
}
