import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {CodigoTipoTributacao} from "./codigo.tipo.tributacao";
import {Municipio} from "./municipio";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("TipoTributacao")
@JsonIdentityInfo()
export class TipoTributacao extends SonnerBaseEntity {

  id: number;

  cancelada: boolean = false;

  @DateType()
  dataCancelamento: Date;

  eletronico: boolean = false;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  incidenciaMunicipio: boolean = false;

  @JacksonType("Municipio")
  municipio: Municipio;

  naoPermitidoST: boolean = false;

  nome: string;

  posicao: number;

  prestador: boolean = false;

  @EnumType(PapelServicoEnum)
  responsavelRecolhimento: PapelServicoEnum;

  retido: boolean = false;

  somenteMateriais: boolean = false;

  tomador: boolean = false;

  valorAbatimento: number;

  @JacksonType("CodigoTipoTributacao")
  codigos: CodigoTipoTributacao[];

  // methods

  disponivel() {
    let disponivel: string = "";

    if (this.eletronico) {
      disponivel += "NF-e";

      if (this.prestador || this.tomador) {
        disponivel += ", "
      }
    }

    if (this.prestador) {
      disponivel += "Escrit. Prestador";

      if (this.tomador) {
        disponivel += ", "
      }
    }

    if (this.tomador) {
      disponivel += "Escrit. Tomador";
    }

    return disponivel;
  }

  recolhimentoRotulo() {
    if (this.responsavelRecolhimento == null) {
      return "Isento";
    }

    return this.responsavelRecolhimento;
  }
}
