import {AfterViewInit, Directive} from "@angular/core";
import * as $ from "jquery";
import {DxTextBoxComponent} from "devextreme-angular";

@Directive({
  selector: '[currencyL2R]'
})
export class CurrencyL2RDirective implements AfterViewInit {

  constructor(private hostElement: DxTextBoxComponent) {
  }

  ngAfterViewInit(): void {
    this.actionDirective();
  }

  private actionDirective() {
    $(this.hostElement['element'].nativeElement).find("input").css('textAlign', 'right');
    $(this.hostElement['element'].nativeElement).find("input").mask('000.000.000.000,00', {reverse: true});
  }
}
