import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("NotaFiscalSimplificadaTO")
export class NotaFiscalSimplificadaTO {

  id: number;

  numero: string;

  senha: string;

  tomador: string;

  valor: number;

  @DateType()
  dataPrestacao: Date;

  @DateType()
  dataEmissao: Date;

  imposto: number;

}
