import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {UserInfoTO} from "../classes/user.info.to";
import {RestService} from "./rest.service";
import {MenuMapTO} from "../classes/menu.map.to";
import {SessionFilter} from "../filters/session.filter";

@Injectable()
export class SessionService {

  constructor(private restService: RestService) {
  }

  acesso(sessionFilter: SessionFilter): Observable<boolean> {
    return this.restService.post("/session/acesso", {data: sessionFilter});
  }

  alterarPessoaSelecionada(sessionFilter: SessionFilter): Observable<any> {
    return this.restService.post("/session/alterarPessoaSelecionada", {data: sessionFilter});
  }

  buscarAvatar(): Observable<string> {
    return this.restService.get("/session/buscarAvatar", {responseType: MenuMapTO});
  }

  buscarMenuMap(sessionFilter: SessionFilter): Observable<MenuMapTO> {
    return this.restService.post("/session/buscarMenuMap", {data: sessionFilter, responseType: MenuMapTO});
  }

  buscarUserInfo(): Observable<UserInfoTO> {
    return this.restService.get('/session/buscarUserInfo', {responseType: UserInfoTO});
  }
}
