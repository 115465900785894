import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "mesPorExtenso"})
export class MesPorExtensoPipe implements PipeTransform {

  transform(mes: number, startWithZero: boolean = true): any {
    if(startWithZero){
      if (mes == 0) {
        return 'Janeiro';
      }

      if (mes == 1) {
        return 'Fevereiro';
      }

      if (mes == 2) {
        return 'Março';
      }

      if (mes == 3) {
        return 'Abril';
      }

      if (mes == 4) {
        return 'Maio';
      }

      if (mes == 5) {
        return 'Junho';
      }

      if (mes == 6) {
        return 'Julho';
      }

      if (mes == 7) {
        return 'Agosto';
      }

      if (mes == 8) {
        return 'Setembro';
      }

      if (mes == 9) {
        return 'Outubro';
      }

      if (mes == 10) {
        return 'Novembro';
      }

      if (mes == 11) {
        return 'Dezembro';
      }
    }else{
      if (mes == 1) {
        return 'Janeiro';
      }

      if (mes == 2) {
        return 'Fevereiro';
      }

      if (mes == 3) {
        return 'Março';
      }

      if (mes == 4) {
        return 'Abril';
      }

      if (mes == 5) {
        return 'Maio';
      }

      if (mes == 6) {
        return 'Junho';
      }

      if (mes == 7) {
        return 'Julho';
      }

      if (mes == 8) {
        return 'Agosto';
      }

      if (mes == 9) {
        return 'Setembro';
      }

      if (mes == 10) {
        return 'Outubro';
      }

      if (mes == 11) {
        return 'Novembro';
      }

      if (mes == 12) {
        return 'Dezembro';
      }
    }
  }
}
