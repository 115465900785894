import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {IBPTArquivo} from "./i.b.p.t.arquivo";
import {IBPTAtividade} from "./i.b.p.t.atividade";

@JacksonType("IBPTTabela")
@JsonIdentityInfo()
export class IBPTTabela extends SonnerBaseEntity {

  id: number;

  @JacksonType("IBPTArquivo")
  arquivo: IBPTArquivo;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  versao: string;

  @JacksonType("IBPTAtividade")
  atividades: IBPTAtividade[];
}
