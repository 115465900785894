import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EstatisticaAcessoFilter")
export class EstatisticaAcessoFilter {

  acao: string;

  constructor(acao: string) {
    this.acao = acao;
  }
}
