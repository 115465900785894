import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Serie} from "../serie";
import {DesifTipoConsolidacaoEnum} from "../../enums/desif.tipo.consolidacao.enum";
import {TipoRegimeISSEnum} from "../../enums/tipo.regime.iss.enum";

@JacksonType("ConfiguracaoAcessoTO")
@JsonIdentityInfo()
export class ConfiguracaoAcessoTO {

  // responsavel

  id: number;

  // acesso

  acessoInscricao: boolean = false;

  permiteSolicitacaoCadastroEventual: boolean = false;

  autorizaNFePrimeiroAcesso: boolean = false;

  downloadArquivoMsgPrimeiroAcesso: boolean = false;

  exigeAprovacaoNovoUsuario: boolean = false;

  exigeCrcCriacaoUsuario: boolean = false;

  exigeEventualCriacaoUsuario: boolean = false;

  exigeVinculoContadorCriacaoUsuario: boolean = false;

  bloqueiaEdicaoRegime: boolean = false;

  bloqueiaEdicaoAtividade: boolean = false;

  // movimentacao

  movInscricao: boolean = false;

  comprovanteInscCadastralExigeInscMun: boolean = false;

  bloqueiaComprovanteInscCadastral: boolean = false;

  naoExibeAreaComprovanteInscCadastral: boolean = false;

  permiteLancNfseOutroContri: boolean = false;

  permiteLancContribInativo: boolean = false;

  // guia

  bloqueiaEmissaoGuiaAvulsa: boolean = false;

  bloqueiaEmissaoGuiaValorFixo: boolean = false;

  bloqueiaEmissaoGuiaPorNFe: boolean = false;

  permiteEmissaoGuiaAvulsaCompetenciaEncerrada: boolean = false;

  bloquearCancelamentoGuiaPaga: boolean = false;

  tsaUnico: boolean = false;

  emissaoGuiaDiaNaoUtil: boolean = false;

  obrigatorioObservacaoGuias: boolean = false;

  mensagemGuia: string;

  utilizaWSBoletoRegistrado: boolean = false;

  urlAcessToken: string;

  urlApiCobranca: string;

  clientId: string;

  // escrituracao

  permiteTomadorNaoIdentEscrit: boolean = false;

  exigeAtividadeEscrituracaoTomador: boolean = false;

  exigeRegimeIssEscrituracaoTomador: boolean = false;

  liberaEscrituracaoMEI: boolean = false;

  permiteEscritBancoAposEncerramento: boolean = false;

  permiteEscritEspecialAposEncerramento: boolean = false;

  edicaoAposEncerramento: boolean = false;

  ocultarServicoSemValorUnitario: boolean = false;

  serieEscritPrestadorOutroMunicipio: string;

  @JacksonType("Serie")
  series: Serie[];

  permiteEscrituracaoAposEncerramento: boolean = true;

  // NFe

  solicitaDeclaracaoAutorizacaoNfe: boolean = false;

  emiteAIDFParaEmissorNFe: boolean = false;

  anfePermiteVencimentoIndeterminado: boolean = false;

  naoPermiteInformarDataFimAnfe: boolean = false;

  exigirDocumentosAnfe: boolean = false;

  documentosExigidosAnfe: string;

  habilitaNotaPremiada: boolean = false;

  notaPremiadaSomentePrestadorPJ: boolean = false;

  notaPremiadaSomenteIncidenciaMunicipio: boolean = false;

  mensagemNotaPremiada: string;

  bloqueiaAlteracaoCadastro: boolean = false;

  novosCadastrosComNaturezaSituacao: boolean = false;

  permiteTomadorNaoIdent: boolean = false;

  bloqueiaRetencaoProprioMunicipio: boolean = false;

  bloqueiaEmissaoNotasRetidasContribuintesEncerrados: boolean = false;

  permiteRetencaoContribuinteEventual: boolean = false;

  permiteRetencaoContribuinteOutroMunicipio: boolean = false;

  obrigarRetencaoTomadorDoMunicipio: boolean = false;

  ativaRetencaoAutomatica: boolean = false;

  desativaRetencaoAutomaticaMei: boolean = false;

  travaDataEmissaoNfe: boolean = false;

  geraReciboTodos: boolean = false;

  naoGeraReciboOrgaoPublico: boolean = false;

  naoGeraReciboGuia: boolean = false;

  bloqueiaTributosFedSimplesNFe: boolean = false;

  permAltAliqSimplesContadores: boolean = false;

  utilizaUnidadesItem: boolean = false;

  autorizacaoGeralParaEmissaoForaDeOrdem: boolean = false;

  maximoDiasAvancaNFe: number;

  maximoDiasRetroageNFe: number;

  mensagemNotaMEI: string;

  regimeSimplesIgnoraSubstitutoTributario: boolean = false;

  requerAutorizacaoEmissaoNfe: boolean = false;

  exibeDescontoIncondicionalNFe: boolean = false;

  exibeDescontoCondicionalNFe: boolean = false;

  exibeOutrasDeducoesNFe: boolean = false;

  exibeValorLiquidoNFe: boolean = false;

  exibeTributosPrestadorSimplesNFe: boolean = false;

  permiteAlteracaoNumeroNFe: boolean = false;

  permiteAlteracaoDocSubstituicaoNFe: boolean = false;

  bloqueiaAlteracaoDataSubstNFe: boolean = false;

  bloqueiaAlteracaoBaseCalcSubstNFe: boolean = false;

  bloqueiaAlteracaoRetencaoSubstNFe: boolean = false;

  bloqCancNfeTodos: boolean = false;

  permCancNfeContadores: boolean = false;

  bloqueiaCancSubstNotasEncIrrelevantes: boolean = false;

  bloqueiaCancSubstNotaPorDiaProxMes: number;

  bloqueiaCancSubstNotaPorDiaPrestacao: boolean = false;

  bloqueiaCancSubstNotaPorDiaEmissao: boolean = false;

  prazoMaximoSolicitacaoNovaNfe: number;

  validadeAutorizacaoNfePredefinidaDias: number;

  exibeMsgCadProvisorioEmissaoNFe: boolean = false;

  msgCadProvisorioEmissaoNFe: string;

  cancelamentoNfeExigeAprovacao: boolean = false;

  msgSolicitacaoCancelamentoNfe: string;

  preVisualizaNFe: boolean = false;

  preVisualizaNFeMensagem: string;

  substitutoTributarioPeriodo: boolean = false;

  // NFe - CB

  bloqueiaEmissaoNFEContrSemEnc: boolean = false;

  bloqueiaEmissaoNFEContrComEnc: boolean = false;

  bloqueiaEmissaoNFEContrSemEncComp: number;

  mesAnoBloqueioEncerramentoMinimo: string;

  mesesAnos: string[] = [];

  alertaBloqueioEmissaoNfeContrEnc: boolean = false;

  alertaBloqueioEmissaoNfeContrEncMEI: boolean = false;

  bloqueiaEmissaoNFEContrSemPagamento: boolean = false;

  bloqueiaEmissaoNFEContrSemPagamentoCompetencia: number;

  mesAnoBloqueioPagamentoMinimo: string;

  alertaBloqueioEmissaoNfeContrPagamento: boolean = false;

  alertaFaltaPagamentoGuias: boolean = false;

  alertaFaltaPagamentoAnoMesInicial: string;

  naoAutorizaAutomaticoComPendendia: boolean = false;

  @EnumType(TipoRegimeISSEnum)
  bloqueiaEmissaoNFRegimes: TipoRegimeISSEnum[];

  // NFe - A

  quantidadeNFeAvulsa: number;

  nfeAvulsaMaximoFaturamentoAnual: number;

  @EnumType(TipoRegimeISSEnum)
  regimesAvulsa: TipoRegimeISSEnum[];

  nfeAvulsaPFSemRegime: boolean = false;

  nfeAvulsaAutorizaPFAutomatico: boolean = false;

  @EnumType(TipoRegimeISSEnum)
  regimesAvulsaPJ: TipoRegimeISSEnum[];

  nfeAvulsaAutorizaPJAutomatico: boolean = false;

  nfeAvulsaAliquotaAtividade: boolean = false;

  nfeAvulsaBloqueiaGuiaNaoPaga: boolean = false;

  nfeAvulsaAutorizaAutomatico: boolean = false;

  nfeAvulsaEscolheAtividadeSeNaoConfigurado: boolean = false;

  nfeAvulsaBloqueiaDeducao: boolean = false;

  nfeAvulsaBloqueiaPrestacao: boolean = false;

  mensagemNotaAvulsa: string;

  bloqueioNfeAvulsaAtividadeUPFMD: boolean = false;

  // DES - IF

  @EnumType(DesifTipoConsolidacaoEnum)
  tiposConsolidacao: DesifTipoConsolidacaoEnum[];

  @DateType()
  desifCompetenciaInicial: Date;

  @DateType()
  desifCompetenciaFinal: Date;

  desifCertificado: boolean = false;

  desifVersao: string;

  // RPS

  requerAutorizacaoImportNfe: boolean = false;

  controleNumeracaoRPS: boolean = false;

  controleNumeracaoRPSobrigatorio: boolean = false;

  nomeAutorizacaoRPS: string;

  dizeresRPS: string;

  taxaAutorizacaoRPS: number;

  denunciaRPS: boolean = false;

  // encerramento

  encerraComAliqAtvHistFatu: boolean = false;

  encerraComDebitoIgualCredito: boolean = false;

  exibeNomeDivisaoGuia: boolean = false;

  nomeDivisao: string;

  textoReciboEncerramento: string;

  @DateType()
  competenciaInicialEncRetroativo: Date;

  usaAtividadeVencimento: boolean = false;

  usaMaiorValorRegimeEstimado: boolean = false;

  mensagemSimplesEstouroLimite: string;

  // notificacao

  emailEncerramentoGuia: boolean = false;

  emailNotificacaoMigrador: string;

  // outros

  exibirNomeSecretaria: string;

  utilizaGraficaCampoLivre: boolean = false;

  autenticaLivroAutomatico: boolean = false;

  livroDetalhado: boolean = false;

  anoMinimoAutenticacaoLivro: number;

  bloqSolAidfNfeRegimeNaoIncidencia: boolean = false;

  exibeAlertaAlteracaoAliquotaSimples: boolean = false;

  permiteAlteracaoAliquotaContrib: boolean = false;

  habilitaTecladoVirtual: boolean = false;

  habilitaContraSenhaFiscalGestor: boolean = false;

  //Exclusão Simples Nacional

  fundamentacaoLegalExclusaoSimples: string;

  nomeAutoridadeAdministrativa: string;

  cargoAutoridadeAdministrativa: string;

  matricularAutoridadeAdministrativa: string;

  localAtendimento: string;

  enderecoLocalAtendimento: string;

  //PIX

  utilizaPix: boolean =false;

  urlPix: string;



  //chat

  horarioComercialInicial: string;

  horarioComercialFinal: string;

  //integração receita federal
  tipoNSU: string;

}
