import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("TipoBloqueioMovimentacao")
@JsonIdentityInfo()
export class TipoBloqueioMovimentacao extends SonnerBaseEntity {

  id: number;

  descricao: string;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  nome: string;
}
