import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ValorTabelaIBPT} from "./valor.tabela.i.b.p.t";

@JacksonType("ItemTabelaIBPT")
@JsonIdentityInfo()
export class ItemTabelaIBPT extends SonnerBaseEntity {

  id: number;

  codigo: string;

  descricao: string;

  @JacksonType("ValorTabelaIBPT")
  valores: ValorTabelaIBPT[];
}
