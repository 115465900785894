import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EscritTransporteArquivoResumoTO")
export class EscritTransporteArquivoResumoTO {

  nomeArquivo: string;

  @DateType()
  horarioEnvio: Date;

  @DateType()
  horarioTermino: Date;

  urlArquivo: string;

  urlErro: string;

  erroProcessamento: boolean;

  valido: boolean;

}
