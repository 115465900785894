import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {Municipio} from "../../../../classes/municipio";
import {Estado} from "../../../../classes/estado";
import {EstadoService} from "../../services/estado.service";
import {MunicipioService} from "../../services/municipio.service";
import {MunicipioFilter} from "../../filters/municipio.filter";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {Subject, Subscription} from "rxjs";
import {switchMap} from "rxjs/operators";

@Component({
  selector: "municipio-selector",
  templateUrl: './municipio.selector.component.html',
  styleUrls: ['./municipio.selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MunicipioSelectorComponent extends BaseComponent {

  get municipio(): Municipio {
    return this._municipio;
  }

  @Input()
  set municipio(value: Municipio) {
    this._municipio = value;

    if (value) {
      if (value.estado && this.estado && value.estado.id == this.estado.id) {
        this.municipioSelecionado = value;
      } else {
        this.estado = value.estado;
      }
    }else{
        this.municipioSelecionado = null;
        this.estado = null;
    }
  }

  @Input()
  readOnly: boolean = false;

  @Input()
  searchEnabled: boolean = true;

  @Input()
  showClearButton: boolean = false;

  @Output()
  municipioChange: EventEmitter<Municipio> = new EventEmitter();

  constructor(private estadoService: EstadoService,
              private injector: Injector,
              private municipioService: MunicipioService) {
    super(injector);
  }

  estado: Estado;

  estadoList: Estado[] = [];

  _municipio: Municipio;

  municipioList: Municipio[] = [];

  municipioSelecionado: Municipio;

  private querySubject: Subject<Estado> = new Subject();

  private querySubscription: Subscription;

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.of(this.estadoService.buscarLista(), estadoList => this.estadoList = estadoList);

    this.querySubscription = this.querySubject.pipe(switchMap(estado => {
      let municipioFilter: MunicipioFilter = new MunicipioFilter();

      if(estado){
        municipioFilter.idEstado = estado.id;
      }

      this.estado = estado;

      return this.municipioService.buscarListaPorEstado(municipioFilter);
    })).subscribe(municipioList => {
      this.municipioList = municipioList;

      if (this._municipio && this._municipio.estado.id == this.estado.id) {
        this.municipioSelecionado = this._municipio;
      } else {
        this.municipioSelecionado = undefined;
      }
    })
  }

  @GlobalLoadingIndicator()
  alteraEstado(estado: Estado) {
    this.querySubject.next(estado);
  }

  alteraMunicipio(municipio: Municipio) {
    if (this.municipioList.length > 0) {
      if (municipio) {
        this.municipioChange.emit(municipio);
      } else {
        this.municipioChange.emit(null);
      }
    }
  }
}
