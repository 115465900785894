import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {AtividadeCNAE} from "../../../classes/atividade.c.n.a.e";
import {AtividadeCNAEFilter} from "../filters/atividade.cnae.filter";

@Injectable()
export class AtividadeCNAEService {

  constructor(private restService: RestService) {
  }

  buscar(atividadeCnaeFilter: AtividadeCNAEFilter): Observable<AtividadeCNAE> {
    return this.restService.post("/atividadecnae/buscar", {data: atividadeCnaeFilter, responseType: AtividadeCNAE});
  }

  buscarListaPorDescricaoOuSubclass(atividadeCnaeFilter: AtividadeCNAEFilter): Observable<AtividadeCNAE[]> {
    return this.restService.post("/atividadecnae/buscarListaPorDescricaoOuSubclass", {data: atividadeCnaeFilter, responseType: AtividadeCNAE});
  }
}
