import {JacksonType, JsonIdentityInfo, SetType} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Pessoa} from "./pessoa";
import {ServicoEspecialGrupo} from "./servico.especial.grupo";
import {ServicoEspecialValor} from "./servico.especial.valor";

@JacksonType("ServicoEspecial")
@JsonIdentityInfo()
export class ServicoEspecial extends SonnerBaseEntity {

  id: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  ativo: boolean;

  codigo: string;

  descricao: string;

  @JacksonType("ServicoEspecialGrupo")
  grupo: ServicoEspecialGrupo;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @JacksonType("ServicoEspecialValor")
  valores: ServicoEspecialValor[];
}
