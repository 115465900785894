import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {FaixaMulta} from "./faixa.multa";
import {MultaStrategy} from "./multa.strategy";

@JacksonType("FaixaMultaStrategy")
@JsonSubType({key: 'br.com.sonner.iss.entity.FaixaMultaStrategy', type: "MultaStrategy"})
export class FaixaMultaStrategy extends MultaStrategy {

  tempo: number;

  @JacksonType("FaixaMulta")
  faixas: FaixaMulta[] = [];

  getDisplayValue(): string {
    if (this.faixas) {
      return "Multa por faixas (" + this.faixas.length + " faixas)";
    }

    return "";
  }
}
