import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AgendaFiscalizacao} from "./agenda.fiscalizacao";
import {TermoAutoInfracao} from "./termo.auto.infracao";
import {TermoInicioFiscalizacao} from "./termo.inicio.fiscalizacao";

@JacksonType("TermoEncerramentoFiscalizacao")
@JsonIdentityInfo()
export class TermoEncerramentoFiscalizacao extends SonnerBaseEntity {

  id: number;

  @JacksonType("AgendaFiscalizacao")
  agendamento: AgendaFiscalizacao;

  descricao: string;

  @DateType()
  emissao: Date;

  senha: string;

  @JacksonType("TermoAutoInfracao")
  termoAutoInfracao: TermoAutoInfracao;

  @JacksonType("TermoInicioFiscalizacao")
  termoInicio: TermoInicioFiscalizacao;
}
