import {EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Atividade} from "./atividade";
import {Estado} from "./estado";
import {Municipio} from "./municipio";
import {Solicitacao} from "./solicitacao";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("SolicitacaoCadastroEventual")
@JsonSubType({key: "br.com.sonner.iss.entity.SolicitacaoCadastroEventual", type: "Solicitacao"})
export class SolicitacaoCadastroEventual extends Solicitacao {

  bairro: string;

  cep: string;

  contribuinteMigrado: boolean = false;

  complemento: string;

  crc: string;

  descricaoAtividade: string;

  documentoContribuinte: string;

  email: string;

  @JacksonType('Estado')
  estadoEndereco: Estado;

  grpMobiliario: string;

  logradouro: string;

  @JacksonType('Municipio')
  municipioEndereco: Municipio;

  nomeContribuinte: string;

  numero: string;

  pais: string;

  senha: string;

  solicitaEmissaoNfe: boolean;

  solicitaEscrituracaoLivrePrestador: boolean;

  telefone: string;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;

  usuario: string;

  @JacksonType('Atividade')
  atividadesContribuinte: Atividade[];
}
