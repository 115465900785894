import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("SolicitacaoAutenticacaoLivroFiscalTO")
export class SolicitacaoAutenticacaoLivroFiscalTO {

  id: string;

  livroAutenticadoExistente: boolean;

}
