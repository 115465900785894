import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";


@JacksonType("Estado")
@JsonIdentityInfo()
export class Estado extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  codigo: string;

  nome: string;

  getDisplayValue(): string {
    return this.codigo;
  }
}
