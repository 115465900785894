import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {JustificativaIndeferimento} from "../../../classes/justificativa.indeferimento";
import {JustificativaIndeferimentoFilter} from "../filters/justificativa.indeferimento.filter";

@Injectable()
export class JustificativaIndeferimentoService {

  constructor(private restService: RestService){
  }

  buscar(justificativaIndeferimentoFilter: JustificativaIndeferimentoFilter): Observable<JustificativaIndeferimento> {
    return this.restService.post("/justificativaindeferimento/buscar", {data: justificativaIndeferimentoFilter, responseType: JustificativaIndeferimento})
  }

  buscarListaPorMunicipio(justificativaIndeferimentoFilter?: JustificativaIndeferimentoFilter): Observable<JustificativaIndeferimento[]> {
    return this.restService.post("/justificativaindeferimento/buscarListaPorMunicipio", {data: justificativaIndeferimentoFilter, responseType: JustificativaIndeferimento})
  }

  buscarListaPorMunicipioETipoSolicitacao(justificativaIndeferimentoFilter: JustificativaIndeferimentoFilter): Observable<JustificativaIndeferimento[]> {
    return this.restService.post("/justificativaindeferimento/buscarListaPorMunicipioETipoSolicitacao", {data: justificativaIndeferimentoFilter, responseType: JustificativaIndeferimento})
  }

  remover(justificativaIndeferimentoFilter: JustificativaIndeferimentoFilter): Observable<any> {
    return this.restService.post("/justificativaindeferimento/remover",{data: justificativaIndeferimentoFilter})
  }

  salvar(justificativaIndeferimento: JustificativaIndeferimento): Observable<any> {
    return this.restService.post("/justificativaindeferimento/salvar", {data: justificativaIndeferimento})
  }
}
