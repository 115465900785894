import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoLogradouroEnum} from "../enums/tipo.logradouro.enum";

@JacksonType("CEP")
@JsonIdentityInfo()
export class CEP extends SonnerBaseEntity {

  id: number;

  bairro: string;

  cep: string;

  logradouro: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  @EnumType(TipoLogradouroEnum)
  tipo: TipoLogradouroEnum;
}
