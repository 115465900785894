import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {Topico} from "./topico";

@JacksonType("Mensagem")
@JsonIdentityInfo()
export class Mensagem extends SonnerBaseEntity {

  id: number;

  conteudo: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataEnvio: Date;

  @JacksonType("Pessoa")
  remetente: Pessoa;

  @JacksonType("Topico")
  topico: Topico;
}
