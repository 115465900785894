import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("PrimeiroAcessoTO")
export class PrimeiroAcessoTO {

  aguardarAprovacao: boolean;

  emailAtivacao: boolean;

  idPessoa: number;
}
