import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifApuracaoMensal} from "./desif.apuracao.mensal";
import {DesifCodigoTributacao} from "./desif.codigo.tributacao";
import {DesifImportacaoArquivo} from "./desif.importacao.arquivo";
import {Pessoa} from "./pessoa";
import {DesifTipoConsolidacaoEnum} from "../enums/desif.tipo.consolidacao.enum";

@JacksonType("DesifApuracaoMensalConsolidado")
@JsonIdentityInfo()
export class DesifApuracaoMensalConsolidado extends SonnerBaseEntity {

  id: number;

  @JacksonType("DesifApuracaoMensal")
  apuracao: DesifApuracaoMensal;

  @JacksonType("DesifImportacaoArquivo")
  arquivo: DesifImportacaoArquivo;

  @JacksonType("DesifCodigoTributacao")
  codigoTributacao: DesifCodigoTributacao;

  @JacksonType("Pessoa")
  dependencia: Pessoa;

  descricaoDeducao: string;

  descricaoIncentivoFiscal: string;

  linhaOrigem: number;

  motivoExigibilidade: string;

  processoExigibilidade: string;

  @JacksonType("Pessoa")
  responsavelRecolhimento: Pessoa;

  @EnumType(DesifTipoConsolidacaoEnum)
  tipoConsolidacao: DesifTipoConsolidacaoEnum;

  tipoArredondamento: number;

  valorAliquota: number;

  valorBaseCalculo: number;

  valorCreditoCompensar: number;

  valorDeducaoConsolidada: number;

  valorDeducaoSubtitulo: number;

  valorReceitaConsolidada: number;

  valorIncentivoFiscal: number;

  valorIncentivoSubtitulo: number;

  valorISSARecolher: number;

  valorISSDevido: number;

  valorISSRecolhido: number;

  valorISSRetido: number;
}
