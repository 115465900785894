import {Component, Injector} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";

@Component({
  selector: 'access-denied-page',
  templateUrl: './access.denied.page.component.html',
  styleUrls: ['./access.denied.page.component.scss']
})
export class AccessDeniedPageComponent extends BaseComponent {

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.disableLoading();
  }
}
