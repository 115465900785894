import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {AppErrorTypeEnum} from "../../../enums/app.error.type.enum";

@Injectable()
export class AppErrorService {

  private _errorSubject: Subject<AppErrorTypeEnum> = new Subject();

  getError(): Observable<AppErrorTypeEnum> {
    return this._errorSubject;
  }

  setError(error: AppErrorTypeEnum): void {
    this._errorSubject.next(error);
  }

  private _homeSubject: Subject<boolean> = new Subject();

  getHome(): Observable<boolean> {
    return this._homeSubject;
  }

  setHome(home: boolean): void {
    this._homeSubject.next(home);
  }
}
