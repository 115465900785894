import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("Pais")
@JsonIdentityInfo()
export class Pais extends SonnerBaseEntity {

  id: number;

  codigoBacen: number;

  nome: string;

  padrao: boolean;
}
