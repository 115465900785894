import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ObraUnica} from "./obra.unica";

@JacksonType("EscrituracaoObraUnica")
@JsonIdentityInfo()
export class EscrituracaoObraUnica extends SonnerBaseEntity {

  id: number;

  @DateType()
  data: Date;

  documentoPrestador: string;

  motivoCorrecao: string;

  nomePrestador: string;

  numero: number;

  @JacksonType("ObraUnica")
  obraUnica: ObraUnica;

  serie: string;

  valor: number;

  valorAntesCorrecao: number;

  valorTotalNota: number;

  editavel: boolean;
}
