import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("AtividadeFilter")
export class AtividadeFilter {

  atividade116: boolean = false;

  codigo: string;

  @DateType()
  dataPrestacao: Date;

  guiaAvulsa: boolean = false;

  id: number;

  idAtividade: number;

  idGrupoAtividade: number;

  idMunicipio: number;

  idPrestador: number;

  idTomador: number;

  anexoSimples: number;

}
