import {JacksonType} from "@sonner/jackson-service-v2";
import {SIAFIPagamentosCompetenciaTO} from "./siafi.pagamentos.competencia.to";

@JacksonType("SIAFIPagamentosTO")
export class SIAFIPagamentosTO{

  @JacksonType("SIAFIPagamentosCompetenciaTO")
  pagamentosCompetencia: SIAFIPagamentosCompetenciaTO[];

}
