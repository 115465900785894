import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AtividadeCNAE} from "./atividade.c.n.a.e";
import {AtividadeCTISS} from "./atividade.c.t.i.s.s";

@JacksonType("VinculoCnaeCtiss")
@JsonIdentityInfo()
export class VinculoCnaeCtiss extends SonnerBaseEntity {

  id: number;

  @JacksonType("AtividadeCNAE")
  atividadeCNAE: AtividadeCNAE;

  @JacksonType("AtividadeCTISS")
  atividadeCTISS: AtividadeCTISS;
}
