import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Estado} from "./estado";
import {IBPTTabela} from "./i.b.p.t.tabela";
import {IBPTVinculoAtividade} from "./i.b.p.t.vinculo.atividade";

@JacksonType("IBPTAtividade")
@JsonIdentityInfo()
export class IBPTAtividade extends SonnerBaseEntity {

  id: number;

  aliquotaEstadual: number;

  aliquotaImportadosFederal: number;

  aliquotaMunicipal: number;

  aliquotaNacionalFederal: number;

  codigoAtividade: string;

  descricao: string;

  @JacksonType("Estado")
  estado: Estado;

  @JacksonType("IBPTTabela")
  ibptTabela: IBPTTabela;

  @JacksonType("IBPTVinculoAtividade")
  vinculos: IBPTVinculoAtividade[];
}
