import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";

import * as _ from 'lodash';
import {Observable} from "rxjs";
import {ExceptionInfo} from "../classes/exception.info";
import {JacksonService} from "@sonner/jackson-service-v2";
import {TipoExceptionEnum} from "../../../enums/tipo.exception.enum";
import {CognitoService} from "./cognito.service";

@Injectable()
export class RestService {

  private uriPrefix = "/v2/rest";

  constructor(public httpClient: HttpClient,
              private jacksonService: JacksonService,
              private cognitoService: CognitoService) {
  }

  get(endpoint: string, serviceOptions?: ServiceOptions): Observable<any> {
    let observable = this.httpClient.request('GET', this.url(endpoint), this.getHttpOptions(serviceOptions));

    observable = this.parseResponse(observable, serviceOptions);

    return this.observable(endpoint, observable);
  }

  put(endpoint: string, serviceOptions?: ServiceOptions): Observable<any> {
    let observable = this.httpClient.request('PUT', this.url(endpoint), this.getHttpOptions(serviceOptions));

    observable = this.parseResponse(observable, serviceOptions);

    return this.observable(endpoint, observable);
  }

  post(endpoint: string, serviceOptions?: ServiceOptions): Observable<any> {
    let observable = this.httpClient.request('POST', this.url(endpoint), this.getHttpOptions(serviceOptions));

    observable = this.parseResponse(observable, serviceOptions);

    return this.observable(endpoint, observable);
  }

  remove(endpoint: string, serviceOptions?: ServiceOptions): Observable<any> {
    let observable = this.httpClient.request('DELETE', this.url(endpoint), this.getHttpOptions(serviceOptions));

    observable = this.parseResponse(observable, serviceOptions);

    return this.observable(endpoint, observable);
  }

  observable(endpoint: string, observable) {
    if (endpoint.includes("/public/")) {
      return observable;
    }

    return this.cognitoService.auth(observable);
  }

  url(endpoint: string): string {
    return this.uriPrefix + endpoint;
  }

  private getHttpOptions(serviceOptions?: ServiceOptions): object {
    let opts = {};
    let ignoreData = false;

    if (!_.isNil(serviceOptions) && serviceOptions.dataAsForm) {
      opts['headers'] = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded");

      if (!_.isNil(serviceOptions.data)) {
        let data = new HttpParams();
        _.forOwn(serviceOptions.data, (val, key) => {
          if (!_.isNil(val)) {
            data = data.set(key, val);
          }
        });

        opts['body'] = data;
        ignoreData = true;
      }
    } else {
      opts['headers'] = new HttpHeaders().set("Content-type", "application/json");
    }

    if (!_.isNil(serviceOptions) && !_.isNil(serviceOptions.queryParams)) {
      opts['params'] = this.serializeParams(serviceOptions.queryParams);
    }

    if (!ignoreData && !_.isNil(serviceOptions) && !_.isNil(serviceOptions.data)) {
      if (serviceOptions.dataAsTuple) {
        let tuple = {};
        let arr = this.jacksonService.encodeToJsPlain(serviceOptions.data);

        for (let i = 0; i < (<any[]>arr).length; i++) {
          let key = 'val' + i;
          tuple[key] = arr[i];
        }

        opts['body'] = this.jacksonService.encodeToJson(tuple);
      } else {
        opts['body'] = this.jacksonService.encodeToJson(serviceOptions.data);
      }
    }

    if (serviceOptions) {
      if (serviceOptions.responseType == 'text') {
        opts['responseType'] = 'text';
      }

      if (serviceOptions.eager) {
        if (_.isEmpty(serviceOptions.headers)) {
          serviceOptions.headers = [];
        }

        serviceOptions.headers.push(new HttpHeader('eager', 'true'));
      }
    }


    if (serviceOptions && serviceOptions.headers) {
      serviceOptions.headers.forEach(httpHeader => {
        opts['headers'] = opts['headers'].set(httpHeader.key, httpHeader.value);
      });
    }

    return opts;
  }

  private serializeParams(params: object): HttpParams {
    let httpParams = new HttpParams();

    _.forOwn(params, (value, key) => httpParams = this.serializaParam(httpParams, key, value));

    return httpParams;
  }

  private serializaParam(httpParams: HttpParams, param: string, value: any): HttpParams {
    if (!_.isNil(value)) {
      if (_.isArray(value)) {
        value.forEach((arrValue) => httpParams = this.serializaParam(httpParams, param, arrValue));
      } else if (_.isNumber(value) || _.isBoolean(value)) {
        httpParams = httpParams.append(param, "" + value);
      } else if (_.isDate(value)) {
        let formattedDate = (value.getFullYear() - 1900) + '-' + value.getMonth() + '-' + value.getDate() + '-' + value.getHours() + '-' + value.getMinutes() + '-' + value.getSeconds();

        httpParams = httpParams.append(param, formattedDate);
      } else {
        httpParams = httpParams.append(param, value);
      }
    }

    return httpParams;
  }

  private parseResponse(httpResponse: Observable<Object>, serviceOptions?: ServiceOptions): Observable<Object> {
    let response = httpResponse;

    if (!_.isNil(serviceOptions) && !_.isNil(serviceOptions.responseType)) {
      if (_.isArray(serviceOptions.responseType)) {
        const tupleType = <any[]>serviceOptions.responseType;

        response = response.pipe(map(data => {
          let tuple = [];

          for (let i = 0; i < tupleType.length; i++) {
            let key = 'val' + i;
            let val = data[key];

            if (_.isNil(val) || _.isString(val) || _.isNumber(val) || _.isBoolean(val)) {
              tuple.push(val);
            } else {
              tuple.push(this.jacksonService.decode(val, serviceOptions.responseType[i]));
            }
          }

          return tuple;
        }));

      } else {
        const respTypeConf = <any>serviceOptions.responseType;

        if (respTypeConf !== 'text') {
          response = response.pipe(map(data => {
            try {
              return this.jacksonService.decode(data, <Function>respTypeConf);
            } catch (e) {
              console.log("Falha ao ler resposta de serviço rest:");
              console.log(e);

              let ex = new ExceptionInfo();

              ex.exceptionMessage = "Falha ao ler resposta de serviço rest: " + e;

              throw ex;
            }
          }));
        }
      }
    }

    response = response.pipe(catchError((err: any) => {
      if (err['exceptionMessage']) {
        throw err;
      }

      throw this.parseHttpError(err as HttpErrorResponse);
    }));

    return response;
  }

  private parseHttpError(httpError: HttpErrorResponse): ExceptionInfo {
    if (httpError.status == 409) {
      return <ExceptionInfo>this.jacksonService.decode(httpError.error, ExceptionInfo);
    } else {
      console.log(httpError);

      let exInfo = new ExceptionInfo();

      exInfo.tipo = TipoExceptionEnum.ServerError;
      exInfo.exceptionMessage = "Falha ao processar requisição. Por favor, consulte seu suporte técnico";
      exInfo.stackTrace = httpError.message;

      return exInfo;
    }
  }
}

export interface ServiceOptions {

  data?: any;

  dataAsForm?: boolean;

  dataAsTuple?: boolean;

  eager?: boolean;

  headers?: HttpHeader[];

  queryParams?: object;

  responseType?: Function | any[] | 'text';
}

export class HttpHeader {
  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }

  key: string;

  value: string;
}
