import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("ConsultaContribuinteAutorizacoesTO")
export class ConsultaContribuinteAutorizacoesTO {

  id:number;

  dataSolicitacao: string;

  tipo: string;

  situacao: string;

  dataSituacao: string;

  validade: string;

  senha: string;

}
