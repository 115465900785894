import {Component, Injector} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";

@Component({
  selector: 'not-found-page',
  templateUrl: './not.found.page.component.html',
  styleUrls: ['./not.found.page.component.scss']
})
export class NotFoundPageComponent extends BaseComponent {

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.disableLoading()
  }
}
