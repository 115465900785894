import {Component, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import {BaseComponent} from "../../base/base.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormatoRelatorioEnum} from "../../../../../enums/formato.relatorio.enum";
import {RelatorioAssincronoService} from "../../../../commons/services/relatorio.assincrono.service";
import {CustomDataGridComponent} from "../../custom-data-grid/custom.data.grid.component";
import {RelatorioAssincrono} from "../../../../../classes/relatorio.assincrono";

@Component({
  selector: 'accordion-panel',
  templateUrl: './accordion.panel.component.html',
  styleUrls: ['./accordion.panel.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('true', style({height: '*', visibility: 'visible'})),
      state('false', style({height: '0px', visibility: 'hidden'})),
      transition('true <=> false', animate('300ms'))
    ])
  ]
})
export class AccordionPanelComponent extends BaseComponent {

  @ViewChild("gridPrintResult")
  gridPrintResult: CustomDataGridComponent;

  toolTipPrint: boolean = false;

  toolTipPrintResult: boolean = false;

  printResultList: RelatorioAssincrono[];

  @Input()
  icon: string;

  @Input()
  opened: boolean = false;

  @Input()
  closable: boolean =true;

  @Input()
  title: string;

  @Input()
  showSubTitle: boolean = false;

  @Input()
  subTitle: string;

  @Input()
  backGrounColor: string = 'transparent';

  @Output()
  toggle: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  showButton: boolean = false;

  @Input()
  iconButton: string = 'fa fa-print';

  @Input()
  colorIconButton: string = 'black';

  @Input()
  idToolTipPrint: string = "tooltip-print-list";

  @Input()
  idToolTipPrintResult: string = "tooltip-print-result";

  @Input()
  showToolTipPrint: boolean = false;

  @Input()
  toolTipPrintTitle: string = "Impressão"

  @Input()
  printListOptions: PrintOption[] = [];

  @Input()
  tipoRelatorio: string;

  @Input()
  index: number;

  @Output()
  onButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onPrintClick: EventEmitter<PrintOption> = new EventEmitter<PrintOption>();

  constructor(private injector: Injector,
              private relatorioAssincronoService: RelatorioAssincronoService) {
    super(injector);

    this.updatePrintResults = this.updatePrintResults.bind(this);
  }

  titleStyle() {
    return {'border-bottom': this.opened ? '1px solid #ccc' : 'none','background':this.backGrounColor}
  }

  buttonStyle() {
    return {'color': this.colorIconButton, 'cursor': 'pointer'}
  }

  buttonClick(evento: any){
    this.onButtonClick.emit(evento);
  }

  printClick(option?: PrintOption) {
    this.toolTipPrint = !this.toolTipPrint;
    if(option){
      this.onPrintClick.emit(option);
    }
  }

  printResultClick() {
    if(this.tipoRelatorio){
      this.updatePrintResults();
      this.toolTipPrintResult = !this.toolTipPrintResult;
    }
  }

  getIconPrintClass(formato: FormatoRelatorioEnum): string{
    if(formato == FormatoRelatorioEnum.PDF){
      return "fa fa-file-pdf-o";
    }
    return "fa fa-file-excel-o";
  }

  updatePrintResults(){
    this.of(this.relatorioAssincronoService.busca(this.tipoRelatorio,10),relatorio =>{
      this.printResultList = relatorio.relatorios;
    });
  }

  click(){
    if(this.closable){
      this.opened = !this.opened;
    }else{
      this.opened = true;
    }
    if((this.index || this.index == 0) && this.opened){
      this.onClick.emit(this.index);
    }
    return this.opened;
  }

}

export class PrintOption{
  label: string;
  acao: string;
  formato: FormatoRelatorioEnum;


  constructor(label: string, acao: string, formato: FormatoRelatorioEnum) {
    this.label = label;
    this.acao = acao;
    this.formato = formato;
  }
}
