import {Component, Injector, Input} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {Documento} from "../../../../classes/documento";

@Component({
  selector: 'cabecalho-documento',
  templateUrl: './cabecalho.documento.component.html',
  styleUrls: ['./cabecalho.documento.component.scss']
})
export class CabecalhoDocumentoComponent extends BaseComponent {

  @Input()
  documento: Documento;

  @Input()
  mostrarCancelar: boolean;

  @Input()
  nomeSecretaria: string;

  @Input()
  producao: boolean;

  brasao: string;

  constructor(private injector: Injector) {
    super(injector);
  }

  doOnInit() {
    this.brasao = "v2/rest/public/buscarLogo/" + this.documento.municipio.id + "/" + Math.random();
  }
}
