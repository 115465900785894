import {JacksonType} from "@sonner/jackson-service-v2";
import {VinculoAtividade} from "../vinculo.atividade";
import {Pessoa} from "../pessoa";
import {ConsultaContribuinteLivrosTO} from "./consulta.contribuinte.livros.to";
import {ConsultaContribuinteAutorizacoesTO} from "./consulta.contribuinte.autorizacoes.to";
import {AlertaFiscalTO} from "./alerta.fiscal.to";
import {NotaEscritMesTO} from "./nota.escrit.mes.to";
import {GuiaMesTO} from "./guia.mes.to";
import {CreditoTO} from "./credito.to";
import {PgdasdTO} from "./pgdasd.to";
import {ParcelamentoSimplesTO} from "./parcelamento.simples.to";
import {SaldoCompetenciaTO} from './saldo.competencia.to';
import {SIAFIPagamentosTO} from "./siafi.pagamentos.to";
import { EncerramentoMesTO } from './encerramento.mes.to';

@JacksonType("ConsultaContribuinteTO")
export class ConsultaContribuinteTO {

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("VinculoAtividade")
  atividades: VinculoAtividade[];

  @JacksonType("ConsultaContribuinteLivrosTO")
  livros: ConsultaContribuinteLivrosTO[];

  @JacksonType("ConsultaContribuinteAutorizacoesTO")
  autorizacoes: ConsultaContribuinteAutorizacoesTO[];

  @JacksonType("AlertaFiscalTO")
  alertas: AlertaFiscalTO[];

  @JacksonType("NotaEscritMesTO")
  notasEscrits: NotaEscritMesTO[];

  @JacksonType("GuiaMesTO")
  guias: GuiaMesTO[];

  @JacksonType("CreditoTO")
  creditos: CreditoTO[];

  @JacksonType("PgdasdTO")
  pgdasd:PgdasdTO[]

  @JacksonType("ParcelamentoSimplesTO")
  parcelamentos:ParcelamentoSimplesTO[]

  @JacksonType("SaldoCompetenciaTO")
  saldosCompetencias: SaldoCompetenciaTO[];

  @JacksonType("SIAFIPagamentosTO")
  siafiPagamentosTO: SIAFIPagamentosTO;

  @JacksonType("EncerramentoMesTO")
  encerramentos: EncerramentoMesTO[];

  ultimoRPS: number;

}
