import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "./rest.service";
import {Municipio} from "../../../classes/municipio";
import {UsuarioTO} from "../../../classes/to/usuario.to";
import {Atividade} from "../../../classes/atividade";
import {CEP} from "../../../classes/c.e.p";
import {Usuario} from "../../../classes/usuario";
import {LoginFilter} from "../filters/login.filter";
import {PrimeiroAcessoTO} from '../../../classes/to/primeiro.acesso.to';
import {BilheteNotaPremiadaContribuinteTO} from "../../../classes/to/bilhete.nota.premiada.contribuinte.to";
import {BilheteNotaPremiadaPeriodoTO} from "../../../classes/to/bilhete.nota.premiada.periodo.to";

@Injectable()
export class LoginService {

  constructor(private restService: RestService) {
  }

  atualizar(loginFilter: LoginFilter): Observable<Usuario> {
    return this.restService.post("/public/login/atualizar", {data: loginFilter, responseType: Usuario});
  }

  autorizar(loginFilter: LoginFilter): Observable<UsuarioTO> {
    return this.restService.post("/public/login/autorizar", {data: loginFilter, responseType: UsuarioTO});
  }

  buscar(loginFilter: LoginFilter): Observable<Usuario> {
    return this.restService.post("/public/login/buscar", {data: loginFilter, responseType: Usuario});
  }

  buscarBilhetes(loginFilter: LoginFilter): Observable<BilheteNotaPremiadaPeriodoTO[]> {
    return this.restService.post("/public/login/buscarBilhetes", {data: loginFilter, responseType: BilheteNotaPremiadaPeriodoTO});
  }

  buscarListaAtividadePorMunicipio(loginFilter: LoginFilter): Observable<Atividade[]> {
    return this.restService.post("/public/login/buscarListaAtividadePorMunicipio", {data: loginFilter, responseType: Atividade});
  }

  buscarCEPPorCEP(loginFilter: LoginFilter): Observable<CEP> {
    return this.restService.post("/public/login/buscarCEPPorCEP", {data: loginFilter, responseType: CEP});
  }

  buscarListaMunicipioCliente(): Observable<Municipio[]> {
    return this.restService.get("/public/login/buscarListaMunicipioCliente", {responseType: Municipio});
  }

  buscarListaMunicipioNotaPremiada(): Observable<Municipio[]> {
    return this.restService.get("/public/login/buscarListaMunicipioNotaPremiada", {responseType: Municipio});
  }

  buscarListaMunicipioPorEstado(loginFilter: LoginFilter): Observable<Municipio[]> {
    return this.restService.post("/public/login/buscarListaMunicipioPorEstado", {data: loginFilter, responseType: Municipio});
  }

  cadastrar(usuarioTO: UsuarioTO): Observable<PrimeiroAcessoTO> {
    return this.restService.post("/public/login/cadastrar", {data: usuarioTO, responseType: PrimeiroAcessoTO});
  }

  enviar(loginFilter: LoginFilter): Observable<any> {
    return this.restService.post("/public/login/enviar", {data: loginFilter});
  }

  enviarSenha(loginFilter: LoginFilter): Observable<any> {
    return this.restService.post("/public/login/enviarSenha", {data: loginFilter});
  }
}
