import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Papel")
@JsonIdentityInfo()
export class Papel extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  ator: Pessoa;

  @EnumType(PerfilEnum)
  perfil: PerfilEnum = PerfilEnum.usuario;

  constructor(perfil: PerfilEnum) {
    super();

    this.perfil = perfil;
  }
}
