import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifConta} from "./desif.conta";
import {DesifPlanoConta} from "./desif.plano.conta";

@JacksonType("DesifTarifa")
@JsonIdentityInfo()
export class DesifTarifa extends SonnerBaseEntity {

  id: number;

  @JacksonType("DesifConta")
  conta: DesifConta;

  codigo: string;

  descricao: string;

  desdobramentoContaMista: string;

  @DateType()
  inicioVigencia: Date;

  @JacksonType("DesifPlanoConta")
  planoConta: DesifPlanoConta;

  valorPercentual: number;

  valorUnitario: number;
}
