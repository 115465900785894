import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("ValorFolhaPagamento")
@JsonIdentityInfo()
export class ValorFolhaPagamento extends SonnerBaseEntity {

  id: number;

  @DateType()
  dataInicio: Date;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  valor: number;
}
