import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Arquivo} from "../../../classes/arquivo";
import {ArquivoFilter} from "../filters/arquivo.filter";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";

@Injectable()
export class ArquivoService {

  constructor(private httpClient: HttpClient,
              private restService: RestService) {
  }

  buscar(arquivoFilter: ArquivoFilter): Observable<Arquivo> {
    return this.restService.post("/arquivo/buscar", {data: arquivoFilter, responseType: Arquivo});
  }

  download(url: string, data?): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(url, { headers: new HttpHeaders().set("Content-type", "application/json"), responseType: 'blob', observe: 'response' });
  }

  remover(arquivoFilter: ArquivoFilter): Observable<any> {
    return this.restService.post("/arquivo/remover", {data: arquivoFilter});
  }
}
