import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {CatalogoEndereco} from "../../../classes/catalogo.endereco";
import {CatalogoEnderecoFilter} from "../../configuracao/filters/catalogo.endereco.filter";

@Injectable()
export class CatalogoEnderecoService {

  constructor(private restService: RestService) {
  }

  carregar(): Observable<CatalogoEndereco[]> {
    return this.restService.post("/catalogoendereco/carregar", {responseType: CatalogoEndereco});
  }

  remover(catalogoEnderecoFilter: CatalogoEnderecoFilter): Observable<any> {
    return this.restService.post("/catalogoendereco/remover", {data:catalogoEnderecoFilter});
  }
}
