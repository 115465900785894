import {JacksonType} from "@sonner/jackson-service-v2";
import {EscritBanco} from "../escrit.banco";
import {EscritEspecial} from "../escrit.especial";
import {EscritPedagio} from "../escrit.pedagio";
import {ConsultaContribuinteNotaEscritTO} from "./consulta.contribuinte.nota.escrit.to";
import {DateUtilsService} from "../../modules/core/services/date.utils.service";
import {EscritCartorio} from "../escrit.cartorio";
import {EscritTransporte} from "../escrit.transporte";
import {GuiaAvulsa} from "../guia.avulsa";

@JacksonType("GuiaSimplificadaTO")
export class GuiaSimplificadaTO {

  numero: string;

  tipo: string;

  pagavel: string;

  dataBaixa: string;

  valor: number;

  senha: string;

  cancelado: boolean;

}
