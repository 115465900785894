import {JacksonType} from "@sonner/jackson-service-v2";
import {ConfigNotaPremiada} from "../config.nota.premiada";
import {ConfigNotaPremiadaPeriodo} from "../config.nota.premiada.periodo";
import {ConfigNotaPremiadaDocumentosExcluidos} from "../config.nota.premiada.documentos.excluidos";

@JacksonType("ConfigNotaPremiadaTO")
export class ConfigNotaPremiadaTO {

  @JacksonType("ConfigNotaPremiada")
  configNotaPremiadaList: ConfigNotaPremiada[];

  utilizaValorFixo: boolean;

  valorFixo: number;

  @JacksonType("ConfigNotaPremiadaPeriodo")
  configNotaPremiadaPeriodos: ConfigNotaPremiadaPeriodo[];

  @JacksonType("ConfigNotaPremiadaDocumentosExcluidos")
  configNotaPremiadaDocumentosExcluidos: ConfigNotaPremiadaDocumentosExcluidos[];
}
