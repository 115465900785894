import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("ConfigNotaPremiadaPeriodo")
@JsonIdentityInfo()
export class ConfigNotaPremiadaPeriodo extends SonnerBaseEntity {

  id: number;

  @DateType()
  periodoInicial: Date;

  @DateType()
  periodoFinal: Date;

  ativo: boolean;

  @JacksonType("Municipio")
  municipio: Municipio;

  @DateType()
  dataLimite: Date;

  quantidadeGanhador: number;

}
