import {RootInjectorFacade} from "../services/root.injector.facade.service";
import {GlobalLoadingIndicatorService} from "../services/global.loading.indicator.service";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";

export function GlobalLoadingIndicator(loadMessage: string = "Carregando") {
  return function (target: any, key: string, value: any) {
    return {
      value: function (...args: any[]) {

        const service: GlobalLoadingIndicatorService = RootInjectorFacade.get(GlobalLoadingIndicatorService);

        service.setLoadState(true, loadMessage);

        const result: Observable<any> = value.value.apply(this, args);

        if (result) {
          result.pipe(first()).subscribe(_ => {
            service.setLoadState(false);
          }, err => {
            service.setLoadState(false);
          });
        } else {
          service.setLoadState(false);
        }

        return result;
      }
    }
  }

}
