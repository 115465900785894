import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AlunoCurso} from "./aluno.curso";
import {Ensino} from "./ensino";
import {Responsavel} from "./responsavel";
import {Municipio} from "./municipio";

@JacksonType("Aluno")
@JsonIdentityInfo()
export class Aluno extends SonnerBaseEntity {

  id: number;

  cnh: string;

  documento: string;

  email: string;

  @JacksonType("Ensino")
  ensino: Ensino;

  matricula: string;

  @DateType()
  nascimento: Date;

  nome: string;

  passaporte: string;

  residencial: boolean = false;

  @JacksonType("Responsavel")
  responsavel: Responsavel;

  rg: string;

  telefone: string;

  //Endereço
  bairro: string;

  cep: string;

  complemento: string;

  @JacksonType("Municipio")
  municipioLogradouro: Municipio;

  logradouro: string;

  numero: string;

  pais: string = "Brasil";

  @JacksonType("AlunoCurso")
  alunoCursoList: AlunoCurso[]

  proprioResponsavel: boolean;

  exibeDoc(): string {
    if (this.documento) {
      return this.documento + " (CPF/CNPJ)";
    } else {
      if (this.rg) {
          return this.rg + " (RG)";
      } else {
          if (this.cnh) {
            return this.cnh + " (CNH)";
          } else {
          if (this.passaporte) {
              return this.passaporte + " (Passaporte)";
          }
        }
      }
    }

    return "Nenhum documento cadastrado.";
  }
}
