import {JacksonType, MapType} from "@sonner/jackson-service-v2";
import {EncerramentoMesTO} from "./encerramento.mes.to";
import {Pessoa} from "../pessoa";

@JacksonType("EncerramentoAnoMesTO")
export class EncerramentoAnoMesTO {

  ano: number;

  mesesPrestador: string[12];

  mesesTomador: string[12];

}
