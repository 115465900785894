import {Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {Pessoa} from "../../../../classes/pessoa";
import {AutocompleteComponent} from "../../../ui/components/autocomplete/autocomplete.component";
import {PessoaService} from "../../services/pessoa.service";
import {PessoaFilter} from "../../filters/pessoa.filter";
import {switchMap} from "rxjs/operators";
import {of, Subject, Subscription} from "rxjs";

@Component({
  selector: "pessoa-autocomplete",
  templateUrl: './pessoa.autocomplete.component.html',
  styleUrls: ['./pessoa.autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PessoaAutocompleteComponent extends BaseComponent {

  @Input()
  set item(value: Pessoa) {
    this._item = value;
    this.itemChange.emit(value);
  }

  get item(): Pessoa {
    return this._item;
  }

  @Input()
  placeholder: string = "Busca por documento completo ou nome...";

  @Input()
  readOnly: boolean = false;

  @Input()
  showClearButton: boolean = false;

  @Input()
  displayProp: string = "docNomeTrunc";

  @Output()
  itemChange: EventEmitter<Pessoa> = new EventEmitter();

  constructor(private injector: Injector,
              private pessoaService: PessoaService) {
    super(injector);
  }

  items: Pessoa[] = [];

  _item: Pessoa;

  opened: boolean = false;

  private querySubject: Subject<string> = new Subject();

  private querySubscription: Subscription;

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.querySubscription = this.querySubject.pipe(switchMap(parametro => {
      if (parametro != null && parametro.trim() != "" && parametro.length >= 3) {
        let pessoaFilter: PessoaFilter = new PessoaFilter();

        pessoaFilter.parametro = parametro.trim();

        return this.pessoaService.buscarListaPorDocumentoOuNome(pessoaFilter);
      } else {
        return of([]);
      }
    })).subscribe(items => {
      this.items = items;
      this.opened = true;
    })
  }

  onQueryChanged(event) {
    this.querySubject.next(event);
  }
}
