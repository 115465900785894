import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {Pessoa} from "./pessoa";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Funcionario")
@JsonSubType({key: 'br.com.sonner.iss.entity.Funcionario', type: "Papel"})
export class Funcionario extends Papel {

  @JacksonType("Pessoa")
  empregador: Pessoa;

  matricula: string;

  constructor() {
    super(PerfilEnum.funcionario);
  }
}
