import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EstatisticaAcessoTO")
export class EstatisticaAcessoTO {

  descricao: string;

  link: string;

  favorito: boolean = false;
}
