import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("UsuarioFilter")
export class UsuarioFilter {

  confirmacao: string;

  senha: string;

  senhaAtual: string;

  usuario: string;
}
