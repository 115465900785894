import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ConfigBoleto} from "./config.boleto";
import {GuiaAvulsa} from "./guia.avulsa";

@JacksonType("GuiaAvulsaConfigBoleto")
@JsonIdentityInfo()
export class GuiaAvulsaConfigBoleto extends SonnerBaseEntity {

  id: number;

  @JacksonType("ConfigBoleto")
  configBoleto: ConfigBoleto;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;
}
