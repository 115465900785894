import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";

@JacksonType("PessoaExigibilidade")
@JsonIdentityInfo()
export class PessoaExigibilidade extends SonnerBaseEntity {

  id: number;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  @JacksonType("Pessoa")
  pessoa: Pessoa;
}
