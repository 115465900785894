import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {TipoRegimeISSEnum} from "../../../../enums/tipo.regime.iss.enum";

@Component({
  selector: "regime-selector",
  templateUrl: './regime.selector.component.html',
  styleUrls: ['./regime.selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class  RegimeSelectorComponent extends BaseComponent {


  tipoRegimeType: any = TipoRegimeISSEnum;

  @Input()
  itemSelecionado: TipoRegimeISSEnum;

  @Input()
  readOnly: boolean = false;

  @Input()
  showClearButton: boolean = false;

  @Output()
  regimeChange:EventEmitter<TipoRegimeISSEnum> = new EventEmitter<TipoRegimeISSEnum>();

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }


  alteraRegime(regime: TipoRegimeISSEnum) {
    this.itemSelecionado = regime;
    this.regimeChange.emit(regime);
  }

  limparItemSelecionado(){
    this.itemSelecionado = null;
  }
}
