import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {Atividade} from "../atividade";
import {GrupoAtividade} from "../grupo.atividade";
import {AtividadeHistorico} from "../atividade.historico";

@JacksonType("ConfiguracaoMunicipioTO")
export class ConfiguracaoMunicipioTO {

  idLogo: number;

  rotulo: string;

  cnpjSecretariaFazenda: string;

  leiLivroPrestador: string;

  leiLivroTomador: string;

  contato: string;

}
