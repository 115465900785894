import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {ObraPrestador} from "./obra.prestador";
import {Solicitacao} from "./solicitacao";

@JacksonType("SolicitacaoAbatimentoObra")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoAbatimentoObra', type: "Solicitacao"})
export class SolicitacaoAbatimentoObra extends Solicitacao {

  @JacksonType("ObraPrestador")
  obraPrestador: ObraPrestador;
}
