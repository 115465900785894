import {BaseComponent} from "../base/base.component";
import {Component, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import {DxPopupComponent} from "devextreme-angular";

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent extends BaseComponent {

  @ViewChild("dxPopup")
  dxPopup: DxPopupComponent;

  @Input()
  dragEnabled: boolean = false;

  @Input()
  height: string = "auto";

  @Input()
  showTitle: boolean = true;

  @Input()
  title: string = "";

  @Input()
  visible: boolean = false;

  @Input()
  width: string = "auto";

  @Output()
  visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private injector: Injector) {
    super(injector);
  }
}
