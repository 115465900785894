import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {AtendimentoFilter} from "../filters/atendimento.filter";
import {AtendimentoTO} from "../../../classes/to/atendimento.to";

@Injectable()
export class AtendimentoService {

  constructor(private restService: RestService){
  }

  buscar(atendimentoFilter: AtendimentoFilter): Observable<AtendimentoTO[]> {
    return this.restService.post("/atendimento/buscar", {data: atendimentoFilter, responseType: AtendimentoTO});
  }

}
