import * as _ from "lodash";

export class EnumUtils {

  public static getKey(type, currentValue): string {
    for (let key in type) {
      if (type[key] == currentValue) {
        return key;
      }
    }

    return null;
  }

  public static valueOf(type, currentValue): any {
    for (let key in type) {
      if (type[key] == currentValue) {
        return type[key];
      }
    }

    return null;
  }

  public static keys(type) {
    const output = [];

    for (let key in type) {
      if (typeof type[key] === 'string') {
        output.push(key);
      }
    }

    return output;
  }

  public static values(type, sortByString:boolean = false) {
    const output = [];

    for (let key in type) {
      if (typeof type[key] === 'string') {
        output.push(type[key]);
      }
    }

    if(sortByString){
      if(output.length){
        output.sort();
      }
    }

    return output;
  }

  public static valueOfByKey(type, targetKey) {
    for (let key in type) {
      if (key == targetKey) {
        return type[key];
      }
    }

    return null;
  }

  public static ordinal<T>(enumType: T, enumValue: any): number {
    if (!enumType || !enumValue) {
      return null;
    }

    return Object.values(enumType).findIndex(e => e == enumValue);
  };

  public static getKeyValue(type, sortByString:boolean = false): any[] {
    let output = [];

    for (let key in type) {
      if (typeof type[key] === 'string') {
        output.push({key :key, value: type[key] });
      }
    }

    if(sortByString){
      if(output.length){
        output = _.sortBy(output,"value");
      }
    }
    return output;
  }

}
