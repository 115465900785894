import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {InicialMenuTO} from "../../../classes/to/inicial.menu.to";
import {InicialMenuFilter} from "../filters/inicial.menu.filter";

@Injectable()
export class InicialMenuService {

  constructor(private restService: RestService) {
  }

  buscar(inicialMenuFilter: InicialMenuFilter): Observable<InicialMenuTO> {
    return this.restService.post("/inicialmenu/buscar", {data: inicialMenuFilter, responseType: InicialMenuTO});
  }

  confirmarLeitura(idComunicado: number): Observable<any> {
    return this.restService.post("/inicialmenu/confirmarLeitura", {data: idComunicado});
  }
}
