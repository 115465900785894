import {loadMessages, locale} from "devextreme/localization";
import {AppErrorService} from "../core/services/app.error.service";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {LandingPageComponent} from "./components/landing/landing.page.component";
import {FormsModule} from "@angular/forms";
import {PublicModule} from "../public/public.module";
import {AmplifyAngularModule, AmplifyModules, AmplifyService} from "aws-amplify-angular";
import {AppRoutingModule} from "./app.routing.module";
import {CommonsModule} from "../commons/commons.module";
import {Auth} from "aws-amplify";
import {AuthHttpInterceptor} from "../core/auth/auth.http.interceptor";
import {DevExtremeModule} from "devextreme-angular";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserModule} from "@angular/platform-browser";
import {HomeModule} from "../home/home.module";
import {AppStateService} from "./services/app.state.service";
import {AppComponent} from "./components/app.component";
import {CoreModule} from "../core/core.module";
import {RootInjectorFacade} from "../ui/services/root.injector.facade.service";
import {GlobalLoadingIndicatorService} from "../ui/services/global.loading.indicator.service";
import {UiModule} from "../ui/ui.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { WindowRefService } from './services/window-ref.service';
import {ServerErrorInterceptor} from "../core/services/server.error.interceptor";
import {ServerErrorComponent} from "./components/server-error/server.error.component";
import {DomHandler} from "./services/dom.handler";

let messagespt = require("devextreme/localization/messages/pt.json");

loadMessages(messagespt);
locale('pt');

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ServerErrorInterceptor,
    multi: true
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ServerErrorComponent
  ],

  exports: [],

  imports: [
    AmplifyAngularModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    CommonsModule,
    CoreModule,
    DevExtremeModule,
    FormsModule,
    HomeModule,
    HttpClientModule,
    PublicModule,
    UiModule
  ],

  providers: [
    AppErrorService,
    AppStateService,
    GlobalLoadingIndicatorService,
    RootInjectorFacade,
    WindowRefService,
    DomHandler,

    httpInterceptorProviders,
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth
        });
      }
    }
  ],

  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
}
