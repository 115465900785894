import {JacksonType} from "@sonner/jackson-service-v2";
import {TipoTributacao} from "../tipo.tributacao";

@JacksonType("ConfiguracaoTipoTributacaoTO")
export class ConfiguracaoTipoTributacaoTO {

  idMunicipio: number;

  @JacksonType("TipoTributacao")
  tipoTributacaoList: TipoTributacao[] = [];

  tipoTributacaoPadrao: boolean = false;
}
