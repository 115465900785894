import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {RelatorioAssincrono} from "./relatorio.assincrono";
import {Municipio} from "./municipio";
import {Arquivo} from "./arquivo";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("TNCSyncScript")
@JsonIdentityInfo()
export class TNCSyncScript extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  @JacksonType("Arquivo")
  arquivo: Arquivo;

  criado: Date;

  modificado: Date;

  versao: string;

  ativo: boolean = false;

  clazz: string;

  descricao: string;

  getDisplayValue(): string {
    return this.arquivo.fileName;
  }

}
