import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {DadosPessoa} from "../dados.pessoa";
import {Municipio} from "../municipio";
import {PapelServicoEnum} from "../../enums/papel.servico.enum";
import {Pessoa} from '../pessoa';
import {Serie} from "../serie";
import {TipoDocumentoEnum} from 'src/app/enums/tipo.documento.enum';
import {TipoTributacao} from '../tipo.tributacao';
import {TipoRegimeISSEnum} from "../../enums/tipo.regime.iss.enum";

@JacksonType("ConsultaContribuinteNotaEscritTO")
export class ConsultaContribuinteNotaEscritTO {

  ano: number;

  anoFmt: string;

  base: number;

  baseFmt: string;

  cancelado: boolean;

  canPrint: boolean;

  codAtividade: string;

  contraparteFmt: string;

  contraparteFmtTrunc: string;

  @JacksonType("DadosPessoa")
  dadosTomador: DadosPessoa;

  @JacksonType("DadosPessoa")
  dadosPrestador: DadosPessoa;

  @JacksonType("DadosPessoa")
  dadosContraparte: DadosPessoa;

  @DateType()
  dataPrestacao: Date;

  dia: number;

  diaFmt: string;

  devido: boolean;

  documentoContraparteFmt: string;

  eletronica: boolean;

  id: number;

  incidenciaMunicipio: boolean;

  mes: number;

  mesFmt: string;

  @JacksonType("Municipio")
  municipioIncidencia: Municipio;

  numero: number;

  numeroSerieFmt: string;

  obs: string;

  outrasDeducoes: number;

  outrasDeducoesFmt: string;

  @EnumType(PapelServicoEnum)
  papelServico: PapelServicoEnum;

  @JacksonType("Pessoa")
  prestador: Pessoa;

  proprietario: boolean;

  retido: boolean;

  senha: string;

  senhaFmt: string;

  @JacksonType("Serie")
  serie: Serie;

  serieFmt: string;

  situacaoFmt: string;

  @EnumType(TipoDocumentoEnum)
  tipoDocumento: TipoDocumentoEnum;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;

  tipoTributacaoFmt: string;

  tributacaoExigibilidadeFmt: string;

  @JacksonType("Pessoa")
  tomador: Pessoa;

  total: number;

  totalFmt: string;

  totalBrutoFmt: string;

  totalNotaFmt: string;

  totalNota: number;

  valorIss: number;

  valorIssFmt: string;

  @DateType()
  dataEmissao: Date;

  aliquotaFmt: string;

  @DateType()
  dataLiquidacao: Date;

  @JacksonType("Municipio")
  localPrestacao: Municipio;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;







}
