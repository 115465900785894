import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {Obra} from "./obra";
import {SolicitacaoAbatimentoObra} from "./solicitacao.abatimento.obra";
import {TipoAbatimentoObra} from "./tipo.abatimento.obra";

@JacksonType("ObraPrestador")
@JsonIdentityInfo()
export class ObraPrestador extends SonnerBaseEntity {

  id: number;

  ativa: boolean = true;

  @DateType()
  criacao: Date;

  @JacksonType("Obra")
  obra: Obra;

  @JacksonType("Pessoa")
  prestador: Pessoa;

  @JacksonType("SolicitacaoAbatimentoObra")
  solicitacao: SolicitacaoAbatimentoObra;

  @JacksonType("TipoAbatimentoObra")
  tipoAbatimentoObra: TipoAbatimentoObra;

  valorComGuia: number;
}
