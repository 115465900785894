import {Component, OnInit} from "@angular/core";

@Component({
    selector: 'server-error-page',
    templateUrl: './server.error.component.html'
})
export class ServerErrorComponent implements OnInit {

    ngOnInit(): void {
        setInterval(() => {
            fetch('/v2/rest/public/version/buscar').then(resp => {
                if (resp.status != 504 && resp.status != 502 && resp.status != 404) {
                    window.location.href = '/';
                }
            }).catch(err => {
                console.log("error");
                console.log(err);
            })
        }, 3000);
    }

}
