import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {EncerramentoAutomaticoDetalhamento} from "./encerramento.automatico.detalhamento";
import {EncerramentoAutomaticoStatusEnum} from "../enums/encerramento.automatico.status.enum";

@JacksonType("EncerramentoAutomatico")
export class EncerramentoAutomatico extends SonnerBaseEntity {

  id: number;

  ano: number;

  mes: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  @DateType()
  submissao: Date;

  @DateType()
  termino: Date;

  @JacksonType("EncerramentoAutomaticoDetalhamento")
  encerramentoAutomaticoDetalhamento: EncerramentoAutomaticoDetalhamento[];

  getCompetenciaFmt(): string{
    return (this.mes + 1) + "/" + this.ano;
  }
}
