import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {GuiaAvulsa} from "./guia.avulsa";
import {Pessoa} from "./pessoa";
import {TipoCorrecaoObraEnum} from "../enums/tipo.correcao.obra.enum";

@JacksonType("ObraCorrecaoNotaFiscal")
@JsonIdentityInfo()
export class ObraCorrecaoNotaFiscal {

  descricaoCorrecao: string;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  salvo: boolean;

  @EnumType(TipoCorrecaoObraEnum)
  tipoCorrecao: TipoCorrecaoObraEnum;

  valorCorrecao: number;
}
