export enum PerfilEnum {

  usuario = "usuarios",
  contador = "contadores",
  fiscal = "fiscais",
  gestor = "gestores",
  empresa = "empresas",
  grafica = "graficas",
  administrador = "administradores",
  banco = "bancos",
  construtora = "construtora",
  funcionario = "funcionario",
  suporte = "suportes",
  pedagio = "pedagio",
  cartorio ="cartorios",
  transporte = "transportes",
  ensino = "ensinos"
}
