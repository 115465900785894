import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ImportacaoPGDASD} from "./importacao.p.g.d.a.sd";

@JacksonType("PAGPagamento")
@JsonIdentityInfo()
export class PAGPagamento extends SonnerBaseEntity {

  id: number;

  agencia: string;

  banco: string;

  @DateType()
  dataArrecadacao: Date;

  @JacksonType("ImportacaoPGDASD")
  importacao: ImportacaoPGDASD;

  numeroGuia: string;

  remessa: string;

  remessaDAF607: string;

  valor: number;
}
