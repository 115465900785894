import { Injectable } from '@angular/core';

function _window() : any {
    return window;
}


@Injectable()
export class WindowRefService {

    constructor() { }

    nativeWindow() : Window {
        return _window();
    }

    setProperty(key: string, value: any) {
        this.nativeWindow()[key] = value;
    }
}
