import {Component, ContentChildren, Injector, Input, QueryList} from "@angular/core";
import {BaseComponent} from "../../../../ui/components/base/base.component";

@Component({
  selector: 'menu-lateral-item',
  templateUrl: './menu.lateral.item.component.html',
  styleUrls: ['./menu.lateral.item.component.scss']
})
export class MenuLateralItemComponent extends BaseComponent {

  constructor(private injector: Injector) {
    super(injector);
  }

  @ContentChildren(MenuLateralItemComponent)
  subMenu: QueryList<MenuLateralItemComponent>;

  @Input()
  viewId: string;

  @Input()
  activeViewId: string;

  @Input()
  label: string;

  @Input()
  altLabel: string;

  @Input()
  icone: string;

  @Input()
  uri: string;

  @Input()
  altUri: string;

  @Input()
  isStruts: boolean = false;

  onClick(event) {
    let ctrlKey: boolean = false;

    if (event) {
      ctrlKey = event.ctrlKey;

      event.preventDefault();
    }

    if (ctrlKey) {
      window.open(this.uri, "_blank", "");
    } else {
      this.routerService.open(this.uri, {}, this.isStruts);
    }
  }

  onAltClick(event) {
    event.preventDefault();

    if (this.altUri) {
      this.routerService.open(this.altUri);
    }
  }
}
