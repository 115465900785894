import {Component, ContentChild, EventEmitter, Injector, Input, Output} from "@angular/core";
import {BaseComponent} from "../base/base.component";
import {DxDataGridComponent} from "devextreme-angular";
import {AlertService} from "../../services/alert.service";
import {confirm} from "devextreme/ui/dialog";
import {Subscription} from "rxjs";
import * as _ from "lodash";

const CONFIRMATION_MESSAGE = "Tem certeza que pretende eliminar este registro?";

@Component({
  selector: 'custom-data-grid',
  templateUrl: './custom.data.grid.component.html',
  styleUrls: ['./custom.data.grid.component.scss'],
})
export class CustomDataGridComponent extends BaseComponent {

  @ContentChild(DxDataGridComponent, {static: true})
  grid: DxDataGridComponent;

  currentRowSelected: any;

  private disabledEditRows = [];

  @Input()
  allowAdding: boolean = false;

  @Input()
  allowDeleting: boolean = true;

  @Input()
  allowUpdating: boolean = true;

  @Input()
  disableButtonAddNewRow: boolean = false;

  @Input()
  disableButtonRefresh: boolean = true;

  @Input()
  disableButtonRemoveSelectedRows: boolean = false;

  @Input()
  editFormConf: any;

  @Input()
  enableLoadPanel: boolean = false;

  @Input()
  gridTitle: string;

  @Input()
  hasCustomSelectionConf: boolean = false;

  @Input()
  height: string = "auto";

  @Input()
  hintAddRow: string = "Incluir registro";

  @Input()
  hintRemoveRow: string = "Excluir registro";

  @Input()
  hintRefresh: string = "Atualizar dados";

  @Input()
  onContentReady: Function;

  @Input()
  onEditingStart: Function;

  @Input()
  onInitNewRow: Function;

  @Input()
  onSelectionChanged: Function;

  @Input()
  onRowClick: Function;

  @Input()
  onRowInserted: Function;

  @Input()
  onRowInserting: Function;

  @Input()
  onRowRemoved: Function;

  @Input()
  onRowRemoving: Function;

  @Input()
  onRowValidating: Function;

  @Input()
  onRowUpdated: Function;

  @Input()
  onRowUpdating: Function;

  @Input()
  onRowPrepared: Function;

  @Input()
  onRowExpanding: Function;

  @Input()
  onCellPrepared: Function;

  @Input()
  remoteOperations: boolean = false;

  @Input()
  updateList: Function;

  @Input()
  pageSize: number = 10;

  @Input()
  reorder: boolean = false;

  @Input()
  reorderProperty: string = 'sequencial';

  @Input()
  rowAlternationEnabled: boolean = true;

  @Input()
  showButtonAddNewRow: boolean = true;

  @Input()
  showButtonRefresh: boolean = false;

  @Input()
  showGridToolBar: boolean = true;

  @Input()
  visible: boolean = true;

  @Input()
  showBorders: boolean = true;

  @Input()
  showButtonRemoveSelectedRows: boolean = true;

  @Input()
  showGridTitle: boolean = true;

  @Input()
  validateAddNewRow: Function | boolean = () => true;

  @Input()
  validateRemoveSelectedRows: Function;

  @Input()
  width: string = "auto";

  @Input()
  contextData: any;

  @Input()
  showSelectPageSize: boolean = false;

  @Input()
  showSearchText: boolean = false;

  @Input()
  selectPageSizeItens: number[] = [10,20,50,100];

  @Output()
  onRowMoved: EventEmitter<RowMoved> = new EventEmitter<RowMoved>();

  @Output()
  searchTextChange: EventEmitter<string> = new EventEmitter<string>();

  dataSourceChangeSubscription: Subscription;

  onContentReadySubscription: Subscription;

  onEditingStartSubscription: Subscription;

  onInitNewRowSubscription: Subscription;

  onSelectionChangedSubscription: Subscription;

  onRowClickSubscription: Subscription;

  onRowInsertedSubscription: Subscription;

  onRowInsertingSubscription: Subscription;

  onRowRemovingSubscription: Subscription;

  onRowValidationSubscription: Subscription;

  onRowUpdatedSubscription: Subscription;

  onRowUpdatingSubscription: Subscription;

  onRowPreparedSubscription: Subscription;

  onRowExpandingSubscription: Subscription;

  onCellPreparedSubscription: Subscription;

  constructor(private injector: Injector) {
    super(injector);
  }

  dataSource: any[];

  messageService: AlertService;

  protected doAfterContentInit() {
    //onContentReady
    this.onContentReadySubscription = this.grid.onContentReady.subscribe(event => {
      this.clearErrorMessages();

      if (this.onContentReady) {
        this.onContentReady(event);
      }
    });

    // onEditingStart
    this.onEditingStartSubscription = this.grid.onEditingStart.subscribe(event => {
      this.clearErrorMessages();

      if (this.onEditingStart) {
        this.onEditingStart(event);
      }
    });

    // onInitNewRow
    this.onInitNewRowSubscription = this.grid.onInitNewRow.subscribe(event => {
      this.clearErrorMessages();

      if (this.onInitNewRow) {
        this.onInitNewRow(event);
      }
    });

    // onSelectionChanged
    this.onSelectionChangedSubscription = this.grid.onSelectionChanged.subscribe(event => {

      if (this.grid.selection.mode == 'single' && !_.isEmpty(event.currentSelectedRowKeys)) {
        this.currentRowSelected = event.currentSelectedRowKeys[0];
      }

      // can't select disabled edit rows
      if (!_.isEmpty(event.currentSelectedRowKeys)) {
        const disabledKeys = event.currentSelectedRowKeys.filter(i => this.disabledEditRows.indexOf(i) > -1);
        if (disabledKeys && disabledKeys.length > 0)
          event.component.deselectRows(disabledKeys);
      }

      if (this.onSelectionChanged) {
        this.onSelectionChanged(event, this.contextData);
      }
    });

    // onRowClick
    this.onRowClickSubscription = this.grid.onRowClick.subscribe(event => {
      if (this.onRowClick) {
        this.onRowClick(event);
      }
    });

    // onRowRemoving
    this.onRowRemovingSubscription = this.grid.onRowRemoving.subscribe(event => {
      if (this.onRowRemoving) {
        this.onRowRemoving(event);
      }
    });

    // onRowInserted
    this.onRowInsertedSubscription = this.grid.onRowInserted.subscribe(event => {
      if (this.onRowInserted) {
        this.onRowInserted(event);
      }
    });

    // onRowInserting
    this.onRowInsertingSubscription = this.grid.onRowInserting.subscribe(event => {
      if (this.onRowInserting) {
        this.onRowInserting(event);
      }
    });

    // onRowValidating
    this.onRowValidationSubscription = this.grid.onRowValidating.subscribe(event => {
      this.clearErrorMessages();

      if (event.oldData) {
        event.mergeData = this._.clone(event.oldData);

        Object.assign(event.mergeData, event.newData);
      } else {
        event.mergeData = this._.clone(event.newData);
      }

      if (this.onRowValidating) {
        this.onRowValidating(event);
      }
    });

    // onRowUpdated
    this.onRowUpdatedSubscription = this.grid.onRowUpdated.subscribe(event => {
      if (this.onRowUpdated) {
        this.onRowUpdated(event);
      }
    });

    // onRowUpdating
    this.onRowUpdatingSubscription = this.grid.onRowUpdating.subscribe(event => {
      if (this.onRowUpdating) {
        this.onRowUpdating(event);
      }
    });

    //onRowPrepared
    this.onRowPreparedSubscription = this.grid.onRowPrepared.subscribe(event => {
      if (this.onRowPrepared) {
        this.onRowPrepared(event);
      }
    });

    //onRowExpanding
    this.onRowExpandingSubscription = this.grid.onRowExpanding.subscribe(event => {
      if (this.onRowExpanding) {
        this.onRowExpanding(event);
      }
    });

    //onCellPrepared
    this.onCellPreparedSubscription = this.grid.onCellPrepared.subscribe(event => {
      if (this.onCellPrepared) {
        this.onCellPrepared(event);
      }
    });

    this.configuration();
  }

  protected doOnDestroy() {
    if (this.dataSourceChangeSubscription) {
      this.dataSourceChangeSubscription.unsubscribe();
    }

    if (this.onContentReadySubscription) {
      this.onContentReadySubscription.unsubscribe();
    }

    if (this.onEditingStartSubscription) {
      this.onEditingStartSubscription.unsubscribe();
    }

    if (this.onInitNewRowSubscription) {
      this.onInitNewRowSubscription.unsubscribe();
    }

    if (this.onRowClickSubscription) {
      this.onRowClickSubscription.unsubscribe();
    }

    if (this.onRowInsertedSubscription) {
      this.onRowInsertedSubscription.unsubscribe();
    }

    if (this.onRowInsertingSubscription) {
      this.onRowInsertingSubscription.unsubscribe();
    }

    if (this.onRowRemovingSubscription) {
      this.onRowRemovingSubscription.unsubscribe();
    }

    if (this.onRowValidationSubscription) {
      this.onRowValidationSubscription.unsubscribe();
    }

    if (this.onRowUpdatedSubscription) {
      this.onRowUpdatedSubscription.unsubscribe();
    }

    if (this.onRowUpdatingSubscription) {
      this.onRowUpdatingSubscription.unsubscribe();
    }

    if(this.onSelectionChangedSubscription){
      this.onSelectionChangedSubscription.unsubscribe();
    }

    if(this.onRowPreparedSubscription){
      this.onRowPreparedSubscription.unsubscribe();
    }

    if(this.onRowExpandingSubscription){
      this.onRowExpandingSubscription.unsubscribe();
    }

    if(this.onCellPreparedSubscription){
      this.onCellPreparedSubscription.unsubscribe();
    }

  }

  protected doOnInit() {
    this.grid.cellHintEnabled = true;
    if(this.grid.editing){
      this.grid.editing.useIcons = true;
    }
    this.grid.height = this.height;
    this.grid.paging = {pageSize: this.pageSize, enabled: true, pageIndex: 0};
    this.grid.remoteOperations = this.remoteOperations;
    this.grid.rowAlternationEnabled = this.rowAlternationEnabled;

    if(!this.hasCustomSelectionConf){
      this.grid.selection = {
        allowSelectAll: true,
        selectAllMode: 'allPages',
        mode: 'single',
        showCheckBoxesMode: 'always'
      };
    }

    this.grid.showBorders = this.showBorders;
    this.grid.width = this.width;
  }

  // method

  addNewRow(event: any) {
    if (this.validateAddNewRow instanceof Function) {
      if (!this.validateAddNewRow(this.dataSource, event)) {

        return;
      }
    }

    if (this.validateAddNewRow) {
      this.grid.instance.addRow();
    }
  }

  canReorderRow() {
    return this.reorder && this.grid.instance.getSelectedRowKeys().length == 1;
  }

  configuration() {
    this.dataSource = <any[]>this.grid.dataSource;

    this.dataSourceChangeSubscription = this.grid.dataSourceChange.subscribe((dataSource) => {
      this.dataSource = dataSource;
    });

    this.grid.scrolling = {mode: 'standard'};
    this.grid.loadPanel = {enabled: this.enableLoadPanel};

    if (this.editFormConf) {
      this.grid.editing = {form: this.editFormConf}
    }

    if (this.grid.editing.mode == 'popup') {
      this.grid.editing.allowAdding = this.allowAdding;
      this.grid.editing.allowDeleting = this.allowDeleting;
      this.grid.editing.allowUpdating = this.allowUpdating;
      this.grid.editing.form.alignItemLabels = true;
      this.grid.editing.form.colCount = 1;
      this.grid.editing.popup.height = 'auto';
      this.grid.editing.popup.position = {my: 'center', at: 'center', of: '#content'};
      this.grid.editing.popup.width = 'auto';
      this.grid.editing.texts.cancelRowChanges = 'Cancelar';
      this.grid.editing.texts.saveRowChanges = 'Aplicar';
    }

    if (this.grid.editing.mode == 'form') {
      this.grid.editing.texts.cancelRowChanges = 'Cancelar';
      this.grid.editing.texts.saveRowChanges = 'Aplicar';
    }
  }

  gridInstance() {
    return this.grid.instance;
  }

  moveObjectGrid(selectedIndexRow, selected, targetIndexRow, target) {
    this.grid.dataSource[selectedIndexRow] = target;
    this.grid.dataSource[targetIndexRow] = selected;

    const oldValue = selected[this.reorderProperty];

    selected[this.reorderProperty] = target[this.reorderProperty];
    target[this.reorderProperty] = oldValue;

    this.onRowMoved.emit({from: selected, target: target});
  }

  moveRowDown() {
    if (this.canReorderRow()) {
      const selected = this.gridInstance().getSelectedRowKeys()[0];
      const selectedIndex = this.gridInstance().getRowIndexByKey(selected);

      if (selectedIndex != this.gridInstance().getDataSource().items().length - 1) {
        const targetIndex = selectedIndex + 1;
        const target = this.gridInstance().getKeyByRowIndex(targetIndex);

        const selectedIndexRow = (this.grid.dataSource as any[]).indexOf(selected);
        const targetIndexRow = (this.grid.dataSource as any[]).indexOf(target);

        this.moveObjectGrid(selectedIndexRow, selected, targetIndexRow, target);
      }
    }
  }

  moveRowUp() {
    if (this.canReorderRow()) {
      const selected = this.gridInstance().getSelectedRowKeys()[0];
      const selectedIndex = this.gridInstance().getRowIndexByKey(selected);

      if (selectedIndex != 0) {
        const targetIndex = selectedIndex - 1;
        const target = this.gridInstance().getKeyByRowIndex(targetIndex);

        const selectedIndexRow = (this.grid.dataSource as any[]).indexOf(selected);
        const targetIndexRow = (this.grid.dataSource as any[]).indexOf(target);

        this.moveObjectGrid(selectedIndexRow, selected, targetIndexRow, target);

      }
    }
  }

  refresh() {
    if (this.grid) {
      this.grid.instance.refresh();
    }
  }

  removeSelectedRows() {
    if (this.validateRemoveSelectedRows) {
      if (!this.validateRemoveSelectedRows()) {
        return;
      }
    }

    const keys = this.grid.instance.getSelectedRowKeys().reverse();

    if (keys.length > 0) {
      this.grid.editing.texts.confirmDeleteMessage = "";

      let result = confirm(CONFIRMATION_MESSAGE, "");

      result.then((dialogResult) => {

        if (dialogResult) {

          keys.forEach(key => {
            let idx = this.dataSource.findIndex(item => item == key);
            let dataToRemove = this.dataSource[idx];
            this.dataSource.splice(idx, 1);

            if (this.onRowRemoved) {
              let event = {data: dataToRemove};
              this.onRowRemoved(event);
            }

          });
          this.grid.instance.refresh();

        }

        this.grid.editing.texts.confirmDeleteMessage = CONFIRMATION_MESSAGE;

      });
    } else {
      this.messageService.show('Nenhum registro selecionado').then(() => {
      });
    }

  }

  toolBarHeight() {
    if (this.height != 'auto') {
      return this.height + 'px';
    }

    return this.height;
  }

  toolBarWidth() {
    if (this.width != 'auto') {
      return this.width + 'px';
    }

    return this.width;
  }

  atualizar() {
    if(this.updateList){
      this.updateList();
    }
  }

  pageSizeChange(event: any) {
      if(event){
        this.grid.paging ={pageSize: event, enabled: true, pageIndex: 0};
      }
  }

  searchChange(evento: any){
    this.searchTextChange.emit(evento);
  }

}

export interface RowMoved {
  from: any,
  target: any
}
