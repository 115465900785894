import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {TipoTributacao} from "./tipo.tributacao";

@JacksonType("Preferencias")
@JsonIdentityInfo()
export class Preferencias extends SonnerBaseEntity {

  id: number;

  @JacksonType("TipoTributacao")
  tribPadraoPrestador: TipoTributacao;

  @JacksonType("TipoTributacao")
  tribPadraoTomador: TipoTributacao;
}
