import {JacksonType} from "@sonner/jackson-service-v2";
import {PGDASDApuracao} from "../p.g.d.a.s.d.apuracao";
import {PGDASDDAS} from "../p.g.d.a.s.d.d.a.s";
import {PAGPagamento} from "../p.a.g.pagamento";

@JacksonType("PGDASDApuracaoTO")
export class PGDASDApuracaoTO {

  mes: number;

  ano: number;

  @JacksonType("PGDASDApuracao")
  apuracoes: PGDASDApuracao[];

  @JacksonType("PGDASDDAS")
  das: PGDASDDAS[];

  @JacksonType("PAGPagamento")
  pagamentos: PAGPagamento[];

}
