import {JacksonType} from "@sonner/jackson-service-v2";
import {SafeResourceUrl} from "@angular/platform-browser";

@JacksonType("BilheteNotaPremiadaContribuinteDetalheTO")
export class BilheteNotaPremiadaContribuinteDetalheTO {

  numero: number;

  ativo: boolean;

  motivoCancelamento: string;

  bilheteImage: string;

  bilheteUrl: SafeResourceUrl;

}
