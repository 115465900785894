import {BehaviorSubject, Observable, Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable()
export class GlobalLoadingIndicatorService {

  private _globalIndicatorSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);

  private _globalIndicatorMessage: string = "Carregando";

  setLoadState(active: boolean, message?: string): void {
    this._globalIndicatorSubject.next(active);
    this._globalIndicatorMessage = message;
  }

  getGlobalInidicatorObservable(): Observable<boolean> {
    return this._globalIndicatorSubject.pipe(debounceTime(200));
  }

  getGlobalIndicatorMessage(): string {
    return this._globalIndicatorMessage;
  }
}
