import {EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Atividade} from "./atividade";
import {Solicitacao} from "./solicitacao";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("SolicitacaoUsuario")
@JsonSubType({key: "br.com.sonner.iss.entity.SolicitacaoUsuario", type: "Solicitacao"})
export class SolicitacaoUsuario extends Solicitacao {

  crc: string;

  regimeOuAtividadeDiferente: boolean = false;

  senha: string;

  solicitaEmissaoNfe: boolean;

  solicitaEscrituracaoLivrePrestador: boolean;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;

  usuario: string;

  @JacksonType('Atividade')
  atividadesContribuinte: Atividade[];
}
