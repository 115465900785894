import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";

@JacksonType("NotificacaoSistema")
@JsonIdentityInfo()
export class NotificacaoSistema extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  autor: Pessoa;

  autoGerado: boolean;

  callback: boolean;

  @DateType()
  dataCriacao: Date;

  @DateType()
  dataVisualizacao: Date;

  descricao: string;

  @JacksonType("Pessoa")
  destinatario: Pessoa;

  @DateType()
  iniciaEm: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  titulo: string;
}
