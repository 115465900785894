import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {Municipio} from "./municipio";

@JacksonType("ConfigSolicitacoes")
@JsonIdentityInfo()
export class ConfigSolicitacoes extends SonnerBaseEntity {

  id: number;

  aidf: boolean = true;

  alteracaoNFe: boolean = true;

  anfe: boolean = true;

  autenticacaoLivroFiscal: boolean = true;

  cadastro: boolean = true;

  cadastroEventual: boolean = true;

  cancelamentoEncerramento: boolean = true;

  cancelamentoNotaFiscal: boolean = true;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  generica: boolean = true;

  importacao_NFE: boolean = true;

  @JacksonType("Municipio")
  municipio: Municipio;

  perfilDescontoNotaFiscal: boolean = true;
}
