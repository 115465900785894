import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Documento} from "./documento";
import {Pessoa} from "./pessoa";
import {SituacaoSolicitacaoEnum} from "../enums/situacao.solicitacao.enum";
import {TipoSolicitacaoEnum} from "../enums/tipo.solicitacao.enum";

@JacksonType("Solicitacao")
@JsonIdentityInfo()
export class Solicitacao extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  aprovador: Pessoa;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataDeferimento: Date;

  @DateType()
  dataIndeferimento: Date;

  @DateType()
  dataSolicitacao: Date;

  @JacksonType("Documento")
  documento: Documento;

  grpProcessado: Boolean;

  @JacksonType("Pessoa")
  indeferidor: Pessoa;

  motivoDeferimento: string;

  motivoIndeferimento: string;

  obs: string;

  @EnumType(SituacaoSolicitacaoEnum)
  situacao: SituacaoSolicitacaoEnum;

  @JacksonType("Pessoa")
  solicitante: Pessoa;

  @EnumType(TipoSolicitacaoEnum)
  tipo: TipoSolicitacaoEnum;

  @DateType()
  ultimaExp;

  criptId: string;
}
