import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Arquivo} from "./arquivo";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {SituacaoProcessoSimplesEnum} from "../enums/situacao.processo.simples.enum";
import {TipoProcessoExportacaoSimplesEnum} from "../enums/tipo.processo.exportacao.simples.enum";

@JacksonType("ProcessoExportacaoSimples")
@JsonIdentityInfo()
export class ProcessoExportacaoSimples extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("Arquivo")
  arquivoExportado: Arquivo;

  @JacksonType("Arquivo")
  arquivoGrp: Arquivo;

  @JacksonType("Arquivo")
  arquivoRetornoSimples: Arquivo;

  @DateType()
  dataGeracaoArquivo: Date;

  @DateType()
  dataInicioProcesso: Date;

  @DateType()
  dataNotificacoes: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("Pessoa")
  responsavel: Pessoa;

  @EnumType(SituacaoProcessoSimplesEnum)
  situacao: SituacaoProcessoSimplesEnum;

  @EnumType(TipoProcessoExportacaoSimplesEnum)
  tipoProcesso: TipoProcessoExportacaoSimplesEnum;
}
