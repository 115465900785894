import {DesifTipoPartidaEnum} from "../enums/desif.tipo.partida.enum";
import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("DesifEventoContabil")
@JsonIdentityInfo()
export class DesifEventoContabil extends SonnerBaseEntity {

  id: number;

  descricao: string;

  @EnumType(DesifTipoPartidaEnum)
  tipoPartida: DesifTipoPartidaEnum;
}
