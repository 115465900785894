import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {Endereco} from "../endereco";
import {TipoSolicitacaoEnum} from "../../enums/tipo.solicitacao.enum";

@JacksonType("AtendimentoTO")
export class AtendimentoTO {

  situacao: string;

  id: number;

  @DateType()
  data: Date;

  nomeSolicitante: string;

  nomeContribuinte: string;

  senhaDocumento: string;

  indeferida: boolean;

  aprovada: boolean;

  pendente: boolean;

  rotulo: string;

  denuncia: boolean;

  angular: boolean;

  rotaAngular: string;

  generica: boolean;

  criptId: string;

  @EnumType(TipoSolicitacaoEnum)
  tipo: TipoSolicitacaoEnum;

  genericaId: string;

  getCriptId(generica: boolean = false){
    if(generica){
      return this.genericaId.replace(/\+/g,"%2B");
    }
    return this.criptId.replace(/\+/g,"%2B");
  }

}
