import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Curso} from "./curso";

@JacksonType("CursoValor")
@JsonIdentityInfo()
export class CursoValor extends SonnerBaseEntity {

  id: number;

  @JacksonType("Curso")
  curso: Curso;

  @DateType()
  inicio: Date;

  @DateType()
  fim: Date;

  valor: number;

}
