import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoAbatimentoObraContribuinte} from "./tipo.abatimento.obra.contribuinte";

@JacksonType("TipoAbatimentoObra")
@JsonIdentityInfo()
export class TipoAbatimentoObra extends SonnerBaseEntity {

  id: number;

  abatimentoPorNota: boolean = false;

  ativa: boolean = true;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  porcentagemAbatimento: number;

  limiteAbatimento: number;

  @JacksonType("TipoAbatimentoObraContribuinte")
  tipoAbatimentoObraContribuintes: TipoAbatimentoObraContribuinte[] = [];

}
