import {Component, EventEmitter, Injector, Input, Output} from "@angular/core";
import {BaseComponent} from "../base/base.component";

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})

export class PanelComponent extends BaseComponent {

  @Input()
  set color(value: string) {
    if (value) {
      if (value == 'blue') {
        this.background = 'rgb(25, 89, 139)';
      }

      if (value == 'green') {
        this.background = 'rgb(60, 118, 61)';
      }

      if (value == 'red') {
        this.background = 'rgb(189, 41, 40)';
      }
    }
  }

  @Input()
  icon: string;

  @Input()
  print: boolean = false;

  @Input()
  title: string;

  @Output()
  printClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(private injector: Injector) {
    super(injector);
  }

  background: string;

  tooltipImprimir: boolean = false;

  mainStyle() {
    return {'color': this.background ? '#fff' : '#333'}
  }

  titleStyle() {
    return {'background-color': this.background ? this.background : '#ccc'}
  }


  imprimir(tipo: string) {
    this.printClick.emit(tipo);
  }

  selecionar() {
    this.tooltipImprimir= true;
  }
}
