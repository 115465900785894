import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {UserInfoTO} from "../../core/classes/user.info.to";

@Injectable()
export class AppStateService {

  userInfoSubject: Subject<UserInfoTO> = new BehaviorSubject(null);

  embeddedSubject:  Subject<boolean> = new BehaviorSubject(null);

  setUserInfoSubject(userInfoTO: UserInfoTO) {
    this.userInfoSubject.next(userInfoTO);
  }

  setEmbeddedSubject(embedded: boolean) {
    this.embeddedSubject.next(embedded);
  }

}
