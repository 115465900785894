import {forkJoin} from "rxjs";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {Component, Injector, ViewChild, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {DomHandlerService} from "../../../ui/services/dom.handler.service";
import {DxAccordionComponent} from "devextreme-angular";
import {Atividade} from "../../../../classes/atividade";
import {Endereco} from "../../../../classes/endereco";
import {Estado} from "../../../../classes/estado";
import {Logradouro} from "../../../../classes/logradouro";
import {Municipio} from "../../../../classes/municipio";
import {Pessoa} from "../../../../classes/pessoa";
import {UsuarioTO} from "../../../../classes/to/usuario.to";
import {LoginService} from "../../../core/services/login.service";
import {TipoLogradouroEnum} from "../../../../enums/tipo.logradouro.enum";
import {TipoRegimeISSEnum} from "../../../../enums/tipo.regime.iss.enum";
import {LoginFilter} from "../../../core/filters/login.filter";
import {PopupComponent} from "../../../ui/components/popup/popup.component";
import {AlertService} from "../../../ui/services/alert.service";
import {ExportService} from "../../../commons/services/export.service";

@Component({
  selector: 'primeiro-acesso-page',
  templateUrl: './primeiro.acesso.page.component.html',
  styleUrls: ['./primeiro.acesso.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeiroAcessoPageComponent extends BaseComponent {

  @ViewChild('popup', {static: false})
  popup: PopupComponent;

  constructor(private alertService: AlertService,
              private domHandler: DomHandlerService,
              private exportService: ExportService,
              private injector: Injector,
              private loginService: LoginService) {
    super(injector)
  }

  accordionRequisito: boolean = true;

  atividades: Atividade[] = [];

  cadastraUsuario: boolean = false;

  CEPcadastrado: boolean = true;

  codigo: string = "";

  documento: string = "";

  email: string = "";

  estado: Estado;

  heightAtividades: string;

  id: number = null;

  municipio: Municipio;

  municipios: Municipio[] = [];

  municipiosCliente: Municipio[] = [];

  popupAtividade: boolean;

  reg = /[^LM\S{NP}]/g;

  senha: string = "";

  tipoLogradouroType: any = TipoLogradouroEnum;

  tipoRegimeType: any = TipoRegimeISSEnum;

  usuario: string = "";

  usuarioTO: UsuarioTO = new UsuarioTO();

  widthAtividades: string;

  ngOnInit(): void {
    this.usuarioTO.endereco = this.limpaEndereco();

    this.buscaMunicipiosCliente();
  }

  acionaAdicionarAtividade(event) {
    this.popup.clearErrorMessages();

    if (this.usuarioTO.atividadeList.length > 0) {
      this.usuarioTO.atividadeList.forEach(atividade => {
        if (atividade.id == event.key.id) {
          this.popup.addErrorMessage('Esta atividade já foi adicionada ao cadastro.');
        }
      });
    }

    if (!this.popup.hasErrorMessages()) {
      this.popupAtividade = false;
      this.usuarioTO.atividadeList.push(event.key);
    }
  }

  @GlobalLoadingIndicator()
  acionaCadastrar() {
    this.clearErrorMessages();

    let reg = /[^LM\S{NP}]/g;

    if (reg.test(this.usuario) || this.usuario == "") {
      this.addErrorMessage("O campo usuário não deve possuir nenhum espaço.");
    }

    if (this.usuarioTO.pessoa.email == "" || this.email == "" || this.usuarioTO.pessoa.email != this.email) {
      this.addErrorMessage("O campo e-mail e sua confirmação deve ser iguais.");
    }

    if (!this.usuarioTO.pessoa.nome || this.usuarioTO.pessoa.nome.trim() == "") {
      this.addErrorMessage("O nome é obrigatório.");
    }

    if(!this.usuarioTO.pessoaPossuiCadastroMunicipio){
      if (this.usuarioTO.endereco.cep == "") {
        this.addErrorMessage("O campo CEP é obrigatório.");
      }

      if (!(this.usuarioTO.usuarioCadastradoGrp || this.CEPcadastrado) && (this.usuarioTO.endereco.logradouro.nome == "" || !this.usuarioTO.endereco.logradouro.tipo || this.usuarioTO.endereco.numero == "" || this.usuarioTO.endereco.bairro == "" || !this.usuarioTO.endereco.logradouro.municipio || !this.usuarioTO.endereco.logradouro.municipio.estado || this.usuarioTO.endereco.pais == "")) {
        this.addErrorMessage("Você está adicionando um novo endereço, por favor, verifique os campos.");
      }
    }

    if (this.errorMessages.length > 0) {
      return;
    }

    this.usuarioTO.usuario = this.usuario;
    this.usuarioTO.pessoa.documento = this.documento;

    return this.of(this.loginService.cadastrar(this.usuarioTO), primeiroAcessoTO => {
      let message: string = "";

      if (primeiroAcessoTO.idPessoa) {
        this.id = primeiroAcessoTO.idPessoa;
      } else if (primeiroAcessoTO.aguardarAprovacao) {
        message = "Cadastro realizado, aguardando aprovação de um fiscal.";
      } else if (primeiroAcessoTO.emailAtivacao) {
        message = "Cadastro realizado, verifique o e-mail do cadastro para fazer o acesso ao sistema.";
      }

      if (!this.id) {
        this.alertService.show(message).then(() => this.navigate('/landing-page'));
      }
    });
  }

  acionaJanelaAtividade() {
    this.domSize();
    this.popupAtividade = true;
  }

  acionaRemoveAtividade(atividade: any) {
    this.usuarioTO.atividadeList.splice(this.usuarioTO.atividadeList.indexOf(atividade), 1)
  }

  @GlobalLoadingIndicator()
  acionaRequisitar() {
    this.clearErrorMessages();

    if (this.municipio && this.documento) {
      let loginFilter: LoginFilter = new LoginFilter();

      loginFilter.documento = this.documento;
      loginFilter.idMunicipio = this.municipio.id;

      return this.of(forkJoin(this.loginService.autorizar(loginFilter), this.loginService.buscarListaAtividadePorMunicipio(loginFilter)), (data: [UsuarioTO, Atividade[]]) => {
        this.usuarioTO = data[0];
        this.usuarioTO.endereco = this.limpaEndereco();

        if (!this.usuarioTO.pessoa) {
          this.usuarioTO.pessoa = new Pessoa();
        }

        this.atualizaEndereco();

        if (this.usuarioTO.endereco && this.usuarioTO.endereco.logradouro && this.usuarioTO.endereco.logradouro.municipio && this.usuarioTO.endereco.logradouro.municipio.estado) {
          this.usuarioTO.endereco.logradouro.municipio.estado = this.usuarioTO.estadoList.find(estado => estado.codigo == this.usuarioTO.endereco.logradouro.municipio.estado.codigo);

          this.buscaMunicipioPorEstado(this.usuarioTO.endereco.logradouro.municipio.estado);
        }

        this.cadastraUsuario = true;
        this.accordionRequisito = false;

        this.atividades = data[1].sort();
      });
    } else {
      this.addErrorMessage("Por favor, informar o município e o documento.");
    }
  }

  acionaVoltar() {
    this.cadastraUsuario = false;
    this.accordionRequisito = true;
    this.usuarioTO = new UsuarioTO();
  }

  alteraEstado(estado: Estado) {
    if (estado) {
      this.buscaMunicipioPorEstado(estado);
      this.usuarioTO.endereco.logradouro.municipio.estado = estado;
    } else {
      this.municipios = [];
      this.usuarioTO.endereco.logradouro.municipio.estado = null;
    }
  }

  alteraMunicipio(municipio: Municipio) {
    if (municipio) {
      this.usuarioTO.endereco.logradouro.municipio = municipio;
    } else {
      this.usuarioTO.endereco.logradouro.municipio = null;
    }
  }

  alteraSolicitaEmissaoNfe(value: boolean) {
    if (value) {
      this.usuarioTO.solicitaEmissaoNfe = value;
      this.usuarioTO.solicitaLivreEscrituracaoPrestador = !value;
    } else {
      this.usuarioTO.solicitaEmissaoNfe = value;
      this.usuarioTO.solicitaLivreEscrituracaoPrestador = value;
    }
  }

  alteraSolicitaLivreEscrituracaoPrestador(value: boolean) {
    if (value) {
      this.usuarioTO.solicitaEmissaoNfe = !value;
      this.usuarioTO.solicitaLivreEscrituracaoPrestador = value;
    } else {
      this.usuarioTO.solicitaEmissaoNfe = value;
      this.usuarioTO.solicitaLivreEscrituracaoPrestador = value;
    }
  }

  atualizaEndereco() {
    let i: boolean = false;

    if (this.usuarioTO.pessoa.enderecos) {
      this.usuarioTO.pessoa.enderecos.forEach((endereco, index) => {
        if ((endereco.principal || this.usuarioTO.pessoa.enderecos.length == index + 1) && !i) {
          this.usuarioTO.endereco = this._.cloneDeep(endereco);
          this.usuarioTO.endereco.id = null;
          if(this.usuarioTO && this.usuarioTO.endereco && this.usuarioTO.endereco.logradouro && this.usuarioTO.endereco.logradouro.municipio){
            this.estado = this.usuarioTO.endereco.logradouro.municipio.estado;
          }
          i = true;
        }
      });
    } else {
      this.usuarioTO.endereco = this.limpaEndereco();
    }
  }

  @GlobalLoadingIndicator()
  buscaCEP(cep: string) {
    if (cep && cep.length > 7) {
      let loginFilter: LoginFilter = new LoginFilter();

      loginFilter.cep = cep;

      return this.of(this.loginService.buscarCEPPorCEP(loginFilter), cepCadastrado => {
        if (cepCadastrado) {
          this.CEPcadastrado = true;

          this.usuarioTO.endereco = this.limpaEndereco();

          this.estado = this.usuarioTO.estadoList.find(estado => estado.codigo == cepCadastrado.municipio.estado.codigo);

          if (this.estado) {
            this.buscaMunicipioPorEstado(this.estado);
          }

          this.usuarioTO.endereco.bairro = cepCadastrado.bairro;
          this.usuarioTO.endereco.cep = cepCadastrado.cep;
          this.usuarioTO.endereco.complemento = null;
          this.usuarioTO.endereco.logradouro.nome = cepCadastrado.logradouro;
          this.usuarioTO.endereco.logradouro.tipo = cepCadastrado.tipo;
          this.usuarioTO.endereco.numero = null;
          this.usuarioTO.endereco.logradouro.municipio = cepCadastrado.municipio;
          this.usuarioTO.endereco.pais = "Brasil";
        } else {
          this.CEPcadastrado = false;
          this.estado = null;
          this.usuarioTO.endereco = this.limpaEndereco();
          this.usuarioTO.endereco.cep = cep;

          this.alertService.show("CEP não encontrado. Para continuar o cadastro preencha os campos obrigatórios do endereço.").then();
        }
      });
    } else {
      this.CEPcadastrado = true;
      this.estado = null;
      this.usuarioTO.endereco = this.limpaEndereco();

      return null;
    }
  }

  @GlobalLoadingIndicator()
  buscaMunicipiosCliente() {
    return this.of(this.loginService.buscarListaMunicipioCliente(), municipios => {
      this.municipiosCliente = municipios
    });
  }

  @GlobalLoadingIndicator()
  buscaMunicipioPorEstado(estado: Estado) {
    let loginFilter: LoginFilter = new LoginFilter();

    loginFilter.idEstado = estado.id;

    return this.of(this.loginService.buscarListaMunicipioPorEstado(loginFilter), municipios => {
      this.municipios = municipios;

      if (this.usuarioTO.endereco.logradouro.municipio.nome) {
        this.usuarioTO.endereco.logradouro.municipio = municipios.find(municipio => municipio.nome == this.usuarioTO.endereco.logradouro.municipio.nome);
      }
    });
  }

  domSize() {
    let content = this.domHandler.jQuery('#main');
    let width = content[0].clientWidth;
    let heigth = content[0].clientHeight;

    if (width > 640) {
      this.widthAtividades = '640';
    } else {
      this.widthAtividades = (width - 40) + "";
    }

    if (width > 640) {
      this.heightAtividades = '480';
    } else {
      this.heightAtividades = (heigth - 40) + "";
    }
  }

  formataDescricao(data: any) {
    return data.data.resumo ? data.data.resumo : data.data.nome;
  }

  limpaEndereco(): Endereco {
    const endereco = new Endereco();

    endereco.logradouro = new Logradouro();
    endereco.logradouro.municipio = new Municipio();

    return endereco;
  };

  url(evento: any) {
    evento.preventDefault();

    this.exportService.relatorio('/downloadRelatorioPrimeiroAcesso.action?id=' + this.id).subscribe(response => this.saveAs(response));
  }
}
