import {Component} from '@angular/core';
import {RootInjectorFacade} from "../../ui/services/root.injector.facade.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'iss-angular';

  constructor(private rootInjectorFacade: RootInjectorFacade) {
  }
}
