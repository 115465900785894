import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Documento} from "./documento";
import {TipoVinculoEnum} from "../enums/tipo.vinculo.enum";

@JacksonType("VinculoDocumento")
@JsonIdentityInfo()
export class VinculoDocumento extends SonnerBaseEntity {

  id: number;

  @JacksonType("Documento")
  destino: Documento;

  @JacksonType("Documento")
  origem: Documento;

  @EnumType(TipoVinculoEnum)
  tipo: TipoVinculoEnum;
}
