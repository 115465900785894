import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Banco} from "./banco";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("VinculoPessoaBancoDesif")
@JsonIdentityInfo()
export class VinculoPessoaBancoDesif extends SonnerBaseEntity {

  id: number;

  @JacksonType("Banco")
  banco: Banco;

  @JacksonType("Pessoa")
  pessoa: Pessoa;
}
