import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {BancoDependencia} from "./banco.dependencia";
import {DesifDemonstrativoContabil} from "./desif.demonstrativo.contabil";

@JacksonType("DesifDemonstrativoContabilDependencia")
@JsonIdentityInfo()
export class DesifDemonstrativoContabilDependencia extends SonnerBaseEntity {

  id: number;

  contabilidadePropria: boolean;

  @DateType()
  dataFimParalizacao: Date;

  @DateType()
  dataInicioParalizacao: Date;

  @JacksonType("DesifDemonstrativoContabil")
  demonstrativoContabil: DesifDemonstrativoContabil;

  @JacksonType("BancoDependencia")
  dependencia: BancoDependencia;

  enderecoDependencia: string;
}
