import {NgModule} from "@angular/core";
import {StrutsComponent} from "./components/struts.component";
import {CommonModule} from "@angular/common";
import {UiModule} from "../ui/ui.module";

@NgModule({
  declarations: [
    StrutsComponent
  ],

  exports: [
    StrutsComponent
  ],

  imports: [
    CommonModule,
    UiModule
  ],

  providers: []
})

export class StrutsModule {
}
