import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {IBPTAtividade} from "./i.b.p.t.atividade";

@JacksonType("IBPTVinculoAtividade")
@JsonIdentityInfo()
export class IBPTVinculoAtividade extends SonnerBaseEntity {

  id: number;

  @JacksonType("Atividade")
  atividade116: Atividade;

  @JacksonType("IBPTAtividade")
  ibptAtividade: IBPTAtividade;
}
