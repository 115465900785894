import {Injectable} from "@angular/core";
import * as _ from "lodash";
import {DateUtilsService} from "./date.utils.service";
import {PropertyValuePair} from "../classes/property.value.pair";

@Injectable()
export class ObjectUtilsService {

  constructor(private dateUtilsService: DateUtilsService) {
  }

  matchesBetween(oQueTestar: string, ondeTestar: string) {
    return new RegExp(".*" + (oQueTestar || "") + ".*", "i").test(ondeTestar);
  }

  getMemberAsFunction(target: any, memberName: string): Function {
    return this.hasMemberAsFunction(target, memberName) ? target.memberName : null;
  }

  hasMemberAsFunction(target: any, memberName: string): boolean {
    return target[memberName] && this.typeOf(target[memberName]) == 'function';
  }

  removeEmptyProperties(obj: any): any {
    if (obj) {
      Object.keys(obj).forEach(prop => {

        if ((!_.isArray(obj[prop]) && _.isNil(obj[prop]))
          || (_.isString(obj[prop]) && _.isEmpty(obj[prop]))) {
          obj[prop] = null;
        }
      });
    }

    return obj;
  }

  toInt(value): number {
    return parseInt(value);
  }

  toFloat(value): number {
    return parseFloat(value);
  }

  toDouble(value): number {
    return this.toFloat(value);
  }

  toString(value: any) {
    return value + "";
  }

  clearEmptyProperties(obj: any): any {
    if (obj) {
      Object.keys(obj).forEach(prop => {

        if (!_.isArray(obj[prop]) && this.isEmpty(obj[prop])) {
          obj[prop] = null;
        }
      });
    }

    return obj;
  }

  isEmpty(val: any) {
    return val == null || typeof val == "undefined" || val.toString().trim() == "";
  }

  isEqual(a, b, key?: string) {
    !key ? key = 'id' : undefined;

    if (a == b) {
      return true;
    }

    return key && !_.isNil(a) && !_.isNil(b) && _.isNumber(a[key]) && _.isNumber(b[key]) && a[key] === b[key];
  }

  isNotEmpty(val: any) {
    return !this.isEmpty(val);
  }

  isEmptyArray(arr: any[]) {
    return !arr || arr.length == 0;
  }

  isEmptyMap(map: Map<any, any>) {
    return !map || map.size == 0;
  }

  isArray(target) {
    return _.isArray(target);
  }

  isNotAnObject(target) {
    return !this.isObject(target);
  }

  isObject(target) {
    return _.isObject(target);
  }

  emptyIfNull(val: any) {
    return (!val || false) ? "" : val;
  }

  keys(target) {
    return Object.keys(target);
  }

  toArray(iterator: IterableIterator<any>) {
    if (iterator) {
      return Array.from(iterator);
    }

    return null;
  }

  toArrayOfSingle(element) {
    return [element];
  }

  addAll(oldArray: any[], newArray: any[]) {
    oldArray.push.apply(oldArray, newArray);
  }

  keysOfNonNull(target) {
    const output = [];
    const self = this;

    Object.keys(target).map(function (key) {
      const value = target[key];

      if (!self.isNullOrUndefined(value)) {
        output.push(key);
      }
    });

    return output;
  }


  typeOf(data: any) {
    if (this.isNullOrUndefined(data)) {
      return "undefined";
    } else if (this.isArray(data)) {
      return "array";
    } else if (this.dateUtilsService.isDate(data)) {
      return "date";
    } else if (this.isNotAnObject(data)) {
      return typeof data;
    }

    return typeof data;
  }

  isNullOrUndefined(obj: any) {
    return obj == null || typeof obj === 'undefined';
  }

  toBoolean(value: any) {
    if (!value) {
      return false;
    } else if (value === true) {
      return true;
    }

    if (this.typeOf(value) == 'string') {
      switch (value.toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
          return true;
        case "false":
        case "no":
        case "0":
        case null:
          return false;
        default:
          return Boolean(value);
      }
    }

    return value;
  }

  clone(aObject) {
    if (!aObject) {
      return aObject;
    }

    var bObject, v, k;
    bObject = Array.isArray(aObject) ? [] : {};

    for (k in aObject) {
      v = aObject[k];
      bObject[k] = (typeof v === "object") ? this.clone(v) : v;
    }

    return bObject;
  }

  withScalarPropertiesOnly(targetObject: any, skipNull: boolean = true) {
    const output = {};

    Object.keys(targetObject).map(function (key) {
      const value = targetObject[key];

      if (skipNull && !value) {
        return;
      }

      if (typeof value != 'object') {
        output[key] = value;
      }
    });

    return output;
  }

  withPropertiesExceptNulls(targetObject: any) {
    const output = {};
    const self = this;

    Object.keys(targetObject).map(function (key) {
      const value = targetObject[key];

      if (!self.isNullOrUndefined(value)) {
        output[key] = value;
      }
    });

    return output;
  }

  withObjectPropertiesOnly(targetObject: any, skipNull: boolean = true) {
    const output = {};

    Object.keys(targetObject).map(function (key) {
      const value = targetObject[key];

      if (skipNull && !value) {
        return;
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        output[key] = value;
      }
    });

    return output;
  }

  getPropertyValuePairs(object): PropertyValuePair[] {
    if (object) {
      const output: PropertyValuePair[] = [];

      Object.keys(object).map(function (key) {
        const propv = new PropertyValuePair();
        propv.propertyName = key;
        propv.propertyValue = object[key];
        output.push(propv);
      });

      return output;
    }

    return null;
  }

  withArraysPropertiesOnly(targetObject: any, skipNull: boolean = true) {
    const output = {};

    Object.keys(targetObject).map(function (key) {
      const value = targetObject[key];

      if (skipNull && !value) {
        return;
      }

      if (typeof value === 'object' && Array.isArray(value)) {
        output[key] = value;
      }
    });

    return output;
  }

  orderedPropertiesByType(targetObject: any, skipNull: boolean = true): any[] {
    var mappings = {};

    return Object.keys(targetObject).reduce(function (baseArray, property, indice, array) {
      var typeOf = Array.isArray(targetObject[property]) ? 'array' : typeof targetObject[property];
      var value = targetObject[property];
      var index = mappings[typeOf];
      var item = {};
      item[`${property}`] = value;

      if (skipNull && !value) {
        return baseArray;
      }

      if (index) {
        baseArray.splice(index, 0, item);
      } else {
        mappings[typeOf] = baseArray.push(item) - 1;
      }
      return baseArray
    }, []);
  }


  applyFieldValueByHierarchyProperty(targetObject: any, targetPropertyName: string, value: any) {
    if (targetObject && targetPropertyName) {
      const hierarchy = targetPropertyName.split(".");

      if (hierarchy.length == 1) {
        targetObject[targetPropertyName] = value;
      } else {
        let lastObjInGraph = targetObject;

        for (let i = 0; i < hierarchy.length; i++) {
          const prop = hierarchy[i];

          if (i + 1 == hierarchy.length) {
            lastObjInGraph[prop] = value;
          } else {
            let current = lastObjInGraph[prop];

            if (!current) {
              current = {};
              lastObjInGraph[prop] = current;
            }

            lastObjInGraph = lastObjInGraph[prop];
          }
        }
      }
    }
  }

  getFieldValueByHierarchyProperty(obj: any, dataField: string) {
    if (obj && dataField) {
      const hierarchy: string[] = dataField.split(".");
      let last = obj;

      if (hierarchy && last) {
        for (let i = 0; i < hierarchy.length; i++) {
          const prop = hierarchy[i];

          if (last) {
            last = last[prop];

            if (hierarchy.length == i + 1) {
              return last;
            }
          }
        }
      }
    }

    return null;
  }

  is(obj: any, type: NumberConstructor): obj is number;

  is(obj: any, type: StringConstructor): obj is string;

  is<T>(obj: any, type: { prototype: T }): obj is T;

  is(obj: any, type: any): boolean {
    const objType: string = typeof obj;
    const typeString = type.toString();
    const nameRegex: RegExp = /Arguments|Function|String|Number|Date|Array|Boolean|RegExp/;

    let typeName: string;

    if (obj && objType === "object") {
      return obj instanceof type;
    }

    if (typeString.startsWith("class ")) {
      return type.name.toLowerCase() === objType;
    }

    typeName = typeString.match(nameRegex);
    if (typeName) {
      return typeName[0].toLowerCase() === objType;
    }

    return false;
  }

  arrayOf(myArray: any[], type: any): boolean {
    return myArray.every(item => this.is(item, type));
  }

  createInstance<T>(clazz: new() => T): T {
    return new clazz();
  };
}
