import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {NaturezaCadastroEnum} from "../../../enums/natureza.cadastro.enum";
import {SituacaoPessoaEnum} from "../../../enums/situacao.pessoa.enum";

@JacksonType("PessoaFilter")
export class PessoaFilter {

  documento: string;

  email: string;

  grpMobiliario: string;

  id: number;

  idContador: number;

  idMunicipio: number;

  nome: string;

  parametro: string;

  @EnumType(NaturezaCadastroEnum)
  natureza: NaturezaCadastroEnum = NaturezaCadastroEnum.PERMANENTE;

  @EnumType(SituacaoPessoaEnum)
  situacao: SituacaoPessoaEnum = SituacaoPessoaEnum.ATIVA;

  @DateType()
  dataComparacao:Date;

  inscricaoMunicipal: string;

}
