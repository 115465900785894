import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EscritTransporteTarifaTO")
export class EscritTransporteTarifaTO {

  id: number;

  codigo: number;

  descricao: string;

}
