import {JacksonType} from "@sonner/jackson-service-v2";
import {NotaDeducaoDocumentoTO} from "./nota.deducao.documento.to";

@JacksonType("NotaDeducaoTO")
export class NotaDeducaoTO {

  @JacksonType("NotaDeducaoDocumentoTO")
  notasDeducaoDocumento: NotaDeducaoDocumentoTO[];

  @JacksonType("NotaDeducaoDocumentoTO")
  notasDeducaoDocumentoSemVinculo: NotaDeducaoDocumentoTO[];

  notasDeducaoDocumentoAdicionadas: number[];

  notasDeducaoDocumentoRemovidas: number[];

  mes: number;

  ano: number;

  valorVinculadoCancelado: number;

}
