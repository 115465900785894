import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EncerramentosAutomaticoDetalhamentoTO")
export class EncerramentosAutomaticoDetalhamentoTO {

  nome: string;

  documento: string;

  erro: string;

}
