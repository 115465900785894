import {DateType, EnumType, JacksonType, JsonIdentityInfo, MapType} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {ProcessoMetadata} from "./processo.metadata";
import {ProcessoAssincronoFilaEnum} from "../enums/processo.assincrono.fila.enum";

@JacksonType("ProcessoAssincrono")
@JsonIdentityInfo()
export class ProcessoAssincrono extends SonnerBaseEntity {

  id: number;

  @DateType()
  agendado: Date;

  erro: string;

  @DateType()
  fimProcessamento: Date;

  @DateType()
  inicioProcessamento: Date;

  mensagem: string;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @DateType()
  terminoInexperado: Date;

  @EnumType(ProcessoAssincronoFilaEnum)
  tipoFila: ProcessoAssincronoFilaEnum;

  urlRetorno: string;

  @MapType({keyType: "string", valueType: "ProcessoMetadata"})
  metadata: Map<string, ProcessoMetadata>;

  @MapType({keyType: "string", valueType: "Date"})
  parametros: Map<string, Date>;
}
