import {Injectable} from "@angular/core";
import {RestService} from "../../core/services/rest.service";
import {ExportTO} from "../../../classes/to/export.to";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {JacksonService} from "@sonner/jackson-service-v2";
import {Observable} from "rxjs";
import {saveAs} from "file-saver";
import {ExportFilter} from "../../solicitacao/filters/export.filter";

@Injectable()
export class ExportService {

  constructor(private jacksonService: JacksonService,
              private httpClient: HttpClient,
              private restService: RestService) {
  }

  downloadNotaFiscalPDF(exportTO: ExportTO): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(this.restService.url("/export/notaFiscalPDF"), this.jacksonService.encodeToJson(exportTO), { headers: new HttpHeaders().set("Content-type", "application/json"), responseType: 'blob', observe: 'response' });
  }

  downloadNotaFiscalXML(exportTO: ExportTO): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(this.restService.url("/export/notaFiscalXML"), this.jacksonService.encodeToJson(exportTO), { headers: new HttpHeaders().set("Content-type", "application/json"), responseType: 'blob', observe: 'response' });
  }

  enviarEmail(exportTO: ExportTO): Observable<boolean> {
    return this.restService.post("/export/email", {data: exportTO});
  }

  relatorio(url: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(url, { headers: new HttpHeaders().set("Content-type", "application/json"), responseType: 'blob', observe: 'response' });
  }

  relatorioTemp(url: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
  }

  exportGov(filter: ExportFilter): Observable<any>{
    return this.restService.post("/export/exportGov", {data: filter});
  }

  static saveAs(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get("content-disposition");
    const contentType = response.headers.get("content-type");
    const blob = new Blob([response.body], {type: contentType});
    const fileName = contentDisposition.substr(contentDisposition.indexOf("filename=") + 9).replace(/\"/g, "");

    saveAs(blob, fileName);
  }
}
