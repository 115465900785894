import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {CEP} from "../../../classes/c.e.p";
import {CEPFilter} from "../filters/cep.filter";

@Injectable()
export class CEPService {

  constructor(private restService: RestService) {
  }

  buscarPorCEP(cepFilter: CEPFilter): Observable<CEP> {
    return this.restService.post("/cep/buscarPorCEP", {data: cepFilter, responseType: CEP});
  }
}
