import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {PerfilDescontoBaseCalculo} from "./perfil.desconto.base.calculo";

@JacksonType("TipoDescontoBaseCalculo")
@JsonIdentityInfo()
export class TipoDescontoBaseCalculo extends SonnerBaseEntity {

  id: number;

  nome: string;

  @JacksonType("PerfilDescontoBaseCalculo")
  perfilDesconto: PerfilDescontoBaseCalculo;

  @JacksonType("Atividade")
  atividadesRelacionadas: Atividade[];
}
