import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {DesifTipoDependenciaEnum} from "../enums/desif.tipo.dependencia.enum";

@JacksonType("BancoDependencia")
@JsonIdentityInfo()
export class BancoDependencia extends SonnerBaseEntity {

  id: number;

  cnpj: string;

  codigoDependencia: string;

  enderecoDependencia: string;

  indicadorCodigoDependencia: number;

  @JacksonType("Pessoa")
  responsavel: Pessoa;

  @EnumType(DesifTipoDependenciaEnum)
  tipoDependencia: DesifTipoDependenciaEnum;
}
