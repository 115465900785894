import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {BancoDependencia} from "./banco.dependencia";
import {ContaCosif} from "./conta.cosif";
import {DesifDemonstrativoContabil} from "./desif.demonstrativo.contabil";

@JacksonType("DesifDemonstrativoContabilBalanceteAnalitico")
@JsonIdentityInfo()
export class DesifDemonstrativoContabilBalanceteAnalitico extends SonnerBaseEntity {

  id: number;

  ano: number;

  conta: string;

  @JacksonType("ContaCosif")
  contaCosif: ContaCosif;

  @JacksonType("DesifDemonstrativoContabilBalanceteAnalitico")
  contaSuperior: DesifDemonstrativoContabilBalanceteAnalitico;

  @JacksonType("DesifDemonstrativoContabil")
  demonstrativoContabil: DesifDemonstrativoContabil;

  @JacksonType("BancoDependencia")
  dependencia: BancoDependencia;

  desdobramentoContaMista: string;

  mes: number;

  saldoFinal: number;

  saldoInicial: number;

  valorDebito: number;

  valorCredito: number;
}
