import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";

@JacksonType("BloqueioFaltaPagamentoCompetenciaIgnorada")
@JsonIdentityInfo()
export class BloqueioFaltaPagamentoCompetenciaIgnorada {

  id: number;

  anoCompetenciaFinal: number;

  anoCompetenciaInicial: number;

  competenciaFinalOrdenacao: string;

  competenciaInicialOrdenacao: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("Pessoa")
  emissor: Pessoa;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  justificativa: string;

  mesCompetenciaFinal: number;

  mesCompetenciaInicial: number;
}
