import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from './pessoa';
import {TipoAbatimentoObra} from './tipo.abatimento.obra';

@JacksonType("TipoAbatimentoObraContribuinte")
@JsonIdentityInfo()
export class TipoAbatimentoObraContribuinte extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @JacksonType("TipoAbatimentoObra")
  tipoAbatimentoObra: TipoAbatimentoObra;

  @JacksonType("Pessoa")
  usuario: Pessoa;

  @DateType()
  dataHora: Date;

}
