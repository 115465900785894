import {JacksonType} from "@sonner/jackson-service-v2";
import {PGDASDDASTO} from "./pgdasd.das.to";

@JacksonType("ConsultaDASTO")
export class ConsultaDASTO {

  ano: number;

  mes: number;

  @JacksonType("PGDASDDASTO")
  pgdasddasList: PGDASDDASTO[];
}
