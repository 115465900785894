export enum ExigibilidadeISSEnum {

  EXIGIVEL = "Exigível",
  NAOINCIDENCIA = "Não incidência",
  ISENCAO = "Isenção",
  EXPORTACAO = "Exportação",
  IMUNIDADE = "Imunidade",
  EXIGIBILIDADESUSPENSAJUDICIAL = "Exigibilidade suspensa por decisão judicial",
  EXIGIBILIDADESUSPENSAADM = "Exigibilidade suspensa por decisão administrativa"
}

export function codigo(exigibilidadeISS: ExigibilidadeISSEnum): number {

  if (exigibilidadeISS == ExigibilidadeISSEnum.EXIGIVEL) {
    return 1;
  } else if (exigibilidadeISS == ExigibilidadeISSEnum.NAOINCIDENCIA) {
    return 2;
  } else if (exigibilidadeISS == ExigibilidadeISSEnum.ISENCAO) {
    return 3;
  } else if (exigibilidadeISS == ExigibilidadeISSEnum.EXPORTACAO) {
    return 4;
  } else if (exigibilidadeISS == ExigibilidadeISSEnum.IMUNIDADE) {
    return 5;
  } else if (exigibilidadeISS == ExigibilidadeISSEnum.EXIGIBILIDADESUSPENSAJUDICIAL) {
    return 6;
  } else if (exigibilidadeISS == ExigibilidadeISSEnum.EXIGIBILIDADESUSPENSAADM) {
    return 7;
  }
}
