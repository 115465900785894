export enum FormaArrecadacaoEnum {

  e1 = "Guich� de Caixa com fatura/guia de arrecada��o",
  e2 = "Arrecada��o Eletr�nica com fatura/guia de arrecada��o (terminais de auto - atendimento, ATM, home/office banking)",
  e3 = "Internet com fatura/guia de arrecada��o",
  e4 = "Outros meios com fatura/guia de arrecada��o",
  e5 = "Casas lot�ricas com fatura/guia de arrecada��o",
  e6 = "Telefone com fatura/guia de arrecada��o",
  ea = "Guich� de Caixa sem fatura/guia de arrecada��o",
  eb = "Arrecada��o Eletr�nica sem fatura/guia de arrecada��o (terminais de auto - atendimento, ATM, home/office banking)",
  ec = "Internet sem fatura/guia de arrecada��o",
  ed = "Casas lot�ricas sem fatura/guia de arrecada��o",
  ee = "Telefone sem fatura/guia de arrecada��o",
  ef = "Outros meios sem fatura/guia de arrecada��o"
}
