import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {Pessoa} from "./pessoa";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Contador")
@JsonSubType({key: 'br.com.sonner.iss.entity.Contador', type: "Papel"})
export class Contador extends Papel {

  @JacksonType("Pessoa")
  contribuinteSelecionado: Pessoa;

  crc: string;

  ufCrc: string;

  // constructors

  constructor() {
    super(PerfilEnum.contador);
  }
}
