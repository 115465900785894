import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {MultaStrategy} from "./multa.strategy";

@JacksonType("MultaDiariaStrategy")
@JsonSubType({key: 'br.com.sonner.iss.entity.MultaDiariaStrategy', type: "MultaStrategy"})
export class MultaDiariaStrategy extends MultaStrategy {

  multa: number;

  teto: number;

  getDisplayValue(): string {
    return "Multa de " + (this.multa * 100).toFixed(2) + "% ao dia até o máximo de " + (this.teto * 100).toFixed(2) + "%";
  }
}
