import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";

@JacksonType("PessoaMigracaoGrp")
@JsonIdentityInfo()
export class PessoaMigracaoGrp {

  id: number;

  forcarMigracao: boolean;

  grpMobiliario: string;

  idContribuinteGrp: number;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  tipoPessoa: string;

  @DateType()
  ultimaMigracao: Date;
}
