import {JacksonType} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {EncerramentoAutomatico} from "./encerramento.automatico";

@JacksonType("EncerramentoAutomaticoDetalhamento")
export class EncerramentoAutomaticoDetalhamento extends SonnerBaseEntity {

  id:number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("EncerramentoAutomatico")
  encerramento:EncerramentoAutomatico;

  encerraPrestador: boolean;

  encerraTomador: boolean;

  mensagemProcessamento: string;

  sucesso: boolean;

}
