import {JacksonType} from "@sonner/jackson-service-v2";
import {CompetenciaDimensao} from "../competencia.dimensao";
import {AlertaFiscal} from "../alerta.fiscal";

@JacksonType("AlertaFiscalTO")
export class AlertaFiscalTO {

  @JacksonType("CompetenciaDimensao")
  competencia: CompetenciaDimensao;

  @JacksonType("AlertaFiscal")
  alertaFiscalList: AlertaFiscal[];

}
