import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Arquivo} from "./arquivo";

@JacksonType("TNCGlobalScript")
@JsonIdentityInfo()
export class TNCGlobalScript {

  id: number;

  ativo: boolean;

  @JacksonType("Arquivo")
  arquivo: Arquivo;

  @DateType()
  criado: Date;

  @DateType()
  modificado: Date;

  posicao: number;
}
