import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("GuiaMensagem")
@JsonIdentityInfo()
export class GuiaMensagem extends SonnerBaseEntity {

  id: number;

  mensagem1: string;

  mensagem2: string;

  mensagem3: string;

  mensagem4: string;

  mensagem5: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  nomeSecretaria: string;

  @DateType()
  vencimentoFim: Date;

  @DateType()
  vencimentoInicio: Date;
}
