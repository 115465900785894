import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("MunicipioVencimento")
@JsonIdentityInfo()
export class MunicipioVencimento extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType('Municipio')
  municipio: Municipio;

  mes: number;

  @DateType()
  vencimentoTomador: Date;

  @DateType()
  vencimento: Date;
}
