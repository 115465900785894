import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {DocumentoPipe} from "../modules/core/pipes/documento.pipe";

@JacksonType("CatalogoEndereco")
@JsonIdentityInfo()
export class CatalogoEndereco extends SonnerBaseEntity {

  id: number;

  bairro: string;

  cep: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  complemento: string;

  emailTomador: string;

  endereco: string;

  documentoTomador: string;

  inscEstTomador: string;

  inscMunTomador: string;

  @JacksonType("Municipio")
  municipioTomador: Municipio;

  nomeTomador: string;

  numero: string;

  pais: string;

  telefone: string;
}
