import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ImportacaoPGDASD} from "./importacao.p.g.d.a.sd";
import {Municipio} from "./municipio";
import {PGDASDApuracaoFilial} from "./p.g.d.a.s.d.apuracao.filial";
import {PGDASDApuracaoReceita} from "./p.g.d.a.s.d.apuracao.receita";
import {PGDASDOperacaoEnum} from "../enums/pgdas.d.operacao.enum";
import {PGDASDTipoArquivoEnum} from "../enums/pgdas.d.tipo.arquivo.enum";

@JacksonType("PGDASDApuracao")
@JsonIdentityInfo()
export class PGDASDApuracao extends SonnerBaseEntity {

  id: number;

  @DateType()
  abertura: Date;

  ano: number;

  cnpjMatriz: string;

  fatorR: number;

  @JacksonType("ImportacaoPGDASD")
  importacao: ImportacaoPGDASD;

  mes: number;

  @JacksonType("Municipio")
  municipioMatriz: Municipio;

  numeroRecibo: string;

  numeroAutenticacao: string;

  @EnumType(PGDASDOperacaoEnum)
  operacao: PGDASDOperacaoEnum;

  optante: boolean;

  pgdasdId: string;

  razaoSocial: string;

  receitaBruta: number;

  receitaBruta12MesesOriginal: number;

  receitaBruta12MesesProporcional: number;

  receitaBrutaAnoAnterior: number;

  receitaBrutaAnoCorrente: number;

  @EnumType(PGDASDTipoArquivoEnum)
  tipoArquivo: PGDASDTipoArquivoEnum;

  @DateType()
  transmissao: Date;

  valorIssFixo: number;

  @JacksonType("PGDASDApuracaoFilial")
  filiais: PGDASDApuracaoFilial[];

  @JacksonType("PGDASDApuracaoReceita")
  receitas: PGDASDApuracaoReceita[];

  //custom
  showReceitas: boolean = false;
}
