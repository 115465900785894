import {EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {AlertaFiscal} from "./alerta.fiscal";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("AlertaFiscalRegimeSimplesIncorreto")
@JsonSubType({key: 'br.com.sonner.iss.entity.dw.AlertaFiscalRegimeSimplesIncorreto', type: 'AlertaFiscal'})
@JsonIdentityInfo()
export class AlertaFiscalRegimeSimplesIncorreto extends AlertaFiscal {

  aliquotaApuracaoSimples: number;

  aliquotaEncontrada: number;

  @EnumType(TipoRegimeISSEnum)
  tipoRegimeConfigurado: TipoRegimeISSEnum;
}
