import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  DoCheck,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import * as _ from 'lodash';
import validationEngine from "devextreme/ui/validation_engine";
import {Observable} from "rxjs";
import {DxComponent} from "devextreme-angular";
import {ExceptionInfo} from "../../../core/classes/exception.info";
import {CustomValidation, CustomValidationResult} from "./custom.validation";
import {ExceptionInfoService} from "../../services/exception.info.service";
import {DevExtremeValidatorHelper} from "../../../core/classes/dev.extreme.validator.helper";
import {ObservableUtils} from "../../../core/utils/observable.utils";
import {Currency} from "../../../../classes/currency";
import {RouterService} from "../../../core/services/router.service";
import {HttpResponse} from "@angular/common/http";
import {saveAs} from 'file-saver';

export abstract class BaseComponent implements AfterViewInit, OnInit, OnChanges, DoCheck, AfterContentInit, AfterContentChecked, OnDestroy, AfterViewChecked {

  _ = _;

  @Input()
  errorMessages: string[] = [];

  errorsVisible: boolean = false;

  @Input()
  validationGroup: string;

  @Input()
  isRequired: boolean = false;

  @Input()
  requiredErrorMessage: string = 'Campo obrigatório';

  @Input()
  customValidation: CustomValidation;

  @Input()
  focusOnInit = false;

  @Input()
  focusFirstInputOnInit = false;

  @Output()
  hasError: EventEmitter<ExceptionInfo> = new EventEmitter<ExceptionInfo>();

  dateFormatDefault = 'dd/MM/yyyy'
  dateTimeFormatDefault = 'dd/MM/yyyy HH:mm'
  dateTimeFullFormatDefault = 'dd/MM/yyyy HH:mm:ss'

  protected constructor(injector: Injector) {
    this.validationCallback = this.validationCallback.bind(this);
    this.exceptionInfoService = injector.get(ExceptionInfoService);
    this.routerService = injector.get(RouterService);
  }

  loading: boolean = true;

  sucess: boolean = false;

  sucessMessage: string = "";

  getCurrency(data: any) {
    if (!data) {
      data = 0;
    }

    let currency: Currency = new Currency();

    currency.value = data;

    return currency.valueString;
  }

  setCurrency(event: string) {
    let currency: Currency = new Currency();

    currency.valueString = event;

    return currency.value;
  }

  getValidators() {
    return DevExtremeValidatorHelper.getValidators(this.validationGroup);
  }

  // services

  exceptionInfoService: ExceptionInfoService;

  routerService: RouterService;

  // methods

  addErrorMessage(message: string) {
    const array = this.errorMessages || [];
    array.push(message);
    this.errorMessages = [];
    this.errorMessages.push.apply(this.errorMessages, array);
    this.errorsVisible = true;
  }

  applyValidatorsByPass(doByPass: boolean) {
    DevExtremeValidatorHelper.applyValidatorsByPass(this.validationGroup, doByPass);
  }

  assertError() {
    if (this.hasErrorMessages()) {
      this.errorsVisible = true;
      this.errorMessages = [].concat(this.errorMessages);
    } else {
      this.clearErrorMessages();
    }
  }

  checkFocus() {
    if (this.focusOnInit) {
      this.doFocusOnInit();
    }
  }

  clearErrorMessages() {
    if (_.isArray(this.errorMessages)) {
      this.errorMessages.length = 0
    } else {
      this.errorMessages = [];
    }

    this.errorsVisible = false;
  }

  clearValidation() {
    if (this.validationGroup) {
      const group = DevExtremeValidatorHelper.findGroup(this.validationGroup);

      if (group) {
        if (group && !_.isEmpty(group.validators)) {
          for (let validator of group.validators) {
            validator.reset();
          }
        }
      }
    }
  }

  clearValidatorStyleByComponent(component: DxComponent, isValid?: boolean) {
    if (component) {
      if (_.isNil(isValid)) {
        isValid = true;
      }

      DevExtremeValidatorHelper.resetStyleComponent(component, isValid);
    }
  }

  disableLoading() {
    setTimeout(() => this.loading = false);
  }

  displayValue(data: any) {
    return data && data.getDisplayValue ? data.getDisplayValue() : data;
  }

  doFocusOnInit() {
  }

  getPercent(data: any) {
    if (!data) {
      data = 0;
    }

    let currency: Currency = new Currency();

    currency.value = data *100;

    return currency.valueString +"%";
  }

  getPercentNoRound(data: any) {
    if (!data) {
      data = 0;
    }

    data *100;

    return ((data*100).toFixed(4)).toString().replace(".",",")+"%";
  }

  hasErrorMessages(): boolean {
    return !_.isEmpty(this.errorMessages);
  }

  handleError(error: ExceptionInfo) {
    this.disableLoading();
    this.clearErrorMessages();
    this.hasError.emit(error);

    this.exceptionInfoService.toMessages(error).forEach(msg => {
      this.errorMessages.push(msg.content);
    });

    if (this.errorMessages.length > 0) {
      this.errorsVisible = true;
    }
  }

  isValid(): boolean {
    return this.validate();
  }

  navigate(uri: string, url?: string, event?, parameter?, openNewWindow: boolean = false) {
    let ctrlKey: boolean = false;

    if (event) {
      ctrlKey = event.ctrlKey;

      event.preventDefault();
    }

    if (ctrlKey || openNewWindow) {
      if (url) {
        window.open(url, "_blank", "");
      } else {
        window.open(uri, "_blank", "");
      }
    } else {
      this.loading = true;
      this.routerService.open(uri, parameter);
    }
  }

  of(observable: Observable<any>, successFn?: Function, errorFn?: Function) {
    const defaultHandleError = this.handleError.bind(this);
    this.sucess = false;

    this.clearErrorMessages();

    return ObservableUtils.of(observable, successFn, errorFn ? errorFn : defaultHandleError);
  }

  saveAs(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get("content-disposition");
    const contentType = response.headers.get("content-type");
    const blob = new Blob([response.body], {type: contentType});
    const fileName = contentDisposition.substr(contentDisposition.indexOf("filename=") + 9).replace(/\"/g, "");

    saveAs(blob, fileName);
  }

  validate(): boolean {
    let isValid = null;

    if (_.isEmpty(this.validationGroup)) {
      throw new Error(`validationGroup não encontrado.`);
    }

    try {
      isValid = validationEngine.validateGroup(this.validationGroup);
    } catch (e) {
      console.log("ValidationGroup nao criado: " + this.validationGroup);
    }

    return (!isValid || isValid.isValid);
  }

  validationCallback(params) {
    params.rule.isValid = true;

    if (this.isRequired) {
      let value = params.value;

      if (!value || value == "" || (_.isArray(value) && value.length == 0)) {
        params.rule.isValid = false;
        params.rule.message = this.requiredErrorMessage;
      }
    }

    if (this.customValidation) {
      let value = params.value;
      let result = this.customValidation(value, params.rule.isValid);

      if (!result) {
        return params.rule.isValid;
      }

      if (result['then']) {
        result = result as Promise<CustomValidationResult>;

        result.then(asyncResult => {
          if (asyncResult) {
            params.rule.isValid = asyncResult.isValid;
            params.rule.message = asyncResult.errorMessage;
            params.validator.validate();
          }
        });
      } else {
        result = result as CustomValidationResult;
        params.rule.isValid = result.isValid;
        params.rule.message = result.errorMessage;
      }
    }

    return params.rule.isValid;
  }

  // implements

  protected doAfterContentChecked() {
  }

  protected doAfterContentInit() {
  }

  protected doAfterViewChecked() {
  }

  protected doAfterViewInit() {
  }

  protected doDoCheck() {
  }

  protected doOnChanges(changes: SimpleChanges) {
  }

  protected doOnDestroy() {
  }

  protected doOnInit() {
  }

  ngAfterContentChecked(): void {
    this.doAfterContentChecked();
  }

  ngAfterContentInit(): void {
    this.doAfterContentInit();
  }

  ngAfterViewChecked(): void {
    this.doAfterViewChecked();
  }

  ngAfterViewInit(): void {
    this.checkFocus();
    this.doAfterViewInit();
  }

  ngDoCheck(): void {
    this.doDoCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.doOnChanges(changes);
  }

  ngOnDestroy(): void {
    this.doOnDestroy();
  }

  ngOnInit(): void {
    this.doOnInit();
  }

  nope() {
  }
}
