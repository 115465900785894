import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("Telefone")
@JsonIdentityInfo()
export class Telefone extends SonnerBaseEntity {

  id: number;

  fax: boolean = false;

  numero: string;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  principal: boolean = false;
}
