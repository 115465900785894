import {Injectable} from "@angular/core";
import {RestService} from "../../core/services/rest.service";
import {Observable} from "rxjs";
import {Tarefa} from "../../../classes/tarefa";
import {TarefaFilter} from "../filters/tarefa.filter";

@Injectable()
export class TarefaService {

  constructor(private restService: RestService) {
  }

  buscar(tarefaFilter: TarefaFilter): Observable<Tarefa> {
    return this.restService.post("/tarefa/buscar", {data: tarefaFilter, responseType: Tarefa});
  }
}
