import {JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {AlertaFiscal} from "./alerta.fiscal";

@JacksonType("AlertaFiscalAliquotaSimplesIncorreta")
@JsonSubType({key: 'br.com.sonner.iss.entity.dw.AlertaFiscalAliquotaSimplesIncorreta', type: 'AlertaFiscal'})
@JsonIdentityInfo()
export class AlertaFiscalAliquotaSimplesIncorreta extends AlertaFiscal {

  aliquotaApuracaoSimples: number;

  aliquotaEncontrada: number;

  retido: boolean;
}
