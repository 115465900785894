import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AgendaFiscalizacao} from "./agenda.fiscalizacao";
import {GuiaAvulsa} from "./guia.avulsa";
import {OcorrenciaTermoAutoInfracao} from "./ocorrencia.termo.auto.infracao";

@JacksonType("TermoAutoInfracao")
@JsonIdentityInfo()
export class TermoAutoInfracao extends SonnerBaseEntity {

  id: number;

  @JacksonType("AgendaFiscalizacao")
  agendamento: AgendaFiscalizacao;

  descricao: string;

  docArrecacaoUnico: boolean;

  @DateType()
  emissao: Date;

  @JacksonType("GuiaAvulsa")
  guiaUnica: GuiaAvulsa;

  senha: string;

  @JacksonType("OcorrenciaTermoAutoInfracao")
  ocorrencias: OcorrenciaTermoAutoInfracao[];
}
