import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("TNCDocumentoExportadosTO")
export class TNCDocumentoExportadosTO {

  dataExportacao: Date;

  descricao: string;

  exportacaoPendente: boolean = false;

  processadoGrp: boolean = false;

  senha: string;
}
