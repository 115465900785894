import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {Municipio} from "./municipio";
import {TipoDocumentoCartorioEnum} from "../enums/tipo.documento.cartorio.enum";
import {TipoServicoCartorio} from "./tipo.servico.cartorio";

@JacksonType("EscritCartorio")
@JsonIdentityInfo()
export class EscritCartorio extends SonnerBaseEntity {

  id: number;

  @EnumType(TipoDocumentoCartorioEnum)
  tipoDocumento: TipoDocumentoCartorioEnum;

  docInicial: number;

  docFinal: number;

  receitaBruta: number;

  /**receita própria*/
  baseCalculo: number;

  @JacksonType("TipoServicoCartorio")
  tipoServico: TipoServicoCartorio;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("Pessoa")
  emissor: Pessoa;

  @JacksonType("Municipio")
  municipio: Municipio;

  mes: number;

  ano: number;

  aliquota: number;

  valorImposto: number;

  @DateType()
  dataAlteracao: Date;

  @DateType()
  dataCadastro: Date;

  @DateType()
  dataCancelamento: Date;

  motivoCancelamento: string;

}
