import {DateType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Curso} from "./curso";
import {Papel} from "./papel";

@JacksonType("Ensino")
@JsonSubType({key: 'br.com.sonner.iss.entity.Ensino', type: 'Papel'})
@JsonIdentityInfo()
export class Ensino extends Papel {

  @DateType()
  dataEmissaoNota: Date;

  @JacksonType("Curso")
  cursoList: Curso[]
}
