import {JacksonType, MapType} from "@sonner/jackson-service-v2";
import {EncerramentoAnoMesTO} from "./encerramento.ano.mes.to";
import {PessoaSimplificadaTO} from "./pessoa.simplificada.to";

@JacksonType("EncerramentoDetalheMesTO")
export class EncerramentoDetalheMesTO {

  // @JacksonType("EncerramentoMesTO")
  // encerramentoMesTOList : EncerramentoMesTO[] = [];

  @MapType()
  cssStylePrestador:Map<string,string> = new Map<string, string>();

  @MapType()
  cssStyleTomador:Map<string,string> = new Map<string, string>();

  anos: number[] = [];

  minData: Date[] = [];

  maxData: Date[] = [];

  @JacksonType("PessoaSimplificadaTO")
  pessoa:PessoaSimplificadaTO;

  @JacksonType("EncerramentoAnoMesTO")
  encerramentoAnoMesTOList: EncerramentoAnoMesTO[];
}
