import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {Serie} from "./serie";

@JacksonType("ControleNumeracaoNota")
@JsonIdentityInfo()
export class ControleNumeracaoNota extends SonnerBaseEntity {

  id: number;

  numero: number;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @JacksonType("Serie")
  serie: Serie;
}
