import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {TipoDeducao} from "./tipo.deducao";

@JacksonType("Deducao")
@JsonIdentityInfo()
export class Deducao extends SonnerBaseEntity {

  id: number;

  @JacksonType("TipoDeducao")
  tipo: TipoDeducao;

  valor: number;
}
