import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {TipoImportacaoDWEnum} from "../enums/tipo.importacao.d.w.enum";

@JacksonType("ImportacaoDWMetaDado")
@JsonIdentityInfo()
export class ImportacaoDWMetaDado extends SonnerBaseEntity {

  id: number;

  metaDadoNome: string;

  metaDadoValor: string;

  @EnumType(TipoImportacaoDWEnum)
  tipo: TipoImportacaoDWEnum;
}
