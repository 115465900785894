import {JacksonType} from "@sonner/jackson-service-v2";
import {ParcelamentoSimples} from "../parcelamento.simples";
import {ParcelamentoSimplesPagamento} from "../parcelamento.simples.pagamento";
import {ParcelamentoConsolidacaoTO} from "./parcelamento.consolidacao.to";

@JacksonType("ParcelamentoSimplesTO")
export class ParcelamentoSimplesTO {

  @JacksonType("ParcelamentoSimples")
  parcelamento: ParcelamentoSimples;

  @JacksonType("ParcelamentoConsolidacaoTO")
  consolidacoes: ParcelamentoConsolidacaoTO[];

  @JacksonType("ParcelamentoSimplesPagamento")
  pagamentos: ParcelamentoSimplesPagamento[];
}
