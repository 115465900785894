import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {CalculoGuiaAvulsa} from "../calculo.guia.avulsa";

@JacksonType("CalculoGuiaAvulsaTO")
export class CalculoGuiaAvulsaTO {

  @JacksonType("CalculoGuiaAvulsa")
  calculoGuiaAvulsa: CalculoGuiaAvulsa;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  quantidadeDia: number;

  quantidadeMes: number;

  @DateType()
  pagavel: Date;

  @DateType()
  vencimento: Date;
}
