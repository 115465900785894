import {Component, ContentChildren, Injector, Input, QueryList} from "@angular/core";
import {BaseComponent} from "../base/base.component";
import {AccordionPanelComponent} from "./accordion-panel/accordion.panel.component";

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent extends BaseComponent {

  @ContentChildren(AccordionPanelComponent)
  panels: QueryList<AccordionPanelComponent>;

  @Input()
  opened: boolean = false;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.panels.toArray()[0].opened = this.opened;

      this.panels.toArray().forEach(panel => {
        panel.toggle.subscribe(() => {
          this.openPanel(panel);
        });
      });
    })
  }

  openPanel(panel: AccordionPanelComponent) {
    this.panels.toArray().forEach(p => p.opened = false);

    panel.opened = true;
  }
}
