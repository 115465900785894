import {JacksonType} from "@sonner/jackson-service-v2";
import {PAGPagamento} from "../p.a.g.pagamento";
import {PGDASDDAS} from "../p.g.d.a.s.d.d.a.s";
import {PGDASDDASDetalhe} from "../p.g.d.a.s.d.d.a.s.detalhe";

@JacksonType("PGDASDDASTO")
export class PGDASDDASTO {

  ano: number;

  mes: number;

  @JacksonType("PGDASDDAS")
  das: PGDASDDAS;

  @JacksonType("PGDASDDASDetalhe")
  detalhe: PGDASDDASDetalhe[];

  @JacksonType("PAGPagamento")
  pagamentos: PAGPagamento[];
}
