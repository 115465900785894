import {DateType, EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {FormaArrecadacaoEnum} from "../enums/forma.arrecadacao.enum";
import {FormaPagamentoEnum} from "../enums/forma.pagamento.enum";

@JacksonType("Pagamento")
@JsonSubType({key: 'br.com.sonner.iss.entity.Pagamento', type: 'Documento'})
@JsonIdentityInfo()
export class Pagamento extends Documento {

  autenticacao: string;

  codAgArrecadadora: string;

  codBarras: string;

  contaCreditada: string;

  @DateType()
  dataCredito: Date;

  @DateType()
  dataPagamento: Date;

  @EnumType(FormaArrecadacaoEnum)
  formaArrecadacao: FormaArrecadacaoEnum;

  @EnumType(FormaPagamentoEnum)
  formaPagamento: FormaPagamentoEnum;

  nsr: string;

  valorRecebido: number;

  valorTarifa: number;
}
