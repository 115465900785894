import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {TipoTributacao} from "./tipo.tributacao";

@JacksonType("CodigoTipoTributacao")
@JsonIdentityInfo()
export class CodigoTipoTributacao extends SonnerBaseEntity {

  id: number;

  codigo: string;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;
}
