import {JacksonType} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {RelatorioAssincrono} from "./relatorio.assincrono";

@JacksonType("Relatorios")
export class Relatorios extends SonnerBaseEntity {

  @JacksonType("RelatorioAssincrono")
  relatorios: RelatorioAssincrono[] = [];
}
