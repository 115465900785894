import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {PerfilDescontoBaseCalculo} from "./perfil.desconto.base.calculo";
import {Pessoa} from "./pessoa";

@JacksonType("PessoaPerfilDescontoBaseCalculo")
@JsonIdentityInfo()
export class PessoaPerfilDescontoBaseCalculo extends SonnerBaseEntity {

  id: number;

  ativo: boolean;

  @JacksonType("PerfilDescontoBaseCalculo")
  perfilDesconto: PerfilDescontoBaseCalculo;

  @JacksonType("Pessoa")
  pessoa: Pessoa;
}
