import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {AppStateService} from "../../../app/services/app.state.service";
import {ConfiguracaoMenuFilter} from "../../filters/configuracao.menu.filter";
import {ConfiguracaoMenuService} from "../../services/configuracao.menu.service";
import {ConfiguracaoMenuTO} from "../../../../classes/to/configuracao.menu.to";

@Component({
  selector: 'configuracao-menu',
  templateUrl: './configuracao.menu.page.component.html',
  styleUrls: ['./configuracao.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfiguracaoMenuPageComponent extends BaseComponent {

  constructor(private appStateService: AppStateService,
              private configuracaoService: ConfiguracaoMenuService,
              private injector: Injector) {
    super(injector);
  }

  aliquotaProgressiva: boolean = false;

  backup:boolean = false;

  banco_desif: boolean = false;

  centralContrib: boolean = false;

  configuracaoTO: ConfiguracaoMenuTO = new ConfiguracaoMenuTO();

  descontoImposto: boolean = false;

  fiscalizacao: boolean = false;

  guia_denunciaEspontanea: boolean = false;

  idMunicipio: number;

  nfe_creditosTomadores: boolean = false;

  notaPremiada: boolean = false;

  obras = false;

  transporteColetivo: boolean = false;


  ngOnInit(): void {
    this.appStateService.userInfoSubject.subscribe(user => {
      if (user) {
        this.idMunicipio = user.pessoaUsuario.responsavel.id;

        if (user.authRegex.includes("iss:aliquotaProgressiva")) {
          this.aliquotaProgressiva = true;
        }

        if (user.authRegex.includes("iss:backup")) {
          this.backup = true;
        }

        if (user.authRegex.includes("iss:banco:desif")) {
          this.banco_desif = true;
        }

        if (user.authRegex.includes("iss:centralContrib")) {
          this.centralContrib = true;
        }

        if (user.authRegex.includes("iss:nfe:descontoImposto")) {
          this.descontoImposto = true;
        }

        if (user.authRegex.includes("iss:fiscalizacao")) {
          this.fiscalizacao = true;
        }

        if (user.authRegex.includes("iss:guia:denunciaEspontanea")) {
          this.guia_denunciaEspontanea = true;
        }

        if (user.authRegex.includes("iss:nfe:creditosTomadores")) {
          this.nfe_creditosTomadores = true;
        }

        if (user.authRegex.includes("iss:notaPremiada")) {
          this.notaPremiada = true;
        }

        if (user.authRegex.includes("iss:obras")) {
          this.obras = true;
        }

        if (user.authRegex.includes("iss:transporteColetivo")) {
          this.transporteColetivo = true;
        }

        this.carrega();
      }
    });
  }

  carrega() {
    let configuracaoMenuFilter: ConfiguracaoMenuFilter = new ConfiguracaoMenuFilter();

    configuracaoMenuFilter.idMunicipio = this.idMunicipio;

    return this.of(this.configuracaoService.buscar(configuracaoMenuFilter), configuracaoTO => {
      this.configuracaoTO = configuracaoTO;

      this.disableLoading();
    });
  }
}
