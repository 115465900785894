import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {ItemTabelaIBPT} from "./item.tabela.i.b.p.t";

@JacksonType("AtividadeTabelaIBPT")
@JsonIdentityInfo()
export class AtividadeTabelaIBPT extends SonnerBaseEntity {

  id: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("ItemTabelaIBPT")
  itemTabelaIBPT: ItemTabelaIBPT;
}
