import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {SolicitacaoAIDF} from "../solicitacao.a.i.d.f";

@JacksonType("SolicitacaoAIDFTO")
export class SolicitacaoAIDFTO {

  id: string;

  numInicial: number;

  numFinal: number;

  @DateType()
  vencimento: Date;

  observacao: string;

  valida: boolean;

  @JacksonType("SolicitacaoAIDF")
  anteriores: SolicitacaoAIDF[];

}
