import {EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";
import {Serie} from "./serie";
import {Solicitacao} from "./solicitacao";
import {TipoAIDFEnum} from "../enums/tipo.a.i.d.f.enum";

@JacksonType("SolicitacaoAIDF")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoAIDF', type: 'Solicitacao'})
@JsonIdentityInfo()
export class SolicitacaoAIDF extends Solicitacao {

  autEstadual: string;

  @JacksonType("Pessoa")
  grafica: Pessoa;

  justificativa: string;

  nomeGrafica: string;

  notasPorBloco: number;

  qtd: number;

  @JacksonType("Serie")
  serie: Serie;

  @EnumType(TipoAIDFEnum)
  tipoAIDF: TipoAIDFEnum;

  vias: number;

  webIssNumDocumento: string;
}
