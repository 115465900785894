import {JacksonType} from "@sonner/jackson-service-v2";
import {SolicitacaoDadosPessoa} from "../solicitacao.dados.pessoa";

@JacksonType("SolicitacaoAlteracaoNFeTO")
export class SolicitacaoAlteracaoNFeTO {

  id: string;

  numeroNota: string;

  emissaoNota: string;

  exibeDescontoIncondicional: boolean;

  exibeOutrasDeducoes: boolean;

  @JacksonType("SolicitacaoDadosPessoa")
  dadosTomador: SolicitacaoDadosPessoa;

}
