import {DateType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {Serie} from "./serie";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";

@JacksonType("AutorizacaoNFe")
@JsonSubType({key: 'br.com.sonner.iss.entity.AutorizacaoNFe', type: 'Documento'})
@JsonIdentityInfo()
export class AutorizacaoNFe extends Documento {

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  @JacksonType("Serie")
  serie: Serie;

  vencimentoNotificado: boolean = false;

  constructor() {
    super(TipoDocumentoEnum.ANFE);
  }
}
