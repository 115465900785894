import {Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {Atividade} from "../../../../classes/atividade";
import {AtividadeService} from "../../services/atividade.service";
import {GrupoAtividadeService} from "../../services/grupo.atividade.service";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {VinculoAtividadeService} from "../../services/vinculo.atividade.service";
import {GrupoAtividade} from "../../../../classes/grupo.atividade";
import {AtividadeFilter} from "../../filters/atividade.filter";
import {DxAutocompleteComponent} from "devextreme-angular";
import list from "devextreme/ui/list";

@Component({
  selector: "atividade-selector",
  templateUrl: './atividade.selector.component.html',
  styleUrls: ['./atividade.selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AtividadeSelectorComponent extends BaseComponent {

  @ViewChild("autoCompleteComponent")
  autoCompleteComponent: DxAutocompleteComponent;

  private _atividade: Atividade;

  atividadeSelecionada: Atividade;

  atividadeList: Atividade[];

  grupoList: Atividade[];

  hint: string;

  placeHolderAtividade: string = "Selecione uma atividade...";

  /** Lista somente as atividades da pessoa selecionada */
  @Input()
  atividadeContribuinte: boolean = false;

  /**Criado para não carregar as atividades logo que inicia o componente
   * Na tela de escrituração quando já existe uma atividade selecionada o componente já foi criado e com isso o valor não era atualizado.*/
  @Input()
  carregaAtividade: boolean = true;

  /** Habilitar a possibilidade de selecionar o grupo e preencher o select-box com as atividades do grupo selecionado */
  @Input()
  usaGrupo: boolean = true;

  /** Usa o autocomplete para trazer as atividades, porém não irá funcionar caso a configuração "usaGrupo" esteja true */
  @Input()
  atividadeAutoComplete: boolean = false;

  /** Usado para os select-box */
  @Input()
  searchEnabled: boolean = true;

  /** Permitir limpar o conteúdo selecionado */
  @Input()
  showClearButton : boolean = true;

  /** Atributos utilizados para fazer a busca dentro do autoComplete */
  @Input()
  searchExpr: string[] = ['nome','codigoFormatado'];

  /** Valor que será demonstrado ao selecionar um item dentro do autoComplete */
  @Input()
  valueExpr: string = "getDisplayValue";

  @Output()
  atividadeChange:EventEmitter<Atividade> = new EventEmitter<Atividade>();

  @Output()
  grupoChange:EventEmitter<GrupoAtividade> = new EventEmitter<GrupoAtividade>();

  constructor(private injector: Injector,
              private atividadeService: AtividadeService,
              private vinculoAtividadeService: VinculoAtividadeService,
              private grupoAtividadeService: GrupoAtividadeService) {
    super(injector);
  }


  get atividade(): Atividade {
    return this._atividade;
  }

  set atividade(value: Atividade) {
    this._atividade = value;
  }

  ngOnInit() {
    if(!this.atividadeList){
      this.atividadeList = [];
    }
    if(this.carregaAtividade){
      this.loadAtividades();
    }

  }

  loadAtividades(){
    if(this.usaGrupo){
      this.carregaGrupos();
    }else{
      if(this.atividadeContribuinte){
        this.carregaAtividadePorPessoa();
      }else{
        this.carregaTodasAtividadesMunicipio();
      }
    }
  }

  @GlobalLoadingIndicator()
  alteraGrupoAtividade(event: GrupoAtividade) {
    this.atividadeList = [];
    if (event) {
      let atividadeFilter: AtividadeFilter = new AtividadeFilter();
      atividadeFilter.idGrupoAtividade = event.id;
      return this.of(this.atividadeService.buscarListaPorAtivaEGrupoAtividade(atividadeFilter), atividadeList => {
        this.setAtividadeList(atividadeList);
        this.atividadeChange.emit(null);
        this.grupoChange.emit(event);
      });
    }else{
      this.atividadeChange.emit(null);
      this.grupoChange.emit(event);
    }
  }

  alteraAtividade(event: any) {
    if(event instanceof Atividade){
      setTimeout(()=>{
        if((this.atividadeSelecionada && event.id != this.atividadeSelecionada.id) || !this.atividadeSelecionada){
          this.atividadeChange.emit(event);
          this.hint = event.getDisplayValue();
        }
        this.atividadeSelecionada = event;
      });
    }else if(!event){
      setTimeout(()=>{
        this.limpaAtividade();
      });
    }
  }

  @GlobalLoadingIndicator()
  carregaAtividadePorPessoa(){
    return this.of(this.vinculoAtividadeService.buscarListaAtividadePorPessoa(),atividadeList => this.setAtividadeList(atividadeList));
  }

  @GlobalLoadingIndicator()
  carregaGrupos(){
    return this.of(this.grupoAtividadeService.buscarListaPorAtivaEMunicipio(), grupoAtividade => this.grupoList = grupoAtividade);
  }

  @GlobalLoadingIndicator()
  carregaTodasAtividadesMunicipio(){
    return this.of(this.atividadeService.buscarListaPorAtivaEMunicipio(),atividadeList => this.setAtividadeList(atividadeList));
  }

  setAtividadeList(atividadeList: Atividade[]){
    this.atividadeList = atividadeList;
    if(atividadeList && atividadeList.length == 0){
      this.placeHolderAtividade = "Nenhuma atividade encontrada";
    }

    this.atividadeSelecionada = this.atividade;
    if(this.atividadeSelecionada){
      this.autoCompleteComponent.value = this.atividadeSelecionada.getDisplayValue() ;
    }
  }

  limpaAtividade(){
    this.atividadeSelecionada = null;
    this.atividade = null;
    if(this.atividadeAutoComplete){
      this.autoCompleteComponent.value = null;
    }
    this.hint = "";
    this.atividadeChange.emit(null);
  }

}
