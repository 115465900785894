import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {RotinaAgendamento} from "../rotina.agendamento";

@JacksonType("RotinaAgendamentoTO")
export class RotinaAgendamentoTO {

  ano: boolean = false;

  @JacksonType("RotinaAgendamento")
  rotinaAgendamento: RotinaAgendamento;

  @DateType()
  competenciaList: Date[];
}
