import {JacksonType} from "@sonner/jackson-service-v2";
import {PagamentoSIAFI} from "../pagamento.s.i.a.f.i";
import {ConsultaContribuinteNotaEscritTO} from "./consulta.contribuinte.nota.escrit.to";
import {DateUtilsService} from "../../modules/core/services/date.utils.service";

@JacksonType("SIAFIPagamentosCompetenciaTO")
export class SIAFIPagamentosCompetenciaTO {

  @JacksonType("ConsultaContribuinteNotaEscritTO")
  notasSemPagamentoList: ConsultaContribuinteNotaEscritTO[];

  @JacksonType("PagamentoSIAFI")
  pagamentosSemNotaIdentificada: PagamentoSIAFI[];

  @JacksonType("PagamentoSIAFI")
  pagamentosComNotaIdentificada: PagamentoSIAFI[];

  valorIssEmNotas: number;

  valorIssPago: number;

  ano: number;

  mes: number;

  getMesAnoFmt(): string {
    let dateUtilsService: DateUtilsService = new DateUtilsService();
    const mesFmt:string  = (this.mes == null) ? "" : dateUtilsService.getMesString(this.mes);
    const anoFmt: string = (this.ano == null) ? "" : this.ano.toString();
    return mesFmt + "/" + anoFmt;
  }


}
