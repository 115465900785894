import {EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {PapelServicoEnum} from "../../../enums/papel.servico.enum";

@JacksonType("EncerramentoFilter")
export class EncerramentoFilter {

  ano: number;

  anoFinal: number;

  documento: string;

  id: number;

  idMunicipio: number;

  idPessoa: number;

  mes: number;

  mesFinal: number;

  @EnumType(PapelServicoEnum)
  papelServico: PapelServicoEnum;
}
