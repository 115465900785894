import {JacksonType} from "@sonner/jackson-service-v2";
import {SolicitacaoArquivoTO} from "./solicitacao.arquivo.to";

@JacksonType("SolicitacaoCancelamentoNFeTO")
export class SolicitacaoCancelamentoNFeTO {

  id: string;

  motivoDeferimento: string;

  @JacksonType("SolicitacaoArquivoTO")
  arquivos: SolicitacaoArquivoTO[];

  guia:string;

  dadosPrestador: string;

  dadosTomador: string;

  numeroSerie: string;

  exigibilidade: string;

  valor: string;

  notaId: string;

  getFormatedNotaId(){
    return this.notaId.replace(/\+/g,"%2B");
  }
}
