import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {EscritTransporteTarifa} from "./escrit.transporte.tarifa";

@JacksonType("EscritTransporteTarifaValor")
@JsonIdentityInfo()
export class EscritTransporteTarifaValor extends SonnerBaseEntity {

  id: number;

  @JacksonType("EscritTransporteTarifa")
  tarifa: EscritTransporteTarifa;

  @DateType()
  inicio: Date;

  @DateType()
  fim: Date;

  valor: number;

}
