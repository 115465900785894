import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("FaixaMulta")
@JsonIdentityInfo()
export class FaixaMulta extends SonnerBaseEntity {

  id: number;

  dias: number;

  multa: number;
}
