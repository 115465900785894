import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("AtividadeDimensao")
@JsonIdentityInfo()
export class AtividadeDimensao extends SonnerBaseEntity {

  id: number;

  ativa: boolean;

  codigo: string;

  idOriginal: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  nomeCurto: string;
}
