import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {EstadoFilter} from "../filters/estado.filter";
import {Estado} from "../../../classes/estado";

@Injectable()
export class EstadoService {

  constructor(private restService: RestService) {
  }

  buscar(estadoFilter: EstadoFilter): Observable<Estado> {
    return this.restService.post("/estado-v2/buscar", {data: estadoFilter, responseType: Estado});
  }

  buscarLista(): Observable<Estado[]> {
    return this.restService.post("/estado-v2/buscarLista", {responseType: Estado});
  }
}
