import {JacksonType} from "@sonner/jackson-service-v2";
import {NotaEnsinoAlunoTO} from "./nota.ensino.aluno.to";

@JacksonType("NotaEnsinoTO")
export class NotaEnsinoTO{

  ano: number;

  mes: number;

  quantidadeNotas: number;

  @JacksonType("NotaEnsinoAlunoTO")
  notasAlunos: NotaEnsinoAlunoTO[];

}
