import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";

@JacksonType("Competencia")
@JsonIdentityInfo()
export class Competencia {

  ano: number;

  @DateType()
  data: Date;

  mes: number;

  possuiEstatistica: boolean = true;
}
