import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {AtividadeTO} from "../../../classes/to/atividade.to";
import {Atividade} from "../../../classes/atividade";
import {AtividadeFilter} from "../filters/atividade.filter";
import {AliquotaSimplesTO} from "../../../classes/to/aliquota.simples.to";
import {VinculoAtividadeAnexoSimplesTO} from "../../../classes/to/vinculo.atividade.anexo.simples.to";

@Injectable()
export class AtividadeService {

  constructor(private restService: RestService){
  }

  atualizar(atividadeFilter: AtividadeFilter): Observable<AtividadeTO> {
    return this.restService.post("/atividade-v2/atualizar", {data: atividadeFilter, responseType: AtividadeTO});
  }

  buscar(atividadeFilter: AtividadeFilter): Observable<AtividadeTO> {
    return this.restService.post("/atividade-v2/buscar", {data: atividadeFilter, responseType: AtividadeTO});
  }


  buscarPorCodigoEMunicipio(atividadeFilter: AtividadeFilter): Observable<Atividade> {
    return this.restService.post("/atividade-v2/buscarPorCodigoEMunicipio", {data: atividadeFilter, responseType: Atividade});
  }

  buscarLista(atividadeFilter: AtividadeFilter): Observable<Atividade[]> {
    return this.restService.post("/atividade-v2/buscarLista", {data: atividadeFilter, responseType: Atividade});
  }
  buscarConfiguracao(): Observable<AtividadeTO> {
    return this.restService.post("/atividade-v2/buscarConfiguracao", {responseType: AtividadeTO});
  }

  buscarListaPorAtivaEGrupoAtividade(atividadeFilter: AtividadeFilter): Observable<Atividade[]> {
    return this.restService.post("/atividade-v2/buscarListaPorAtivaEGrupoAtividade", {data: atividadeFilter, responseType: Atividade});
  }


  buscarListaPorAtivaEMunicipio(atividadeFilter?: AtividadeFilter): Observable<Atividade[]> {
    return this.restService.post("/atividade-v2/buscarListaPorAtivaEMunicipio", {data: atividadeFilter, responseType: Atividade});
  }

  desativarAtividade(atividadeFilter: AtividadeFilter): Observable<Atividade> {
    return this.restService.post("/atividade-v2/desativarAtividade", {data: atividadeFilter, responseType: Atividade});
  }

  remover(atividadeFilter: AtividadeFilter): Observable<any> {
    return this.restService.post("/atividade-v2/remover", {data: atividadeFilter});
  }

  salvar(atividadeTO: AtividadeTO): Observable<any> {
    return this.restService.post("/atividade-v2/salvar", {data: atividadeTO});
  }

  buscarAliquota(aliquotaSimplesTO: AliquotaSimplesTO): Observable<number> {
    return this.restService.post("/atividade-v2/buscarAliquota", {data: aliquotaSimplesTO});
  }

  buscarVinculosAnexoSimples(atividadeFilter: AtividadeFilter): Observable<VinculoAtividadeAnexoSimplesTO> {
    return this.restService.post("/atividade-v2/buscarVinculosAnexoSimples", {data: atividadeFilter, responseType: VinculoAtividadeAnexoSimplesTO});
  }

  atualizarVinculosAnexoSimples(vinculoAtividadeAnexoSimples: VinculoAtividadeAnexoSimplesTO): Observable<VinculoAtividadeAnexoSimplesTO> {
    return this.restService.post("/atividade-v2/atualizarVinculosAnexoSimples", {data: vinculoAtividadeAnexoSimples});
  }
}
