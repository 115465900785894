import {JacksonType} from "@sonner/jackson-service-v2";
import {JustificativaIndeferimento} from "../justificativa.indeferimento";
import {Solicitacao} from "../solicitacao";

@JacksonType("SolicitacaoIndeferidaTO")
export class SolicitacaoIndeferidaTO {

  enviarEmail: boolean = false;

  idSolicitacao: string;

  @JacksonType('JustificativaIndeferimento')
  justificativaIndeferimentoList: JustificativaIndeferimento[];

  motivoIndeferimento: string;

  numeroNotaCancelar: number;

  @JacksonType('Solicitacao')
  solicitacao: Solicitacao;
}
