import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {PGDASDApuracao} from "./p.g.d.a.s.d.apuracao";
import {PGDASDApuracaoFilialAtividade} from "./p.g.d.a.s.d.apuracao.filial.atividade";

@JacksonType("PGDASDApuracaoFilial")
@JsonIdentityInfo()
export class PGDASDApuracaoFilial extends SonnerBaseEntity {

  id: number;

  @JacksonType("PGDASDApuracao")
  apuracao: PGDASDApuracao;

  cnpj: string;

  ime: number;

  limite: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  valorReceita: number;

  @JacksonType("PGDASDApuracaoFilialAtividade")
  atividades: PGDASDApuracaoFilialAtividade[];
}
