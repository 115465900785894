import {Injectable} from "@angular/core";
import {AppShell} from "../../core/classes/app.shell";
import {Observable} from "rxjs";
import {UserInfoTO} from "../../core/classes/user.info.to";
import {RouterService} from "../../core/services/router.service";

@Injectable()
export class AppShellService {

  private _appShell: AppShell = new class implements AppShell {

    getUserInfoObservable(): Observable<UserInfoTO> {
      return undefined;
    }

    onShellItemClicked() {
    }

    refreshUserInfo(): void {
    }

    scrollToTop() {
    }

    setCurrentViewId(viewId: string) {
    }
  };

  constructor(private routerService: RouterService) {
  }

  set appShell(value: AppShell) {
    this._appShell = value;
  }

  getUserInfoObservable(): Observable<UserInfoTO> {
    return this._appShell.getUserInfoObservable();
  }

  onShellItemClicked() {
    this._appShell.onShellItemClicked();
  }

  refreshUserInfo(refreshIframe: boolean = false): void {
    this._appShell.refreshUserInfo();

    if (refreshIframe) {
      this.routerService.refreshStruts();
    }
  }

  setCurrentViewId(viewId: string) {
    this._appShell.setCurrentViewId(viewId);
  }

  scrollToTop() {
    this._appShell.scrollToTop();
  }
}
