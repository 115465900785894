import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {Atividade} from "../atividade";
import {GrupoAtividade} from "../grupo.atividade";
import {AtividadeHistorico} from "../atividade.historico";
import {Pessoa} from "../pessoa";
import {TipoRegimeISSEnum} from "../../enums/tipo.regime.iss.enum";

@JacksonType("AliquotaSimplesTO")
export class AliquotaSimplesTO {

   aliquotaApurada: number;

   aliquotaConfigurada: number;

   ano: number;

   @JacksonType("Atividade")
   atividade: Atividade;

   @DateType()
   dataPrestacao;

   escritEspecial: boolean = false;

   guiaAvulsa: boolean = false;

   incerto: boolean;

   mensagem: string;

   mes: number;

   passivelFatorR: boolean = false;

   @JacksonType("Pessoa")
   pessoa: Pessoa;

   serieAvulsa: boolean;

   @EnumType(TipoRegimeISSEnum)
   tipoRegimeConfigurado: TipoRegimeISSEnum;

   @JacksonType("Pessoa")
   tomador: Pessoa;

   documentoPrestador: string;

   documentoTomador: string;

   idAtividade: number;

}
