import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("TipoCredito")
@JsonIdentityInfo()
export class TipoCredito extends SonnerBaseEntity {

  id: number;

  acumulativo: boolean;

  @DateType()
  dataCancelamento: Date;

  @JacksonType('Municipio')
  municipio: Municipio;

  nome: string;

  naoUsaEncerramento: boolean = false;
}
