import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {ObraUnicaFilter} from "../filters/obra.unica.filter";
import {ObraUnica} from "../../../classes/obra.unica";

@Injectable()
export class ObraUnicaService {

  constructor(private restService: RestService){
  }

  obrasTomador(obraUnicaFilter: ObraUnicaFilter): Observable<ObraUnica[]> {
    return this.restService.post("/obraunica/obrasTomador", {data: obraUnicaFilter, responseType: ObraUnica});
  }

}
