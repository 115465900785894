import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {EncerramentoAutomatico} from "../encerramento.automatico";
import {EncerramentoDetalheTO} from "./encerramento.detalhe.to";
import {EncerramentosAutomaticoDetalhamentoTO} from "./encerramentos.automatico.detalhamento.to";
import {EncerramentoAutomaticoStatusEnum} from "../../enums/encerramento.automatico.status.enum";

@JacksonType("EncerramentosAutomaticoDetalheTO")
export class EncerramentosAutomaticoDetalheTO {

  @JacksonType("EncerramentoAutomatico")
  encerramentoAutomatico: EncerramentoAutomatico;

  @EnumType(EncerramentoAutomaticoStatusEnum)
  status: EncerramentoAutomaticoStatusEnum;

  @DateType()
  termino: Date;

  @JacksonType("EncerramentoDetalheTO")
  itensEncerramento: EncerramentoDetalheTO[];

  @JacksonType("EncerramentosAutomaticoDetalhamentoTO")
  inconsistencias: EncerramentosAutomaticoDetalhamentoTO[];
}
