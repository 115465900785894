import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {OrdemServicoArquivoFilter} from "../filters/ordem.servico.arquivo.filter";
import {OrdemServicoArquivo} from "../../../classes/ordem.servico.arquivo";

@Injectable()
export class OrdemServicoArquivoService {

  constructor(private restService: RestService){
  }

  buscar(ordemServicoArquivoFilter: OrdemServicoArquivoFilter): Observable<OrdemServicoArquivo> {
    return this.restService.post("/ordemservicoarquivo/buscar", {data: ordemServicoArquivoFilter, responseType: OrdemServicoArquivo});
  }

  buscarListaPorOrdemServico(ordemServicoArquivoFilter: OrdemServicoArquivoFilter): Observable<OrdemServicoArquivo[]> {
    return this.restService.post("/ordemservicoarquivo/buscarListaPorOrdemServico", {data: ordemServicoArquivoFilter, responseType: OrdemServicoArquivo});
  }

  remover(ordemServicoArquivoFilter: OrdemServicoArquivoFilter): Observable<any> {
    return this.restService.post("/ordemservicoarquivo/remover", {data: ordemServicoArquivoFilter});
  }
}
