export enum MesEnum {

  JAN = "Janeiro",
  FEV = "Fevereiro",
  MAR = "Março",
  ABR = "Abril",
  MAI = "Maio",
  JUN = "Junho",
  JUL = "Julho",
  AGO = "Agosto",
  SET = "Setembro",
  OUT = "Outubro",
  NOV = "Novembro",
  DEZ = "Dezembro"

}

export function mesNumero(mes: MesEnum): number {

  if (mes == MesEnum.JAN) {
    return 0;
  }else if (mes == MesEnum.FEV) {
    return 1;
  }else if (mes == MesEnum.MAR) {
    return 2;
  }else if (mes == MesEnum.ABR) {
    return 3;
  }else if (mes == MesEnum.MAI) {
    return 4;
  }else if (mes == MesEnum.JUN) {
    return 5;
  }else if (mes == MesEnum.JUL) {
    return 6;
  }else if (mes == MesEnum.AGO) {
    return 7;
  }else if (mes == MesEnum.SET) {
    return 8;
  }else if (mes == MesEnum.OUT) {
    return 9;
  }else if (mes == MesEnum.NOV) {
    return 10;
  }else if (mes == MesEnum.DEZ) {
    return 11;
  }

}

export function getMesEnum(mes: number): MesEnum {

  if (mes == 0) {
    return MesEnum.JAN;
  }else if (mes == 1) {
    return MesEnum.FEV;
  }else if (mes == 2) {
    return MesEnum.MAR;
  }else if (mes == 3) {
    return MesEnum.ABR;
  }else if (mes == 4) {
    return MesEnum.MAI;
  }else if (mes == 5) {
    return MesEnum.JUN;
  }else if (mes == 6) {
    return MesEnum.JUL;
  }else if (mes == 7) {
    return MesEnum.AGO;
  }else if (mes == 8) {
    return MesEnum.SET;
  }else if (mes == 9) {
    return MesEnum.OUT;
  }else if (mes == 10) {
    return MesEnum.NOV;
  }else if (mes == 11) {
    return MesEnum.DEZ;
  }

}
