import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {EscritTransporte} from "./escrit.transporte";

@JacksonType("EscritTransporteArquivo")
@JsonIdentityInfo()
export class EscritTransporteArquivo extends SonnerBaseEntity {

  id: number;

  bucketArquivoEntrada: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("Pessoa")
  emissor: Pessoa;

  @DateType()
  horarioEnvio: Date;

  @DateType()
  horarioTermino: Date;

  keyArquivoEntrada: string;

  keyErro: string;

  logProcessamento: string;

  nomeArquivoEntrada: string;

  ano: number;

  mes: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  valido: boolean;

  @JacksonType("EscritTransporte")
  escritTransporteList: EscritTransporte[];


}
