import {JacksonType} from "@sonner/jackson-service-v2";
import {VinculoAtividade} from "../vinculo.atividade";
import {Pessoa} from "../pessoa";

@JacksonType("ConsultaContribuinteLivrosTO")
export class ConsultaContribuinteLivrosTO {

  codigo: number;

  periodo: string;

  tipo: string;

  competencia: string;

  emissao: string;

  situacao: string;

  url: string;

  obs: string;

  nomeArquivo: string;

}
