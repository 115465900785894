import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {Curso} from "../curso";

@JacksonType("ControleExportacaoTO")
export class ControleExportacaoTO {

  @DateType()
  dataAgendamento: Date;

  @DateType()
  dataInicio: Date;

  @DateType()
  dataFim: Date;

  exportador: string;

  erro: boolean;

  urlArquivo: string;

}
