import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {Pessoa} from "../pessoa";
import {EncerramentoOficioDetalheTO} from "./encerramentos.oficio.detalhe.to";

@JacksonType("EncerramentoOficioTO")
export class EncerramentoOficioTO {

  codigo: number;

  idContribuinte: number;

  @DateType()
  competenciaInicial: Date;

  @DateType()
  competenciaFinal: Date;

  processo: string;

  observacao: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("EncerramentoOficioDetalheTO")
  detalheTOList: EncerramentoOficioDetalheTO[];

  competenciaFmt: string;

  docNomeContribuinte:string;

  nomeFiscal: string;

}
