import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {MensagemErroImportacaoWebISSEnum} from "../enums/mensagem.erro.importacao.web.i.s.s.enum";

@JacksonType("ErroImportWebISS")
@JsonIdentityInfo()
export class ErroImportWebISS extends SonnerBaseEntity {

  id: number;

  conteudoLinha: string;

  mensagem: string;

  nomeArquivo: string;

  numeroLinha: number;

  tipoBean: string;

  @EnumType(MensagemErroImportacaoWebISSEnum)
  tipoMensagem: MensagemErroImportacaoWebISSEnum;
}
