import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("MunicipioDimensao")
@JsonIdentityInfo()
export class MunicipioDimensao extends SonnerBaseEntity {

  id: number;

  estado: string;

  idOriginal: number;

  nome: string;

  pais: string;
}
