import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {JurosStrategy} from "./juros.strategy";
import {InicioContagemTempoEnum} from "../enums/inicio.contagem.tempo.enum";
import {Indice} from "./indice";

@JacksonType("JuroSelicStrategy")
@JsonSubType({key: 'br.com.sonner.iss.entity.JuroSelicStrategy', type: "JurosStrategy"})
export class JuroSelicStrategy extends JurosStrategy {

  @JacksonType("Indice")
  indice: Indice;

  getDisplayValue(): string {
    let descricao = "Juro Selic";

    if (this.inicioTempo != null && !(this.inicioTempo == InicioContagemTempoEnum.APOS_VENCIMENTO)) {
      descricao += " (cobrar após " + this.inicioTempo + " )";
    }

    return descricao;
  }
}
