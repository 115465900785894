import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ConfigMunicipio} from "./config.municipio";
import {ConfigRegimeTipoTributacao} from "./config.regime.tipo.tributacao";
import {Arquivo} from "./arquivo";
import {Estado} from "./estado";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("Municipio")
@JsonIdentityInfo()
export class Municipio extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  authRegex: string;

  cliente: boolean = false;

  clienteGrp: boolean;

  cnpj: string;

  codAtvSep: string;

  codigo: string;

  codigoBacen: number;

  codigoIBGE: number;

  codigoSIAFI: string;

  codigoSimples: string;

  contato: string;

  dizeresAIDF: string;

  @JacksonType("Estado")
  estado: Estado;

  incNfsBloco: number;

  livroDatado: boolean;

  @JacksonType("Arquivo")
  logo: Arquivo;

  maxNfsBloco: number;

  maxViasAidf: number;

  minViasAidf: number;

  minNfsBloco: number;

  nome: string;

  refLeiLivroPrestador: string;

  refLeiLivroTomador: string;

  rotulo: string;

  site: string;

  sync: boolean;

  taxaAIDF: number;

  vencimentoAIDF: number;

  @JacksonType("ConfigMunicipio")
  configs: ConfigMunicipio[];

  @JacksonType("ConfigRegimeTipoTributacao")
  regimeTipoTributacaoConfig: ConfigRegimeTipoTributacao[];

  getDisplayValue(): string {
    return this.nome + " - " + this.estado?.codigo;
  }
}
