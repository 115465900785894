import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("FiscalMenuTO")
export class FiscalMenuTO {

  controlaNumeracaoRPS: boolean = false;

  @DateType()
  data: Date;

  encerraComAliqAtvHistFatu: boolean = false;

  notaPremiada: boolean = false;

  status: string;
}
