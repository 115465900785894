import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {EstatisticaBancaria} from "./estatistica.bancaria";
import {Verbete} from "./verbete";

@JacksonType("EstatisticaVerbete")
@JsonIdentityInfo()
export class EstatisticaVerbete extends SonnerBaseEntity {

  id: number;

  @JacksonType("EstatisticaBancaria")
  estatistica: EstatisticaBancaria;

  @JacksonType("Verbete")
  verbete: Verbete;

  valor: number;
}
