export enum TributoEnum {

  ISS = "ISSQN",
  TaxaAIDF = "Taxa de AIDF",
  Multa = "Multa"
}

export function codigo(tributo: TributoEnum): string {

  if (tributo == TributoEnum.ISS) {
    return "0001";
  } else if (tributo == TributoEnum.TaxaAIDF) {
    return "0064";
  } else if (tributo == TributoEnum.Multa) {
    return "9999";
  }
}
