import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {AtividadeCTISS} from "../../../classes/atividade.c.t.i.s.s";
import {AtividadeCTISSFilter} from "../filters/atividade.ctiss.filter";

@Injectable()
export class AtividadeCTISSService {

  constructor(private restService: RestService) {
  }

  buscar(atividadeCTISSFilter: AtividadeCTISSFilter): Observable<AtividadeCTISS> {
    return this.restService.post("/atividadectiss/buscar", {data: atividadeCTISSFilter, responseType: AtividadeCTISS});
  }

  buscarListaPorAtivaEMunicipio(atividadeCTISSFilter?: AtividadeCTISSFilter): Observable<AtividadeCTISS[]> {
    return this.restService.post("/atividadectiss/buscarListaPorAtivaEMunicipio", {data: atividadeCTISSFilter, responseType: AtividadeCTISS});
  }

  salvar(atividadeCTISS: AtividadeCTISS): Observable<any> {
    return this.restService.post("/atividadectiss/salvar", {data: atividadeCTISS});
  }
}
