import {DateType, EnumType, JacksonType, MapType} from "@sonner/jackson-service-v2";
import {ObraUnica} from "../obra.unica";
import {EscritPapel} from "../escrit.papel";
import {Endereco} from "../endereco";
import {NotaEscritTO} from "./nota.escrit.to";
import {TipoRegimeISSEnum} from "../../enums/tipo.regime.iss.enum";
import {PapelServicoEnum} from "../../enums/papel.servico.enum";

@JacksonType("EscrituracaoTO")
export class EscrituracaoTO {

  id: number;

  proximoNumeroPrestador: number;

  @JacksonType("ObraUnica")
  obraUnicaList: ObraUnica[];

  exibeCampoDeducoesPermitidasEmLeiConfiGeral: boolean;

  @MapType()
  vinculoAtividadeExibeDeducoesPermitidasEmLei:Map<any,boolean>;

  retencaoAutomatica: boolean;

  usaDataLiquidacao: boolean;

  contribuinteInativo: boolean;

  //Consulta retenção

  documentoPrestador: string;

  documentoTomador: string;

  idMunicipioPrestacao: number;

  idMunicipioTomador: number;

  @DateType()
  dataPrestacao: Date;

  incidenciaFora: boolean;

  exterior: boolean;

  @EnumType(TipoRegimeISSEnum)
  regimePrestador: TipoRegimeISSEnum;

  @JacksonType("EscritPapel")
  escritPapel: EscritPapel;

  aliquota: number;

  idObraUnica: number;

  idAtividade: number;

  idAtividadeCTISS: number;

  @JacksonType("Endereco")
  enderecoPrestador: Endereco;

  @JacksonType("Endereco")
  enderecoTomador: Endereco;

  docEncerrado: boolean;

  dataEncerramento: string;

  senhaRecibo: string;

  @JacksonType("NotaEscritTO")
  notaEscritTOList:NotaEscritTO[];

  motivoCancelamento: string;

  errorsList: string[];

  sucessList: string[];

  geraGuia: boolean;

  valorISSRetido: number;

  valorISSNaoRetido: number;

  valorISSCancelado: number;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

}


