import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EscritPedagioTO")
export class EscritPedagioTO {

    id: string;

    idAtividade: number;

    idContribuinte: number;

    idMunicipio: number;

    nomePracaPedagio: string;

    valorTotalArrecadado: number;

    kmInicial: number;

    kmFinal: number;

    extensaoVia: number;

    baseCalculo: number;

    aliquota: number;

    valorImposto: number;

    @DateType()
    competencia: Date;

    competenciaFmt: string;

    competenciaEncerrada: boolean;
}
