import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AliquotaProgressivaItem} from "./aliquota.progressiva.item";
import {Municipio} from "./municipio";

@JacksonType("AliquotaProgressivaTabela")
@JsonIdentityInfo()
export class AliquotaProgressivaTabela extends SonnerBaseEntity {

  id: number;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("AliquotaProgressivaItem")
  aliquotas: AliquotaProgressivaItem[];
}
