import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("LoteRps")
@JsonIdentityInfo()
export class LoteRps extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataRecebimento: Date;

  @JacksonType("BigInteger")
  numeroLote: number;

  quantidadeRps: number;
}
