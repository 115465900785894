import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("ExportTO")
export class ExportTO {

  // e-mail
  email: string;

  // PDF
  id: number;

  // NF (XML)
  dataFimTexto: string;

  dataInicioTexto: string;

  padrao: string;

  papelServico: string;

  senha: string
}
