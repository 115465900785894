import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {AuthResponseTO} from "../../core/classes/auth.response.to";
import {UsuarioFilter} from "../filters/usuario.filter";

@Injectable()
export class UsuarioService {

  constructor(private restService: RestService){
  }

  alterarSenha(usuarioFilter: UsuarioFilter) {
    return this.restService.post("/usuario/alterarSenha", {data: usuarioFilter});
  }

  buscarAuthResponsePorUsuario(usuarioFilter: UsuarioFilter): Observable<AuthResponseTO> {
    return this.restService.post("/usuario/buscarAuthResponsePorUsuario", {data: usuarioFilter, responseType: AuthResponseTO});
  }
}
