import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AcaoFiscalTipoDocumento} from "./acao.fiscal.tipo.documento";
import {AgendaFiscalizacao} from "./agenda.fiscalizacao";
import {AIDF} from "./a.i.d.f";
import {AutorizacaoNFe} from "./autorizacao.n.fe";

@JacksonType("TermoInicioFiscalizacao")
@JsonIdentityInfo()
export class TermoInicioFiscalizacao extends SonnerBaseEntity {

  id: number;

  @JacksonType("AgendaFiscalizacao")
  agendamento: AgendaFiscalizacao;

  @JacksonType("AIDF")
  aidf: AIDF;

  @JacksonType("AutorizacaoNFe")
  autorizacaoNfe: AutorizacaoNFe;

  descricao: string;

  @DateType()
  emissao: Date;

  senha: string;

  @JacksonType("AcaoFiscalTipoDocumento")
  documentosSolicitados: AcaoFiscalTipoDocumento[];
}
