import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifConta} from "./desif.conta";
import {DesifImportacaoArquivo} from "./desif.importacao.arquivo";
import {DesifEventoContabil} from "./desif.evento.contabil";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {DesifTipoPartidaEnum} from "../enums/desif.tipo.partida.enum";

@JacksonType("DesifDemonstrativoLancamentoContabil")
@JsonIdentityInfo()
export class DesifDemonstrativoLancamentoContabil extends SonnerBaseEntity {

  id: number;

  cnpj: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataLancamento: Date;

  descricao: string;

  @JacksonType("DesifImportacaoArquivo")
  desifImportacaoArquivo: DesifImportacaoArquivo;

  @JacksonType("DesifEventoContabil")
  eventoContabil: DesifEventoContabil;

  linhaOrigem: number;

  @JacksonType("Municipio")
  municipioSituacao: Municipio;

  @JacksonType("Municipio")
  municipioVinculado: Municipio;

  numeroIndentificaoLancamento: string;

  @JacksonType("Municipio")
  responsavel: Municipio;

  @JacksonType("DesifConta")
  subtituloPGCC: DesifConta;

  @EnumType(DesifTipoPartidaEnum)
  tipoPartida: DesifTipoPartidaEnum;

  valorPartidaDoLancamento: number;
}
