import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {JurosStrategy} from "./juros.strategy";
import {InicioContagemTempoEnum} from "../enums/inicio.contagem.tempo.enum";

@JacksonType("JuroDiarioStrategy")
@JsonSubType({key: 'br.com.sonner.iss.entity.JuroDiarioStrategy', type: "JurosStrategy"})
export class JuroDiarioStrategy extends JurosStrategy {

  apenasUteis: boolean =  false;

  taxa: number;

  getDisplayValue(): string {
    let descricao = "Juro de " + (this.taxa * 100).toFixed(2) + "% por dia " + (this.apenasUteis ? "útil" : "corrido");

    if (this.inicioTempo != null && !(this.inicioTempo == InicioContagemTempoEnum.APOS_VENCIMENTO)) {
      descricao += " (cobrar após " + this.inicioTempo + " )";
    }

    return descricao;
  }
}
