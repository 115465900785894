import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Arquivo} from "./arquivo";
import {Pessoa} from "./pessoa";

@JacksonType("ArquivoImportacaoFiscal")
@JsonIdentityInfo()
export class ArquivoImportacaoFiscal extends SonnerBaseEntity {

  id: number;

  @JacksonType("Arquivo")
  arquivo: Arquivo;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  horario: Date;

  @JacksonType("Pessoa")
  usuarioImportacao: Pessoa;
}
