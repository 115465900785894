import {DateType, EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {CalculoGuiaAvulsa} from "./calculo.guia.avulsa";
import {Documento} from "./documento";
import {ItemGuiaAvulsa} from "./item.guia.avulsa";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {RegimeISS} from "./regime.i.s.s";
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {TipoBaixaEnum} from "../enums/tipo.baixa.enum";
import {TipoGuiaEnum} from "../enums/tipo.guia.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";
import {TributoEnum} from "../enums/tributo.enum";

@JacksonType("GuiaAvulsa")
@JsonSubType({key: 'br.com.sonner.iss.entity.GuiaAvulsa', type: "Documento"})
export class GuiaAvulsa extends Documento {

  ano: number;

  andoDocumento: number;

  @JacksonType("CalculoGuiaAvulsa")
  calculo: CalculoGuiaAvulsa;

  codBarras: string;

  codigo: string;

  codigoFebrabam: string;

  @DateType()
  dataBaixa: Date;

  @DateType()
  dataRecalculo: Date;

  @JacksonType("GuiaAvulsa")
  guiaDenuncia: GuiaAvulsa;

  inscritaDividaAtiva: boolean = false;

  mes: number;

  @JacksonType("Municipio")
  municipioEmissor: Municipio;

  numero: number;

  numeroBoleto: number;

  obs: string;

  @DateType()
  pagavel: Date;

  @EnumType(PapelServicoEnum)
  papelServico: PapelServicoEnum;

  permiteRecalculo: boolean = false;

  @JacksonType("Pessoa")
  pessoaBaixa: Pessoa;

  @JacksonType("RegimeISS")
  regime: RegimeISS;

  rotuloTaxa: string;

  @EnumType(TipoBaixaEnum)
  tipoBaixa: TipoBaixaEnum;

  @EnumType(TipoGuiaEnum)
  tipoGuia: TipoGuiaEnum;

  total: number;

  @EnumType(TributoEnum)
  tributo = TributoEnum.ISS;

  valorBaixaCorrecao: number;

  valorBaixaDesconto: number;

  valorBaixaJuros: number;

  valorBaixaMulta: number;

  valorBaixaPrincipal: number;

  valorBaixaTaxa: number;

  valorBaixaTotal: number;

  valorInscrito: number;

  @JacksonType("ItemGuiaAvulsa")
  itens: ItemGuiaAvulsa[];

  constructor() {
    super(TipoDocumentoEnum.GUIAAVULSA);
  }

  descricao() {
    return "Guia de Pagamento #" + this.numero + (this.tributo != null ? " - " + this.tributo : "");
  }
}
