import {DateType, EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {DadosEndereco} from "./dados.endereco";
import {DadosPessoa} from "./dados.pessoa";
import {Documento} from "./documento";
import {GuiaAvulsa} from "./guia.avulsa";
import {ItemEscrit} from "./item.escrit";
import {ObraUnica} from "./obra.unica";
import {Serie} from "./serie";
import {TipoTributacao} from "./tipo.tributacao";
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";
import {Municipio} from "./municipio";
import {NotaEscrit} from "./nota.escrit";

@JacksonType("EscritPapel")
@JsonSubType({key: "br.com.sonner.iss.entity.EscritPapel", type: "Documento"})
export class EscritPapel extends Documento implements NotaEscrit{

  ano: number;

  codigoSerie: string;

  correlacao: string;

  @JacksonType("DadosPessoa")
  dadosPrestador: DadosPessoa;

  @JacksonType("DadosPessoa")
  dadosTomador: DadosPessoa;

  @DateType()
  dataPrestacao: Date;

  descontoCondicional: number;

  descontoIncondicional: number;

  @JacksonType("DadosEndereco")
  enderecoPrestador: DadosEndereco;

  @JacksonType("DadosEndereco")
  enderecoTomador: DadosEndereco;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  mes: number;

  numero: number;

  obs: string;

  @JacksonType("ObraUnica")
  obraUnica: ObraUnica;

  outrasDeducoes: number;

  outrasRetencoes: number;

  @EnumType(PapelServicoEnum)
  papelEscriturador = PapelServicoEnum.prestador;

  prestadorOptanteSimples: boolean = false;

  recibo: boolean = false;

  retencaoCOFINS: number;

  retencaoCSLL: number;

  retencaoINSS: number;

  retencaoIRRF: number;

  retencaoPIS: number;

  @JacksonType("Serie")
  serie: Serie;

  somenteMateriais: boolean = false;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;

  valorImposto: number;

  valorTotal: number;

  webIssCodBarras: string;

  erros: string[];

  @JacksonType("ItemEscrit")
  itens: ItemEscrit[];

  @JacksonType("Municipio")
  municipioPrestacao: Municipio;

  incidenciaFora: boolean = false;

  @DateType()
  dataLiquidacao: Date;

  notaRetida: boolean;

  constructor() {
    super(TipoDocumentoEnum.ESCRITPAPEL);
  }
}
