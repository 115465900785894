import {Injectable} from "@angular/core";
import * as Dialog from "devextreme/ui/dialog";

@Injectable()
export class AlertService {

  show(message): Promise<void> {
    return Dialog.alert(`<i class="fa fa-exclamation-triangle" style="margin: 1px; color: rgb(25, 89, 139)"></i> <span>${message}</span>`, "Aviso");
  }

  showError(message): Promise<void> {
    return Dialog.alert(`<i class="fa fa-exclamation-triangle" style="margin: 1px; color: rgb(189, 41, 40)"></i> <span>${message}</span>`, "Erro");
  }

  confirm(message, title): Promise<boolean> {
    return Dialog.confirm(`<i class="fa fa-exclamation-triangle" style="margin: 1px; color: rgb(246,209,21)"></i> <span>${message}</span>`, title);
  }
}
