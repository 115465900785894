import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("PessoaDescontoEncerramento")
@JsonIdentityInfo()
export class PessoaDescontoEncerramento extends SonnerBaseEntity {

  id : number;

  anoInicio: number;

  mesInicio: number;

  anoFinal: number;

  mesFinal: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  processo: string;

  observacao: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  percentualDesconto: number;

  valorMaximoDesconto: number;

  @JacksonType("Pessoa")
  inativador: Pessoa;

  @DateType()
  dataInativacao: Date;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;
}
