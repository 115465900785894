import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {TipoCredito} from "../../../classes/tipo.credito";
import {TipoCreditoFilter} from "../filters/tipo.credito.filter";

@Injectable()
export class TipoCreditoService {

  constructor(private restService: RestService){
  }

  buscar(tipoCreditoFilter: TipoCreditoFilter): Observable<TipoCredito> {
    return this.restService.post("/tipocredito/buscar", {data: tipoCreditoFilter, responseType: TipoCredito});
  }

  buscarListaPorMunicipio(): Observable<TipoCredito[]> {
    return this.restService.post("/tipocredito/buscarListaPorMunicipio", {responseType: TipoCredito});
  }

  remover(tipoCreditoFilter: TipoCreditoFilter): Observable<any> {
    return this.restService.post("/tipocredito/remover", {data: tipoCreditoFilter});
  }

  salvar(tipoCreditoList: TipoCredito[]): Observable<any> {
    return this.restService.post("/tipocredito/salvar", {data: tipoCreditoList});
  }
}
