import {EnumUtils} from "../modules/core/utils/enum.utils";

export enum DesifTipoConsolidacaoEnum {

  INSTITUICAO_ALIQUOTA = "Instituição e alíquota",
  INSTITUICAO_ALIQUOTA_CODIGO_TRIBUTACAO = "Instituição, alíquota e código de tributação DES-IF",
  DEPENDENCIA_ALIQUOTA = "Dependência e alíquota",
  DEPENDENCIA_ALIQUOTA_CODIGO_TRIBUTACAO = "Dependência, alíquota e código de tributação DES-IF",
}

export function codigo(desifTipoConsolidacao: DesifTipoConsolidacaoEnum): number {

  if (desifTipoConsolidacao == DesifTipoConsolidacaoEnum.INSTITUICAO_ALIQUOTA) {
    return 1;
  } else if (desifTipoConsolidacao == DesifTipoConsolidacaoEnum.INSTITUICAO_ALIQUOTA_CODIGO_TRIBUTACAO) {
    return 2;
  } else if (desifTipoConsolidacao == DesifTipoConsolidacaoEnum.DEPENDENCIA_ALIQUOTA) {
    return 3;
  } else if (desifTipoConsolidacao == DesifTipoConsolidacaoEnum.DEPENDENCIA_ALIQUOTA_CODIGO_TRIBUTACAO) {
    return 4;
  }
}

export class DESIFTipoConsolidacaoEnumUtils {
  id: number;
  nome: string;

  constructor(id: number, nome: string) {
    this.id = id;
    this.nome = nome;
  }
}

export function convertDESIFTipoConsolidacaoEnumUtils(): DESIFTipoConsolidacaoEnumUtils[] {
  let list: DESIFTipoConsolidacaoEnumUtils[] = [];

  EnumUtils.values(DesifTipoConsolidacaoEnum).forEach((value, index) => {
    list.push(new DESIFTipoConsolidacaoEnumUtils(index, value))
  });

  return list;
}
