import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {RotinaAgendamento} from "./rotina.agendamento";
import {RotinaAgendamentoParametroTipoEnum} from "../enums/rotina.agendamento.parametro.tipo.enum";

@JacksonType("RotinaAgendamentoParametro")
@JsonIdentityInfo()
export class RotinaAgendamentoParametro extends SonnerBaseEntity {

  id: number;

  nome: string;

  @JacksonType("RotinaAgendamento")
  rotina: RotinaAgendamento;

  @EnumType(RotinaAgendamentoParametroTipoEnum)
  tipo: RotinaAgendamentoParametroTipoEnum;

  valor: string;
}
