import {DateType, JacksonType, SetType} from "@sonner/jackson-service-v2";
import {BloqueioMovimentacao} from "../bloqueio.movimentacao";
import {Comunicado} from "../comunicado";
import {EstatisticaAcessoTO} from "./estatistica.acesso.to";
import {Pessoa} from "../pessoa";
import {PessoaSimplificadaTO} from "./pessoa.simplificada.to";

@JacksonType("InstanceMonitorTO")
export class InstanceMonitorTO {

  id: string;

  @DateType()
  atualizacao: Date;

  @DateType()
  inicio: Date;

  nome: string;

}
