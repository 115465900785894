export enum PGDASDTipoArquivoEnum {

  PGDASD,
  PGDASD2018,
  PGDASD_DAS,
  PAG,
  DAS_COBRANCA,
  DAS_SENDA,
  PARCELAMENTO,
  PARCELAMENTO_ESP
}
