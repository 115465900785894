import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("PerfilDescontoImpostoNfe")
@JsonIdentityInfo()
export class PerfilDescontoImpostoNfe extends SonnerBaseEntity {

  id: number;

  ativa: boolean = false;

  descricao: string;

  diasPadraoAutorizacao: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  mensagemInterface: string;

  mensagemNota: string;

  mensagemSolicitacao: string;

  percentual: boolean;

  permiteConfiguracaoPorPessoa: boolean;

  rotulo: string;

  valor: number;

  valorMaximo: number;
}
