import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {BancoDependencia} from "./banco.dependencia";
import {DesifDemonstrativoContabil} from "./desif.demonstrativo.contabil";
import {DesifEventoContabil} from "./desif.evento.contabil";
import {DesifTipoPartidaEnum} from "../enums/desif.tipo.partida.enum";

@JacksonType("DesifDemonstrativoContabilRateioInterno")
@JsonIdentityInfo()
export class DesifDemonstrativoContabilRateioInterno extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("BancoDependencia")
  dependencia: BancoDependencia;

  @JacksonType("DesifDemonstrativoContabil")
  demonstrativoContabil: DesifDemonstrativoContabil;

  descricao: string;

  @JacksonType("DesifEventoContabil")
  evento: DesifEventoContabil;

  mes: number;

  @EnumType(DesifTipoPartidaEnum)
  tipoPartida: DesifTipoPartidaEnum;

  valorReceitaRateada: number;
}
