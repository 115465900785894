import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Municipio} from "./municipio";

@JacksonType("TNCError")
@JsonIdentityInfo()
export class TNCError {

  id: number;

  analisado: boolean;

  descricao: string;

  @DateType()
  gerado: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  titulo: string;
}
