import {Component, ElementRef, Injector, ViewChild} from "@angular/core";
import {UserInfoTO} from "../../core/classes/user.info.to";
import {SessionService} from "../../core/services/session.service";
import {LoginService} from "../../core/services/login.service";
import {MenuLateralComponent} from "./menu-lateral/menu.lateral.component";
import {Observable, Subject, Subscription} from "rxjs";
import {debounceTime, filter, mergeMap} from "rxjs/operators";
import {MenuMapTO} from "../../core/classes/menu.map.to";
import {ViewService} from "../services/view.service";
import {ViewTypeEnum} from "../../../enums/view.type.enum";
import {AppShellService} from "../services/app.shell.service";
import {AppErrorService} from "../../core/services/app.error.service";
import {AppErrorTypeEnum} from "../../../enums/app.error.type.enum";
import {AlertService} from "../../ui/services/alert.service";
import {AppStateService} from "../../app/services/app.state.service";
import {SessionFilter} from "../../core/filters/session.filter";
import {BaseComponent} from "../../ui/components/base/base.component";
import {GlobalLoadingIndicator} from "../../ui/decorators/global.loading.indicator.decorator";
import {PessoaService} from "../../commons/services/pessoa.service";
import {PessoaFilter} from "../../commons/filters/pessoa.filter";
import {AuthGuard} from "../../core/auth/auth.guard";
import {CognitoService} from "../../core/services/cognito.service";
import {LogoutService} from "../../core/services/logout.service";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent {

  @ViewChild(MenuLateralComponent, {static: true})
  appMenu: MenuLateralComponent;

  @ViewChild("content", {static: true})
  content: ElementRef;

  private _errorSubscription: Subscription;

  private _stopErrorNotification: boolean = false;


  constructor(private alertService: AlertService,
              private appErrorService: AppErrorService,
              private appShellService: AppShellService,
              private appStateService: AppStateService,
              private authGuard: AuthGuard,
              private cognitoService: CognitoService,
              private injector: Injector,
              private loginService: LoginService,
              private logoutService: LogoutService,
              private sessionService: SessionService,
              private pessoaService: PessoaService,
              private viewService: ViewService) {
    super(injector);

    this.appShellService.appShell = this;
    this.userInfoObservable = appStateService.userInfoSubject.pipe(filter(user => user != null));
    this._errorSubscription = appErrorService.getError().subscribe(error => this.handleAppError(error));
  }

  acesso_Senha_Alterar: boolean = false;

  currentQuery: string = "";

  currentViewType: ViewTypeEnum;

  isValidPopup: boolean = false;

  menuLocked: boolean = true;

  menuOpen: boolean = true;

  mobileDropdownOpen: boolean = false;

  menuMap: MenuMapTO;

  ordemServico: boolean = false;

  queryMenu: Subject<string> = new Subject();

  userDropdownOpen: boolean = false;

  userInfo: UserInfoTO;

  userInfoObservable: Observable<UserInfoTO>;

  // troca de e-mail

  trocarEmail: boolean = false;

  email: string = "";

  ngOnInit(): void {
    this.appErrorService.setHome(true);

    this.queryMenu.pipe(debounceTime(300),
      mergeMap(query => {
        let sessionFilter: SessionFilter = new SessionFilter();

        sessionFilter.query = query;

        return this.sessionService.buscarMenuMap(sessionFilter)
      })).subscribe(menuMap => this.menuMap = menuMap);

    this.viewService.viewTypeObservable().subscribe(viewType => {
      this.currentViewType = viewType;

      if (viewType != ViewTypeEnum.DESKTOP) {
        this.menuOpen = false;
      }
    });

    this.refreshUserInfo();
  }

  ngOnDestroy() {
    if (this._errorSubscription) {
      this._errorSubscription.unsubscribe();
    }
  }

  acionaOrdemServico() {
    this.toggleUserDropdown();
    this.routerService.open("/home/ordem-servico");
  }

  @GlobalLoadingIndicator()
  acionaSalvar() {
    this.clearErrorMessages();

    if (!this.email) {
      this.addErrorMessage("O e-mail é obrigatório.")

      return;
    }

    let pessoaFilter: PessoaFilter = new PessoaFilter();

    pessoaFilter.email = this.email;

    return this.of(this.pessoaService.alterarEmail(pessoaFilter), () => {
      this.trocarEmail = false;
      this.refreshUserInfo();
    });
  }

  acionaVoltar() {
    this.toggleUserDropdown();

    let sessionFilter: SessionFilter = new SessionFilter();

    sessionFilter.idPessoa = this.userInfo.autoridadeId;

    this.sessionService.alterarPessoaSelecionada(sessionFilter).subscribe(() => {
      this.appShellService.refreshUserInfo(true);
      this.routerService.open("/home/voltar");
    })
  }

  alterarSenha() {
    this.toggleUserDropdown();
      this.routerService.open("/home/senha");
  }

  getUrlAvatar(): string {
    if (this.userInfo && this.userInfo.possuiAvatar) {
      return "/v2/rest/session/buscarAvatar/" + this.userInfo.pessoaSelecionada.id;
    }

    return "/assets/img/company.png";
  }

  getUserInfoObservable(): Observable<UserInfoTO> {
    return this.userInfoObservable;
  }

  handleAppError(error: AppErrorTypeEnum): void {
    if (this._stopErrorNotification) {
      return;
    }

    if (error == AppErrorTypeEnum.AUTHENTICATION_ERROR) {
      this._stopErrorNotification = true;

      this.alertService.showError("Sua sessão expirou. Você será redirecionada para a página inicial").then(() => {
        this.cognitoService.signOut();

        setTimeout(() => {
          this.routerService.open("/landing-page");
          this._stopErrorNotification = false;
        })
      });
    } else if (error == AppErrorTypeEnum.AUTHORIZATION_ERROR) {
      this._stopErrorNotification = true;

      this.alertService.showError("Você não possui permissão para acessar este recurso.").then(() => {
        this.routerService.open("home/access-denied");
        this._stopErrorNotification = false;
      });
    }
  }

  onContentClick() {
    if (!this.menuLocked) {
      this.menuOpen = false;
    }
  }

  onRouteActivated() {
    if (this.currentViewType != ViewTypeEnum.DESKTOP) {
      this.menuOpen = false;
    }
  }

  onShellItemClicked() {
    this.onContentClick();
  }

  open(uri: string) {
    this.routerService.open(uri);
    this.currentQuery = "";
  }

  openConfig() {
    this.toggleUserDropdown();
    if(this.userInfo.gestor()){
      this.routerService.open("/home/configuracao-menu");
    }else{
      this.routerService.open("/home/actions/central-contrib");
    }
  }

  queryChanged(event: any) {
    this.currentQuery = event.target.value;

    this.queryMenu.next(this.currentQuery);
  }

  refreshUserInfo(): void {
    this.sessionService.buscarUserInfo().subscribe(userInfo => {
      if (userInfo) {
        if(userInfo.authRegex.includes("iss:acesso:senha:alterar")){
          this.acesso_Senha_Alterar = true;
        }
        this.userInfo = userInfo;
        this.appStateService.setUserInfoSubject(userInfo);

        if (userInfo.email == "noreply@nfe-cidades.com.br") {
          this.trocarEmail = true;
        } else {
          this.trocarEmail = false;
        }

        if(userInfo.authRegex.includes("iss:ordemServico")){
          this.ordemServico = true;
        }
      }
    });
  }

  scrollToTop() {
    this.content.nativeElement.scrollTop = 0;
  }

  setCurrentViewId(viewId: string) {
    this.appMenu.activeViewId = viewId;
  }

  signOut() {
    return this.of(this.logoutService.logout(), () =>{
      this.cognitoService.signOut().subscribe(() => {
        this.cognitoService.removeAllCookie();
        window.location.href = '/home';
      });
    })

  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  toggleUserDropdown() {
    this.userDropdownOpen = !this.userDropdownOpen;
  }

  toggleMobileDropdown() {
    this.mobileDropdownOpen = !this.mobileDropdownOpen;
  }
}
