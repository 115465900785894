import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {PGDASDDAS} from "./p.g.d.a.s.d.d.a.s";
import {ReceitaEnum} from "../enums/receita.enum";

@JacksonType("PGDASDDASDetalhe")
@JsonIdentityInfo()
export class PGDASDDASDetalhe extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("PGDASDDAS")
  das: PGDASDDAS;

  mes: number;

  @JacksonType("Municipio")
  municipioIncidencia: Municipio;

  @EnumType(ReceitaEnum)
  receitaJuros: ReceitaEnum;

  @EnumType(ReceitaEnum)
  receitaMulta: ReceitaEnum;

  @EnumType(ReceitaEnum)
  receitaPrincipal: ReceitaEnum;

  valorJuros: number;

  valorMulta: number;

  valorPrincipal: number;
}
