import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {DesifVersaoEnum} from "../enums/desif.versao.enum";
import {DesifModuloEnum} from "../enums/desif.modulo.enum";

@JacksonType("DesifImportacaoArquivo")
@JsonIdentityInfo()
export class DesifImportacaoArquivo extends SonnerBaseEntity {

  id: number;

  analisado: boolean;

  bucketArquivoEntrada: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("Pessoa")
  emissor: Pessoa;

  enviado: boolean;

  importado: boolean;

  inicioCnpj: string;

  @DateType()
  horarioAnalise: Date;

  @DateType()
  horarioEnvio: Date;

  @DateType()
  horarioFimAnalise: Date;

  keyArquivoEntrada: string;

  keyArquivoErro: string;

  @EnumType(DesifModuloEnum)
  modulo: DesifModuloEnum;

  nomeArquivoEntrada: string;

  observacao: string;

  quantidadeLinhas: number;

  totalDeErros: number;

  @EnumType(DesifVersaoEnum)
  versao: DesifVersaoEnum;
}
