import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {AtividadeCTISS} from "./atividade.c.t.i.s.s";
import {EscritPapel} from "./escrit.papel";
import {SituacaoItem} from "./situacao.item";

@JacksonType("ItemEscrit")
@JsonIdentityInfo()
export class ItemEscrit extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("AtividadeCTISS")
  atividadeCTISS: AtividadeCTISS;

  base: number;

  descricao: string;

  @JacksonType("EscritPapel")
  escritPapel: EscritPapel;

  eventual: boolean = false;

  imposto: number;

  situacao: SituacaoItem;

  valor: number;
}
