import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {CompetenciaDimensao} from "./competencia.dimensao";
import {MunicipioDimensao} from "./municipio.dimensao";
import {Pessoa} from "./pessoa";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("MediaMovimentacao")
@JsonIdentityInfo()
export class MediaMovimentacao extends SonnerBaseEntity {

  id: number;

  @JacksonType("CompetenciaDimensao")
  competencia: CompetenciaDimensao;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  desvioTributoARecolherFora12: number;

  desvioTributoARecolherFora3: number;

  desvioTributoRecolhidoMunicipio12: number;

  desvioTributoRecolhidoMunicipio3: number;

  desvioValorBrutoFaturamento12: number;

  desvioValorBrutoFaturamento3: number;

  desvioValorTributoARecolherMunicipio12: number;

  desvioValorTributoARecolherMunicipio3: number;

  @JacksonType("MunicipioDimensao")
  municipio: MunicipioDimensao;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  mediaTributoARecolherFora12: number;

  mediaTributoARecolherFora3: number;

  mediaTributoRecolhidoMunicipio12: number;

  mediaTributoRecolhidoMunicipio3: number;

  mediaValorBrutoFaturamento3: number;

  mediaValorBrutoFaturamento12: number;

  mediaValorTributoARecolherMunicipio3: number;

  mediaValorTributoARecolherMunicipio12: number;

  valorBrutoFaturamento: number;

  valorTributoARecolherFora: number;

  valorTributoARecolherMunicipio: number;

  valorTributoRecolhidoMunicipio: number;
}
