import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {MunicipioVencimentoFilter} from "../filters/municipio.vencimento.filter";
import {MunicipioVencimento} from "../../../classes/municipio.vencimento";

@Injectable()
export class MunicipioVencimentoService {

  constructor(private restService: RestService) {
  }

  buscar(municipioVencimentoFilter: MunicipioVencimentoFilter): Observable<MunicipioVencimento> {
    return this.restService.post("/municipiovencimento/buscar", {data: municipioVencimentoFilter, responseType: MunicipioVencimento});
  }

  buscarListaPorAnoEMunicipio(municipioVencimentoFilter: MunicipioVencimentoFilter): Observable<MunicipioVencimento[]> {
    return this.restService.post("/municipiovencimento/buscarListaPorAnoEMunicipio", {data: municipioVencimentoFilter, responseType: MunicipioVencimento});
  }

  remover(municipioVencimentoFilter: MunicipioVencimentoFilter): Observable<any> {
    return this.restService.post("/municipiovencimento/remover", {data: municipioVencimentoFilter});
  }

  salvar(municipioVencimentoList: MunicipioVencimento[]): Observable<any> {
    return this.restService.post("/municipiovencimento/salvar", {data: municipioVencimentoList})
  }
}
