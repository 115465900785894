import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {EstatisticaAcessoFilter} from "../filters/estatistica.acesso.filter";
import {EstatisticaAcessoTO} from "../../../classes/to/estatistica.acesso.to";

@Injectable()
export class EstatisticaAcessoService {

  constructor(private restService: RestService) { }

  atualizarEstatisticaAcesso(estatisticaAcessoFilter: EstatisticaAcessoFilter): Observable<boolean> {
    return this.restService.post("/estatisticaacesso/atualizarEstatisticaAcesso", {data: estatisticaAcessoFilter});
  }

  atualizarFavorito(estatisticaAcessoFilter: EstatisticaAcessoFilter): Observable<boolean> {
    return this.restService.post("/estatisticaacesso/atualizarFavorito", {data: estatisticaAcessoFilter});
  }

  buscar(): Observable<EstatisticaAcessoTO[]> {
    return this.restService.get("/estatisticaacesso/buscar", {responseType: EstatisticaAcessoTO});
  }

  buscarFavorito(estatisticaAcessoFilter: EstatisticaAcessoFilter): Observable<boolean> {
    return this.restService.post("/estatisticaacesso/buscarFavorito", {data: estatisticaAcessoFilter});
  }
}
