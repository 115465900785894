import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Transporte")
@JsonSubType({key: 'br.com.sonner.iss.entity.Transporte', type: "Papel"})
export class Transporte extends Papel {

  constructor() {
    super(PerfilEnum.transporte);
  }
}
