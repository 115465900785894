import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {AtividadeCNAE} from "./atividade.c.n.a.e";
import {ItemNotaFiscalHistorico} from "./item.nota.fiscal.historico";
import {Municipio} from "./municipio";
import {NotaFiscal} from "./nota.fiscal";
import {SolicitacaoDadosPessoa} from "./solicitacao.dados.pessoa";
import {TipoTributacao} from "./tipo.tributacao";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";

@JacksonType("NotaFiscalHistorico")
@JsonIdentityInfo()
export class NotaFiscalHistorico extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("AtividadeCNAE")
  atividadeCNAE: AtividadeCNAE;

  condicaoPagamento: string;

  correlacao: string;

  @JacksonType("SolicitacaoDadosPessoa")
  dadosTomador: SolicitacaoDadosPessoa;

  @DateType()
  dataAlteracao: Date;

  @DateType()
  dataPrestacao: Date;

  descontoCondicional: number;

  descontoImposto: number;

  descontoIncondicional: number;

  empenho: string;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  @JacksonType("NotaFiscal")
  notaFiscal: NotaFiscal;

  num: number;

  @JacksonType("Municipio")
  municipioIncidencia: Municipio;

  outrasDeducoes: number;

  outrasRetencoes: number;

  obs: string;

  retido: boolean;

  retencaoCOFINS: number;

  retencaoCSLL: number;

  retencaoINSS: number;

  retencaoIRRF: number;

  retencaoPIS: number;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;

  valorImposto: number;

  valorTotal: number;

  @JacksonType("ItemNotaFiscalHistorico")
  itens: ItemNotaFiscalHistorico[];
}
