import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Cotacao} from "./cotacao";
import {Municipio} from "./municipio";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("Indice")
@JsonIdentityInfo()
export class Indice extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  composto: boolean = false;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  @JacksonType("Cotacao")
  cotacoes: Cotacao[];

  selic: boolean = false;

  getDisplayValue(): string {
    return this.nome;
  }
}
