import {DateType, EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {AtividadeCNAE} from "./atividade.c.n.a.e";
import {AtividadeCTISS} from "./atividade.c.t.i.s.s";
import {DadosEndereco} from "./dados.endereco";
import {DadosPessoa} from "./dados.pessoa";
import {Documento} from "./documento";
import {GuiaAvulsa} from "./guia.avulsa";
import {ItemNotaFiscal} from "./item.nota.fiscal";
import {Municipio} from "./municipio";
import {NotaPremiada} from "./nota.premiada";
import {RegimeISS} from "./regime.i.s.s";
import {Serie} from "./serie";
import {TipoTributacao} from "./tipo.tributacao";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";
import {ItemReciboLocacao} from "./item.recibo.locacao";

@JacksonType("ReciboLocacao")
@JsonSubType({key: 'br.com.sonner.iss.entity.ReciboLocacao', type: "Documento"})
export class ReciboLocacao extends Documento {

  constructor() {
    super(TipoDocumentoEnum.RECIBOLOCACAO);
    this.dadosTomador = new DadosPessoa();
  }

  @JacksonType("DadosPessoa")
  dadosPrestador: DadosPessoa;

  @JacksonType("DadosPessoa")
  dadosTomador: DadosPessoa;

  @DateType()
  dataPrestacao: Date;

  emailTomador: string;

  @JacksonType("DadosEndereco")
  enderecoPrestador: DadosEndereco;

  @JacksonType("DadosEndereco")
  enderecoTomador: DadosEndereco;

  inscricaoMunicipalTomador: string;

  @JacksonType("Municipio")
  municipioResponsavel: Municipio;

  numero: number;

  obs: string;

  passaporteTomador: string;

  valorLocacao: number;

  @JacksonType("ItemReciboLocacao")
  itens: ItemReciboLocacao[];

  addItem(item: ItemReciboLocacao) {
    item.reciboLocacao = this;
    this.itens.push(item);
  }

}
