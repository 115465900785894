import {EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {GuiaAvulsa} from "./guia.avulsa";
import {Pessoa} from "./pessoa";
import {Serie} from "./serie";
import {TipoAIDFEnum} from "../enums/tipo.a.i.d.f.enum";

@JacksonType("AIDF")
@JsonSubType({key: 'br.com.sonner.iss.entity.AIDF', type: 'Documento'})
@JsonIdentityInfo()
export class AIDF extends Documento {

  anterior: boolean;

  autEstadual: string;

  dizeres: string;

  @JacksonType("Pessoa")
  grafica: Pessoa;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  nomeGrafica: string;

  notaFinal: number;

  notaInicial: number;

  notasPorBloco: number;

  numero: number;

  obs: string;

  qtd: number;

  @JacksonType("Serie")
  serie: Serie;

  @EnumType(TipoAIDFEnum)
  tipo: TipoAIDFEnum;

  vias: number;
}
