import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Documento} from "./documento";
import {Pessoa} from "./pessoa";
import {TipoEventoEnum} from "../enums/tipo.evento.enum";

@JacksonType("Evento")
@JsonIdentityInfo()
export class Evento extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("Documento")
  documento: Documento;

  @DateType()
  horario: Date;

  observacao: string;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @EnumType(TipoEventoEnum)
  tipo: TipoEventoEnum;

  visualizado: boolean;
}
