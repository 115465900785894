import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("GrupoAtividade")
@JsonIdentityInfo()
export class GrupoAtividade extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  codigo: string = "";

  descricao: string = "";

  @JacksonType("Municipio")
  municipio: Municipio;

  representaCBO: boolean = false;

  getDisplayValue(): string {
    return this.codigo + " - " + this.descricao;
  }
}
