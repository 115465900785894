import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";

@JacksonType("IsencaoGuia")
@JsonIdentityInfo()
export class IsencaoGuia extends SonnerBaseEntity {

  id: number;

  ano: number;

  isentaCorrecao: boolean = false;

  isentaGuia: boolean = false;

  isentaJuros: boolean = false;

  isentaMulta: boolean = false;

  mes: number;

  @JacksonType("Municipio")
  municipio: Municipio;
}
