import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("Verbete")
@JsonIdentityInfo()
export class Verbete extends SonnerBaseEntity {

  id: number;

  codigo: string;

  descricao: string;
}
