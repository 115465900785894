import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {AtividadeCTISS} from "./atividade.c.t.i.s.s";
import {Pessoa} from "./pessoa";

@JacksonType("VinculoAtividade")
@JsonIdentityInfo()
export class VinculoAtividade extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("AtividadeCTISS")
  atividadeCTISS: AtividadeCTISS;

  exibeCampoOutrasDeducoes: boolean;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  permiteIncidenciaForaMunicipio: boolean = false;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  principal: boolean;
}
