import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("ConfiguracaoMenuTO")
export class ConfiguracaoMenuTO {

  @DateType()
  data: Date;

  status: string;
}
