import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {ConfigMunicipio} from "../../../classes/config.municipio";
import {ConfigMunicipioFilter} from "../filters/config.municipio.filter";

@Injectable()
export class ConfigMunicipioService {

  constructor(private restService: RestService){
  }

  buscar(configMunicipioFilter: ConfigMunicipioFilter): Observable<ConfigMunicipio> {
    return this.restService.post("/configmunicipio/buscar", {data: configMunicipioFilter, responseType: ConfigMunicipio});
  }

  buscarListaValorPorNome(configMunicipioFilter: ConfigMunicipioFilter): Observable<Map<string, string>> {
    return this.restService.post("/configmunicipio/buscarListaValorPorNome", {data: configMunicipioFilter});
  }

  buscarPorMunicipioENome(configMunicipioFilter: ConfigMunicipioFilter): Observable<ConfigMunicipio> {
    return this.restService.post("/configmunicipio/buscarPorMunicipioENome", {data: configMunicipioFilter, responseType: ConfigMunicipio});
  }
}
