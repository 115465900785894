import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Arquivo} from "./arquivo";
import {Municipio} from "./municipio";

@JacksonType("Agendamento")
@JsonIdentityInfo()
export class Agendamento extends SonnerBaseEntity {

  id: number;

  @JacksonType("Arquivo")
  arquivo: Arquivo;

  ativo: boolean;

  frequencia: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  @DateType()
  proximaExecucao: Date;

  @DateType()
  ultimaExecucao: Date;

  ultimoResultado: string;
}
