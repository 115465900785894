import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {CompetenciaDimensao} from "./competencia.dimensao";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {TipoAlertaEnum} from "../enums/tipo.alerta.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("AlertaFiscal")
@JsonIdentityInfo()
export class AlertaFiscal extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  chaveLogica: string;

  @JacksonType("CompetenciaDimensao")
  competencia: CompetenciaDimensao;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  descricao: string;

  severidade: number;

  @EnumType(TipoAlertaEnum)
  tipoAlerta: TipoAlertaEnum;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;
}
