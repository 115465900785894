import {JacksonType} from "@sonner/jackson-service-v2";
import {TNCAgendamento} from "../t.n.c.agendamento";

@JacksonType("TNCAgendamentoExecTO")
export class TNCAgendamentoExecTO {

  @JacksonType("TNCAgendamento")
  tncAgendamento: TNCAgendamento;

  status:boolean =false;

  ultimaExecucao: string;

  proxExecucao: string;

}
