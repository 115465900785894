import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "aliquota"})
export class AliquotaPipe implements PipeTransform {

  transform(value: number): string {
    if (value == null) {
      return "";
    }

    return ((value * 100)).toFixed(2) +" %";
  }
}
