import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {BilheteNotaPremiadaContribuinteTO} from "./bilhete.nota.premiada.contribuinte.to";

@JacksonType("BilheteNotaPremiadaPeriodoTO")
export class BilheteNotaPremiadaPeriodoTO {

  periodo: string;

  @DateType()
  inicio: Date;

  @JacksonType("BilheteNotaPremiadaContribuinteTO")
  bilhetesNotaPremiadaContribuinteTO: BilheteNotaPremiadaContribuinteTO[];

}
