import {Component, Input, OnInit} from "@angular/core";
import {AppShellService} from "../../../home/services/app.shell.service";
import {RouterService} from "../../../core/services/router.service";
import {GoogleAnalyticsService} from "../../../home/services/google.analytics.service";
import {EstatisticaAcessoService} from "../../../home/services/estatistica.acesso.service";
import {EstatisticaAcessoFilter} from "../../../home/filters/estatistica.acesso.filter";
import {GlobalLoadingIndicatorService} from "../../services/global.loading.indicator.service";

@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  @Input()
  podeFavoritar: boolean = true;

  @Input()
  loading: boolean = true;

  @Input()
  subTitulo: string;

  @Input()
  titulo: string;

  @Input()
  viewId: string;

  constructor(private appShellService: AppShellService,
              private estatisticaAcessoService: EstatisticaAcessoService,
              private globalLoadingIndicatorService: GlobalLoadingIndicatorService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private routerService: RouterService) {
  }

  favorito: boolean = false;

  globalPanelVisible: boolean = false;

  private _currentUri: string;

  loadMessage: string = "Carregando";

  ngOnInit(): void {
    this.appShellService.setCurrentViewId(this.viewId);

    this._currentUri = this.routerService.currentUri();

    this.googleAnalyticsService.sendPageRequest(this._currentUri);

    if(!this._currentUri.includes("/bloqueio-movimentacao")){
      this.estatisticaAcessoService.atualizarEstatisticaAcesso(new EstatisticaAcessoFilter(this._currentUri)).subscribe();

      this.estatisticaAcessoService.buscarFavorito(new EstatisticaAcessoFilter(this._currentUri)).subscribe(resp => this.favorito = resp);
    }

    this.globalLoadingIndicatorService.getGlobalInidicatorObservable().subscribe(loading => {
      this.globalPanelVisible = loading;
      if(loading){
        this.loadMessage = this.globalLoadingIndicatorService.getGlobalIndicatorMessage() + "...";
      }
    });
  }

  favoritar() {
    this.estatisticaAcessoService.atualizarFavorito(new EstatisticaAcessoFilter(this._currentUri)).subscribe(favorito => this.favorito = favorito);
  }
}
