import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Arquivo} from "./arquivo";
import {OrdemServico} from "./ordem.servico";

@JacksonType("OrdemServicoArquivo")
@JsonIdentityInfo()
export class OrdemServicoArquivo extends SonnerBaseEntity {

  id: number;

  @JacksonType("Arquivo")
  anexo: Arquivo;

  @JacksonType("OrdemServico")
  ordemServico: OrdemServico;
}
