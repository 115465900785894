import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {ServicoEspecial} from "./servico.especial";

@JacksonType("ServicoEspecialGrupo")
@JsonIdentityInfo()
export class ServicoEspecialGrupo extends SonnerBaseEntity {

  id: number;

  codigo: string;

  descricao: string;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @JacksonType("ServicoEspecial")
  servicos: ServicoEspecial[];
}
