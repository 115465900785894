import {JacksonType} from "@sonner/jackson-service-v2";
import {AuthErrorTO} from "./auth.error.to";

@JacksonType("AuthResponseTO")
export class AuthResponseTO {

  success: boolean;

  @JacksonType("AuthErrorTO")
  errors: AuthErrorTO[];
}
