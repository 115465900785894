import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("AliquotaProgressivaConfiguracao")
@JsonIdentityInfo()
export class AliquotaProgressivaConfiguracao extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;
}
