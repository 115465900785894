import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Encerramento} from "./encerramento";
import {TipoDescontoBaseCalculo} from "./tipo.desconto.base.calculo";

@JacksonType("EncerramentoDesconto")
@JsonIdentityInfo()
export class EncerramentoDesconto extends SonnerBaseEntity {

  id: number;

  @JacksonType("Encerramento")
  encerramento: Encerramento;

  @JacksonType("TipoDescontoBaseCalculo")
  tipoDesconto: TipoDescontoBaseCalculo;

  valor: number;
}
