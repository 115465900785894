import {Component, OnInit} from "@angular/core";
import {GlobalLoadingIndicatorService} from "../../ui/services/global.loading.indicator.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AppStateService} from "../../app/services/app.state.service";

@Component({
  selector: 'public-shell',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  constructor(private appStateService: AppStateService,
              private router: Router,
              private globalLoadingIndicatorService: GlobalLoadingIndicatorService) {
  }

  embedded: boolean;

  globalPanelPosition: object = {of: '#mainContainer'};

  globalPanelVisible: boolean = false;

  ngOnInit(): void {

    this.appStateService.embeddedSubject.subscribe(embedded =>{
        this.embedded = embedded;
    });

    this.globalLoadingIndicatorService.getGlobalInidicatorObservable().subscribe(loading => {
      this.globalPanelVisible = loading;
    });
  }

  back() {
    this.router.navigate(['/home']);
  }
}
