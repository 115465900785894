import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ProcessoAssincrono} from "./processo.assincrono";

@JacksonType("ProcessoMetadata")
@JsonIdentityInfo()
export class ProcessoMetadata extends SonnerBaseEntity {

  id: number;

  chave: string;

  @JacksonType("ProcessoAssincrono")
  processo: ProcessoAssincrono;

  valor: string;
}
