import {JacksonType} from "@sonner/jackson-service-v2";
import {DateUtilsService} from "../../modules/core/services/date.utils.service";
import {GuiaAvulsa} from "../guia.avulsa";
import {GuiaSimplificadaTO} from "./guia.simplificada.to";

@JacksonType("GuiaMesTO")
export class GuiaMesTO {

  mes: number;

  ano: number;

  @JacksonType("GuiaAvulsa")
  guiasPrestador: GuiaAvulsa[];

  @JacksonType("GuiaAvulsa")
  guiasTomador: GuiaAvulsa[];

  @JacksonType("GuiaSimplificadaTO")
  guiasPrestadorList: GuiaSimplificadaTO[];

  @JacksonType("GuiaSimplificadaTO")
  guiasTomadorList: GuiaSimplificadaTO[];

  totalGuiaPrestador: number = 0;

  totalGuiaTomador: number = 0;

  getMesAnoFmt(): string {
    let dateUtilsService: DateUtilsService = new DateUtilsService();
    const mesFmt:string  = (this.mes == null) ? "" : dateUtilsService.getMesString(this.mes);
    const anoFmt: string = (this.ano == null) ? "" : this.ano.toString();
    return mesFmt + "/" + anoFmt;
  }

}
