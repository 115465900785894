import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {OrdemServicoArquivo} from "./ordem.servico.arquivo";
import {OrdemServicoItem} from "./ordem.servico.item";
import {SituacaoOrdemServicoEnum} from "../enums/situacao.ordem.servico.enum";
import {TipoOrdemServicoEnum} from "../enums/tipo.ordem.servico.enum";

@JacksonType("OrdemServico")
@JsonIdentityInfo()
export class OrdemServico extends SonnerBaseEntity {

  id: number;

  @DateType()
  abertura: Date;

  @DateType()
  conclusao: Date;

  descricao: string;

  @DateType()
  fechamento: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  @EnumType(SituacaoOrdemServicoEnum)
  situacaoOrdemServico: SituacaoOrdemServicoEnum = SituacaoOrdemServicoEnum.ABERTO;

  @JacksonType("Pessoa")
  solicitante: Pessoa;

  @EnumType(TipoOrdemServicoEnum)
  tipoOrdemServico: TipoOrdemServicoEnum;

  titulo: string;

  @JacksonType('OrdemServicoArquivo')
  ordemServicoArquivoList: OrdemServicoArquivo[];

  @JacksonType('OrdemServicoItem')
  ordemServicoItemList: OrdemServicoItem[];
}
