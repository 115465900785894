import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {OrdemServicoFilter} from "../filters/ordem.servico.filter";
import {OrdemServico} from "../../../classes/ordem.servico";
import {OrdemServicoTO} from "../../../classes/to/ordem.servico.to";

@Injectable()
export class OrdemServicoService {

  constructor(private restService: RestService){
  }

  buscar(ordemServicoFilter: OrdemServicoFilter): Observable<OrdemServico> {
    return this.restService.post("/ordemservico/buscar", {data: ordemServicoFilter, responseType: OrdemServico});
  }

  buscarTO(ordemServicoFilter: OrdemServicoFilter): Observable<OrdemServicoTO> {
    return this.restService.post("/ordemservico/buscarTO", {data: ordemServicoFilter, responseType: OrdemServicoTO});
  }

  salvar(ordemServico: OrdemServico): Observable<any> {
    return this.restService.post("/ordemservico/salvar", {data: ordemServico});
  }
}
