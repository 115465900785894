import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AtividadeCNAE} from "./atividade.c.n.a.e";
import {Pessoa} from "./pessoa";

@JacksonType("VinculoAtividadeCNAE")
@JsonIdentityInfo()
export class VinculoAtividadeCNAE extends SonnerBaseEntity {

  id: number;

  @JacksonType("AtividadeCNAE")
  atividade: AtividadeCNAE;

  @DateType()
  inicioVinculo: Date;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  principal: boolean;

  @DateType()
  terminoVinculo: Date;
}
