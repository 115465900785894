import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next
            .handle(req).pipe(
            tap((ev) => {}),
            catchError(response => {

                if (response instanceof HttpErrorResponse) {

                 if (response.status == 502 || response.status == 504) {
                        if (window.location.href.indexOf('/server-error') == -1) {
                            window.location.href = '/server-error';
                        }
                    }
                }

                return observableThrowError(response);
            }),);

    }

}
