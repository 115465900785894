import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoTributacao} from "./tipo.tributacao";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("ConfigRegimeTipoTributacao")
@JsonIdentityInfo()
export class ConfigRegimeTipoTributacao extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  @EnumType(TipoRegimeISSEnum)
  regime: TipoRegimeISSEnum;

  @JacksonType("TipoTributacao")
  tiposTributacao: TipoTributacao[];
}
