import {DateType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Empresa")
@JsonSubType({key: 'br.com.sonner.iss.entity.Empresa', type: "Papel"})
export class Empresa extends Papel {

  bloqueada: boolean = false;

  bloqueiroInformacao: string;

  @DateType()
  dataAbertura: Date;

  grafica: boolean = false;

  inscEst: string;

  nomeFantasia: string;

  podeEscriturar: boolean;

  constructor() {
    super(PerfilEnum.empresa);
  }
}
