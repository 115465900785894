import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {PerfilEnum} from "../../../enums/perfil.enum";
import {Pessoa} from "../../../classes/pessoa";

@JacksonType("UserInfoTO")
@JsonIdentityInfo()
export class UserInfoTO {

  producao: boolean;

  authRegex: string;

  autoridadeId: number;

  bairro: string;

  cep: string;

  codigoMunicipio: string;

  documentoPessoaSelecionada: string;

  email: string;

  endereco: string;

  idPessoaSelecionada: number;

  idUsuario: number;

  inscMun: string;

  mobiliario: string;

  municipioFMT: string;

  nomeAutorizacaoRps: string;

  nomePessoaSelecionada: string;

  nomeUsuario: string;

  @EnumType(PerfilEnum)
  perfis: PerfilEnum[];

  pessoaAutorizadaEscriturar: boolean;

  pessoaAutorizadaNfe: boolean;

  @JacksonType("Pessoa")
  pessoaUsuario: Pessoa;

  @JacksonType("Pessoa")
  pessoaSelecionada: Pessoa;

  podeAlterarAliquotaSimples: boolean;

  podeCancelarNfe: boolean;

  possuiAvatar: boolean;

  prestador: boolean;

  regimeISS: string;

  responsavelEstado: string;

  responsavelNome: string;

  temAtividadeDeObras: boolean;

  permiteEmitirReciboLocacao: boolean;

  simples: boolean;

  permiteNotaDeducao: boolean;

  usuario(): boolean {
    return this.possuiPerfil(PerfilEnum.usuario);
  }

  funcionario(): boolean {
    return this.possuiPerfil(PerfilEnum.funcionario);
  }

  gestor(): boolean {
    return this.possuiPerfil(PerfilEnum.gestor);
  }

  fiscal(): boolean {
    return this.possuiPerfil(PerfilEnum.fiscal);
  }

  contador(): boolean {
    return this.possuiPerfil(PerfilEnum.contador);
  }

  empresa(): boolean {
    return this.possuiPerfil(PerfilEnum.empresa);
  }

  ensino(): boolean {
    return this.possuiPerfil(PerfilEnum.ensino);
  }

  construtora(): boolean {
    return this.possuiPerfil(PerfilEnum.construtora);
  }

  transporte(): boolean {
    return this.possuiPerfil(PerfilEnum.transporte);
  }

  administrador(): boolean {
    return this.possuiPerfil(PerfilEnum.administrador);
  }

  pedagio(): boolean {
    return this.possuiPerfil(PerfilEnum.pedagio);
  }

  cartorio(): boolean {
    return this.possuiPerfil(PerfilEnum.cartorio);
  }

  suporte(): boolean {
    return this.possuiPerfil(PerfilEnum.suporte);
  }

  possuiPerfil(perfil: PerfilEnum): boolean {
    return this.perfis && this.perfis.findIndex(p => p == perfil) >= 0;
  }

  possuiFuncao(func: string): boolean {
    if (!this.authRegex) {
      return false;
    }
    return this.authRegex.includes(func);
  }

}
