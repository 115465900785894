import {NgModule} from "@angular/core";
import {
  DxAccordionModule,
  DxAutocompleteModule,
  DxBoxModule,
  DxButtonModule,
  DxCalendarModule,
  DxChartModule,
  DxCheckBoxModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxGalleryModule,
  DxHtmlEditorModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxNumberBoxModule,
  DxPopoverModule,
  DxPopupModule,
  DxProgressBarModule,
  DxRadioGroupModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTagBoxModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule
} from "devextreme-angular";
import {PageComponent} from "./components/page/page.component";
import {CommonModule} from "@angular/common";
import {AlertService} from "./services/alert.service";
import {DomHandlerService} from "./services/dom.handler.service";
import {SelectBoxEnumBasedComponent} from "./components/select-box-enum-based/select.box.enum.based.component";
import {ExceptionInfoService} from "./services/exception.info.service";
import {DateUtilsService} from "../core/services/date.utils.service";
import {ObjectUtilsService} from "../core/services/object.utils.service";
import {TarefaService} from "./services/tarefa.service";
import {TarefaResultadoService} from "./services/tarefa.resultado.service";
import {TarefaComponent} from "./components/tarefa/tarefa.component";
import {MessagePanelComponent} from "./components/message-panel/message.panel.component";
import {CurrencyL2RDirective} from "./directives/currency.l.2.r.directive";
import {CustomDataGridComponent} from "./components/custom-data-grid/custom.data.grid.component";
import {FileUploaderComponent} from "./components/file-uploader/file.uploader.component";
import {SelectBoxEntityBasedComponent} from "./components/select-box-entity-based/select.box.entity.based.component";
import {AutocompleteComponent} from "./components/autocomplete/autocomplete.component";
import {BlockClipboardDirective} from "./directives/block.clipboard.directive";
import {PopupComponent} from "./components/popup/popup.component";
import {DateRangeComponent} from "./components/date-range/date.range.component";
import {PanelComponent} from "./components/panel/panel.component";
import {AccordionComponent} from "./components/accordion/accordion.component";
import {AccordionPanelComponent} from "./components/accordion/accordion-panel/accordion.panel.component";
import {CKEditorModule} from "ckeditor4-angular";
import { CompetenciaComponent } from './components/competencia/competencia.component';
import {LoadingComponent} from "./components/loading/loading.component";
import {ReCaptchaComponent} from "./components/captcha/re.captcha.component";

@NgModule({
  imports: [
    CKEditorModule,
    CommonModule,
    DxAccordionModule,
    DxAutocompleteModule,
    DxBoxModule,
    DxButtonModule,
    DxCalendarModule,
    DxChartModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxFormModule,
    DxGalleryModule,
    DxHtmlEditorModule,
    DxListModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxPopoverModule,
    DxProgressBarModule,
    DxRadioGroupModule,
    DxSchedulerModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxToolbarModule,
    DxTooltipModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxDataGridModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxScrollViewModule,
    DxValidatorModule
  ],

  declarations: [
    AccordionComponent,
    AccordionPanelComponent,
    AutocompleteComponent,
    BlockClipboardDirective,
    CompetenciaComponent,
    CurrencyL2RDirective,
    CustomDataGridComponent,
    DateRangeComponent,
    FileUploaderComponent,
    LoadingComponent,
    MessagePanelComponent,
    PageComponent,
    PanelComponent,
    PopupComponent,
    ReCaptchaComponent,
    SelectBoxEntityBasedComponent,
    SelectBoxEnumBasedComponent,
    TarefaComponent,
  ],

  exports: [
    CKEditorModule,
    CompetenciaComponent,
    DxAccordionModule,
    DxAutocompleteModule,
    DxBoxModule,
    DxButtonModule,
    DxCalendarModule,
    DxChartModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxFormModule,
    DxGalleryModule,
    DxHtmlEditorModule,
    DxListModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxPopoverModule,
    DxProgressBarModule,
    DxRadioGroupModule,
    DxSchedulerModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxToolbarModule,
    DxTooltipModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxValidatorModule,
    DxDataGridModule,
    DxScrollViewModule,
    AccordionComponent,
    AccordionPanelComponent,
    AutocompleteComponent,
    BlockClipboardDirective,
    CurrencyL2RDirective,
    CustomDataGridComponent,
    DateRangeComponent,
    FileUploaderComponent,
    LoadingComponent,
    MessagePanelComponent,
    PageComponent,
    PanelComponent,
    PopupComponent,
    ReCaptchaComponent,
    SelectBoxEntityBasedComponent,
    SelectBoxEnumBasedComponent,
    TarefaComponent,
  ],

  providers: [
    DomHandlerService,
    DateUtilsService,
    ExceptionInfoService,
    AlertService,
    ObjectUtilsService,
    TarefaService,
    TarefaResultadoService
  ]
})

export class UiModule {
}
