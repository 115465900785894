import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {PGDASDApuracao} from "./p.g.d.a.s.d.apuracao";
import {DateUtilsService} from "../modules/core/services/date.utils.service";

@JacksonType("PGDASDApuracaoReceita")
@JsonIdentityInfo()
export class PGDASDApuracaoReceita extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("PGDASDApuracao")
  apuracao: PGDASDApuracao;

  mes: number;

  valorReceitaBruta: number;

  getMesAnoFmt(): string {
    let dateUtilsService: DateUtilsService = new DateUtilsService();
    const mesFmt:string  = (this.mes == null) ? "" : dateUtilsService.getMesString(this.mes);
    const anoFmt: string = (this.ano == null) ? "" : this.ano.toString();
    return mesFmt + "/" + anoFmt;
  }
}
