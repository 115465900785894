import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Gestor")
@JsonSubType({key: 'br.com.sonner.iss.entity.Gestor', type: "Papel"})
export class Gestor extends Papel {

  jiraPassword: string;

  jiraUsername: string;

  constructor() {
    super(PerfilEnum.gestor);
  }
}
