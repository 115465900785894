import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("AtividadeCTISS")
@JsonIdentityInfo()
export class AtividadeCTISS extends SonnerBaseEntity implements ValueDisplayable{

  id: number;

  abateObra: boolean = false;

  @JacksonType("Atividade")
  atividade116: Atividade;

  codigo: string;

  descricao: string;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  codigoFormatado() {
    if (this.codigo.length == 9) {
      return this.codigo.substr(0, 4) + "-" + this.codigo.substr(4, 2) + "/" + this.codigo.substr(5, 2) + "-" + this.codigo.substr(7);
    }

    return this.codigo
  }

  getDisplayValue(): string {
    return this.codigoFormatado() +" - "+this.descricao;
  }


}
