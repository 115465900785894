import {Component, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import {BaseComponent} from "../base/base.component";
import {DxSelectBoxComponent} from "devextreme-angular";
import {ObjectUtilsService} from "../../../core/services/object.utils.service";
import {SonnerBaseEntity} from "../../../core/classes/sonner.base.entity";

@Component({
  selector: "select-box-entity-based",
  templateUrl: './select.box.entity.based.component.html',
  styleUrls: ['./select.box.entity.based.component.scss'],
})
export class SelectBoxEntityBasedComponent extends BaseComponent {

  @ViewChild("selectBox")
  selectBox: DxSelectBoxComponent;

  @Input()
  disabled: boolean = false;

  @Input()
  displayExpr: Function | string = "getDisplayValue";

  @Input()
  get items(): SonnerBaseEntity[] {
    return this._items;
  }

  set items(value: SonnerBaseEntity[]) {
    this._items = value;
    this.applyOnDataSource(this._selectedItem);
  }

  @Input()
  keyExp: string = "id";

  @Input()
  placeholder: string = 'Selecione...';

  @Input()
  readOnly: boolean = false;

  @Input()
  searchEnabled: boolean = true;

  @Input()
  set selectFirst(value: any) {
    this._selectFirst = value;

    if (value) {
      this.doSelectFist();
    }
  }

  get selectFirst(): any {
    return this._selectFirst;
  }

  @Input()
  set selectedItem(value: SonnerBaseEntity) {
    if (!value || (!value.getId() && !(this._.isFunction(this._.get(value, this.keyExp)) ? this._.get(value, this.keyExp)() : this._.get(value, this.keyExp)))) {
      this._selectedItem = null;

      return;
    }

    if (!this._selectedItem) {
      this._selectedItem = value;
    }

    this.applyOnDataSource(value);
  }

  get selectedItem(): SonnerBaseEntity {
    return this._selectedItem;
  }

  @Input()
  showClearButton: boolean;

  @Input()
  sortByString: boolean = true;

  @Input()
  validationGroup: string;

  @Input()
  visible: boolean = true;

  @Input()
  width: string = "100%";

  @Output()
  selectedItemChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private injector: Injector,
              private objectUtilsService: ObjectUtilsService) {
    super(injector);

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  private _items: SonnerBaseEntity[] = [];

  private _selectFirst = false;

  private _selectedItem: SonnerBaseEntity;

  private applyOnDataSource(value: SonnerBaseEntity) {
    if (value && !this._.isEmpty(this.items)) {
      for (let obj of this.items) {
        if (this.objectUtilsService.isEqual(obj, value, this.keyExp)) {
          this._selectedItem = obj;

          return;
        }
      }

      this._selectedItem = null;
    }
  }

  clearSelectedItem() {
    this.selectedItem = null;
  }

  doAfterViewInit() {
    if (this.selectFirst) {
      this.doSelectFist();
    }
  }

  doSelectFist() {
    if (!this.objectUtilsService.isEmpty(this._items)) {
      this.selectedItem = this._items[0];
    }
  }

  focus() {
    this.selectBox.instance.focus();
  }

  onSelectionChanged(event: any) {
    this.selectedItemChange.emit(event);
  }
}
