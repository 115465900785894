import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {EscritTransporteTarifa} from "./escrit.transporte.tarifa";
import {EscritTransporteArquivo} from "./escrit.transporte.arquivo";

@JacksonType("EscritTransporte")
@JsonIdentityInfo()
export class EscritTransporte extends SonnerBaseEntity {

  id: number;

  numeroOnibus: string;

  giroInicial: number;

  giroFinal: number;

  quantidadeGiros: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  deducao: number;

  @JacksonType("EscritTransporteTarifa")
  tarifa: EscritTransporteTarifa;

  valor: number;

  @DateType()
  dataPrestacao: Date;

  simplesNacional: boolean;

  aliquotaSimples: number;

  aliquotaAtividade: number;

  @JacksonType("EscritTransporteArquivo")
  arquivo: EscritTransporteArquivo;

}
