import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Municipio} from "./municipio";
import {CreditoTomadorBaseCalculoEnum} from "../enums/credito.tomador.base.calculo.enum";
import {TipoCreditoTomadorConfigEnum} from "../enums/tipo.credito.tomador.config.enum";

@JacksonType("CreditoTomadorConfig")
@JsonIdentityInfo()
export class CreditoTomadorConfig extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  aplicarTodasAtividades: boolean = false;

  @EnumType(CreditoTomadorBaseCalculoEnum)
  baseCalculo: CreditoTomadorBaseCalculoEnum;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  @JacksonType('Municipio')
  municipio: Municipio;

  @EnumType(TipoCreditoTomadorConfigEnum)
  tipoCredito: TipoCreditoTomadorConfigEnum;

  @JacksonType("Atividade")
  atividades: Atividade[];
}
