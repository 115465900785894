import {Injectable, OnDestroy} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable, of, Subscription} from "rxjs";
import {LoginService} from "../services/login.service";
import {mergeMap, tap} from "rxjs/operators";
import {SessionService} from "../services/session.service";
import {AppErrorService} from "../services/app.error.service";
import {SessionFilter} from "../filters/session.filter";
import {AppErrorTypeEnum} from "../../../enums/app.error.type.enum";
import {CognitoService} from "../services/cognito.service";

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {

  constructor(private appErrorService: AppErrorService,
              private cognitoService: CognitoService,
              private router: Router,
              private sessionService: SessionService) {

    this._homeSubscription = appErrorService.getHome().subscribe(home => this.home = home);
  }

  private _homeSubscription: Subscription;

  home: boolean = false;

  ngOnDestroy() {
    if (this._homeSubscription) {
      this._homeSubscription.unsubscribe();
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkSession(state.url);
  }

  checkSession(url: string): Observable<boolean> {
    return this.cognitoService.currentAuthenticatedUser().pipe(
      mergeMap(resp => {
        if (!resp) {
          this.router.navigate(['/landing-page']);

          return of(resp);
        } else {
          if (url != '/home' && url != '/home/access-denied' && url != '/home/inicial-menu' && url != '/home/not-found' && url.startsWith("/home/")) {
            let sessionFilter: SessionFilter = new SessionFilter();

            sessionFilter.acao = url;

            return this.sessionService.acesso(sessionFilter).pipe(
              tap(possuiPermissao => {
                if (!possuiPermissao) {
                  if (this.home) {
                    this.appErrorService.setError(AppErrorTypeEnum.AUTHORIZATION_ERROR);
                  } else {
                    setTimeout(() => this.router.navigate(['home/access-denied']));
                  }
                }
              })
            );
          }

          return of(resp);
        }
      })
    );
  }
}
