import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {Component, Injector, ViewChild, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {Municipio} from "../../../../classes/municipio";
import {LoginService} from "../../../core/services/login.service";
import {LoginFilter} from "../../../core/filters/login.filter";
import {BilheteNotaPremiadaContribuinteTO} from "../../../../classes/to/bilhete.nota.premiada.contribuinte.to";
import {ReCaptchaComponent} from "../../../ui/components/captcha/re.captcha.component";
import {DomSanitizer} from "@angular/platform-browser";
import {BilheteNotaPremiadaPeriodoTO} from "../../../../classes/to/bilhete.nota.premiada.periodo.to";

@Component({
  selector: 'bilhete-nota-premiada-contribuinte-page',
  templateUrl: './bilhete.nota.premiada.contribuinte.page.component.html',
  styleUrls: ['./bilhete.nota.premiada.contribuinte.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BilheteNotaPremiadaContribuintePageComponent extends BaseComponent {

  @ViewChild(ReCaptchaComponent) reCaptchaComponent: ReCaptchaComponent;

  constructor(private injector: Injector,
              private loginService: LoginService,
              private sanitizer: DomSanitizer) {
    super(injector)

    this.onContentReady = this.onContentReady.bind(this);
  }

  documento: string = "";

  municipio: Municipio;

  municipiosCliente: Municipio[] = [];

  resultList: BilheteNotaPremiadaPeriodoTO[];

  reCaptchaResponse: string;

  showPopUpCancelamento: boolean = false;

  motivoCancelamento: string;

  ngOnInit(): void {
    this.buscaMunicipiosCliente();
  }

  @GlobalLoadingIndicator()
  buscaMunicipiosCliente() {
    return this.of(this.loginService.buscarListaMunicipioNotaPremiada(), municipios => {
      this.municipiosCliente = municipios;
      if(this.reCaptchaComponent){
        this.reCaptchaComponent.init();
      }
    });
  }

  @GlobalLoadingIndicator()
  acionaPesquisar() {
    this.clearErrorMessages();
    this.resultList = [];

    if (!this.reCaptchaResponse) {
      this.addErrorMessage("Captcha obrigatório.");
      return;
    }

    if(!this.municipio){
      this.addErrorMessage("Selecione o município");
    }

    if(!this.documento){
      this.addErrorMessage("Informe o CPF para ser consultado");
    }

    if(!this.hasErrorMessages()){
      let loginFilter = new LoginFilter();
      loginFilter.idMunicipio = this.municipio.id;
      loginFilter.documento = this.documento;
      loginFilter.captcha = this.reCaptchaResponse;
      return this.of(this.loginService.buscarBilhetes(loginFilter), result => {
        if(result && result.length){
          result.forEach(periodo => {
            periodo.bilhetesNotaPremiadaContribuinteTO.forEach(bilhete =>{
              bilhete.detalhes.forEach(detalhe =>{
                if(detalhe){
                  detalhe.bilheteUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + detalhe.bilheteImage);
                }
              });
            })
          });
        }
        this.resultList = result;
        this.reCaptchaComponent.reset();
      });
    }
  }

  showPopUp(motivoCancelamento: string){
    if(motivoCancelamento && motivoCancelamento.trim().length){
      this.motivoCancelamento = motivoCancelamento;
      this.showPopUpCancelamento = true;
    }
  }

  onContentReady(event: any) {
    if (!event.component.isNotFirstLoad) {
      event.component.isNotFirstLoad = true;
      event.component.expandRow(event.component.getKeyByRowIndex(0));
    }
  }

}
