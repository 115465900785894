import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("ArquivoImportacao")
@JsonIdentityInfo()
export class ArquivoImportacao extends SonnerBaseEntity {

  id: number;

  // @JacksonType("byte[]")
  // arquivoEnviado: byte[];
  //
  // @JacksonType("byte[]")
  // arquivoRetorno: byte[];

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  enviado: Date;

  importadoSucesso: boolean;

  mensagem: string;

  nomeArqEnviado: string;

  nomeArqRetorno: string;

  @DateType()
  retornado: Date;

  tamanhoArqEnviado: number;

  tamanhoArqRetorno: number;

  @JacksonType("Pessoa")
  usuario: Pessoa;
}
