import {NgModule} from "@angular/core";
import {HomeComponent} from "./components/home.component";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "../core/auth/auth.guard";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {MenuLateralComponent} from "./components/menu-lateral/menu.lateral.component";
import {MenuLateralItemComponent} from "./components/menu-lateral/menu-lateral-item/menu.lateral.item.component";
import {StrutsModule} from "../struts/struts.module";
import {UiModule} from "../ui/ui.module";
import {ViewService} from "./services/view.service";
import {EstatisticaAcessoService} from "./services/estatistica.acesso.service";
import {AppShellService} from "./services/app.shell.service";
import {GoogleAnalyticsService} from "./services/google.analytics.service";
import {CoreModule} from "../core/core.module";
import {FiscalMenuPageComponent} from "./components/fiscal-menu/fiscal.menu.page.component";
import {FiscalMenuService} from "./services/fiscal.menu.service";
import {ConfiguracaoMenuPageComponent} from "./components/configuracao-menu/configuracao.menu.page.component";
import {ConfiguracaoMenuService} from "./services/configuracao.menu.service";
import {NotFoundPageComponent} from "./components/not-found/not.found.page.component";
import {AccessDeniedPageComponent} from "./components/access-denied/access.denied.page.component";
import {CadastroMenuPageComponent} from "./components/cadastro-menu/cadastro.menu.page.component";
import {InicialMenuPageComponent} from "./components/inicial-menu/inicial.menu.page.component";
import {InicialMenuService} from "./services/inicial.menu.service";
import {EmissaoMenuPageComponent} from "./components/emissao-menu/emissao.menu.page.component";
import {CommonsModule} from "../commons/commons.module";
import {AlterarSenhaPageComponent} from "./components/alterar-senha/alterar.senha.page.component";
import {EscrituracaoMenuPageComponent} from "./components/escrituracao-menu/escrituracao.menu.page.component";
import {BloqueioMovimentacaoPageComponent} from "./components/bloqueio-movimentacao/bloqueio.movimentacao.page.component";
import {AdminMenuPageComponent} from "./components/admin-menu/admin.menu.page.component";
import {EnsinoMenuPageComponent} from "./components/ensino-menu/ensino.menu.page.component";
import {OrdemServicoService} from "./services/ordem.servico.service";
import {OrdemServicoItemService} from "./services/ordem.servico.item.service";
import {OrdemServicoPageComponent} from "./components/ordem-servico/ordem.servico.page.component";
import {OrdemServicoArquivoService} from "./services/ordem.servico.arquivo.service";
import {NotaDeducaoMenuPageComponent} from "./components/nota-deducao-menu/nota.deducao.menu.page.component";
import {NotaDeducaoService} from "./services/nota.deducao.service";
import {AtendimentoMenuPageComponent} from "./components/atendimento-menu/atendimento.menu.page.component";
import {AtendimentoService} from "./services/atendimento.service";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    children: [
      {path: "", redirectTo: 'inicial-menu', pathMatch: 'full'},

      {path: "access-denied", component: AccessDeniedPageComponent},

      {path: "bloqueio-movimentacao", component: BloqueioMovimentacaoPageComponent},

      {path: "admin", loadChildren: "../admin/admin.module#AdminModule"},

      {path: "admin-menu", component: AdminMenuPageComponent},

      {path: "actions", loadChildren: "./struts.routing.module#StrutsRoutingModule"},

      {path: "atender", loadChildren: "../atendimento/atendimento.module#AtendimentoModule"},

      {path: 'atendimento', component: AtendimentoMenuPageComponent},

      {path: "nota-deducao", loadChildren: "../nota-deducao/nota.deducao.module#NotaDeducaoModule"},

      {path: "nota-deducao-menu", component: NotaDeducaoMenuPageComponent, canActivate: [AuthGuard]},

      {path: "cadastro", loadChildren: "../cadastro/cadastro.module#CadastroModule"},

      {path: "cadastro-menu", component: CadastroMenuPageComponent, canActivate: [AuthGuard]},

      {path: "configuracao", loadChildren: "../configuracao/configuracao.module#ConfiguracaoModule"},

      {path: "configuracao-menu", component: ConfiguracaoMenuPageComponent, canActivate: [AuthGuard]},

      {path: "documento", loadChildren: "../documento/documento.module#DocumentoModule"},

      {path: "duvida", loadChildren: "../duvida/duvida.module#DuvidaModule"},

      {path: "emissao", loadChildren: "../emissao/emissao.module#EmissaoModule"},

      {path: "emissao-menu", component: EmissaoMenuPageComponent, canActivate: [AuthGuard]},

      {path: "ensino", loadChildren: "../ensino/ensino.module#EnsinoModule"},

      {path: "ensino-menu", component: EnsinoMenuPageComponent, canActivate: [AuthGuard]},

      {path: "escrituracao", loadChildren: "../escrituracao/escrituracao.module#EscrituracaoModule"},

      {path: "escrituracao-menu", component: EscrituracaoMenuPageComponent, canActivate: [AuthGuard]},

      {path: "fiscal", loadChildren: "../fiscal/fiscal.module#FiscalModule"},

      {path: "fiscal-menu", component: FiscalMenuPageComponent, canActivate: [AuthGuard]},

      {path: 'inicial-menu', component: InicialMenuPageComponent},

      {path: "not-found", component: NotFoundPageComponent},

      {path: "pedagio", loadChildren: "../pedagio/pedagio.module#PedagioModule"},

      {path: "cartorio", loadChildren: "../cartorio/cartorio.module#CartorioModule"},

      {path: "transporte", loadChildren: "../transporte/transporte.module#TransporteModule"},

      {path: "recibo-locacao-menu", loadChildren: "../recibo-locacao/recibo.locacao.module#ReciboLocacaoModule"},

      {path: "ordem-servico", component: OrdemServicoPageComponent, canActivate: [AuthGuard]},

      {path: "senha", component: AlterarSenhaPageComponent, canActivate: [AuthGuard]},

      {path: "solicitacao", loadChildren: "../solicitacao/solicitacao.module#SolicitacaoModule"},

      {path: 'voltar', component: InicialMenuPageComponent}
    ]
  }
];

@NgModule({
  declarations: [
    NotaDeducaoMenuPageComponent,
    AccessDeniedPageComponent,
    AdminMenuPageComponent,
    AlterarSenhaPageComponent,
    BloqueioMovimentacaoPageComponent,
    CadastroMenuPageComponent,
    ConfiguracaoMenuPageComponent,
    EmissaoMenuPageComponent,
    EnsinoMenuPageComponent,
    EscrituracaoMenuPageComponent,
    FiscalMenuPageComponent,
    InicialMenuPageComponent,
    HomeComponent,
    MenuLateralComponent,
    MenuLateralItemComponent,
    NotFoundPageComponent,
    OrdemServicoPageComponent,
    AtendimentoMenuPageComponent
  ],

  exports: [],

  imports: [
    CommonModule,
    CommonsModule,
    CoreModule,
    FormsModule,
    RouterModule.forChild(routes),
    StrutsModule,
    UiModule
  ],

  providers: [
    AppShellService,
    ConfiguracaoMenuService,
    EstatisticaAcessoService,
    FiscalMenuService,
    GoogleAnalyticsService,
    InicialMenuService,
    NotaDeducaoService,
    OrdemServicoService,
    OrdemServicoArquivoService,
    OrdemServicoItemService,
    ViewService,
    AtendimentoService
  ]
})

export class HomeModule {
}
