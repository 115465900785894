import {Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import {BaseComponent} from "../base/base.component";
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {Arquivo} from "../../../../classes/arquivo";
import {AlertService} from "../../services/alert.service";
import {DxFileUploaderComponent} from "devextreme-angular";

@Component({
  selector: 'file-uploader',
  templateUrl: './file.uploader.component.html',
  styleUrls: ['./file.uploader.component.scss'],
})
export class FileUploaderComponent extends BaseComponent {

  @ViewChild("form", {static: true})
  form: ElementRef;

  @ViewChild("fileUploader", {static: true})
  fileUploader: DxFileUploaderComponent;

  @Input()
  tipo: string = "*";

  @Input()
  url: string = "file/upload";

  @Input()
  showSucess: boolean = true;

  @Output()
  arquivo: EventEmitter<Arquivo> = new EventEmitter<Arquivo>();

  constructor(private alertService: AlertService,
              private httpClient: HttpClient,
              private injector: Injector) {
    super(injector);
  }

  fileName: string = " ";
  labelText: string = " ";
  progress: number = 0;

  onUploadStarted(evento: any) {
    console.log(evento);
  }

  onUploaded(evento: any) {
    console.log(evento);
  }

  valueChange(evento: any) {
    if(evento){
      if(evento[0]){
        if (evento[0].size > 16777216) {
          this.alertService.show("O tamanho do arquivo não pode exceder 16 MB.");
        } else {
          this.fileName = evento[0].name;
          this.labelText = this.fileName + " (" + this.progress + "%)";

          let formData: any = new FormData((this.form.nativeElement as HTMLFormElement));
          let httpRequest = new HttpRequest('POST', "/v2/rest/" + this.url, formData, { responseType: 'json', reportProgress: true });

          this.processaRequest(httpRequest);
        }
      }
    }
  }

  clear(){
    this.fileUploader.instance.reset();
    this.fileName = " ";
    this.labelText = " ";
    this.progress = 0;
    this.sucess = false;
    this.clearErrorMessages();
  }

  processaRequest(httpRequest: HttpRequest<any>) {
    this.httpClient.request(httpRequest).subscribe((evento: any) => {

      if (evento.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * evento.loaded / evento.total);
        this.labelText = this.fileName + " (" + this.progress + "%)";
      } else if (evento instanceof HttpResponse) {
        this.progress = 0;
        this.labelText = this.fileName + " (100%)";
        if(this.showSucess){
          this.sucess = true;
        }
        this.arquivo.emit(evento.body);

        (this.form.nativeElement as HTMLFormElement).reset();
      }
    }, error => {
      error.error.mensagens.forEach(erro =>{
        this.errorMessages.push(erro.message);
      });
      this.errorsVisible = true;
      this.progress = 0;
      (this.form.nativeElement as HTMLFormElement).reset();
      this.labelText = this.labelText = this.fileName + " (falha ao enviar)";
    });
  }
}
