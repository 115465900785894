import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("OrdemServicoFilter")
export class OrdemServicoFilter {

  id: number;

  idMunicipio: number;

  idPessoa: number;
}
