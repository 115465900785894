import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {Pessoa} from "../pessoa";
import {PapelServicoEnum} from "../../enums/papel.servico.enum";

@JacksonType("PessoaDescontoEncerramentoTO")
export class PessoaDescontoEncerramentoTO {

  id: string;

  idContribuinte: number;

  @DateType()
  competenciaInicial: Date;

  @DateType()
  competenciaFinal: Date;

  processo: string;

  observacao: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  competenciaFmt: string;

  docNomeContribuinte: string;

  percentualDesconto: number;

  valorMaximoDesconto: number;

  ativo: boolean = true;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

}
