import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";

@JacksonType("SolicitacaoDadosPessoa")
@JsonIdentityInfo()
export class SolicitacaoDadosPessoa {

  bairro: string;

  cep: string;

  complemento: string;

  cnpj: string;

  email: string;

  endereco: string;

  estado: string;

  inscricaoEstadual: string;

  inscricaoMunicipal: string;

  municipio: string;

  nome: string;

  numero: string;

  pais: string;

  passaporte: string;

  telefone: string;
}
