import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {TipoImportacaoEnum} from "../enums/tipo.importacao.enum";

@JacksonType("LogImportacao")
@JsonIdentityInfo()
export class LogImportacao extends SonnerBaseEntity {

  id: number;

  @DateType()
  data: Date;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  sucesso: boolean;

  @EnumType(TipoImportacaoEnum)
  tipo: TipoImportacaoEnum;
}
