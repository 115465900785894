import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {TipoMultaStrategyEnum} from "../enums/tipo.multa.strategy.enum";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("MultaStrategy")
@JsonIdentityInfo()
export class MultaStrategy extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  @EnumType(TipoMultaStrategyEnum)
  tipo: TipoMultaStrategyEnum;

  getDisplayValue(): string {
    return "";
  }
}
