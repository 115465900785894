import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {EncerramentoFilter} from "../filters/encerramento.filter";
import {Encerramento} from "../../../classes/encerramento";

@Injectable()
export class EncerramentoService {

  constructor(private restService: RestService){
  }

  buscar(encerramentoFilter: EncerramentoFilter): Observable<Encerramento> {
    return this.restService.post("/encerramento-v2/buscar", {data: encerramentoFilter, responseType: Encerramento});
  }

  buscarListaPorAnoEAtivoEMesEPapelServicoEPessoa(encerramentoFilter: EncerramentoFilter): Observable<Encerramento[]> {
    return this.restService.post("/encerramento-v2/buscarListaPorAnoEAtivoEMesEPapelServicoEPessoa", {data: encerramentoFilter, responseType: Encerramento});
  }

  verificarPorAnoEAtivoEMesEPapelServicoEPessoa(encerramentoFilter: EncerramentoFilter): Observable<boolean> {
    return this.restService.post("/encerramento-v2/verificarPorAnoEAtivoEMesEPapelServicoEPessoa", {data: encerramentoFilter});
  }

  buscarEncerramentoDetalheMesTO(encerramentoFilter: EncerramentoFilter): Observable<number> {
    return this.restService.post("/encerramento-v2/buscarEncerramentoDetalheMesTO", {data: encerramentoFilter});
  }
}
