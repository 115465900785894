import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifDemonstrativoContabilBalanceteAnalitico} from "./desif.demonstrativo.contabil.balancete.analitico";
import {DesifDemonstrativoContabilDependencia} from "./desif.demonstrativo.contabil.dependencia";
import {DesifDemonstrativoContabilRateioInterno} from "./desif.demonstrativo.contabil.rateio.interno";
import {DesifImportacaoArquivo} from "./desif.importacao.arquivo";
import {Municipio} from "./municipio";
import {DesifTitulosEnum} from "../enums/desif.titulos.enum";

@JacksonType("DesifDemonstrativoContabil")
@JsonIdentityInfo()
export class DesifDemonstrativoContabil extends SonnerBaseEntity {

  id: number;

  @JacksonType("DesifImportacaoArquivo")
  arquivoImportacao: DesifImportacaoArquivo;

  @JacksonType("DesifDemonstrativoContabil")
  demonstrativoAnterior: DesifDemonstrativoContabil;

  @DateType()
  fimCompetencia: Date;

  inicioCnpj: string;

  @DateType()
  inicioCompetencia: Date;

  nome: string;

  @JacksonType("Municipio")
  responsavel: Municipio;

  retificadora: boolean;

  @EnumType(DesifTitulosEnum)
  titulo: DesifTitulosEnum;

  @JacksonType("DesifDemonstrativoContabilBalanceteAnalitico")
  balancetesAnaliticos: DesifDemonstrativoContabilBalanceteAnalitico[];

  @JacksonType("DesifDemonstrativoContabilDependencia")
  dependencias: DesifDemonstrativoContabilDependencia[];

  @JacksonType("DesifDemonstrativoContabilRateioInterno")
  rateios: DesifDemonstrativoContabilRateioInterno[];
}
