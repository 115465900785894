import {Municipio} from "./municipio";
import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {TNCSignalTypeEnum} from "../enums/t.n.c.signal.type.enum";

@JacksonType("TNCSignal")
@JsonIdentityInfo()
export class TNCSignal {

  id: number;

  @DateType()
  criado: Date;

  @DateType()
  finalizado: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  @EnumType(TNCSignalTypeEnum)
  tipo: TNCSignalTypeEnum;
}
