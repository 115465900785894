import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {JurosStrategy} from "./juros.strategy";
import {InicioContagemTempoEnum} from "../enums/inicio.contagem.tempo.enum";

@JacksonType("JuroMensalStrategy")
@JsonSubType({key: 'br.com.sonner.iss.entity.JuroMensalStrategy', type: "JurosStrategy"})
export class JuroMensalStrategy extends JurosStrategy {

  contabilizaMesFracao: boolean = false;

  proRataDiario: boolean = false;

  proRataFracao: boolean = false;

  taxa: number;

  getDisplayValue(): string {
    let descricao = "Juro mensal de ";

    if (this.taxa) {
      descricao += (this.taxa * 100).toFixed(2) + "%";
    }

    if (this.proRataDiario) {
      descricao += " (pró rata diário)";
    } else if (this.proRataFracao) {
      descricao += " (pró rata somente da parte fracionária do mês)";
    } else if (this.contabilizaMesFracao) {
      descricao += " (mês e fração)";
    }

    if (this.inicioTempo != null && !(this.inicioTempo == InicioContagemTempoEnum.APOS_VENCIMENTO)) {
      descricao += " (cobrar após " + this.inicioTempo + " )";
    }

    return descricao;
  }
}
