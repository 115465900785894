import {Injectable} from "@angular/core";
import {RestService} from "../../core/services/rest.service";
import {Observable} from "rxjs";
import {TarefaResultadoFilter} from "../filters/tarefa.resultado.filter";

@Injectable()
export class TarefaResultadoService {

  constructor(private restService: RestService) {
  }

  erro(tarefaResultadoFilter: TarefaResultadoFilter): Observable<string> {
    return this.restService.post("/tarefaresultado/erro", {data: tarefaResultadoFilter, responseType: "text"})
  }

  objeto(tarefaResultadoFilter: TarefaResultadoFilter, responseType: Function): Observable<any> {
    return this.restService.post("/tarefaresultado/objeto", {data: tarefaResultadoFilter, responseType: responseType});
  }
}
