import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {CorrecaoStrategy} from "./correcao.strategy";
import {Indice} from "./indice";

@JacksonType("CorrecaoIndiceStrategy")
@JsonSubType({key: 'br.com.sonner.iss.entity.CorrecaoIndiceStrategy', type: "CorrecaoStrategy"})
export class CorrecaoIndiceStrategy extends CorrecaoStrategy {

  @JacksonType("Indice")
  indice: Indice;

  getDisplayValue(): string {
    if(this.indice){
      return "Correção pelo índice " + this.indice.nome;
    }

    return "Correção ";
  }
}
