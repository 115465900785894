import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "./rest.service";

@Injectable()
export class VersionService {

  constructor(private restService: RestService) {
  }

  buscar(): Observable<string> {
    return this.restService.get("/public/version/buscar", {responseType: 'text'});
  }
}
