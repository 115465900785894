import {JacksonType} from "@sonner/jackson-service-v2";
import {ContribuinteEncerramentoResumidoTO} from "./contribuinte.encerramento.resumido.to";

@JacksonType("EncerramentoAutomaticoTO")
export class EncerramentoAutomaticoTO {

  @JacksonType("ContribuinteEncerramentoResumidoTO")
  prestadores: ContribuinteEncerramentoResumidoTO[];

  @JacksonType("ContribuinteEncerramentoResumidoTO")
  tomadores:  ContribuinteEncerramentoResumidoTO[];

}
