import {EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {Encerramento} from "./encerramento";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("Debito")
@JsonSubType({key: 'br.com.sonner.iss.entity.Debito', type: 'Documento'})
@JsonIdentityInfo()
export class Debito extends Documento {

  ano: number;

  @JacksonType("Encerramento")
  encerramentoGerador: Encerramento;

  @JacksonType("Encerramento")
  encerramentoGuia: Encerramento;

  mes: number;

  obs: string;

  @EnumType(PapelServicoEnum)
  papelServico: PapelServicoEnum;

  valor: number;
}
