import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Municipio} from "./municipio";

@JacksonType("TipoDeducao")
@JsonIdentityInfo()
export class TipoDeducao extends SonnerBaseEntity {

  id: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  codigo: string;

  @DateType()
  dataCancelamento: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  posicao: number = 0;

  rotulo: string;

  valorMaximo: number;

  valorPadrao: number;
}
