import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {OrdemServicoItemFilter} from "../filters/ordem.servico.item.filter";
import {OrdemServicoItem} from "../../../classes/ordem.servico.item";

@Injectable()
export class OrdemServicoItemService {

  constructor(private restService: RestService){
  }

  buscar(ordemServicoItemFilter: OrdemServicoItemFilter): Observable<OrdemServicoItem> {
    return this.restService.post("/ordemservicoitem/buscar", {data: ordemServicoItemFilter, responseType: OrdemServicoItem});
  }

  buscarListaPorOrdemServico(ordemServicoItemFilter: OrdemServicoItemFilter): Observable<OrdemServicoItem[]> {
    return this.restService.post("/ordemservicoitem/buscarListaPorOrdemServico", {data: ordemServicoItemFilter, responseType: OrdemServicoItem});
  }
}
