import {JacksonType} from "@sonner/jackson-service-v2";
import {EncerramentoSimplificadoTO} from "./encerramento.simplificado.to";

@JacksonType("SolicitacaoCancelamentoEncerramentoTO")
export class SolicitacaoCancelamentoEncerramentoTO {

  id: string;

  @JacksonType("EncerramentoSimplificadoTO")
  encerramento: EncerramentoSimplificadoTO;

  motivoDeferimento: string;

}
