export enum WebISSTipoContribuinteEnum {

  AdministracaoPublica = "Administração pública",
  Associacao = "Associação",
  Autonomo = "Autônomo",
  Comercio = "Comércio",
  Industria = "Industria",
  InstituicaoFinanceira = "Instituição financeira",
  Mista = "Mista",
  Obras = "Obras",
  Outro = "Outro",
  Prestador = "Prestador",
  PrestadorOutroMunicipio = "Prestador de outro município"
}
