import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoTermoFiscalEnum} from "../enums/tipo.termo.fiscal.enum";

@JacksonType("TextoTermoFiscal")
@JsonIdentityInfo()
export class TextoTermoFiscal extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  texto: string;

  @EnumType(TipoTermoFiscalEnum)
  tipoTermo: TipoTermoFiscalEnum;
}
