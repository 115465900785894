import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("AgendaFiscalizacao")
@JsonIdentityInfo()
export class AgendaFiscalizacao extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  enviarNotificacao: boolean;

  @DateType()
  fimCompetencia: Date;

  @DateType()
  fimFiscalizacao: Date;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  @DateType()
  inicioCompetencia: Date;

  @DateType()
  inicioFiscalizacao: Date;

  observacao: string;

  situacao: string;

  textoNotificacao: string;
}
