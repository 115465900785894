import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Comunicado} from "../../../classes/comunicado";
import {ComunicadoFilter} from "../filters/comunicado.filter";

@Injectable()
export class ComunicadoService {

  constructor(private restService: RestService) {
  }

  buscar(comunicadoFilter: ComunicadoFilter): Observable<Comunicado> {
    return this.restService.post("/comunicado/buscar", {data: comunicadoFilter, responseType: Comunicado});
  }

  buscarListaPorMunicipio(comunicadoFilter: ComunicadoFilter): Observable<Comunicado[]> {
    return this.restService.post("/comunicado/buscarListaPorMunicipio", {data: comunicadoFilter, responseType: Comunicado});
  }

  enviar(comunicadoFilter: ComunicadoFilter): Observable<any> {
    return this.restService.post("/comunicado/enviar", {data: comunicadoFilter});
  }

  remover(comunicadoFilter: ComunicadoFilter): Observable<any> {
    return this.restService.post("/comunicado/remover", {data: comunicadoFilter});
  }

  salvar(comunicado: Comunicado): Observable<number> {
    return this.restService.post("/comunicado/salvar", {data: comunicado});
  }
}
