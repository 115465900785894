import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {RotinaAgendamentoParametro} from "./rotina.agendamento.parametro";
import {RotinaAgendamentoLog} from "./rotina.agendamento.log";
import {logger} from "codelyzer/util/logger";

@JacksonType("RotinaAgendamento")
@JsonIdentityInfo()
export class RotinaAgendamento extends SonnerBaseEntity {

  id: number;

  ano: number;

  ativa: boolean = false;

  dia: number;

  hora: number;

  mes: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  nomeCommand: string;

  @JacksonType("RotinaAgendamentoLog")
  logs: RotinaAgendamentoLog[];

  @JacksonType("RotinaAgendamentoParametro")
  parametros: RotinaAgendamentoParametro[];

  execucao() {
    return new Date(this.ano, this.mes, this.dia);
  }

  executado() {
    if (this.logs && this.logs.length > 0) {
      return this.logs[this.logs.length - 1].horario
    }
  }
}
