import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Competencia} from "./competencia";
import {EstatisticaVerbete} from "./estatistica.verbete";
import {InstituicaoBancaria} from "./instituicao.bancaria";
import {Municipio} from "./municipio";

@JacksonType("EstatisticaBancaria")
@JsonIdentityInfo()
export class EstatisticaBancaria extends SonnerBaseEntity {

  id: number;

  agenciasEsperadas: number;

  agenciasProcessadas: number;

  codigoBacenMunicipio: number;

  @JacksonType("Competencia")
  competencia: Competencia;

  inicioCnpjBanco: string;

  @JacksonType("InstituicaoBancaria")
  instituicao: InstituicaoBancaria;

  @JacksonType("Municipio")
  municipio: Municipio;

  nomeBanco: string;

  nomeMunicipio: string;

  numeroLinha: number;

  ufMunicipio: string;

  @JacksonType("EstatisticaVerbete")
  verbetes: EstatisticaVerbete[];
}
