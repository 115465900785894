import {EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {AlertaFiscal} from "./alerta.fiscal";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("AlertaFiscalDivergenciaMovimentacaoReceita")
@JsonSubType({key: 'br.com.sonner.iss.entity.dw.AlertaFiscalDivergenciaMovimentacaoReceita', type: 'AlertaFiscal'})
@JsonIdentityInfo()
export class AlertaFiscalDivergenciaMovimentacaoReceita extends AlertaFiscal {

  movimentacaoMaiorReceita: boolean;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  valorMovimentacao: number;

  valorReceita: number;
}
