import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("CalculoEncerramento")
export class CalculoEncerramento {

  cemPorcentoRetido: boolean = false;

  qtdEscrit: number;

  qtdCreditos: number;

  qtdDebitoGuia: number;

  qtdDebitosAvulsos: number;

  qtdGuias: number;

  qtdNFe: number;

  totalBanco: number = 0;

  totalCredito: number = 0;

  totalDebitoGuia: number = 0;

  totalDebitosAvulsos: number = 0;

  valorDevido: number = 0;

  valorDevidoFixo: number = null;

  valorEncerrado: number = 0;

  totalEscrit: number = 0;

  totalEspecial: number = 0;

  totalPedagio: number = 0;

  totalCartorio: number = 0;

  totalTransporte: number = 0;

  totalFaturado: number = 0;

  totalImposto: number = 0;

  totalImpostoNRetido: number = 0;

  totalImpostoRetido: number = 0;

  totalNFe: number = 0;

  totalRecebido: number = 0;

  valorDesconto: number = 0;

  getTotalCreditos(): number{
    let creditos: number = 0;

    creditos += this.totalRecebido;
    creditos += this.totalCredito;
    creditos += this.totalDebitoGuia;

    return creditos
  }

  getTotalDebitos(): number{
    let debitos: number = 0;

    debitos += this.totalNFe;
    debitos += this.totalEscrit;
    debitos += this.totalBanco;
    debitos += this.totalEspecial;
    debitos += this.totalDebitosAvulsos;
    debitos += this.totalPedagio;
    debitos += this.totalCartorio;
    debitos += this.totalTransporte;

    return debitos
  }

  getValorAPagar(): number{
    let debitos: number = 0;
    let creditos = this.getTotalCreditos();

    if(this.valorDevidoFixo !=null){
      debitos = this.valorDevidoFixo
    }else if(this.getTotalDebitos()!= null){
      debitos = this.getTotalDebitos();
    }

    let valorAPagar = debitos - creditos - this.valorDesconto;

    if(valorAPagar < 0 ){
      valorAPagar = 0;
    }

    return valorAPagar;
  }

}
