import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ImportacaoPGDASD} from "./importacao.p.g.d.a.sd";
import {Municipio} from "./municipio";
import {PGDASDApuracao} from "./p.g.d.a.s.d.apuracao";
import {PGDASDDASDetalhe} from "./p.g.d.a.s.d.d.a.s.detalhe";
import {PGDASDTipoDASEnum} from "../enums/pgdas.d.tipo.das.enum";
import {SistemaOrigemEnum} from "../enums/sistema.origem.enum";

@JacksonType("PGDASDDAS")
@JsonIdentityInfo()
export class PGDASDDAS extends SonnerBaseEntity {

  id: number;

  @JacksonType("PGDASDApuracao")
  apuracao: PGDASDApuracao;

  cnpjMatriz: string;

  @DateType()
  dataGeracao: Date;

  @EnumType(SistemaOrigemEnum)
  identificadorSistOrigem: SistemaOrigemEnum;

  @JacksonType("ImportacaoPGDASD")
  importacao: ImportacaoPGDASD;

  @JacksonType("Municipio")
  municipioMatriz: Municipio;

  numeroGuia: string;

  pgdasdId: string;

  @EnumType(PGDASDTipoDASEnum)
  tipo: PGDASDTipoDASEnum;

  @DateType()
  validadeCalculo: Date;

  valorEncargoLegal: number;

  valorJuros: number;

  valorMulta: number;

  valorPrincipal: number;

  valorTotalDAS: number;

  valorTotalDevido: number;

  @DateType()
  vencimento: Date;

  @JacksonType("PGDASDDASDetalhe")
  detalhes: PGDASDDASDetalhe[];
}
