import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {AliquotaProgressivaTabela} from "./aliquota.progressiva.tabela";

@JacksonType("AliquotaProgressivaItem")
@JsonIdentityInfo()
export class AliquotaProgressivaItem extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("AliquotaProgressivaTabela")
  tabela: AliquotaProgressivaTabela;

  valorFinal: number;

  valorInicial: number;
}
