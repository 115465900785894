import {JacksonType} from "@sonner/jackson-service-v2";
import {Endereco} from "../endereco";

@JacksonType("PessoaSimplificadaTO")
export class PessoaSimplificadaTO {

  documento: string;

  id: number;

  nome: string;

  @JacksonType("Endereco")
  endereco: Endereco;

  email: string;

  telefone: string;

  grpMobiliario: string;


}
