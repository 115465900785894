import {environment} from './environments/environment';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {loadTypes} from "./jackson.module";
import {enableProdMode} from '@angular/core';
import Amplify from '@aws-amplify/core';
import {AppModule} from './app/modules/app/app.module';

if (environment.production) {
  enableProdMode();

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-1:60c2953f-a5b8-4e9e-88ba-55e59e3e405d',
      region: 'us-east-1',
      userPoolId: 'us-east-1_Bby2vKCKX',
      userPoolWebClientId: '2fm7u3lrvnomsdh28riqoi7045',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
  });
} else {
  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-1:3ad466ba-b0f1-4c1b-b724-03c4a2c30bf0',
      region: 'us-east-1',
      userPoolId: 'us-east-1_dR1WUyD17',
      userPoolWebClientId: '4ah6ppp11ld4tvhhv95uumfql9',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
  });
}

loadTypes();

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
