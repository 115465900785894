import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {Encerramento} from "../encerramento";
import {CalculoEncerramento} from "../calculo.encerramento";
import {DateUtilsService} from "../../modules/core/services/date.utils.service";

@JacksonType("EncerramentoMesTO")
export class EncerramentoMesTO {

  ano : number;

  @JacksonType("CalculoEncerramento")
  calculoEncerramentoPrestador : CalculoEncerramento;

  @JacksonType("CalculoEncerramento")
  calculoEncerramentoTomador : CalculoEncerramento;

  mes : number;

  totalValorAEncerrarPrestador : number = 0;

  totalValorAEncerrarTomador : number = 0;

  @JacksonType("Encerramento")
  encerramentosPrestador : Encerramento[] = [];

  @JacksonType("Encerramento")
  encerramentosTomador : Encerramento[] = [];

  getMesAnoFmt(): string {
    let dateUtilsService: DateUtilsService = new DateUtilsService();
    const mesFmt:string  = (this.mes == null) ? "" : dateUtilsService.getMesString(this.mes);
    const anoFmt: string = (this.ano == null) ? "" : this.ano.toString();
    return mesFmt + "/" + anoFmt;
  }

}
