import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {AppStateService} from "../../../app/services/app.state.service";
import {UsuarioService} from "../../../commons/services/usuario.service";
import {UsuarioFilter} from "../../../commons/filters/usuario.filter";

@Component({
  selector: 'alterar-senha-page',
  templateUrl: './alterar.senha.page.component.html',
  styleUrls: ['./alterar.senha.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlterarSenhaPageComponent extends BaseComponent {

  constructor(private appStateService: AppStateService,
              private injector: Injector,
              private usuarioService: UsuarioService) {
    super(injector);
  }

  confirmacao: string = "";

  senha: string = "";

  senhaAtual: string = "";

  usuario: string = "";

  ngOnInit(): void {
    this.appStateService.userInfoSubject.subscribe(user => {
      if (user) {
        this.usuario = user.nomeUsuario;

        this.disableLoading();
      }
    });
  }

  @GlobalLoadingIndicator()
  acionaAlterar() {
    let usuarioFilter: UsuarioFilter = new UsuarioFilter();

    usuarioFilter.confirmacao = this.confirmacao;
    usuarioFilter.senha = this.senha;
    usuarioFilter.senhaAtual = this.senhaAtual;

    return this.of(this.usuarioService.alterarSenha(usuarioFilter), () => {
      this.confirmacao = "";
      this.senha = "";
      this.senhaAtual = "";

      this.sucess = true;
    });
  }

}
