import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Pessoa} from "../../../classes/pessoa";
import {PessoaFilter} from "../filters/pessoa.filter";
import {PessoaSimplificadaTO} from "../../../classes/to/pessoa.simplificada.to";

@Injectable()
export class PessoaService {

  constructor(private restService: RestService){
  }

  alterarEmail(pessoaFilter: PessoaFilter): Observable<any> {
    return this.restService.post("/pessoa/alterarEmail", {data: pessoaFilter});
  }

  buscar(pessoaFilter: PessoaFilter): Observable<Pessoa> {
    return this.restService.post("/pessoa/buscar", {data: pessoaFilter, responseType: Pessoa});
  }

  buscarPorDocumentoEMunicipio(pessoaFilter: PessoaFilter): Observable<Pessoa> {
    return this.restService.post("/pessoa/buscarPorDocumentoEMunicipio", {data: pessoaFilter, responseType: Pessoa});
  }

  buscarPorDocumento(pessoaFilter: PessoaFilter): Observable<PessoaSimplificadaTO> {
    return this.restService.post("/pessoa/buscarPorDocumento", {data: pessoaFilter, responseType: PessoaSimplificadaTO});
  }

  buscarListaPorDocumentoOuNome(pessoaFilter: PessoaFilter): Observable<Pessoa[]> {
    return this.restService.post("/pessoa/buscarListaPorDocumentoOuNome", {data: pessoaFilter, responseType: Pessoa});
  }


  filtrar(pessoaFilter: PessoaFilter): Observable<Pessoa[]> {
    return this.restService.post("/pessoa/filtrar", {data: pessoaFilter, responseType: Pessoa});
  }

  validaSituacao(pessoaFilter: PessoaFilter): Observable<boolean> {
    return this.restService.post("/pessoa/validaSituacao", {data: pessoaFilter});
  }
}
