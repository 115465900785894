import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {ServicoEspecial} from "./servico.especial";
import {TipoDocumentoFiscalEnum} from "../enums/tipo.documento.fiscal.enum";
import {Currency} from "./currency";

@JacksonType("EscritEspecial")
@JsonIdentityInfo()
export class EscritEspecial extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  ano: number;

  @DateType()
  dataAlteracao: Date;

  documentoTomador: string;

  mes: number;

  nomeTomador: string;

  numeroDocumentoFiscalInicial: number;

  numeroDocumentoFiscalFinal: number;

  observacao: string;

  quantidade: number;

  @JacksonType("ServicoEspecial")
  servico: ServicoEspecial;

  @EnumType(TipoDocumentoFiscalEnum)
  tipoDocumentoFiscal: TipoDocumentoFiscalEnum;

  @JacksonType("Pessoa")
  tomador: Pessoa;

  valor: number;

  valorDesconto: number;

  valorTaxa: number;

  valorUnitario: number;

  valorUnitarioTaxa: number;

  //custom
  valorFaturadoFmt: string;


  //methods

  getValorFaturadoFmt(): string{
    let calculo: string = "";
    if(this.quantidade!=null){
      let currency: Currency =  new Currency();
      currency.value = this.valorUnitario;
      calculo = this.quantidade + " * " + currency.valueString;
      if(this.valorDesconto!=null){
        currency.value = this.valorDesconto;
        calculo += " - " + currency.valueString;
      }
      calculo += " = ";
    }
    return calculo + this.valorFaturadoFmt;
  }
}
