import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";

@JacksonType("TNCCadastroMigracaoPendente")
@JsonIdentityInfo()
export class TNCCadastroMigracaoPendente {

  id: number;

  cadastroNovo: boolean;

  @JacksonType("Pessoa")
  pessoa: Pessoa;
}
