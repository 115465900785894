import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Solicitacao} from "./solicitacao";

@JacksonType("SolicitacaoArquivo")
@JsonIdentityInfo()
export class SolicitacaoArquivo extends SonnerBaseEntity {

  id: number;

  bucketArquivo: string;

  keyArquivo: string;

  nomeArquivo: string;

  @JacksonType("Solicitacao")
  solicitacao: Solicitacao;
}
