import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {AppStateService} from "../../../app/services/app.state.service";
import {PerfilEnum} from "../../../../enums/perfil.enum";
import {Gestor} from "../../../../classes/gestor";
import {FiscalMenuFilter} from "../../filters/fiscal.menu.filter";
import {FiscalMenuTO} from "../../../../classes/to/fiscal.menu.to";
import {FiscalMenuService} from "../../services/fiscal.menu.service";

@Component({
  selector: 'fiscal-menu',
  templateUrl: './fiscal.menu.page.component.html',
  styleUrls: ['./fiscal.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiscalMenuPageComponent extends BaseComponent {

  constructor(private appStateService: AppStateService,
              private fiscalMenuService: FiscalMenuService,
              private injector: Injector) {
    super(injector);
  }

  admin: boolean = false;

  atividadeCNAE: boolean = false;

  banco_desif: boolean = false;

  bloqueioMov: boolean = false;

  cambui: boolean = false;

  chat: boolean = false;

  desconto_encerramento: boolean = false;

  encerramento_automatico: boolean = false;

  fiscal: boolean = false;

  fiscal_nfe_avulsa: boolean = false;

  fiscalMenuTO: FiscalMenuTO = new FiscalMenuTO();

  gestor: boolean = false;

  guia_relatorioPagamentoAbaixo: boolean = false;

  guia_valorFixo_emitir: boolean = false;

  idMunicipio: number;

  importEscritCartorio: boolean = false;

  fiscalizacao: boolean = false;

  jiraPass: string;

  jiraUser: string;

  jiraUsername: boolean = false;

  monitorFiscal: boolean = false;

  nfe_emitir: boolean = false;

  nfe_substituir: boolean = false;

  obras: boolean = false;

  pgdas_exportarDevedores: boolean = false;

  pgdas_import: boolean = false;

  prod: boolean = true;

  sqlInterativo: boolean = false;

  integracaoreceita: boolean = false;

  cancelaNotaLote: boolean = false;

  ngOnInit(): void {
    this.appStateService.userInfoSubject.subscribe(user => {
      if (user) {
        this.idMunicipio = user.pessoaUsuario.responsavel.id;

        if (user.perfis) {
          if (user.perfis.includes(PerfilEnum.fiscal)) {
            this.fiscal = true;
          }

          if(user.perfis.includes(PerfilEnum.administrador)){
            this.admin = true;
          }

          if (user.perfis.includes(PerfilEnum.gestor)) {
            this.gestor = true;

            if (user.pessoaSelecionada.papeis) {
              user.pessoaSelecionada.papeis.forEach(papel => {
                if (papel instanceof Gestor && papel.jiraUsername) {
                  this.jiraUsername = true;
                }
              })
            }
          }
        }

        if (user.authRegex.includes("iss:atividadeCNAE")) {
          this.atividadeCNAE = true;
        }

        if (user.authRegex.includes("iss:banco:desif")) {
          this.banco_desif = true;
        }

        if (user.authRegex.includes("iss:bloqueioMov")) {
          this.bloqueioMov = true;
        }

        if (user.authRegex.includes("iss:cambui")) {
          this.cambui = true;
        }

        if (user.authRegex.includes("iss:chat")) {
          this.chat = true;
        }

        if (user.authRegex.includes("iss:encerramento:automatico")) {
          this.encerramento_automatico = true;
        }

        if (user.authRegex.includes("iss:fiscal:nfe:avulsa")) {
          this.fiscal_nfe_avulsa = true;
        }

        if (user.authRegex.includes("iss:fiscalizacao")) {
          this.fiscalizacao = true;
        }

        if (user.authRegex.includes("iss:guia:relatorioPagamentoAbaixo")) {
          this.guia_relatorioPagamentoAbaixo = true;
        }

        if (user.authRegex.includes("iss:guia:valorFixo:emitir")) {
          this.guia_valorFixo_emitir = true;
        }

        if (user.authRegex.includes("iss:importEscritCartorio")) {
          this.importEscritCartorio = true;
        }

        if (user.authRegex.includes("iss:monitorFiscal")) {
          this.monitorFiscal = true;
        }

        if (user.authRegex.includes("iss:nfe:emitir")) {
          this.nfe_emitir = true;
        }

        if (user.authRegex.includes("iss:nfe:substituir")) {
          this.nfe_substituir = true;
        }

        if (user.authRegex.includes("iss:obras")) {
          this.obras = true;
        }

        if (user.authRegex.includes("iss:pgdas:exportarDevedores")) {
          this.pgdas_exportarDevedores = true;
        }

        if (user.authRegex.includes("iss:pgdas:import")) {
          this.pgdas_import = true;
        }

        if (user.authRegex.includes("iss:sqlInterativo")) {
          this.sqlInterativo = true;
        }

        if (user.authRegex.includes("iss:desconto:encerramento")) {
          this.desconto_encerramento = true;
        }

        if (user.authRegex.includes("iss:integracao:receita")) {
          this.integracaoreceita = true;
        }

        if (user.authRegex.includes("iss:cancela:nota:lote")) {
          this.cancelaNotaLote = true;
        }

        this.prod = user.producao;

        if(this.fiscal || this.admin || this.gestor){
          this.carrega();
        }
      }
    });
  }

  acionaAcessar() {
    setTimeout(() => window.open("https://govdigital.jira.com.br?os_username=" + this.jiraUser + "&os_password=" + this.jiraPass, '_blank'));
  }

  carrega() {
    let fiscalMenuFilter: FiscalMenuFilter = new FiscalMenuFilter();

    fiscalMenuFilter.idMunicipio = this.idMunicipio;

    return this.of(this.fiscalMenuService.buscar(fiscalMenuFilter), fiscalMenuTO => {
      this.fiscalMenuTO = fiscalMenuTO;

      this.disableLoading();
    });
  }

  desabilitaAcessar(): boolean {
    return !this.jiraUser || this.jiraUser.trim() == "" || !this.jiraPass || this.jiraPass.trim() == "";
  }
}
