import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ImportacaoPGDASD} from "./importacao.p.g.d.a.sd";

@JacksonType("ParcelamentoSimplesPagamento")
@JsonIdentityInfo()
export class ParcelamentoSimplesPagamento extends SonnerBaseEntity {

  id: number;

  cnpj: string;

  @JacksonType("ImportacaoPGDASD")
  importacao: ImportacaoPGDASD;

  numeroDas: string;

  parcelamento: number;

  @DateType()
  vencimento: Date;
}
