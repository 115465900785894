import {JacksonType} from "@sonner/jackson-service-v2";
import {CreditoTO} from "./credito.to";
import {DocumentoPipe} from "../../modules/core/pipes/documento.pipe";

@JacksonType("CreditosTO")
export class CreditosTO {

  id: number;

  nome: string;

  documento: string;

  @JacksonType("CreditoTO")
  creditoList: CreditoTO[] = [];

  mobiliario: string;

  nomeDoc(): string{
    return this.nome + " - " +   (new DocumentoPipe()).transform(this.documento);
  }
}
