import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {CompetenciaDimensao} from "./competencia.dimensao";
import {Municipio} from "./municipio";
import {MunicipioDimensao} from "./municipio.dimensao";
import {Pessoa} from "./pessoa";
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("CreditoAReceber")
@JsonIdentityInfo()
export class CreditoAReceber extends SonnerBaseEntity {

  id: number;

  @JacksonType("CompetenciaDimensao")
  competencia: CompetenciaDimensao;

  competenciaEncerrada: boolean;

  @JacksonType("Pessoa")
  contribuinteResponsavel: Pessoa;

  encerramentoCompleto: boolean;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("MunicipioDimensao")
  municipioIncidencia: MunicipioDimensao;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  @EnumType(TipoRegimeISSEnum)
  regimeContribuinte: TipoRegimeISSEnum;

  valorBrutoFaturamento: number;

  valorCreditos: number;

  valorDeducoesBaseCalculoEncerramento: number;

  valorDeducoesBaseCalculoNF: number;

  valorDescontoImpostoNF: number;

  valorTributo: number;

  valorTributoARecolher: number;

  valorTributoForaMunicipio: number;
}
