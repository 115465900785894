import {JacksonType, JsonIdentityInfo, SetType} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {TNCPropriedade} from "./t.n.c.propriedade";

@JacksonType("TNCGlobalConfig")
@JsonIdentityInfo()
export class TNCGlobalConfig extends SonnerBaseEntity {

  id: number;

  @JacksonType("TNCPropriedade")
  propriedades: TNCPropriedade[];
}
