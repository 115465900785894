import {JacksonType, SetType} from "@sonner/jackson-service-v2";
import {BloqueioMovimentacao} from "../bloqueio.movimentacao";
import {Comunicado} from "../comunicado";
import {EstatisticaAcessoTO} from "./estatistica.acesso.to";
import {Pessoa} from "../pessoa";
import {PessoaSimplificadaTO} from "./pessoa.simplificada.to";

@JacksonType("InicialMenuTO")
export class InicialMenuTO {

  alertaList: string[];

  aliquotaSimples: string;

  autorizacaoList: string[];

  @JacksonType("BloqueioMovimentacao")
  bloqueioList: BloqueioMovimentacao[];

  @JacksonType("Comunicado")
  comunicadoList: Comunicado[];

  @JacksonType("EstatisticaAcessoTO")
  estatisticaAcessoTOList: EstatisticaAcessoTO[];

  @JacksonType("Pessoa")
  pessoaList: Pessoa[];

  @JacksonType("PessoaSimplificadaTO")
  pessoaSimplificadaTOList: PessoaSimplificadaTO[] = [];

  @SetType()
  comunicadoLido: Set<number>;

  desenquadradoSimples: string;
}
