import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("FaixaValoresRecebidosSimplesNacionalTO")
export class FaixaValoresRecebidosSimplesNacionalTO {

  faixa: string;

  valor: number;

  ordem: number;

}
