import {Injectable} from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DateUtilsService {

  format(date: Date, pattern: string) {
    return moment(date.getTime()).format(pattern);
  }

  toMoment(date: Date) {
    return moment(date.getTime());
  }

  isValid(dateAsString: string, patternOftheDate: string): boolean {
    let m = moment(dateAsString, patternOftheDate);

    return m && m.isValid ? m.isValid() : false;
  }

  parse(dateAsString: string, patternOftheDate: string): Date {
    return moment(dateAsString, patternOftheDate).toDate();
  }

  dayOfYear(date: Date) {
    return this.toMoment(date).dayOfYear();
  }

  dayOfMonth(date: Date) {
    return this.toMoment(date).date();
  }

  dayOfWeek(date: Date) {
    return this.toMoment(date).day();
  }

  weekOfMonth(date: Date) {
    return this.toMoment(date).weekday();
  }

  weekOfYear(date: Date) {
    return this.toMoment(date).week();
  }

  quarter(date: Date) {
    return this.toMoment(date).quarter();
  }

  getDay(date: Date) {
    return this.toMoment(date).date();
  }

  getYear(date: Date) {
    return this.toMoment(date).year();
  }

  getMonth(date: Date) {
    return this.toMoment(date).month();
  }

  isBefore(date1: Date, date2: Date) {
    return this.toMoment(date1).isBefore(this.toMoment(date2));
  }

  isSame(date1: Date, date2: Date) {
    return this.toMoment(date1).isSame(this.toMoment(date2));
  }

  isAfter(dataAnterior: Date, date2: Date) {
    return this.toMoment(dataAnterior).isAfter(this.toMoment(date2));
  }

  isSameOrBefore(date1: Date, date2: Date) {
    return this.toMoment(date1).isSameOrBefore(this.toMoment(date2));
  }

  isSameOrAfter(date1: Date, date2: Date) {
    return this.toMoment(date1).isSameOrAfter(this.toMoment(date2));
  }

  isBetween(compareDate: Date, date1: Date, date2: Date) {
    return this.toMoment(compareDate).isBetween(this.toMoment(date1), this.toMoment(date2));
  }

  getDate(ano: number): Date {
    return new Date(ano, 0, 1, 0, 0, 0);
  }

  firstDayOfMonth(ano: number, mes: number): Date {
    return new Date(ano, mes, 1, 0, 0, 0);
  }

  isDate(target) {
    return target && target instanceof Date;
  }

  daysOfMonth(ano: number, mes: number): number {
    const data = new Date(ano, mes - 1, 1, 0, 0, 0);

    return moment(data).daysInMonth()
  }

  lastDayOfMonth(ano: number, mes: number): Date {
    const data = new Date(ano, mes, 1, 0, 0, 0);

    return new Date(ano, mes, moment(data).daysInMonth(), 0, 0, 0);
  }

  byMilliSeconds(timeInMillis: number) {
    return new Date(timeInMillis);
  }

  setHourMinuteSecond(date: Date, hour: number, minute: number, second: number): Date {
    const m = this.toMoment(date);
    m.set('hour', hour);
    m.set('minute', minute);
    m.set('second', second);

    return m.toDate();
  }

  clearTime(now: Date, inicio: boolean) {
    const m = this.toMoment(now);

    if (inicio) {
      m.set('hour', 0);
      m.set('minute', 0);
      m.set('second', 0);
      m.set('millisecond', 0);
    } else {
      m.set('hour', 23);
      m.set('minute', 59);
      m.set('second', 59);
      m.set('millisecond', 999);
    }

    return m.toDate();
  }

  addMinutes(date: Date, numMinutesToAdd: number) {
    return moment(date).add(numMinutesToAdd, 'minutes').toDate();
  }

  addHours(date: Date, numHoursToAdd: number) {
    return moment(date).add(numHoursToAdd, 'hours').toDate();
  }

  addDays(date: Date, numDaysToAdd: number) {
    return moment(date).add(numDaysToAdd, 'day').toDate();
  }

  addMonth(date: Date, numMonthToAdd: number) {
    return moment(date).add(numMonthToAdd, 'month').toDate();
  }

  lastDayOfYear(year: number): Date {
    return moment("31-12-" + year || this.getYear(new Date()), "DD-MM-YYYY").toDate();
  }

  diffBetweenTwoDates(start: Date, end: Date) {
    return moment.duration(moment(end).diff(moment(start)));
  }

  diffBetweenTwoDatesAsDays(start: Date, end: Date) {
    return moment.duration(moment(end).diff(moment(start))).asDays();
  }

  diffBetweenTwoDatesAsMonth(start: Date, end: Date) {
    return moment.duration(moment(end).diff(start)).asMonths();
  }

  modifyDate(date: Date, option: { day?: number, month?: number, year?: number }) {
    if (!option.day) {
      option.day = this.getDay(date);
    }

    if (!option.month) {
      option.month = this.getMonth(date);
    }

    if (!option.year) {
      option.year = this.getYear(date);
    }

    return new Date(option.year, option.month, option.day);
  }

  anosString(){
    let data = this.addMonth(new Date(),2);
    let result : string[] = [];
    let ano : number = data.getFullYear();

    for (let i = 0; i < 10; i++) {
      result.push((ano - i)+'');
    }

    return result;
  }

  mesesAnosString(): string[]{
    let result: string[] = [];
    let inicio = new Date(2006,0,1);
    this.clearTime(inicio,true);

    let fim = this.addMonth(new Date(),2);
    fim = this.firstDayOfMonth(fim.getFullYear(),fim.getMonth());

    for(var i =0;this.diffBetweenTwoDatesAsDays(inicio,fim)>=0;i++){
      result.push(this.getMesAno(fim.getMonth(), fim.getFullYear()));
      fim= this.addMonth(fim,-1);
    }
    return result;
  }

  getMesString(mes: number) : string{
    switch (mes) {
      case 0:
        return "Janeiro";
      case 1:
        return "Fevereiro";
      case 2:
        return "Março";
      case 3:
        return "Abril";
      case 4:
        return "Maio";
      case 5:
        return "Junho";
      case 6:
        return "Julho";
      case 7:
        return "Agosto";
      case 8:
        return "Setembro";
      case 9:
        return "Outubro";
      case 10:
        return "Novembro";
      case 11:
        return "Dezembro";
      default:
        return "";
    }
  }

  getMesNumber(mes: string) : number {
    if (mes) {
      switch (mes) {
        case "Janeiro":
          return 0;
        case "Fevereiro":
          return 1;
        case "Março":
          return 2;
        case "Abril":
          return 3;
        case "Maio":
          return 4;
        case "Junho":
          return 5;
        case "Julho":
          return 6;
        case "Agosto":
          return 7;
        case "Setembro":
          return 8;
        case "Outubro":
          return 9;
        case "Novembro":
          return 10;
        case "Dezembro":
          return 11;
        default:
          return -1;
      }
    }
    return -1;
  }

  getMesAno(mes:number, ano: number){
    return this.getMesString(mes) + " / " + ano;
  }

}
