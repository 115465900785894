import {JacksonType} from "@sonner/jackson-service-v2";
import {ParcelamentoSimples} from "../parcelamento.simples";
import {ParcelamentoSimplesPagamento} from "../parcelamento.simples.pagamento";
import {ParcelamentoConsolidacaoTO} from "./parcelamento.consolidacao.to";
import {DateUtilsService} from "../../modules/core/services/date.utils.service";

@JacksonType("SaldoCompetenciaTO")
export class SaldoCompetenciaTO{

  ano: number;

  mes: number;

  encerradoPrestador: boolean;

  valorDevidoPrestador: number;

  valorPagoPrestador: number;

  existeMovimentacaoPrestador: boolean;

  existeParcelamentoAtivo: boolean;

  parcelamentoEmDia: boolean;

  encerradoTomador: boolean;

  valorDevidoTomador: number;

  valorPagoTomador: number;

  existeMovimentacaoTomador: number;

  idContribuinte: number;

  getMesAnoFmt(): string {
    let dateUtilsService: DateUtilsService = new DateUtilsService();
    const mesFmt:string  = (this.mes == null) ? "" : dateUtilsService.getMesString(this.mes);
    const anoFmt: string = (this.ano == null) ? "" : this.ano.toString();
    return mesFmt + "/" + anoFmt;
  }

}
