export class Currency {

  get valueString() {
    return this._valueString;
  }

  set valueString(numeroString) {
    this._valueString = numeroString;

    let novaString = numeroString.replace(/\./g, "");
    let split = novaString.split(",");

    if (split[1]) {
      this._value = Number(split[0]) + (Number(split[1]) / 100);
    } else {
      this._value = Number(split[0]);
    }
  }

  get value() {
    return this._value;
  }

  set value(numero) {
    this._value = numero;

    let valueString = new Intl.NumberFormat('pt-BR').format(numero);

    if (valueString.search(",") > 0) {
      if (valueString.search(",") == valueString.length - 3) {
        this._valueString = valueString;
      } else {
        this._valueString = valueString + "0";
      }
    } else {
      this._valueString = valueString + ",00";
    }
  }

  constructor() {
    this.valueString = "";
  }

  private _value: number;

  private _valueString: string;
}
