import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("FuncionarioEmpresas")
@JsonIdentityInfo()
export class FuncionarioEmpresas extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  empregador: Pessoa;

  @JacksonType("Pessoa")
  funcionario: Pessoa;
}
