import {JacksonType} from "@sonner/jackson-service-v2";
import {BilheteNotaPremiadaContribuinteDetalheTO} from "./bilhete.nota.premiada.contribuinte.detalhe.to";

@JacksonType("BilheteNotaPremiadaContribuinteTO")
export class BilheteNotaPremiadaContribuinteTO {

  @JacksonType("BilheteNotaPremiadaContribuinteDetalheTO")
  detalhes:BilheteNotaPremiadaContribuinteDetalheTO[];

}
