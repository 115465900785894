import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {PGDASDApuracaoFilial} from "./p.g.d.a.s.d.apuracao.filial";
import {PGDASDApuracaoFilialAtividadeDetalhe} from "./p.g.d.a.s.d.apuracao.filial.atividade.detalhe";

@JacksonType("PGDASDApuracaoFilialAtividade")
@JsonIdentityInfo()
export class PGDASDApuracaoFilialAtividade extends SonnerBaseEntity {

  id: number;

  @JacksonType("PGDASDApuracaoFilial")
  apuracaoFilial: PGDASDApuracaoFilial;

  valorTotal: number;

  @JacksonType("PGDASDApuracaoFilialAtividadeDetalhe")
  detalhes: PGDASDApuracaoFilialAtividadeDetalhe[];
}
