import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ImportacaoPGDASD} from "./importacao.p.g.d.a.sd";
import {ParcelamentoSimples} from "./parcelamento.simples";

@JacksonType("ParcelamentoSimplesConsolidacao")
@JsonIdentityInfo()
export class ParcelamentoSimplesConsolidacao extends SonnerBaseEntity {

  id: number;

  @DateType()
  dataConsolidacao: Date;

  @JacksonType("ImportacaoPGDASD")
  importacao: ImportacaoPGDASD;

  @JacksonType("ParcelamentoSimples")
  parcelamento: ParcelamentoSimples;

  quantidadeParcelas: number;

  valorConsolidado: number;

  valorParcela: number;
}
