import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoLogradouroEnum} from "../enums/tipo.logradouro.enum";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("Logradouro")
@JsonIdentityInfo()
export class Logradouro extends SonnerBaseEntity implements ValueDisplayable{

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  @EnumType(TipoLogradouroEnum)
  tipo: TipoLogradouroEnum = TipoLogradouroEnum.rua;

  getDisplayValue(): string {
    let tp: string = "";
    if(this.tipo!=null){
      tp += this.tipo
    }
    return (tp + " " + this.nome).trim();
  }


}
