import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Banco} from "./banco";
import {ContaCosif} from "./conta.cosif";

@JacksonType("ServicoBancario")
@JsonIdentityInfo()
export class ServicoBancario extends SonnerBaseEntity {

  id: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  ativo: boolean;

  @JacksonType("Banco")
  banco: Banco;

  @JacksonType("ContaCosif")
  contaCosif: ContaCosif;

  nome: string;

  rubrica: string;
}
