import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Feriado} from "../../../classes/feriado";
import {FeriadoFilter} from "../filters/feriado.filter";

@Injectable()
export class FeriadoService {

  constructor(private restService: RestService) {
  }

  buscar(feriadoFilter: FeriadoFilter): Observable<Feriado> {
    return this.restService.post("/feriado/buscar", {data: feriadoFilter, responseType: Feriado});
  }

  buscarListaPorAnoEMunicipio(feriadoFilter: FeriadoFilter): Observable<Feriado[]> {
    return this.restService.post("/feriado/buscarListaPorAnoEMunicipio", {data: feriadoFilter, responseType: Feriado});
  }

  buscarListaPorMunicipio(feriadoFilter: FeriadoFilter): Observable<Feriado[]> {
    return this.restService.post("/feriado/buscarListaPorMunicipio", {data: feriadoFilter, responseType: Feriado});
  }

  duplicar(feriadoFilter: FeriadoFilter): Observable<any> {
    return this.restService.post("/feriado/duplicar", {data: feriadoFilter});
  }

  remover(feriadoFilter: FeriadoFilter): Observable<any> {
    return this.restService.post("/feriado/remover", {data: feriadoFilter});
  }

  salvar(feriado: Feriado): Observable<any> {
    return this.restService.post("/feriado/salvar", {data: feriado});
  }
}
