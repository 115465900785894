import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {EscritPapel} from "./escrit.papel";

@JacksonType("RelacaoNotaEscrit")
@JsonIdentityInfo()
export class RelacaoNotaEscrit extends SonnerBaseEntity {

  id: number;

  @DateType()
  dataPrestada: Date;

  @DateType()
  dataTomada: Date;

  @JacksonType("EscritPapel")
  escritPrestada: EscritPapel;

  @JacksonType("EscritPapel")
  escritTomada: EscritPapel;

  relacaoAutomatica: boolean;

  @JacksonType("Municipio")
  responsavel: Municipio;
}
