export enum TipoRelatorioEnum {

  EMPRESA_EMISSAO_BLOQ,
  EMPRESA_SEM_EMISSAO,
  LIVRO_ESCRITURACAO,
  FATURAMENTO_EMPRESA_MEI,
  EMPRESA_NOTA_ALIQUOTA_ZERO,
  MOVIMENTACAO_FISCAIS,
  NFE_FORA_MUNICIPIO,
  CONTRIBUINTE_SEM_ENCERRAMENTO_POR_CONTADOR,
  RELATORIO_RECEITAS,
  EXPORTACAO_TRE,
  EXPORTACAO_GOV,
  NOTAS_FISCAIS_TOMADOR_N_IDENTIFICADO,
  CONTRIBUINTES_AUTORIZADOS_EMITIR_NFE_SEM_TOMADOR_IDENTIFICADO,
  MOVIMENTACAO_MUNICIPIO,
  BASE_CALCULO_SIMPLES_NACIONAL,
  GUIAS_POR_PAPEL,
  NOTAS_CANCELADAS_POR_CONTRIBUINTE,
  EMPRESAS_POR_CONTADOR,
  CONTADORES_FISCAIS,
  ISS_RETIDO_MESMO_MUNICIPIO,
  RECEITA_SERVICOS,
  PLANO_CONTAS,
  CONSULTA_GERAL_CONTRIB,
  AUTORIZACOES_AIDF,
  NOTAS_POR_ATIVIDADE,
  PESSOAS_POR_BAIRRO,
  MAIORES_EMISSORES_NFE,
  ADESAO_SISTEMA,
  GERENCIAL_PRESTADORES,
  IMPOSTOS_FEDERAIS,
  INSTITUICAO_BANCARIA_DESIF,
  TOMADOS_FORA_MUNICIPIO,
  ACESSOS,
  CONTRIBUINTES_GUIA_AVULSO,
  RELACAO_NOTA_ESCRIT,
  GUIA_PAGA_VALOR_ABAIXO,
  MAIORES_CONTRIBUINTES,
  PESSOAS_ENCERRAMENTO,
  CADASTRO_MOBILIARIO,
  AGENDAMENTO_FISCAL,
  CONVERSA_CANAL_COMUNICACAO,
  ESCRITPAPEL_FORA_MUNICIPIO,
  COMUNICADOS,
  PRESTADORES_AUTORIZADOS_NFE,
  EMPRESA_POR_REGIME_ISS,
  MONITOR_ALERTA_FISCAL,
  RELATORIO_EXTRATO_GERAL_ISS,
  ALIQUOTA_SIMPLES_INCORRETA_ANALISE,
  ENCERRAMENTO_NOTAS_VALOR_PAGO,
  RECOLHIMENTO_POR_ATIVIDADE,
  ENCERRAMENTO_NOTAS_ALIQ_INCORRETA,
  CONTRIBUINTES_SIMPLES_SEM_CADASTRO_MUNICIPIO,
  MAIORES_DEVEDORES,
  DESCONTO_POR_CONTRIBUINTE,
  DESIF_APURACAO_MENSAL_POR_ATIVIDADE,
  DESIF_RELACAO_PGCC_COSIF,
  DESIF_COMPARE_APURACAO_BALANCETE,
  DESIF_APURACAO_ISS_DETALHADA,
  DESIF_DEMONSTRATIVO_PARTIDAS,
  DESIF_CONTROLE_CUMPRIMENTO_MODULO_1,
  DESIF_CONTROLE_CUMPRIMENTO_MODULO_2,
  DESIF_CONTROLE_CUMPRIMENTO_MODULO_3,
  DESIF_CONTROLE_CUMPRIMENTO_MODULO_4,
  RELACAO_NOTAS,
  RELATORIO_ALUNO,
  RELACAO_ESCRIT_NOTAS,
  MAIORES_TOMADORES,
  MONITOR_INTEGRACAO

}
