import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {PessoaSimplificadaTO} from "./pessoa.simplificada.to";

@JacksonType("SolicitacaoImportacaoNFETO")
export class SolicitacaoImportacaoNFETO {

  id: string;

  @JacksonType("PessoaSimplificadaTO")
  graficas: PessoaSimplificadaTO[];

  dizeres: string;

  @DateType()
  vencimento: Date;

  obrigaControlaNumeracaoRPS: boolean;

  numeracaoRPSControlada: boolean;

  graficaLivre: boolean;

  graficaSelecionada: number;

  nomeGrafica: string;

  numeroFinal: number;

  numeroInicial: number;

  quantidadePorBloco: number = 25;

  vias: number;

  observacao: string;

}
