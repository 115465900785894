import {EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";
import {TipoCredito} from "./tipo.credito";

@JacksonType("Credito")
@JsonSubType({key: 'br.com.sonner.iss.entity.Credito', type: "Documento"})
export class Credito extends Documento {

  ano: number;

  mes: number;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  obs: string;

  @JacksonType("TipoCredito")
  tipoCredito: TipoCredito;

  valor: number;

  constructor() {
    super(TipoDocumentoEnum.CREDITO);
  }

  competencia() {
    return (this.mes + 1) + "/" + this.ano;
  }
}
