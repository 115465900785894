import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("TabelaSimples")
@JsonIdentityInfo()
export class TabelaSimples extends SonnerBaseEntity {

  id: number;

  anexo: number;

  descricao: string;

  @DateType()
  fimVigencia: Date;

  @DateType()
  inicioVigencia: Date;
}
