import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {AutorizacaoImportacaoNFe} from "./autorizacao.importacao.n.fe";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {GuiaAvulsa} from "./guia.avulsa";

@JacksonType("AutorizacaoImportacaoNFeGuia")
@JsonIdentityInfo()
export class AutorizacaoImportacaoNFeGuia extends SonnerBaseEntity {

  id: number;

  @JacksonType("AutorizacaoImportacaoNFe")
  autorizacao: AutorizacaoImportacaoNFe;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;
}
