import {JacksonType} from "@sonner/jackson-service-v2";
import {Serie} from "../serie";

@JacksonType("ConfiguracaoSerieTO")
export class ConfiguracaoSerieTO {

  avulsa: string;

  idMunicipio: number;

  padrao: string;

  @JacksonType("Serie")
  serieList: Serie[] = [];
}
