import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {TipoAlteracaoNFEnum} from "../../enums/tipo.alteracao.nf.enum";

@JacksonType("ConsultaNotaFiscalHistoricoTO")
export class ConsultaNotaFiscalHistoricoTO {

  @DateType()
  dataPrestacao: Date;

  numero: number;

  prestador: string;

  senha: string;

  @EnumType(TipoAlteracaoNFEnum)
  tipoAlteracaoNF: TipoAlteracaoNFEnum;

  tomador: string;

  valor: number;
}
