import {EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {CalculoEncerramento} from "./calculo.encerramento";
import {Documento} from "./documento";
import {EncerramentoAutomatico} from "./encerramento.automatico";
import {GuiaAvulsa} from './guia.avulsa';
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {PerfilDescontoBaseCalculo} from "./perfil.desconto.base.calculo";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";
import {TipoEncerramentoEnum} from "../enums/tipo.encerramento.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("Encerramento")
@JsonSubType({key: 'br.com.sonner.iss.entity.Encerramento', type: "Documento"})
export class Encerramento extends Documento {

  ano: number;

  @JacksonType("CalculoEncerramento")
  calculoEncerramento: CalculoEncerramento;

  @JacksonType("EncerramentoAutomatico")
  encerramentoAutomatico: EncerramentoAutomatico;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  mes: number;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  @JacksonType("PerfilDescontoBaseCalculo")
  perfilDesconto: PerfilDescontoBaseCalculo;

  @EnumType(TipoEncerramentoEnum)
  tipo: TipoEncerramentoEnum;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;

  webIssDeS: string;

  webIssValorRetido: number;

  constructor() {
    super(TipoDocumentoEnum.ENCERRAMENTO);
  }
}
