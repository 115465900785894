import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {EscritPapel} from "./escrit.papel";
import {EscrituracaoObraUnica} from "./escrituracao.obra.unica";
import {Municipio} from "./municipio";
import {NotaFiscal} from "./nota.fiscal";
import {Pessoa} from "./pessoa";
import {TipoAbatimentoObra} from "./tipo.abatimento.obra";
import {SituacaoObraUnicaEnum} from "../enums/situacao.obra.unica.enum";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {Estado} from "./estado";
import {RelacaoObraNotaEscrit} from "./relacao.obra.nota.escrit";

@JacksonType("ObraUnica")
@JsonIdentityInfo()
export class ObraUnica extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  alvara: string;

  bairro: string;

  cep: string;

  complemento: string;

  @DateType()
  dataInicio: Date;

  descricao: string;

  documentoProprietario: string;

  @DateType()
  emissao: Date;

  @JacksonType("EscritPapel")
  escritPapel: EscritPapel;

  @JacksonType("Estado")
  estado: Estado;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  logradouro: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  nomeProprietario: string;

  @JacksonType("NotaFiscal")
  nota: NotaFiscal;

  numero: string;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  @EnumType(SituacaoObraUnicaEnum)
  situacao: SituacaoObraUnicaEnum = SituacaoObraUnicaEnum.SemAnalise;

  @DateType()
  ultimaAlteracao: Date;

  @JacksonType("TipoAbatimentoObra")
  tipoAbatimentoObra: TipoAbatimentoObra;

  @JacksonType("EscrituracaoObraUnica")
  escrituracoes: EscrituracaoObraUnica[];


  @JacksonType("RelacaoObraNotaEscrit")
  notasEscrits: RelacaoObraNotaEscrit[];

  saldo: number;

  // aux

  totalParaAbatimento: number;

  getDisplayValue(): string {
    return this.id + " - " + this.descricao;
  }
}
