import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Municipio} from "./municipio";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("AtividadeVencimento")
@JsonIdentityInfo()
export class AtividadeVencimento extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @DateType()
  dataVencimento: Date;

  mes: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;
}
