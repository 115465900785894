import {Component, Injector, Input, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {RelatorioAssincronoService} from "../../services/relatorio.assincrono.service";
import {RelatorioAssincrono} from "../../../../classes/relatorio.assincrono";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";

@Component({
  selector: 'report-list',
  templateUrl: './report.list.component.html',
  styleUrls: ['./report.list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportListComponent extends BaseComponent {

  @Input()
  tipoRelatorio: string;

  @Input()
  gridTitle: string = "Relatórios Agendados";

  quantidade: number = 10;

  relatorioAssincronoList: RelatorioAssincrono[];

  constructor(private relatorioAssincronoService: RelatorioAssincronoService,
              private injector: Injector) {
    super(injector);
  }

  doOnInit() {
    this.carrega();
  }


  @GlobalLoadingIndicator()
  carrega() {
    if(!this.quantidade){
      this.quantidade = 10;
    }
    if(this.tipoRelatorio){
      return this.of(this.relatorioAssincronoService.busca(this.tipoRelatorio, this.quantidade), relatorios => {
        if (relatorios) {
          this.relatorioAssincronoList = relatorios.relatorios
        }
      });
    }
  }

}
