import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {IBPTTabela} from "./i.b.p.t.tabela";

@JacksonType("IBPTArquivo")
@JsonIdentityInfo()
export class IBPTArquivo extends SonnerBaseEntity {

  id: number;

  bucket: string;

  @DateType()
  fimProcessamento: Date;

  importadoComSucesso: boolean = false;

  @DateType()
  inicioProcessamento: Date;

  keyName: string;

  nomeArquivo: string;

  @JacksonType("IBPTTabela")
  tabela: IBPTTabela;

  versao: string;
}
