import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("SessionFilter")
export class SessionFilter {

  acao: string;

  idPessoa: number;

  query: string;
}
