import {Component, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import * as _ from 'lodash';
import {DxSelectBoxComponent} from "devextreme-angular";
import {BaseComponent} from "../base/base.component";
import {EnumUtils} from "../../../core/utils/enum.utils";
import {ObjectUtilsService} from "../../../core/services/object.utils.service";

@Component({
  selector: "select-box-enum-based",
  templateUrl: './select.box.enum.based.component.html',
  styleUrls: ['./select.box.enum.based.component.scss']
})
export class SelectBoxEnumBasedComponent extends BaseComponent {

  @ViewChild("selectBox", {static: true})
  selectBox: DxSelectBoxComponent;

  @Input()
  disabled: boolean = false;

  @Input()
  items: any[];

  @Input()
  set selectFirst(value: any) {
    this._selectFirst = value;
    if (value) {
      this.doSelectFist();
    }
  }

  get selectFirst(): any {
    return this._selectFirst;
  }

  @Input()
  set selectedItem(value: any) {
    if (this._selectedItem != value) {
      this._selectedItem = value;
      this.selectedItemChange.emit(value);
    }
  }

  get selectedItem(): any {
    return this._selectedItem;
  }

  @Input()
  placeholder: string = 'Selecione...';

  @Input()
  readOnly: boolean = false;

  @Input()
  searchEnabled: boolean = true;

  @Input()
  showClearButton: boolean;

  @Input()
  sortByString: boolean = true;

  @Input()
  set type(value: any) {
    this._type = value;
    this.loadItems();
  }

  get type(): any {
    return this._type;
  }

  @Input()
  validationGroup: string;

  @Input()
  visibled: boolean = true;

  @Input()
  width: string = "100%";

  @Output()
  selectedItemChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private objectUtilsService: ObjectUtilsService,
              private injector: Injector) {
    super(injector);

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  private _selectFirst = false;

  private _selectedItem: any;

  private _type: any;

  clearSelectedItem() {
    this.selectedItem = null;
  }

  doAfterViewInit() {
    if (this.selectFirst) {
      this.doSelectFist();
    }
  }

  doSelectFist() {
    if (!this.objectUtilsService.isEmpty(this.items)) {
      this.selectedItem = this.items[0];
    }
  }

  focus() {
    this.selectBox.instance.focus();
  }

  loadItems() {
    if (this.type) {
      if (_.isEmpty(this.items)) {
        this.items = EnumUtils.values(this.type);
        setTimeout(()=>{
          if (this.sortByString) {
            this.items = this.items.sort();
          }
        })
      }
    }
  }

  onSelectionChanged(event: any) {
    const data = event && event.selectedItem ? EnumUtils.valueOf(this.type, event.selectedItem) : undefined;
    this.selectedItem = data;
  }
}
