import {JacksonType} from "@sonner/jackson-service-v2";
import {SqlInterativoTitle} from "./sql.interativo.title";
import {SqlInterativoRow} from "./sql.interativo.row";

@JacksonType("SqlInterativoTO")
export class SqlInterativoTO {

  retorno: String;

  @JacksonType("SqlInterativoTitle")
  titulo: SqlInterativoTitle;

  @JacksonType("SqlInterativoRow")
  linhas: SqlInterativoRow[];

}
