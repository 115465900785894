import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Indice} from "./indice";
import {Pessoa} from "./pessoa";

@JacksonType("Cotacao")
@JsonIdentityInfo()
export class Cotacao extends SonnerBaseEntity {

  id: number;

  @DateType()
  dia: Date;

  @JacksonType("Indice")
  indice: Indice;

  valor: number;

  @DateType()
  cadastro: Date;

  @JacksonType("Pessoa")
  usuario: Pessoa;

}
