import {JacksonType} from "@sonner/jackson-service-v2";
import {Curso} from "../curso";

@JacksonType("CursoTO")
export class CursoTO {

  @JacksonType("Curso")
  curso: Curso;

  valorOld: number;
}
