import {debounceTime} from "rxjs/operators";
import {Component, ContentChild, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import {DxDataGridComponent, DxTextBoxComponent, DxValidatorComponent} from "devextreme-angular";
import {Subject, Subscription} from "rxjs";
import {BaseComponent} from "../base/base.component";
import {AlertService} from "../../services/alert.service";
import {DomHandlerService} from "../../services/dom.handler.service";
import {ObjectUtilsService} from "../../../core/services/object.utils.service";

let idGen = 0;

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent extends BaseComponent {

  @ContentChild(DxDataGridComponent, {static: true})
  dataGrid: DxDataGridComponent;

  @ViewChild("textBox", {static: true})
  textBox: DxTextBoxComponent;

  @ViewChild("validatorAutoComplete", {static: true})
  validator: DxValidatorComponent;

  @Input()
  debounceTime: number = 500;

  @Input()
  disabled: boolean;

  @Input()
  displayProp: string;

  @Input()
  confirmNewTypeMessage: string;

  get items() {
    return this._items;
  }

  @Input()
  set items(value) {
    if (this._items != value) {
      this._items = value;

      if (this.dataGrid) {
        if (this.opened) {
          this.dataGrid.dataSource = value;
        } else {
          this.dataGrid.dataSource = [];
        }
        if (this.dataGrid.instance && !!this.dataGrid.instance.deselectAll) {
          this.dataGrid.instance.deselectAll();
        }
        this.selectedRow = null;
      }

      this.mapItems = new Map();

      if ((value && value.length > 0 && this.query && this.query != "")) {
        value.forEach(item => this.mapItems.set(item[this.valueProp], item));
        this.opened = true;
      } else {
        this.opened = false;
      }
    }

    this.selectFirstElementGrid();
  }

  @Input()
  mask: string;

  @Input()
  newType: Function;

  @Input()
  newTypeQueryProp: string;

  @Input()
  opened: boolean = false;

  @Input()
  placeholder: string;

  @Input()
  queryProp: string;

  @Input()
  readOnly: boolean;

  @Input()
  selectedFirstByDefault: boolean = true;

  @Input()
  showClearButton: boolean = true;

  @Input()
  upperCase: boolean = false;

  get value() {
    return this._value;
  }

  @Input()
  set value(value) {
    if (!this.objectUtils.isEqual(this._value, value) && (!value || !this._value || !value.id || !this._value.id || this._getDisplayProp(value) !== this._getDisplayProp(this._value))) {
      if (value) {
        this._value = value;
        if (this.queryProp) {
          this._query = value[this.queryProp];
        } else {
          this._query = this._getDisplayProp(value);
        }
      } else {
        this._value = null;
        this._query = "";

        if (this.dataGrid) {
          this.dataGrid.selectedRowKeys = [];
        }
      }

      setTimeout(() => this.cursorSelectionEnd());
    }
  }

  @Input()
  valueProp: string = "id";

  @Input()
  visible: boolean = true;

  @Output()
  onQueryChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private alertService: AlertService,
              private domHandlerService: DomHandlerService,
              private injector: Injector,
              private objectUtils: ObjectUtilsService) {
    super(injector);

    this.width = this.width.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onPopupVisible = this.onPopupVisible.bind(this);
  }

  private gridContentReadySubscription: Subscription;

  private _items: any;

  private keyUpSubscription: Subscription;

  private mapItems: Map<any, any> = new Map();

  private _query: string;

  get query(): string {
    return this._query;
  }

  set query(value: string) {
    this._query = value;
  }

  private querySubject: Subject<string> = new Subject<string>();

  private selectedRow: number = null;

  textFieldId: string = `autocompleteTextField-${idGen++}`;

  private _value: any;

  doAfterContentInit(): void {
    if (!this.dataGrid) {
      this.alertService.showError("DataGrid não encontrado!");

      return;
    }

    if (this._items) {
      this.dataGrid.dataSource = this._items;
    }

    this.dataGrid.hoverStateEnabled = true;

    this.dataGrid.selection = {mode: 'single'};

    const $ = this.domHandlerService.jQuery;

    this.dataGrid.onRowPrepared.subscribe(row => {
      if (row.rowType != "header") {
        let that = this;

        $(row.rowElement).click(function (e) {
          setTimeout(() => {
            that.value = row.data;
            that._notifyValue();
            that.opened = false;
          });
        });
      }
    });

    this.dataGrid.onSelectionChanged.subscribe(options => {
      let scrollable = options.component.getView('rowsView')._scrollable;
      let selectedRowElements = this.domHandlerService.jQuery(this.dataGrid.instance.element()).find('tr.dx-selection');

      if (scrollable) {
        scrollable.scrollToElement(selectedRowElements[0]);
      }
    });
  }

  doOnDestroy(): void {
    if (this.keyUpSubscription) {
      this.keyUpSubscription.unsubscribe();
    }

    if (this.gridContentReadySubscription) {
      this.gridContentReadySubscription.unsubscribe();
    }
  }

  doOnInit(): void {
    this.keyUpSubscription = this.querySubject.pipe(debounceTime(this.debounceTime)).subscribe(value => {
      this.onQueryChanged.emit(value);
    });
  }

  private createNewValue(q) {
    let newSelected = new (this.newType as any)();

    newSelected[this.newTypeQueryProp] = q;
    this.value = newSelected;
    this._notifyValue();
  }

  private _notifyValue() {
    this.valueChange.emit(this._value);
  }

  private _selectRowIndex(absoluteRowIndex) {
    if (absoluteRowIndex >= 0) {
      let gridInstance = this.dataGrid.instance;

      if (gridInstance) {
        let pgSize = gridInstance.pageSize();
        let pageIndex = Math.floor(absoluteRowIndex / pgSize);
        let visibleRowIndex = absoluteRowIndex - (pageIndex * pgSize);

        if (pageIndex !== gridInstance.pageIndex()) {
          gridInstance.pageIndex(pageIndex);
        }

        setTimeout(() => gridInstance.selectRowsByIndexes([visibleRowIndex]), 100);
      }
    }
  }

  private _getDisplayProp(origem: any): string {
    if (this.displayProp) {
      let prop = origem[this.displayProp];

      if (prop instanceof Function) {
        prop = prop.bind(origem);

        return prop();
      } else {
        if (prop) {
          return prop.toString();
        } else {
          return "";
        }
      }
    }

    return origem.toString();
  }

  cursorSelectionEnd() {
    let element = this.textBox.instance.element().getElementsByClassName("dx-texteditor-input");
    const maskedText: string = this.textBox.text;
    const maskedChar: string = this.textBox.maskChar;

    if (maskedText) {
      let focusIndex: number = maskedText.indexOf(maskedChar);

      this.textBox.instance.focus();

      setTimeout(() => this.domHandlerService.setSelectionRange(element, focusIndex, focusIndex));
    }
  }

  onKeyDown(event: any) {
    let q = event.component && event.component['_value'] ? event.component['_value'] :
      this.domHandlerService.jQuery(this.textBox.instance.element()).find('input').val();

    if (event && event.event && event.event.key == 'Escape') {
      if (event.event.key == 'Escape' || (!this.newType && !this.newTypeQueryProp)) {
        if (this.value) {
          this._query = this._getDisplayProp(this.value);

        } else {

          this._query = "";
        }
      } else if (this.newType && this.newTypeQueryProp) {
        this.createNewValue(q);

      }
      this.opened = false;
      this.dataGrid.dataSource = [];
      return;
    }

    if (event && event.event && q && q != "" && (event.event.key == 'Tab' || event.event.key == 'Enter')) {
      // event.event.preventDefault();
      if (this.opened && this.selectedRow !== null) {
        setTimeout(() => {
          this.value = this._items[this.selectedRow];
          this._notifyValue();
          this.opened = false;
        });
      } else if (!this.selectedRow && this.newType && this.newTypeQueryProp) {
        if (this.confirmNewTypeMessage) {
          this.alertService.confirm(this.confirmNewTypeMessage, "Novo Registro").then(resp => {
            if (resp) {
              setTimeout(() => {
                this.createNewValue(q);
                this.opened = false;
              });
            }
          });
        } else {
          setTimeout(() => {
            this.createNewValue(q);
            this.opened = false;
          });
        }
      }
      return;
    }

    if (event && event.event && event.event.key == 'ArrowDown' && q && q != "" && this.opened) {
      event.event.preventDefault();
      if (!this._items || this._items.length == 0) {
        this.selectedRow = null;
      } else if (this.selectedRow === null || this.selectedRow >= this._items.length - 1) {
        this.selectedRow = 0;
      } else {
        this.selectedRow = this.selectedRow + 1;
      }

      if (this.selectedRow !== null) {
        this._selectRowIndex(this.selectedRow);
      } else {
        this.dataGrid.instance.deselectAll();
      }
      return;
    }

    if (event && event.event && event.event.key == 'ArrowUp' && q && q != "" && this.opened) {
      event.event.preventDefault();
      if (!this._items || this._items.length == 0) {
        this.selectedRow = null;
      } else if (this.selectedRow === null || this.selectedRow === 0) {
        this.selectedRow = this._items.length - 1;
      } else {
        this.selectedRow = this.selectedRow - 1;
      }

      if (this.selectedRow !== null) {
        this._selectRowIndex(this.selectedRow);
      } else {
        this.dataGrid.instance.deselectAll();
      }
      return;
    }
  }

  onPopupVisible() {
    this.selectFirstElementGrid();

    if (this.dataGrid) {
      this.dataGrid.dataSource = this._items;
    }
  }

  onQueryChange(event: any) {

    if (event && event.event && (event.event.key == 'Escape' || event.event.key == 'Tab')) {
      return;
    }

    if (event && event.event && event.event.key == 'Enter') {
      if (this.selectedRow !== null) {
        this.value = this._items[this.selectedRow];
        this._notifyValue();
        this.opened = false;
      }
      return;
    }

    let q = event.component && event.component['_value'] ? event.component['_value'] :
      this.domHandlerService.jQuery(this.textBox.instance.element()).find('input').val();

    if (q) {
      q = q.trim();
      if (this.upperCase) {
        q = q.toUpperCase();
      }
    }

    let lastQuery = this._query ? this._query.trim() : null;

    if (q == lastQuery) {
      return;
    }

    this._query = q;

    if (q == null || q == "") {
      this.opened = false;
      this.value = null;
      this._notifyValue();
    } else {
      this.querySubject.next(q);
    }
  }

  selectFirstElementGrid() {
    if (this.selectedFirstByDefault) {
      this.selectedRow = 0;
      this._selectRowIndex(this.selectedRow);
    }
  }

  width() {
    return this.domHandlerService.jQuery(this.textBox.instance.element()).width();
  }
}
