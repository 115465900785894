import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifConta} from "./desif.conta";
import {DesifImportacaoArquivo} from "./desif.importacao.arquivo";
import {Municipio} from "./municipio";
import {DesifTitulosEnum} from "../enums/desif.titulos.enum";

@JacksonType("DesifPlanoConta")
@JsonIdentityInfo()
export class DesifPlanoConta extends SonnerBaseEntity {

  id: number;

  @JacksonType("DesifImportacaoArquivo")
  arquivoImportacao: DesifImportacaoArquivo;

  @DateType()
  dataAtivacao: Date;

  @JacksonType("DesifPlanoConta")
  declaracaoRetificada: DesifPlanoConta;

  @DateType()
  fimCompetencia: Date;

  inicioCnpj: string;

  @DateType()
  inicioCompetencia: Date;

  nome: string;

  @JacksonType("Municipio")
  responsavel: Municipio;

  retificadora: boolean;

  @EnumType(DesifTitulosEnum)
  titulo: DesifTitulosEnum;

  @JacksonType("DesifConta")
  contas: DesifConta[];
}
