import {NgModule} from "@angular/core";
import {PrimeiroAcessoPageComponent} from "./components/primeiro-acesso/primeiro.acesso.page.component";
import {RouterService} from "../core/services/router.service";
import {RouterModule, Routes} from "@angular/router";
import {StrutsModule} from "../struts/struts.module";
import {StrutsComponent} from "../struts/components/struts.component";
import {PublicComponent} from "./components/public.component";
import {UiModule} from "../ui/ui.module";
import {RecuperaSenhaPageComponent} from "./components/recupera-senha/recupera.senha.page.component";
import {CommonModule} from "@angular/common";
import {RemoveInscricaoEmailPageComponent} from "./components/remove-inscricao-email/remove.inscricao.email.page.component";
import {BilheteNotaPremiadaContribuintePageComponent} from "./components/bilhete-nota-premiada-contribuinte/bilhete.nota.premiada.contribuinte.page.component";

const routes: Routes = [

  {path: "", component: PublicComponent,
    children: [
      {path: "bilhete-nota-premiada-contribuinte", component: BilheteNotaPremiadaContribuintePageComponent},

      {path: 'comprovante-inscricao-cadastral/:embedded', component: StrutsComponent, data: [{actionUri: '/comprovanteInscricaoCadastral.action'}]},

      {path: 'comprovante-inscricao-cadastral', component: StrutsComponent, data: [{actionUri: '/comprovanteInscricaoCadastral.action'}]},

      {path: 'documentos', component: StrutsComponent, data: [{actionUri: '/documentos.action'}]},

      {path: "download-relatorio-primeiro-acesso", component: StrutsComponent, data: [{actionUri: "/downloadRelatorioPrimeiroAcesso.action"}]},

      {path: 'notas-versao', component: StrutsComponent, data: [{actionUri: '/notasVersao.action'}]},

      {path: "primeiro-acesso", component: PrimeiroAcessoPageComponent},

      {path: "recupera-senha", component: RecuperaSenhaPageComponent},

      {path: "recupera-senha/:usuario", component: RecuperaSenhaPageComponent},

      {path: "recupera-senha/:usuario/:codigo", component: RecuperaSenhaPageComponent},

      {path: 'rps', component: StrutsComponent, data: [{actionUri: '/rps.action'}]},

      {path: 'ativar', component: StrutsComponent, data: [{actionUri: '/ativar.action'}]},

      {path: "remove-inscricao-email/:email", component: RemoveInscricaoEmailPageComponent}
    ]
  }
];

@NgModule({
  declarations: [
    BilheteNotaPremiadaContribuintePageComponent,
    PrimeiroAcessoPageComponent,
    PublicComponent,
    RecuperaSenhaPageComponent,
    RemoveInscricaoEmailPageComponent
  ],

  exports: [],

  imports: [
    UiModule,
    CommonModule,
    RouterModule.forChild(routes),
    StrutsModule,
  ],

  providers: [
    RouterService
  ]
})

export class PublicModule {
}
