import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {CorrecaoStrategy} from "./correcao.strategy";
import {JurosStrategy} from "./juros.strategy";
import {MultaStrategy} from "./multa.strategy";
import {Municipio} from "./municipio";

@JacksonType("ConfigGuia")
@JsonIdentityInfo()
export class ConfigGuia extends SonnerBaseEntity {

  id: number;

  anoBloqueioEmissaoGuias: number;

  anoBloqueioRecalculoGuias: number;

  codigoFebrabam: string;

  compativelFebraban: boolean = false;

  @JacksonType("CorrecaoStrategy")
  correcaoStrategy: CorrecaoStrategy;

  @JacksonType("CorrecaoStrategy")
  correcaoStrategyDenuncia: CorrecaoStrategy;

  diaVencimentoGuias: number = 20;

  diaVencimentoGuiasAvulsa: number;

  diaVencimentoGuiasTomador: number = 20;

  emiteGuiaAutocadastro: boolean = false;

  guiaValorMinimo: number;

  jurosSobreCorrigido: boolean = false;

  jurosSobreCorrigidoDenuncia: boolean = false;

  @JacksonType("JurosStrategy")
  jurosStrategy: JurosStrategy;

  @JacksonType("JurosStrategy")
  jurosStrategyDenuncia: JurosStrategy;

  inicioGuias: number = 0;

  isentaTsaIssSt: boolean = false;

  limPagFuturo: number;

  limPagFuturoDenuncia: number;

  limPagPassado: number;

  maxMesesGuia: number;

  mesBloqueioEmissaoGuias: number;

  mesBloqueioRecalculoGuias: number;

  mesTempoCorrecao: number;

  multaSobreCorrigido: boolean = true;

  multaSobreCorrigidoDenuncia: boolean = true;

  @JacksonType("MultaStrategy")
  multaStrategy: MultaStrategy;

  @JacksonType("MultaStrategy")
  multaStrategyDenuncia: MultaStrategy;

  @JacksonType("Municipio")
  municipio: Municipio;

  permiteRecalculoViradaAno: boolean = true;

  rotuloTaxa: string;

  @JacksonType("CorrecaoStrategy")
  tomadorCorrecaoStrategy: CorrecaoStrategy;

  @JacksonType("CorrecaoStrategy")
  tomadorCorrecaoStrategyDenuncia: CorrecaoStrategy;

  tomadorJurosSobreCorrigido: boolean = false;

  tomadorJurosSobreCorrigidoDenuncia: boolean = false;

  @JacksonType("JurosStrategy")
  tomadorJurosStrategy: JurosStrategy;

  @JacksonType("JurosStrategy")
  tomadorJurosStrategyDenuncia: JurosStrategy;

  tomadorMesTempoCorrecao: number;

  tomadorMultaSobreCorrigido: boolean = true;

  tomadorMultaSobreCorrigidoDenuncia: boolean = true;

  @JacksonType("MultaStrategy")
  tomadorMultaStrategy: MultaStrategy;

  @JacksonType("MultaStrategy")
  tomadorMultaStrategyDenuncia: MultaStrategy;

  tsa: number;

  tsaSomenteNotaAvulsa: boolean = false;

  multaSobreJuros: boolean = false;
}
