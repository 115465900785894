import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Observable, Subject} from "rxjs";

@Injectable()
export class RouterService {

  private refreshStrutsSubject: Subject<boolean> = new Subject();

  constructor(private router: Router) {
  }

  currentUri(): string {
    return this.router.url;
  }

  getRefreshStrutsObservable(): Observable<boolean> {
    return this.refreshStrutsSubject;
  }

  open(uri: string, params?: object, isStruts: boolean = false, method?: string): void {
    let rotaAtual = this.router.url;

    if (rotaAtual.includes("?")) {
      rotaAtual = rotaAtual.split("?")[0];
    }

    if (method) {
      this.router.navigate([uri], {fragment: method, queryParams: params}).then(() => {
        if (rotaAtual === uri && isStruts) {
          this.refreshStruts();
        }
      });
    } else {
      this.router.navigate([uri], {queryParams: params}).then(() => {
        if (rotaAtual === uri && isStruts) {
          this.refreshStruts();
        }
      });
    }
  }

  refreshStruts(): void {
    this.refreshStrutsSubject.next(true);
  }

  navigateSelf(url: string){
    this.router.navigateByUrl("/",{skipLocationChange: true}).then(() =>{
      this.router.navigate([url]);
    });
  }
}
