import {Component, EventEmitter, Injector, Input, Output} from "@angular/core";
import {DomHandlerService} from "../../services/dom.handler.service";
import {BaseComponent} from "../base/base.component";

@Component({
  selector: 'message-panel',
  templateUrl: './message.panel.component.html',
  styleUrls: ['./message.panel.component.scss'],
})

export class MessagePanelComponent extends BaseComponent {

  @Input()
  message: string;

  @Input()
  messages: string[] = [];

  @Input()
  type: string = 'ERROR';

  _visible: boolean = false;

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;

    if (value) {
      this.focus();
    }
  }

  @Output()
  close: EventEmitter<any> = new EventEmitter<any>();

  constructor(private domHandler: DomHandlerService,
              private injector: Injector) {
    super(injector);
  }

  focus() {
    this.domHandler.jQuery(".container")[0].scrollIntoView();
  }
}
