import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {ProcessoExportacaoSimplesDetalhe} from "./processo.exportacao.simples.detalhe";

@JacksonType("TermoExclusaoSimples")
@JsonIdentityInfo()
export class TermoExclusaoSimples extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataTermo: Date;

  @JacksonType("ProcessoExportacaoSimplesDetalhe")
  detalhes: ProcessoExportacaoSimplesDetalhe[];
}
