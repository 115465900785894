import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {PessoaService} from "../../../commons/services/pessoa.service";
import {PessoaFilter} from "../../../commons/filters/pessoa.filter";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {Pessoa} from "../../../../classes/pessoa";
import {AppStateService} from "../../../app/services/app.state.service";
import {NaturezaCadastroEnum} from "../../../../enums/natureza.cadastro.enum";
import {SituacaoPessoaEnum} from "../../../../enums/situacao.pessoa.enum";

@Component({
  selector: 'cadastro-menu-page',
  templateUrl: './cadastro.menu.page.component.html',
  styleUrls: ['./cadastro.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CadastroMenuPageComponent extends BaseComponent {

  constructor(private appStateService: AppStateService,
              private injector: Injector,
              private pessoaService: PessoaService) {
    super(injector);
  }

  pessoaList: Pessoa[] = [];

  pessoaFilter: PessoaFilter = new PessoaFilter();

  naturezaType: any = NaturezaCadastroEnum;

  situacaoType: any = SituacaoPessoaEnum;



  ngOnInit(): void {
    this.appStateService.userInfoSubject.subscribe(user => {
      if (user) {
        this.pessoaFilter.idMunicipio = user.pessoaUsuario.responsavel.id;

        this.disableLoading();
      }
    });
  }

  @GlobalLoadingIndicator()
  acionaCadastrar() {
    if (this.pessoaFilter.documento) {
      return this.of(this.pessoaService.buscarPorDocumentoEMunicipio(this.pessoaFilter), pessoa => {
        if (pessoa) {
          this.addErrorMessage("Documento já está cadastrado no município.");
        } else {
          setTimeout(() => this.navigate('/home/actions/pessoa', null, null, {documento: this.pessoaFilter.documento.replace(/\D/g,""), mobiliario: this.pessoaFilter.grpMobiliario, nome: this.pessoaFilter.nome}));
        }
      });
    } else {
      setTimeout(() => this.navigate('/home/actions/pessoa'));
    }
  }

  @GlobalLoadingIndicator()
  acionaConsultar() {
    return this.of(this.pessoaService.filtrar(this.pessoaFilter), pessoaList => {
      if (pessoaList.length == 1) {
        setTimeout(() => this.navigate('/home/actions/pessoa', null, null, {id: pessoaList[0].id}));
      } else {
        this.pessoaList = pessoaList;
      }
    });
  }

  url(data: any) {
    return '/home/actions/pessoa?id=' + data.id;
  }
}
