import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";

@JacksonType("SaldoCompetencia")
@JsonIdentityInfo()
export class SaldoCompetencia extends SonnerBaseEntity {

  id: number;

  ano: number;

  competenciaOrdenacao: string;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  encerradoPrestador: boolean = false;

  encerradoTomador: boolean = false;

  existeMovimentacaoPrestador: boolean = false;

  existeMovimentacaoTomador: boolean = false;

  existeParcelamentoAtivo: boolean = false;

  @JacksonType("Municipio")
  municipio: Municipio;

  mes: number;

  parcelamentoEmDia: boolean = false;

  @EnumType(TipoRegimeISSEnum)
  regimeContribuinte: TipoRegimeISSEnum;

  valorDevidoPrestador: number;

  valorPagoPrestador: number;

  valorDevidoTomador: number;

  valorPagoTomador: number;
}
