export enum TipoEncerramentoEnum {

  Regular = "Regular",
  SemMovimento = "Sem movimento",
  CEM_PORCENTO_RETIDO = "100% Retido",
  Complementar = "Complementar"
}

export function codigo(tipoEncerramento: TipoEncerramentoEnum): number {

  if (tipoEncerramento == TipoEncerramentoEnum.Regular) {
    return 1;
  } else if (tipoEncerramento == TipoEncerramentoEnum.SemMovimento) {
    return 0;
  } else if (tipoEncerramento == TipoEncerramentoEnum.CEM_PORCENTO_RETIDO) {
    return 100;
  } else if (tipoEncerramento == TipoEncerramentoEnum.Complementar) {
    return 2;
  }
}
