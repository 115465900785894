import {DateType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {SolicitacaoImportacaoNFE} from "./solicitacao.importacao.n.f.e";
import {Pessoa} from "./pessoa";

@JacksonType("AutorizacaoImportacaoNFe")
@JsonSubType({key: 'br.com.sonner.iss.entity.AutorizacaoImportacaoNFe', type: 'Documento'})
@JsonIdentityInfo()
export class AutorizacaoImportacaoNFe extends Documento {

  controleNumeracao: boolean;

  dizeres: string;

  @JacksonType("SolicitacaoImportacaoNFE")
  geradoPor: SolicitacaoImportacaoNFE;

  @JacksonType("Pessoa")
  grafica: Pessoa;

  nomeGrafica: string;

  numeroInicial: number;

  numeroFinal: number;

  obs: string;

  quantidadePorBloco: number;

  @DateType()
  validade: Date;

  vencimentoNotificado: boolean;

  vias: number;
}
