import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {Municipio} from "./municipio";
import {TipoDescontoBaseCalculo} from "./tipo.desconto.base.calculo";

@JacksonType("PerfilDescontoBaseCalculo")
@JsonIdentityInfo()
export class PerfilDescontoBaseCalculo extends SonnerBaseEntity {

  id: number;

  descricao: string;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  @JacksonType("Atividade")
  atividadesAbatimento: Atividade[];

  @JacksonType("TipoDescontoBaseCalculo")
  tiposDesconto: TipoDescontoBaseCalculo[];
}
