import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from "@angular/core";
import {TarefaService} from "../../services/tarefa.service";
import {TarefaResultadoService} from "../../services/tarefa.resultado.service";
import {forkJoin, interval, Subscription} from "rxjs";
import {filter, first, mergeMap, tap} from "rxjs/operators";
import {TarefaResultadoFilter} from "../../filters/tarefa.resultado.filter";
import {TipoDeducaoFilter} from "../../../commons/filters/tipo.deducao.filter";
import {TarefaFilter} from "../../filters/tarefa.filter";

let tarefaSet: Set<number> = new Set<number>();

@Component({
  selector: 'tarefa',
  templateUrl: './tarefa.component.html',
  styleUrls: ['./tarefa.component.scss'],
})
export class TarefaComponent implements OnChanges, OnDestroy {

  @Input()
  idTarefa: number;

  @Input()
  tarefaResultadoType: Function;

  @Output()
  onErro: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onResultado: EventEmitter<any> = new EventEmitter<any>();

  constructor(private tarefaService: TarefaService,
              private tarefaResultadoService: TarefaResultadoService) {
  }

  loading: boolean = false;

  processando: boolean = false;

  progresso: number = 0;

  tarefaWatcher: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idTarefa) {
      if (this.idTarefa) {
        if (!tarefaSet.has(this.idTarefa)) {
          tarefaSet.add(this.idTarefa);
          this.initTarefaWatcher();
        }
      } else if (this.tarefaWatcher) {
        this.processando = false;
        this.progresso = 0;
        this.tarefaWatcher.unsubscribe();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.tarefaWatcher) {
      this.tarefaWatcher.unsubscribe();
    }
  }

  private initTarefaWatcher() {
    this.loading = true;
    this.processando = true;
    this.progresso = 0;

    this.tarefaWatcher = interval(3000).pipe(
      mergeMap(_ => {
        let tarefaFilter: TarefaFilter = new TarefaFilter();

        tarefaFilter.id = this.idTarefa;

        return this.tarefaService.buscar(tarefaFilter)
      }),
      tap(tarefa => this.progresso = tarefa.progresso),
      filter(tarefa => tarefa.progresso == 100),
      first(),
      mergeMap(tarefa => {
        let tarefaResultadoFilter: TarefaResultadoFilter = new TarefaResultadoFilter();

        tarefaResultadoFilter.idTarefa = tarefa.id;

        return forkJoin([
          this.tarefaResultadoService.objeto(tarefaResultadoFilter, this.tarefaResultadoType),
          this.tarefaResultadoService.erro(tarefaResultadoFilter)
        ])
      }),
    ).subscribe((response: [any, string]) => {
      this.processando = false;

      if (response[1]) {
        this.onErro.emit(response[1]);
      } else {
        this.onResultado.emit(response[0]);
      }

      this.loading = false;
    })
  }
}
