import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ImportacaoPGDASD} from "./importacao.p.g.d.a.sd";
import {ParcelamentoSituacaoEnum} from "../enums/parcelamento.situacao.enum";

@JacksonType("ParcelamentoSimples")
@JsonIdentityInfo()
export class ParcelamentoSimples extends SonnerBaseEntity {

  id: number;

  cnpj: string;

  @DateType()
  dataSituacao: Date;

  @DateType()
  dataPedido: Date;

  @JacksonType("ImportacaoPGDASD")
  importacao: ImportacaoPGDASD;

  parcelamento: number;

  @EnumType(ParcelamentoSituacaoEnum)
  situacao: ParcelamentoSituacaoEnum;

  @JacksonType("ImportacaoPGDASD")
  ultimaImportacao: ImportacaoPGDASD;
}
