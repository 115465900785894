import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {OrdemServico} from "./ordem.servico";
import {Pessoa} from "./pessoa";

@JacksonType("OrdemServicoItem")
@JsonIdentityInfo()
export class OrdemServicoItem extends SonnerBaseEntity {

  id: number;

  conteudo: string;

  @DateType()
  data: Date;

  @JacksonType("OrdemServico")
  ordemServico: OrdemServico;

  @JacksonType("Pessoa")
  pessoa: Pessoa;
}
