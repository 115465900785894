import {DateType, JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("CalculoGuiaAvulsa")
export class CalculoGuiaAvulsa {

  correcao: number = 0;

  juros: number = 0;

  multa: number = 0;

  tsa: number = 0;

  @DateType()
  vencimento: Date;

  total(valor: number): number {
    let total: number = valor;

    total = this.adicao(total, this.correcao);
    total = this.adicao(total, this.juros);
    total = this.adicao(total, this.multa);
    total = this.adicao(total, this.tsa);

    return total;
  }

  adicao(a: number, b: number): number {
    if (a == null || a == 0) {
      return b;
    }

    if (b == null || b == 0) {
      return a;
    }

    a = +a.toPrecision(2);
    b = +b.toPrecision(2);

    return a + b;
  }
}
