export enum DesifTitulosEnum {

  U = "Bancos Múltiplos",
  B = "Bancos Comerciais",
  D = "Bancos de Desenvolvimento",
  K = "Agências de Fomento ou de Desenvolvimento",
  I = "Bancos de Investimentos",
  F = "Sociedades de Crédito, Financiamento e Investimentos",
  J = "Sociedades de Crédito ao Microempreendedor",
  A = "Sociedades de Arrendamento Mercantil",
  C = "Sociedades Corretoras de Títulos e Valores Mobiliários e Câmbio",
  T = "Sociedades Distribuidoras de Títulos e Valores Mobiliários",
  S = "Sociedades de Crédito Imobiliário e Associações de Poupança e Empréstimo",
  W = "Companhias Hipotecárias",
  E = "Caixas Econômicas",
  R = "Cooperativas de Crédito",
  O = "Fundos de Investimento",
  L = "Banco do Brasil S.A",
  M = "Caixa Econômica Federal",
  N = "Banco Nacional de Desenvolvimento Econômico e Social",
  H = "Administradoras de Consórcio",
  P = "Grupos de Consórcio",
  Z = "Empresas em Liquidação Extrajudicial"
}
