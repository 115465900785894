import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {ValueDisplayable} from "../../modules/ui/interface/value.displayable";
import {NotaEnsinoTO} from "./nota.ensino.to";

@JacksonType("MonitorInstituicaoEnsinoTO")
export class MonitorInstituicaoEnsinoTO implements ValueDisplayable{

  id: number;

  nome: string;

  documento: string;

  diaGeracao: number;

  @JacksonType("NotaEnsinoTO")
  notasEnsino: NotaEnsinoTO[];

  situacao: string;

  @DateType()
  dataBaixa: Date;

  quantidadeCursos: number;

  quantidadeAlunos: number;

  //Para reprocessamento

  ano: number;

  mes:number;



  getDisplayValue(): string {
    return this.documento + " - " + this.nome ;
  }

}
