import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {TipoSolicitacaoEnum, TipoSolicitacaoEnumUtils} from "../../../../enums/tipo.solicitacao.enum";
import {EnumUtils} from "../../../core/utils/enum.utils";
import {SituacaoSolicitacaoEnum} from "../../../../enums/situacao.solicitacao.enum";
import {FiscalService} from "../../../commons/services/fiscal.service";
import {PessoaSimplificadaTO} from "../../../../classes/to/pessoa.simplificada.to";
import {AtendimentoFilter} from "../../filters/atendimento.filter";
import {DateUtilsService} from "../../../core/services/date.utils.service";
import {AtendimentoService} from "../../services/atendimento.service";
import {AtendimentoTO} from "../../../../classes/to/atendimento.to";

@Component({
  selector: 'atendimento-menu-page',
  templateUrl: './atendimento.menu.page.component.html',
  styleUrls: ['./atendimento.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AtendimentoMenuPageComponent extends BaseComponent {

  constructor(private injector: Injector,
              private fiscalService: FiscalService,
              private dateUtilsService: DateUtilsService,
              private atendimentoService: AtendimentoService) {
    super(injector);
  }

  tipos: any = EnumUtils.getKeyValue(TipoSolicitacaoEnum, true);

  atendimentoFilter: AtendimentoFilter = new AtendimentoFilter();

  situacaoType: any = SituacaoSolicitacaoEnum;

  fiscalList: PessoaSimplificadaTO[];

  tipoSelecionados: string[] = [];

  dataSource: AtendimentoTO[];

  search: string;

  ngOnInit(): void {
    this.of(this.fiscalService.fiscalPessoaTOPorMunicipio(), fiscais => {
      this.fiscalList = fiscais;
      this.atendimentoFilter.situacao = SituacaoSolicitacaoEnum.pendente;
      this.atendimentoFilter.dataInicial = this.dateUtilsService.clearTime(this.dateUtilsService.addDays(new Date(), -1), true);
      this.atendimentoFilter.dataFinal = this.dateUtilsService.clearTime(new Date(), false);
      this.disableLoading();
    });
  }

  @GlobalLoadingIndicator()
  acionaBuscar() {
    this.atendimentoFilter.tipos= [];
    if(this.tipoSelecionados && this.tipoSelecionados.length){
      this.tipoSelecionados.forEach(key => this.atendimentoFilter.tipos.push(TipoSolicitacaoEnum[key]));
    }
    return this.of(this.atendimentoService.buscar(this.atendimentoFilter), list=> this.dataSource = list);
  }

  checkSituacao(situacao: SituacaoSolicitacaoEnum) {
    this.atendimentoFilter.situacao = situacao;
    setTimeout(() =>{
      if(!situacao){
        this.atendimentoFilter.situacao = SituacaoSolicitacaoEnum.pendente;
      }
    });
  }

  getUrl(data): string {
    if(data.angular){
      return data.rotaAngular;
    }else if(data.indeferida){
      return "/app/indeferida.action?id=" + data.getCriptId();
    }else if(data.aprovada){
      if(data.generica){
        return this.navigateDuvidas(data);
      }else{
        return "/app/documentos.action?senha=" + data.senhaDocumento;
      }
    }else if(data.pendente){
      if(data.generica){
        return this.navigateDuvidas(data);
      }else{
        return "/home/atender/" + TipoSolicitacaoEnumUtils.atendimento(data.tipo) + "/" + data.criptId;
      }
    }else if(data.denuncia){
      return "/app/denuncia.action?id=" + data.getCriptId();
    }else{
      return "/home/atendimento"
    }
  }

  redirect(data, event) {
    if(data.angular){
      this.navigate(data.rotaAngular, data.rotaAngular, event);
    }else if(data.indeferida){
      this.navigate("/home/actions/indeferida", this.getUrl(data), event, {id: data.getCriptId()});
    }else if(data.aprovada){
      if(data.generica){
        this.navigateDuvidas(data,false);
      }else{
        this.navigate("/home/actions/documentos", this.getUrl(data), event, {senha: data.senhaDocumento})
      }
    }else if(data.pendente){
      if(data.generica){
        this.navigateDuvidas(data,false);
      }else{
        this.navigate("/home/atender/"+TipoSolicitacaoEnumUtils.atendimento(data.tipo) + "/" + data.criptId, this.getUrl(data), event);
      }
    }else if(data.denuncia){
      this.navigate("/home/actions/denuncia", this.getUrl(data), event, {id: data.getCriptId()});
    }else{
      this.navigate("/home/atendimento");
    }
  }

  navigateDuvidas(data: AtendimentoTO, action:boolean = true){
    if(action){
      return "/app/duvidasFiscal.action?id=" + data.getCriptId(true);
    }
    this.navigate("/home/actions/duvidas-fiscal", this.getUrl(data), event, {id: data.getCriptId(true)});
  }

  getColor(situacao) {
    if(situacao == SituacaoSolicitacaoEnum.aprovada){
      return {color: "#179814"};
    }else if(situacao == SituacaoSolicitacaoEnum.indeferida){
      return {color: "red"};
    }
  }
}
