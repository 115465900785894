import {DateType, EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Atividade} from "./atividade";
import {AtividadeCNAE} from "./atividade.c.n.a.e";
import {Municipio} from "./municipio";
import {Solicitacao} from "./solicitacao";
import {SolicitacaoDadosPessoa} from "./solicitacao.dados.pessoa";
import {TipoTributacao} from "./tipo.tributacao";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";
import {ItemSolicitacaoAlteracaoNFe} from "./item.solicitacao.alteracao.n.f.e";

@JacksonType("SolicitacaoAlteracaoNFe")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoAlteracaoNFe', type: 'Solicitacao'})
@JsonIdentityInfo()
export class SolicitacaoAlteracaoNFe extends Solicitacao {

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  @JacksonType("AtividadeCNAE")
  atividadeCNAE: AtividadeCNAE;

  condicaoPagamento: string;

  correlacao: string;

  @JacksonType("SolicitacaoDadosPessoa")
  dadosTomador: SolicitacaoDadosPessoa;

  @DateType()
  dataPrestacao: Date;

  descontoCondicional: number;

  descontoImposto: number;

  descontoIncondicional: number;

  empenho: string;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  motivo: string;

  @JacksonType("Municipio")
  municipioIncidencia: Municipio;

  num: number;

  obs: string;

  outrasDeducoes: number;

  outrasRetencoes: number;

  retido: boolean;

  retencaoCOFINS: number;

  retencaoCSLL: number;

  retencaoINSS: number;

  retencaoIRRF: number;

  retencaoPIS: number;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;

  valorImposto: number;

  valorTotal: number;

  @JacksonType("ItemSolicitacaoAlteracaoNFe")
  itens: ItemSolicitacaoAlteracaoNFe[];

  issRetido: number;

  totalLiquido: number;

  totalNota: number;

}
