import {Component, Injector} from "@angular/core";
import {LoginService} from "../../../core/services/login.service";
import {Municipio} from "../../../../classes/municipio";
import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {LoginFilter} from "../../../core/filters/login.filter";
import {AlertService} from "../../../ui/services/alert.service";
import {CognitoService} from "../../../core/services/cognito.service";

@Component({
  selector: 'recupera-senha-page',
  templateUrl: './recupera.senha.page.component.html',
  styleUrls: ['./recupera.senha.page.component.scss']
})
export class RecuperaSenhaPageComponent extends BaseComponent {

  constructor(private activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private cognitoService: CognitoService,
              private injector: Injector,
              private loginService: LoginService) {
    super(injector);
  }

  accordionDocumento: boolean = false;

  accordionUsuario: boolean = true;

  accordionSenha: boolean = false;

  bloqueiaCodigo: boolean = false;

  codigo: string = "";

  documento: string = "";

  municipio: Municipio;

  municipios: Municipio[] = [];

  senha: string = "";

  senhaConfirma: string = "";

  usuario: string = "";

  visibleDocumento: boolean = false;

  visibleUsuario: boolean = false;

  visibleSenha: boolean = false;

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(map => {
      this.usuario = map.get("usuario");
      this.codigo = map.get("codigo");

      this.visibleUsuario = true;

      if (this.usuario && this.codigo) {
        this.bloqueiaCodigo = true;
        this.visibleUsuario = false;
        this.visibleSenha = true;
      }

      this.carrega();
    });
  }

  @GlobalLoadingIndicator()
  acionaAlterar() {
    this.clearErrorMessages();

    if (!this.codigo) {
      this.addErrorMessage("O campo código é obrigatório.");

      return;
    }

    if (!this.senha || !this.senhaConfirma) {
      this.addErrorMessage("Os campos senha e confirma senha são obrigatórios.");

      return;
    }

    if (this.senha.length < 6) {
      this.addErrorMessage("O campo senha deve possuir pelo menos seis caracteres.");

      return;
    }

    if (this.senha == this.senhaConfirma) {
      this.of(this.cognitoService.forgotPasswordSubmit(this.usuario, this.codigo, this.senha), (resp: string) => {
        this.clearErrorMessages();

        if (!resp || resp == "SUCCESS") {
          this.alertService.show("A sua senha foi redefinida com sucesso.").then(() => this.navigate('/landing-page'));
        } else {
          this.addErrorMessage(resp);
        }
      })
    } else {
      this.addErrorMessage('A senha e sua confirmação deve ser iguais.');

      return;
    }
  }

  @GlobalLoadingIndicator()
  acionaEnviar() {
    this.clearErrorMessages();

    let reg = /[^LM\S{NP}]/g;

    if (!this.usuario || this.usuario.trim() == "") {
      this.addErrorMessage("O campo usuário é obrigatório.");

      return;
    }

    if (reg.test(this.usuario)) {
      let loginFilter: LoginFilter = new LoginFilter();

      loginFilter.usuario = this.usuario;

      return this.of(this.loginService.enviarSenha(loginFilter), () => {
        this.alertService.show("A senha foi enviado para o seu e-mail cadastrado.").then();
      });
    }

    return this.of(this.cognitoService.forgotPassword(this.usuario.replace("\'", "\\'")), (resp: string) => {
      if (resp.substr(0, 5) == "EMAIL") {
        this.bloqueiaCodigo = false;
        this.codigo = "";
        this.accordionUsuario = false;
        this.visibleSenha = true;

        this.alertService.show("O código de verificação foi enviado para o seu e-mail cadastrado.").then();
      } else {
        this.addErrorMessage(resp);
      }
    });
  }

  @GlobalLoadingIndicator()
  acionaEnviarDocumento() {
    this.clearErrorMessages();

    if (this.municipio && this.documento) {
      let loginFilter: LoginFilter = new LoginFilter();

      loginFilter.documento = this.documento;
      loginFilter.idMunicipio = this.municipio.id;

      return this.of(this.loginService.enviar(loginFilter), () => {
        this.alertService.show("A informação do usuário foi enviada para o seu e-mail cadastrado.").then();

        this.accordionDocumento = false;
        this.accordionUsuario = true;
      })
    } else {
      this.addErrorMessage("Por favor, informar o município e o documento.");

      return;
    }
  }

  acionaLembrar() {
    this.clearErrorMessages();

    this.accordionUsuario = false;
    this.accordionDocumento = true;
    this.visibleDocumento = true
  }

  acionaVoltar() {
    this.clearErrorMessages();

    this.codigo = "";
    this.senha = "";
    this.usuario = "";
    this.accordionUsuario = true;

    this.visibleSenha = false;
    this.visibleUsuario = true;
  }

  acionaVoltarDocumento() {
    this.clearErrorMessages();

    this.documento = "";
    this.municipio = null;
    this.usuario = "";
    this.visibleDocumento = false;

    this.accordionUsuario = true;
  }

  alteraDocumento(evento: any) {
    if (evento) {
      this.documento = evento.replace(/\D/g, "");
    } else {
      this.documento = "";
    }
  }

  @GlobalLoadingIndicator()
  carrega() {
    return this.of(this.loginService.buscarListaMunicipioCliente(), municipios => this.municipios = municipios);
  }
}
