import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {ArquivoImportacao} from "./arquivo.importacao";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {NotaFiscal} from "./nota.fiscal";
import {WebServiceArquivo} from "./web.service.arquivo";

@JacksonType("NotaFiscalOrigem")
@JsonIdentityInfo()
export class NotaFiscalOrigem extends SonnerBaseEntity {

  id: number;

  @JacksonType("NotaFiscal")
  nota: NotaFiscal;

  @JacksonType("ArquivoImportacao")
  opImportacao: ArquivoImportacao;

  @JacksonType("WebServiceArquivos")
  opWebservice: WebServiceArquivo;
}
