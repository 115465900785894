import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {ConfigNotaPremiadaPeriodoFilter} from "../filters/config.nota.premiada.periodo.filter";
import {ConfigNotaPremiadaPeriodoTO} from "../../../classes/to/config.nota.premiada.periodo.to";

@Injectable()
export class ConfigNotaPremiadaPeriodoService {

  constructor(private restService: RestService) {
  }

  remover(configNotaPremiadaPeriodoFilter: ConfigNotaPremiadaPeriodoFilter): Observable<any> {
    return this.restService.post("/confignotapremiadaperiodo/remover", {data: configNotaPremiadaPeriodoFilter});
  }

  buscarPorMunicipio(): Observable<ConfigNotaPremiadaPeriodoTO[]> {
    return this.restService.post("/confignotapremiadaperiodo/buscarPorMunicipio", {responseType:ConfigNotaPremiadaPeriodoTO});
  }

}
