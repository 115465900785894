import {Component, Injector, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";

@Component({
  selector: 'escrituracao-menu-page',
  templateUrl: './escrituracao.menu.page.component.html',
  styleUrls: ['./escrituracao.menu.page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EscrituracaoMenuPageComponent extends BaseComponent {

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.navigate("/home/escrituracao/list");
  }
}
