import {JacksonType} from "@sonner/jackson-service-v2";
import {PGDASDDASTO} from "./pgdasd.das.to";
import {PessoaSimplesTO} from "./pessoa.simples.to";
import {ConsultaSimplesNacionalResumoAtividadeTO} from "./consulta.simples.nacional.resumo.atividade.to";
import {ProcessoExportacaoSimplesTO} from "./processo.exportacao.simples.to";
import {FaixaValoresRecebidosSimplesNacionalTO} from "./faixa.valores.recebidos.simples.nacional.to";

@JacksonType("ConsultaSimplesNacionalTO")
export class ConsultaSimplesNacionalTO {

  @JacksonType("PGDASDDASTO")
  pgdasddasList: PGDASDDASTO[];

  @JacksonType("PessoaSimplesTO")
  pessoaSimplesInconsistenciasList: PessoaSimplesTO[];

  @JacksonType("PessoaSimplesTO")
  pessoaSimplesNacionalList: PessoaSimplesTO[];

  @JacksonType("FaixaValoresRecebidosSimplesNacionalTO")
  resumoValoreRecebidosList: FaixaValoresRecebidosSimplesNacionalTO[];

  @JacksonType("ConsultaSimplesNacionalResumoAtividadeTO")
  simplesNacionalResumoAtividadeList: ConsultaSimplesNacionalResumoAtividadeTO[];

  @JacksonType("ProcessoExportacaoSimplesTO")
  processoExportacaoSimplesList: ProcessoExportacaoSimplesTO[];
}
