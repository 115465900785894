import {JacksonType, JsonIdentityInfo, SetType} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TNCPropriedade} from "./t.n.c.propriedade";

@JacksonType("TNCMunicipioConfig")
@JsonIdentityInfo()
export class TNCMunicipioConfig extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  @JacksonType("TNCPropriedade")
  propriedades: TNCPropriedade[];
}
