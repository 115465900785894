import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";

@JacksonType("SolicitacaoSenha")
@JsonIdentityInfo()
export class SolicitacaoSenha {

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  senha: string;
}
