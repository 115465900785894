import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {Serie} from "./serie";

@JacksonType("DeclaracaoNF")
@JsonIdentityInfo()
export class DeclaracaoNF extends SonnerBaseEntity {

  id: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataCancelamento: Date;

  numero: number;

  @JacksonType("Serie")
  serie: Serie;
}
