import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("NotaDeducaoDocumentoTO")
export class NotaDeducaoDocumentoTO {

  id: number;

  nota: string;

  valor: number;

  prestador: string;

  atividade: string;

  verificador: string;

  tipoNota: string;

  cancelado: boolean;

}
