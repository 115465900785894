import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";
import {Municipio} from "./municipio";
import {Atividade} from "./atividade";

@JacksonType("EscritPedagio")
@JsonIdentityInfo()
export class EscritPedagio extends SonnerBaseEntity {

  id: number;

  @JacksonType("Atividade")
  atividade:Atividade;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("Municipio")
  municipio: Municipio;

  nomePracaPedagio: string;

  mes: number;

  ano: number;

  valorTotalArrecadado: number;

  kmInicial: number;

  kmFinal: number;

  extensaoVia: number;

  baseCalculo: number;

  aliquota: number;

  valorImposto: number;

  @DateType()
  dataAlteracao: Date;

  @DateType()
  dataCadastro: Date;

}
