export enum OrigemEnum {

  desconhecida,
  cadastro,
  autocadastro,
  importacao,
  TOMerger,
  povoador,
  rest,
  unitTest,
  cadastrarPessoaCmd,
  usuario,
  grp,
  ajusteGuiasPocos
}
