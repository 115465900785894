import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {EscritPapel} from "./escrit.papel";
import {NotaFiscal} from "./nota.fiscal";
import {ObraCorrecaoNotaFiscal} from "./obra.correcao.nota.fiscal";
import {ObraPrestador} from "./obra.prestador";
import {TipoNotaFiscalObraEnum} from "../enums/tipo.nota.fiscal.obra.enum";

@JacksonType("ObraNotaFiscal")
@JsonIdentityInfo()
export class ObraNotaFiscal extends SonnerBaseEntity {

  id: number;

  @JacksonType("ObraCorrecaoNotaFiscal")
  correcao: ObraCorrecaoNotaFiscal;

  @JacksonType("EscritPapel")
  escrit: EscritPapel;

  @JacksonType("NotaFiscal")
  nota: NotaFiscal;

  @JacksonType("ObraPrestador")
  obraPrestadorDeducao: ObraPrestador;

  @JacksonType("ObraPrestador")
  obraPrestadorPrestacao: ObraPrestador;

  @EnumType(TipoNotaFiscalObraEnum)
  tipo: TipoNotaFiscalObraEnum;

  valor: number;

  utilizada: boolean;
}
