import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("CompetenciaDimensao")
@JsonIdentityInfo()
export class CompetenciaDimensao extends SonnerBaseEntity {

  id: number;

  ano: number;

  formatoOrdenacao: string;

  mes: number;

  nomeCurto: string;

  nomeLongo: string;

  nomeNumerico: string;
}
