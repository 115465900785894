import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("OrdemServicoArquivoFilter")
export class OrdemServicoArquivoFilter {

  id: number;

  idArquivo: number;

  idOrdemServico: number;
}
