import {JacksonType} from "@sonner/jackson-service-v2";
import {SqlInterativoTitle} from "./sql.interativo.title";
import {SqlInterativoRow} from "./sql.interativo.row";

@JacksonType("SqlInterativoValorTO")
export class SqlInterativoValorTO {

  colPadrao: String;

}
