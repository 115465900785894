export enum ExclusaoSimplesCodigosEnum {

  E353 = "Exclus�o de Of�cio - Excesso de receita bruta no ano calend�rio de in�cio de atividades - acima de 20% do limite",
  E354 = "Exclus�o de Of�cio - Excesso de receita bruta no ano calend�rio de in�cio de atividades - at� 20% do limite",
  E355 = "Exclus�o de Of�cio - Excesso de receita bruta fora do ano calend�rio de in�cio de atividades",
  E356 = "Exclus�o de Of�cio - D�bitos",
  E357 = "Exclus�o de Of�cio - Natureza jur�dica vedada",
  E358 = "Exclus�o de Of�cio - Sociedade por a��es",
  E359 = "Exclus�o de Of�cio - Cooperativa",
  E360 = "Exclus�o de Of�cio - Atividade econ�mica vedada",
  E361 = "Exclus�o de Of�cio - S�cio domiciliado no exterior",
  E362 = "Exclus�o de Of�cio - Por ser filial, sucursal, ag�ncia ou representa��o, no pa�s, de Pessoa Jur�dica com sede no exterior",
  E363 = "Exclus�o de Of�cio - Participa��o no capital de outra Pessoa Jur�dica",
  E364 = "Exclus�o de Of�cio - Titular ou s�cio com participa��o superior a 10% no capital de outra PJ, n�o beneficiada pela LC n� 123, tendo a RB global ultrapassado o limite",
  E365 = "Exclus�o de Of�cio - de seu capital participa pessoa f�sica inscrita como empres�rio ou seja s�cia de outra empresa beneficiada pela LC n� 123, tendo a RB global ultrapassado o limite",
  E366 = "Exclus�o de Of�cio - S�cio ou titular � administrador de outra Pessoa Jur�dica com fins lucrativos",
  E367 = "Exclus�o de Of�cio - Participa��o de outra Pessoa Jur�dica no capital da empresa optante",
  E370 = "Exclus�o de Of�cio - Empresa resultante ou remanescente de cis�o ocorrida nos �ltimos cinco anos",
  E396 = "Exclus�o de Of�cio - Quando do ingresso no Simples Nacional, a empresa incorria em veda��o",
  E397 = "Exclus�o de Of�cio - Declara��o inver�dica prestada no momento da op��o",
  E398 = "Exclus�o de Of�cio - Aus�ncia de regulariza��o da inscri��o Estadual ou Municipal",
  R01 = "Empresa inclu�da por medida judicial",
  R02 = "C�digo de evento n�o permitido para exclus�o em lote",
  R03 = "Data inv�lida",
  R04 = "Empresa n�o optante pelo Simples Nacional",
  R05 = "Empresa j� baixada no CNPJ",
  R06 = "Data do fato motivador fora do intervalo permitido*",
  R07 = "Data do fato motivador no ano de in�cio de atividades (evento 355)",
  R08 = "Data do fato motivador no ano de in�cio de atividades (evento 355)",
  R09 = "Tentativa de alterar um per�odo fechado por outro Ente Federativo, com data efeito posterior",
  R10 = "Data de efeito fora do intervalo",
  R11 = "Empresa n�o consta na base do Cadastro CNPJ",
  R12 = "J� existe um evento com a mesma data de fato motivador para este CNPJ",
  R13 = "CNPJ inv�lido"
}
