import {NgModule} from "@angular/core";
import {AuthGuard} from "./auth/auth.guard";
import {LoginService} from "./services/login.service";
import {RestService} from "./services/rest.service";
import {JacksonService} from "@sonner/jackson-service-v2";
import {SessionService} from "./services/session.service";
import {VersionService} from "./services/version.service";
import {CookieService} from "./services/cookie.service";
import {MesPorExtensoPipe} from "./pipes/mes.por.extenso.pipe";
import {SimNaoPipe} from "./pipes/sim.nao.pipe";
import {RouterService} from "./services/router.service";
import {DocumentoPipe} from "./pipes/documento.pipe";
import {HtmlPipe} from "./pipes/html.pipe";
import {CognitoService} from "./services/cognito.service";
import {RemoveInscricaoEmailService} from "./services/remove.inscricao.email.service";
import {CepPipe} from "./pipes/cep.pipe";
import {AliquotaPipe} from "./pipes/aliquota.pipe";
import {LogoutService} from "./services/logout.service";

@NgModule({
  declarations: [
    AliquotaPipe,
    CepPipe,
    DocumentoPipe,
    HtmlPipe,
    MesPorExtensoPipe,
    SimNaoPipe
  ],

  exports: [
    AliquotaPipe,
    CepPipe,
    DocumentoPipe,
    HtmlPipe,
    MesPorExtensoPipe,
    SimNaoPipe
  ],

  imports: [],

  providers: [
    AuthGuard,
    CognitoService,
    CookieService,
    JacksonService,
    LoginService,
    LogoutService,
    RestService,
    RouterService,
    SessionService,
    VersionService,
    RemoveInscricaoEmailService
  ]
})

export class CoreModule {
}
