import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("MonitorMunicipioTabelaTO")
export class MonitorMunicipioTabelaTO {

  competencia01: number;

  competencia02: number;

  competencia03: number;

  competencia04: number;

  competencia05: number;

  competencia06: number;

  competencia07: number;

  competencia08: number;

  competencia09: number;

  competencia10: number;

  competencia11: number;

  competencia12: number;

  nome: string;

  total: number;
}
