import {JacksonType} from "@sonner/jackson-service-v2";
import {CompetenciaDimensao} from "../competencia.dimensao";
import {AlertaFiscal} from "../alerta.fiscal";

@JacksonType("BilheteNotaPremiadaTO")
export class BilheteNotaPremiadaTO {

  numero: number;

  tomador: string;

  notaFiscal: string;

  dataPrestacaoNotaFmt: string;

  docTomador: string;

  ativo: boolean;

}
