import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Suporte")
@JsonSubType({key: 'br.com.sonner.iss.entity.Suporte', type: "Papel"})
export class Suporte extends Papel {

  constructor() {
    super(PerfilEnum.suporte);
  }
}
