import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ServicoEspecial} from "./servico.especial";

@JacksonType("ServicoEspecialValor")
@JsonIdentityInfo()
export class ServicoEspecialValor extends SonnerBaseEntity {

  id: number;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  @JacksonType("ServicoEspecial")
  servico: ServicoEspecial;

  valorUnitario: number;

  valorUnitarioTaxa: number;
}
