import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";

@JacksonType("EspecieAIDF")
@JsonIdentityInfo()
export class EspecieAIDF extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  @DateType()
  dataCancelamenta: Date;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  getDisplayValue(): string {
    return this.nome;
  }
}
