import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ItemTabelaIBPT} from "./item.tabela.i.b.p.t";

@JacksonType("ValorTabelaIBPT")
@JsonIdentityInfo()
export class ValorTabelaIBPT extends SonnerBaseEntity {

  id: number;

  aliqImp: number;

  aliqNac: number;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  @JacksonType("ItemTabelaIBPT")
  itemTabela: ItemTabelaIBPT;

  versao: string;
}
