import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Municipio} from "../../../classes/municipio";
import {MunicipioFilter} from "../filters/municipio.filter";

@Injectable()
export class MunicipioService {

  constructor(private restService: RestService){
  }

  alterarCodigoSeparador(municipioFilter: MunicipioFilter): Observable<any> {
    return this.restService.post("/municipio/alterarCodigoSeparador", {data: municipioFilter});
  }

  buscarCodigoSeparador(): Observable<string> {
    return this.restService.post("/municipio/buscarCodigoSeparador", {responseType: 'text'});
  }

  buscar(municipioFilter?: MunicipioFilter): Observable<Municipio> {
    return this.restService.post("/municipio/buscar", {data: municipioFilter, responseType: Municipio});
  }

  buscarListaMunicipioCertificado(): Observable<Municipio[]> {
    return this.restService.get("/municipio/buscarListaMunicipioCertificado", {responseType: Municipio});
  }

  buscarListaMunicipioSync(): Observable<Municipio[]> {
    return this.restService.get("/municipio/buscarListaMunicipioSync", {responseType: Municipio});
  }

  buscarListaPorEstado(municipioFilter: MunicipioFilter): Observable<Municipio[]> {
    return this.restService.post("/municipio/buscarListaPorEstado", {data: municipioFilter, responseType: Municipio});
  }

  salvar(municipio: Municipio): Observable<any> {
    return this.restService.post("/municipio/salvar", {data: municipio});
  }
}
