import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {Pessoa} from "./pessoa";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ProcessoAssincronoFilaEnum} from "../enums/processo.assincrono.fila.enum";
import {TipoRelatorioEnum} from "../enums/tipo.relatorio.enum";

@JacksonType("RelatorioAssincrono")
@JsonIdentityInfo()
export class RelatorioAssincrono extends SonnerBaseEntity {

  id: number;

  @EnumType(ProcessoAssincronoFilaEnum)
  tipoFila: ProcessoAssincronoFilaEnum;

  @JacksonType('Pessoa')
  usuario: Pessoa;

  @JacksonType('Pessoa')
  contribuinte: Pessoa;

  @DateType()
  agendado: Date;

  @DateType()
  inicioProcessamento: Date;

  @DateType()
  fimProcessamento: Date;

  mensagem: string;

  nomeArquivo: string;

  contentType: string;

  @EnumType(TipoRelatorioEnum)
  tipo: TipoRelatorioEnum;

  @DateType()
  terminoInexperado: Date;

  erro: string;

  urlRetorno: string;

  bucket: string;

  bucketFileName: string;

}
