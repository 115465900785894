import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {AppShellService} from "./app.shell.service";
import {first} from "rxjs/operators";

@Injectable()
export class GoogleAnalyticsService {

  private _pageSubject: Subject<string> = new Subject();

  private _gaInitialized: boolean = false;

  constructor(private appShellService: AppShellService) {
    this._pageSubject.subscribe(uri => this.processPageRequest(uri));
  }

  sendPageRequest(uri: string): void {
    this._pageSubject.next(uri);
  }

  private processPageRequest(uri: string): void {
    let ga = this.initGoogleAnalytics();

    if (this.appShellService.getUserInfoObservable()) {
      this.appShellService.getUserInfoObservable().pipe(first()).subscribe(userInfo => {

        ga('set', 'dimension1', userInfo.codigoMunicipio);

        if (uri.match(/.*action$/)) {
          uri = uri.substr(uri.lastIndexOf('/') + 1);
        }

        ga('send', 'event', 'municipio', uri, userInfo.codigoMunicipio);
        ga('set', 'page', uri);
        ga('send', 'pageview');

      });
    }
  }

  private initGoogleAnalytics(): any {
    let win = window as any;

    if (this._gaInitialized) {
      return win.ga;
    }

    this._gaInitialized = true;

    win.initGa();
    win.ga('create', 'UA-19304791-3', 'govdigital.com.br');

    return win.ga;
  }
}
