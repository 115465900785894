import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Pessoa} from "./pessoa";

@JacksonType("PessoaPerfilDescontoImpostoNfe")
@JsonIdentityInfo()
export class PessoaPerfilDescontoImpostoNfe extends SonnerBaseEntity {

  id: number;

  @DateType()
  fim: Date;

  @DateType()
  inicio: Date;

  observacao: string;

  @JacksonType("PessoaPerfilDescontoImpostoNfe")
  perfilDesconto: PessoaPerfilDescontoImpostoNfe;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  valor: number;
}
