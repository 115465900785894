import {JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {Papel} from "./papel";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Pedagio")
@JsonSubType({key: 'br.com.sonner.iss.entity.Pedagio', type: "Papel"})
export class Pedagio extends Papel {

  constructor() {
    super(PerfilEnum.pedagio);
  }
}
