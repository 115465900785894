import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("ConfiguracaoContribuinteTO")
export class ConfiguracaoContribuinteTO {

  idMunicipio: number;

  permiteAlterarEmail: boolean = false;

  permiteAlterarImagem: boolean = false;

  permiteAlterarTelefone: boolean = false;

  permiteSolicitarAltCRC: boolean = false;

  permiteSolicitarAltDoc: boolean = false;

  permiteSolicitarAltEndereco: boolean = false;

  permiteSolicitarAltInscEst: boolean = false;

  permiteSolicitarAltInscMun: boolean = false;

  permiteSolicitarAltNome: boolean = false;

  permiteSolicitarAltNomeFant: boolean = false;

  permiteSolicitarAltRegime: boolean = false;

  permiteSolicitarAltTelefone: boolean = false;

  permiteGerenciarPlanoContas: boolean = false;
}
