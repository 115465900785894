import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifImportacaoArquivo} from "./desif.importacao.arquivo";
import {DesifPlanoConta} from "./desif.plano.conta";
import {Pessoa} from "./pessoa";
import {DesifTipoConsolidacaoEnum} from "../enums/desif.tipo.consolidacao.enum";

@JacksonType("DesifApuracaoMensal")
@JsonIdentityInfo()
export class DesifApuracaoMensal extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("DesifImportacaoArquivo")
  arquivo: DesifImportacaoArquivo;

  ativa: boolean;

  @JacksonType("DesifApuracaoMensal")
  declaracaoRetificada: DesifApuracaoMensal;

  @JacksonType("Pessoa")
  emissor: Pessoa;

  inicioCnpj: string;

  mes: number;

  @JacksonType("DesifPlanoConta")
  planoConta: DesifPlanoConta;

  @JacksonType("Pessoa")
  responsavelRecolhimento: Pessoa;

  senha: string;

  @EnumType(DesifTipoConsolidacaoEnum)
  tipoConsolidacao: DesifTipoConsolidacaoEnum;

  tipoDeclaracao: number;
}
