import {JacksonType} from "@sonner/jackson-service-v2";
import {DadosEndereco} from "./dados.endereco";
import {DadosPessoa} from "./dados.pessoa";

@JacksonType("DadosCadastro")
export class DadosCadastro {

  @JacksonType("DadosEndereco")
  endereco: DadosEndereco;

  @JacksonType("DadosPessoa")
  pessoa: DadosPessoa;
}
