import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("AcaoFiscalTipoDocumento")
@JsonIdentityInfo()
export class AcaoFiscalTipoDocumento extends SonnerBaseEntity {

  id: number;

  descricao: string;
}
