import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("EncerramentoDetalheTO")
export class EncerramentoDetalheTO {


  id: number;

  papel: string;

  senha: string;

  documentoFmt: string;

  nomeContribuinte: string;

}
