import {JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";

@JacksonType("Convite")
@JsonSubType({key: 'br.com.sonner.iss.entity.Convite', type: 'Documento'})
@JsonIdentityInfo()
export class Convite extends Documento {

  documento: string;

  email: string;
}
