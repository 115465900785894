import {Component, EventEmitter, Injector, Input, Output, ViewEncapsulation} from "@angular/core";
import {BaseComponent} from "../../../ui/components/base/base.component";
import {AppStateService} from "../../../app/services/app.state.service";
import {Endereco} from "../../../../classes/endereco";
import {Municipio} from "../../../../classes/municipio";
import {CEPService} from "../../services/cep.service";
import {CEPFilter} from "../../filters/cep.filter";
import {GlobalLoadingIndicator} from "../../../ui/decorators/global.loading.indicator.decorator";
import {Logradouro} from "../../../../classes/logradouro";

@Component({
  selector: 'endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnderecoComponent extends BaseComponent {



  constructor(private appStateService: AppStateService,
              private cepService: CEPService,
              private injector: Injector) {
    super(injector);
  }

  @Input()
  endereco: Endereco = new Endereco();

  @Input()
  readOnly: boolean = false;

  @Output()
  enderecoChange: EventEmitter<Endereco> = new EventEmitter();

  alteradoPeloCep: boolean = false;

  municipioReadOnly: boolean;

  ngOnInit(): void {
    if(this.endereco){
      if(!this.endereco.pais){
        this.endereco.pais = "Brasil";
      }
    }
  }

  @GlobalLoadingIndicator()
  cepChange(cep: string) {
    if(this.endereco && this.endereco.cep!= cep){
      let cepFilter : CEPFilter = new CEPFilter();
      cepFilter.cep=cep;
      return this.of(this.cepService.buscarPorCEP(cepFilter),cep =>{
        if(cep){
            this.municipioReadOnly = false;
            this.alteradoPeloCep = true;
            this.endereco = new Endereco();
            this.endereco.cep = cep.cep;
            this.endereco.bairro = cep.bairro;
            this.endereco.pais = "Brasil";
            if(!this.endereco.logradouro){
              this.endereco.logradouro = new Logradouro();
              this.endereco.logradouro.nome = cep.tipo +' '+cep.logradouro;
              this.endereco.logradouro.tipo = cep.tipo;
              this.endereco.logradouro.municipio = cep.municipio;
            }

        }else{
          this.endereco.cep = cepFilter.cep;
        }
        this.alteradoPeloCep = false;
        this.enderecoChange.emit(this.endereco);
      });
    }
  }

  logradouroChange(logradouro: string) {
    if(!this.alteradoPeloCep){
      this.checkLogradouro();
      if(this.endereco && this.endereco.logradouro &&  this.endereco.logradouro.nome!= logradouro){
        this.endereco.logradouro.nome = logradouro;
        this.enderecoChange.emit(this.endereco);
      }
    }
  }

  numeroChange(numero: string) {
    if(!this.alteradoPeloCep){
      if(this.endereco && this.endereco.numero!= numero){
        this.endereco.numero = numero;
        this.enderecoChange.emit(this.endereco);
      }
    }
  }

  complementoChange(complemento: string) {
    if(!this.alteradoPeloCep){
      if(this.endereco && this.endereco.complemento!= complemento){
        this.endereco.complemento = complemento;
        this.enderecoChange.emit(this.endereco);
      }
    }
  }

  bairroChange(bairro: string) {
    if(!this.alteradoPeloCep){
      if(this.endereco && this.endereco.bairro!= bairro){
        this.endereco.bairro = bairro;
        this.enderecoChange.emit(this.endereco);
      }
    }
  }

  municipioChange(municipio: Municipio) {
    setTimeout(()=>{
      if(!this.alteradoPeloCep){
        this.checkLogradouro();
        if(this.endereco && this.endereco.logradouro && municipio && municipio.estado &&  (!this.endereco.logradouro.municipio || this.endereco.logradouro.municipio.estado.codigo!= municipio.estado.codigo || this.endereco.logradouro.municipio.nome!= municipio.nome)){
          this.endereco.logradouro.municipio = municipio;
          this.enderecoChange.emit(this.endereco);
        }
      }
    });
  }

  paisChange(pais: string){
    if(!this.alteradoPeloCep){
      this.checkLogradouro();
      if(this.endereco && this.endereco.pais!= pais){
        this.endereco.pais = pais;
        if(!this.endereco.pais || this.endereco.pais.toUpperCase() != "BRASIL"){
          this.municipioReadOnly = true;
          this.endereco.logradouro.municipio = null;
        }else{
          this.municipioReadOnly = false;
        }
        this.enderecoChange.emit(this.endereco);
      }
    }

    setTimeout(()=>{
      if(this.endereco && (!this.endereco.pais || this.endereco.pais.toUpperCase() != "BRASIL")){
        this.municipioReadOnly = true;
      }else{
        this.municipioReadOnly = false;
      }
    });
  }

  checkLogradouro(){
    if(!this.endereco.logradouro){
      this.endereco.logradouro  = new Logradouro();
    }
  }

}
