import {Injectable} from "@angular/core";
import {BehaviorSubject, fromEvent, Observable, Subject} from "rxjs";
import {ViewTypeEnum} from "../../../enums/view.type.enum";

const MAX_MOBILE_WIDTH = 480;
const MAX_TABLET_WIDTH = 760;

@Injectable()
export class ViewService {

  private viewTypeSubject: Subject<ViewTypeEnum> = new BehaviorSubject(null);

  constructor() {
    this.updateCurrentViewType();

    fromEvent(window, 'resize').subscribe(() => this.updateCurrentViewType());
  }

  viewTypeObservable(): Observable<ViewTypeEnum> {
    return this.viewTypeSubject;
  }

  private updateCurrentViewType(): void {
    let width = window.innerWidth;

    if (width <= MAX_MOBILE_WIDTH) {
      this.viewTypeSubject.next(ViewTypeEnum.MOBILE);
    } else if (width <= MAX_TABLET_WIDTH) {
      this.viewTypeSubject.next(ViewTypeEnum.TABLET);
    } else {
      this.viewTypeSubject.next(ViewTypeEnum.DESKTOP);
    }
  }
}
