import {JacksonType} from "@sonner/jackson-service-v2";
import {EscritBanco} from "../escrit.banco";
import {EscritEspecial} from "../escrit.especial";
import {EscritPedagio} from "../escrit.pedagio";
import {ConsultaContribuinteNotaEscritTO} from "./consulta.contribuinte.nota.escrit.to";
import {DateUtilsService} from "../../modules/core/services/date.utils.service";
import {EscritCartorio} from "../escrit.cartorio";
import {EscritTransporte} from "../escrit.transporte";

@JacksonType("NotaEscritMesTO")
export class NotaEscritMesTO {

  mes: number;

  ano: number;

  @JacksonType("ConsultaContribuinteNotaEscritTO")
  notasPrestadorList: ConsultaContribuinteNotaEscritTO[];

  totalValorPrestador: number = 0;

  totalBasePrestador: number = 0;

  totalISSPrestador: number = 0;

  totalISSNaoRetidoPrestador: number = 0;

  totalISSRetidoPrestador: number = 0;

  totalISSCanceladasPrestador: number = 0;

  @JacksonType("ConsultaContribuinteNotaEscritTO")
  notasTomadorList: ConsultaContribuinteNotaEscritTO[];

  totalValorTomador: number = 0;

  totalBaseTomador: number = 0;

  totalISSTomador: number = 0;

  totalISSNaoRetidoTomador: number = 0;

  totalISSRetidoTomador: number = 0;

  totalISSCanceladasTomador: number = 0;

  @JacksonType("EscritBanco")
  escritsBanco: EscritBanco[];

  totalFaturadoBanco: number = 0;

  totalImpostoBanco: number = 0;

  @JacksonType("EscritEspecial")
  escritsEspecial: EscritEspecial[];

  totalFaturadoEspecial: number = 0;

  totalImpostoEspecial: number = 0;

  @JacksonType("EscritPedagio")
  escritsPedagio: EscritPedagio[];

  totalFaturadoPedagio: number = 0;

  totalImpostoPedagio: number = 0;

  @JacksonType("EscritCartorio")
  escritsCartorio: EscritCartorio[];

  totalFaturadoCartorio: number = 0;

  totalImpostoCartorio: number = 0;

  totalReceitaPropriaCartorio: number = 0;

  @JacksonType("EscritTransporte")
  escritsTransporte: EscritTransporte[];

  totalFaturadoTransporte: number = 0;

  totalImpostoTransporte: number = 0;

  getMesAnoFmt(): string {
    let dateUtilsService: DateUtilsService = new DateUtilsService();
    const mesFmt:string  = (this.mes == null) ? "" : dateUtilsService.getMesString(this.mes);
    const anoFmt: string = (this.ano == null) ? "" : this.ano.toString();
    return mesFmt + "/" + anoFmt;
  }

}
