import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {TipoTributacao} from "../tipo.tributacao";
import {ExigibilidadeISSEnum} from "../../enums/exigibilidade.iss.enum";
import {PapelServicoEnum} from "../../enums/papel.servico.enum";

@JacksonType("NotaEscritTO")
export class NotaEscritTO {

  @DateType()
  dataPrestacao: Date;

  id: number;

  tipo: any;

  eletronica: boolean;

  nomePrestador: string;

  docPrestador: string;

  nomeTomador: string;

  docTomador: string;

  valorBase: number;

  valorIss: number;

  valorTotal: number;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  @EnumType(PapelServicoEnum)
  papelServico: PapelServicoEnum;

  serie: string;

  numero: number;

  @DateType()
  dataCancelamento: Date;

  @DateType()
  emissao: Date;

  senha: string;

  senhaFmt: string;

  retido: boolean;

  encerramentoId: number;

  atividade: string;

  aliquota: string;

  reciboId: number;

  ano: string;

  mes: string;

}
