import {EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {AlertaFiscal} from "./alerta.fiscal";
import {PapelServicoEnum} from "../enums/papel.servico.enum";

@JacksonType("AlertaFiscalMovimentacaoAtipica")
@JsonSubType({key: 'br.com.sonner.iss.entity.dw.AlertaFiscalMovimentacaoAtipica', type: 'AlertaFiscal'})
@JsonIdentityInfo()
export class AlertaFiscalMovimentacaoAtipica extends AlertaFiscal {

  @EnumType(PapelServicoEnum)
  papel: PapelServicoEnum;

  valorReceita: number;

  valorMedio: number;
}
