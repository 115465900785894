import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ObraUnica} from "./obra.unica";
import {EscritPapel} from "./escrit.papel";
import {NotaFiscal} from "./nota.fiscal";

@JacksonType("RelacaoObraNotaEscrit")
@JsonIdentityInfo()
export class RelacaoObraNotaEscrit extends SonnerBaseEntity {

  id: number;

  @JacksonType("ObraUnica")
  obraUnica: ObraUnica;

  @JacksonType("EscritPapel")
  escritPapel: EscritPapel;


  @JacksonType("NotaFiscal")
  nota: NotaFiscal;

  /**Valor do abatimento usado na geração da nota/escrituração*/
  valorUtilizado: number;

  @DateType()
  dataUtilizacao: Date;

  /** Controla se a obra foi vinculada através da susbtituição de nota
   * Pois no caso a soma do valor utilizado irá ficar maior do que o valor permitido*/
  substituicao: boolean;

  /**No casos de escrituração que sofram exclusão, manter o id para consultar nos Eventos se precisar.*/
  docRemovido: number;

}
