import {debounceTime} from "rxjs/operators";
import {Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {RouterService} from "../../core/services/router.service";
import {Subject, Subscription} from "rxjs";
import {EstatisticaAcessoService} from "../../home/services/estatistica.acesso.service";
import {AppShellService} from "../../home/services/app.shell.service";
import {GoogleAnalyticsService} from "../../home/services/google.analytics.service";
import {SessionService} from "../../core/services/session.service";
import {EstatisticaAcessoFilter} from "../../home/filters/estatistica.acesso.filter";
import {AppStateService} from "../../app/services/app.state.service";

@Component({
  selector: 'struts',
  templateUrl: './struts.component.html',
  styleUrls: ['./struts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StrutsComponent implements OnInit, OnDestroy {

  get actionUri(): string {
    return this._actionUri;
  }

  @Input()
  set actionUri(value: string) {
    this._actionUri = value;
    if (value) {
      this.actionSafeUri = this.sanitizer.bypassSecurityTrustResourceUrl(value);
      if (!value.includes("#")) {
        this.loadingSubject.next(true);
      }
    } else {
      this.actionSafeUri = null;
    }
  }

  constructor(private activatedRoute: ActivatedRoute,
              private appShellService: AppShellService,
              private appStateService: AppStateService,
              private sessionService: SessionService,
              private sanitizer: DomSanitizer,
              private ngZone: NgZone,
              private routerService: RouterService,
              private estatisticaAcessoService: EstatisticaAcessoService,
              private googleAnalyticsService: GoogleAnalyticsService) {
  }

  actionSafeUri: SafeUrl;

  _actionUri: string;

  loading: boolean = false;

  loadingSubject: Subject<boolean> = new Subject();

  positions = ['Valor 1', 'Valor 2', 'Valor 3', 'Valor 4', 'Valor 5'];

  refreshStrutsSubscription: Subscription;

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe(paramMap =>{
      this.appStateService.setEmbeddedSubject(paramMap.get("embedded")!=null);
    });

    this.loadingSubject.pipe(debounceTime(100)).subscribe(loading => this.loading = loading);

    if (this._actionUri) {
      return;
    }

    this.updatePage();

    this.refreshStrutsSubscription = this.routerService.getRefreshStrutsObservable().subscribe(() => {
      this.updatePage();
    });
  }

  ngOnDestroy(): void {
    if (this.refreshStrutsSubscription) {
      this.refreshStrutsSubscription.unsubscribe();
    }
  }

  atualizarIframe(frame, scrollToTop: boolean) {
    if (scrollToTop) {
      this.appShellService.scrollToTop();
    }

    if(frame.contentWindow && frame.contentWindow.document){
      let bodyElem = frame.contentWindow.document.getElementsByClassName('home').item(0);
      let rootElem = frame.contentWindow.document.getElementsByClassName('body-wrapper').item(0);
      let modalElem = frame.contentWindow.document.getElementsByClassName('modal-backdrop').item(0);
      let tempoData = frame.contentWindow.document.getElementsByClassName('tempoData').item(0);
      let tempoCompetencia = frame.contentWindow.document.getElementsByClassName('tempoCompetencia').item(0);
      let size1 = 0;
      let size2 = 0;

      if (bodyElem) {
        size1 = (this.getSize(rootElem.offsetHeight) + this.getSize(bodyElem.style.paddingTop));
      }

      if (modalElem) {
        size2 = (this.getSize(modalElem.offsetHeight) + this.getSize(bodyElem.style.paddingTop));
      }

      let offset;
      if (tempoData || tempoCompetencia) {
        offset = 300;
      } else {
        offset = 0;
      }

      if (size1 >= size2) {
        frame.style.height = (size1 + offset) + 'px';
      } else {
        frame.style.height = size2 + 'px';
      }
    }
  }

  atualizarEstatisticaAcesso() {
    if (!this.routerService.currentUri().startsWith("/public/")) {
      this.estatisticaAcessoService.atualizarEstatisticaAcesso(new EstatisticaAcessoFilter(this.actionUri)).subscribe();
    }
  }

  atualizarFavorito(callback) {
    if (!this.routerService.currentUri().startsWith("/public/")) {
      this.estatisticaAcessoService.atualizarFavorito(new EstatisticaAcessoFilter(this.actionUri)).subscribe(callback)
    }
  }

  buscarFavorito(callback) {
    if (!this.routerService.currentUri().startsWith("/public/")) {
      this.estatisticaAcessoService.buscarFavorito(new EstatisticaAcessoFilter(this.actionUri)).subscribe(callback);
    }
  }

  getSize(value: string | number) {
    if (!value) {
      return 0;
    }

    if (typeof value == 'string') {
      return parseInt(value.replace(/[^0-9]/g, ""));
    }

    return value;
  }

  onFrameLoaded(frame): void {
    if (!frame.src) {
      return;
    }

    this.ngZone.run(() => this.loadingSubject.next(false));

    // altura do frame

    frame.contentWindow.angularViewCallback = (scrollToTop: boolean = true) => this.atualizarIframe(frame, scrollToTop);
    frame.contentWindow.angularSessionCallback = () => {
      this.ngZone.run(() => this.appShellService.refreshUserInfo());
      this.ngZone.run(() => this.routerService.open('/home'));
    };

    frame.contentWindow.redirectInterno = (route: string) => {
      if (route) {
        this.ngZone.run(() => setTimeout(() => this.routerService.open(route)));
      }
    };

    this.atualizarEstatisticaAcesso();

    this.atualizarIframe(frame, true);

    // favorito

    frame.contentWindow.toggleFavorito = (callback) => this.atualizarFavorito(callback);

    this.appStateService.userInfoSubject.subscribe(user => {
      if (user && frame.contentWindow) {
        this.buscarFavorito(frame.contentWindow.setFavorito);
      }
    });

    // click event

    frame.contentWindow.document.body.addEventListener("click", () => this.ngZone.run(() => this.appShellService.onShellItemClicked()));

    // metadados

    let metadados = frame.contentWindow.document.getElementsByTagName("meta");

    for (let i = 0; i < metadados.length; i++) {
      let meta = metadados.item(i);

      if (meta.name == 'viewId') {
        this.ngZone.run(() => this.appShellService.setCurrentViewId(meta.content));
      }
    }

    // redirect

    let links = frame.contentWindow.document.getElementsByTagName("a");

    for (let i = 0; i < links.length; i++) {
      let aElem = links.item(i);
      let actionRegex = /\/app\/(.*)\.action/;
      let paramsRegex = /.*\?(.*)$/;

      if (aElem.href && actionRegex.test(aElem.href)) {
        let actionName = aElem.href.match(actionRegex)[1];
        let method;

        if (actionName.includes("!")) {
          method = actionName.split("!")[1];
          actionName = actionName.split("!")[0];
        }

        actionName = actionName.replace(/([A-Z])/g, "-$1").toLowerCase();

        let uri = '/home/actions/' + actionName;
        let params = undefined;
        let fragment;
        let uriRaw = aElem.href;

        if (uriRaw.includes("#")) {
          if (uriRaw.includes("#")) {
            fragment = true;
          }
        }

        if (paramsRegex.test(uriRaw)) {
          let paramsRaw = uriRaw.match(paramsRegex)[1];

          params = {};
          paramsRaw.split("&").forEach(p => {
            let pair = p.split("=");

            params[pair[0]] = pair[1];
          })
        }

        if (!fragment) {
          aElem.addEventListener("click", evt => {
            evt.preventDefault();

            this.ngZone.run(() => this.routerService.open(uri, params, true, method));

            setTimeout(() => this.atualizarIframe(frame, true), 100);
          });
        }
      }
    }
  }

  updatePage() {
    let data = this.activatedRoute.snapshot.data;
    let method = this.activatedRoute.snapshot.fragment;
    let params = this.activatedRoute.snapshot.queryParamMap;

    let queryParams = "";

    params.keys.forEach(k => {
      queryParams = queryParams + (queryParams ? "&" : "?") + k + "=" + params.get(k);
    });

    let newUri = data[0].actionUri + queryParams;

    if (method) {
      newUri = `${newUri.split(".")[0]}!${method}.${newUri.split(".")[1]}`;
    }

    this.actionUri = newUri;

    this.googleAnalyticsService.sendPageRequest(this.actionUri);
  }
}
