import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {ExigibilidadePadrao} from "../../../classes/exigibilidade.padrao";
import {ExigibilidadePadraoFilter} from "../filters/exigibilidade.padrao.filter";

@Injectable()
export class ExigibilidadePadraoService {

  constructor(private restService: RestService) {
  }

  buscar(exigibilidadePadraoFilter: ExigibilidadePadraoFilter): Observable<ExigibilidadePadrao> {
    return this.restService.post("/exigibilidadepadrao/buscar", {data: exigibilidadePadraoFilter, responseType: ExigibilidadePadrao});
  }

  buscarListaPorMunicipio(): Observable<ExigibilidadePadrao[]> {
    return this.restService.post("/exigibilidadepadrao/buscarListaPorMunicipio", {responseType: ExigibilidadePadrao});
  }

  salvar(exigibilidadePadraoList: ExigibilidadePadrao[]): Observable<any> {
    return this.restService.post("/exigibilidadepadrao/salvar", {data: exigibilidadePadraoList});
  }
}
