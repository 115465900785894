import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("ConfiguracaoNotificacaoTO")
export class ConfiguracaoNotificacaoTO {

  avisaExpiracao: boolean = false;

  avisaAutor: boolean = false;

  imortal: boolean = false;

  intervaloCheck: string;

  habilitaNotificacoes: boolean = false;

  habilitaFiscais: boolean = false;

  naoGeraNotificacaoLogin: boolean = false;

  naoGeraNotificacaoMigrador: boolean = false;

  posicao: string;

  quantidadeMaxima: string;

  stick: boolean = false;

  tempoExibicao: string;

  tempoVida: string;

  tituloCallback: string;
}
