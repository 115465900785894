import {EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {Municipio} from "../municipio";
import {Atividade} from "../atividade";
import {Estado} from "../estado";
import {TipoRegimeISSEnum} from "../../enums/tipo.regime.iss.enum";
import {Pessoa} from "../pessoa";
import {Endereco} from "../endereco";

@JacksonType("UsuarioTO")
export class UsuarioTO {

  autorizaNfePrimeiroAcesso: boolean = false;

  bloqueiaAtividade: boolean = false;

  bloqueiaRegime: boolean = false;

  crc: string;

  descricaoAtividade: string;

  @JacksonType("Endereco")
  endereco: Endereco;

  erroMessage: string;

  @JacksonType("Estado")
  estado: Estado;

  eventual: boolean = false;

  exibeDadosCompletos: boolean = false;

  @JacksonType("Municipio")
  municipio: Municipio;

  municipioEmiteNFeAvulsa: boolean = false;

  permiteCadastroGov: boolean = false;

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  pessoaFisica: boolean = false;

  possuiAutorizacaoNfe: boolean = false;

  observacao: string;

  realizaraSolicitacao: boolean = false;

  solicitaEmissaoNfe: boolean = false;

  solicitaLivreEscrituracaoPrestador: boolean = false;

  telefone: string;

  @EnumType(TipoRegimeISSEnum)
  tipoRegime: TipoRegimeISSEnum;

  usuario: string;

  usuarioAtivo: boolean = false;

  usuarioCadastradoGrp: boolean = false;

  pessoaPossuiCadastroMunicipio: boolean = false;

  @JacksonType("Atividade")
  atividadeList: Atividade[] = [];

  @JacksonType("Estado")
  estadoList: Estado[] = [];

  @JacksonType("Municipio")
  municipioList: Municipio[] = [];
}
