import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Verbete} from "./verbete";

@JacksonType("ContaCosif")
@JsonIdentityInfo()
export class ContaCosif extends SonnerBaseEntity {

  id: number;

  codigo: string;

  nome: string;

  @JacksonType("Verbete")
  verbete: Verbete;

  @DateType()
  inicio: Date;

  @DateType()
  fim: Date;
}
