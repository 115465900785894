import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {TipoDeducao} from "../../../classes/tipo.deducao";
import {TipoDeducaoFilter} from "../filters/tipo.deducao.filter";

@Injectable()
export class TipoDeducaoService {

  constructor(private restService: RestService) {
  }

  buscar(tipoDeducaoFilter: TipoDeducaoFilter): Observable<TipoDeducao> {
    return this.restService.post("/tipodeducao/buscar", {data: tipoDeducaoFilter, responseType: TipoDeducao});
  }

  buscarListaPorMunicipio(tipoDeducaoFilter?: TipoDeducaoFilter): Observable<TipoDeducao[]> {
    return this.restService.post("/tipodeducao/buscarListaPorMunicipio", {data: tipoDeducaoFilter, responseType: TipoDeducao});
  }

  salvar(tipoDeducao: TipoDeducao): Observable<any> {
    return this.restService.post("/tipodeducao/salvar", {data: tipoDeducao});
  }
}
