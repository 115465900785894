import {JacksonType} from "@sonner/jackson-service-v2";
import {PGDASDDASTO} from "./pgdasd.das.to";
import {PGDASDApuracaoTO} from "./pgdasd.apuracao.to";
import {DateUtilsService} from "../../modules/core/services/date.utils.service";

@JacksonType("PgdasdTO")
export class PgdasdTO {

  mes: number;

  ano: number;

  @JacksonType("PGDASDDASTO")
  das: PGDASDDASTO[];

  @JacksonType("PGDASDApuracaoTO")
  apuracoes:PGDASDApuracaoTO[];

  getMesAnoFmt(): string {
    let dateUtilsService: DateUtilsService = new DateUtilsService();
    const mesFmt:string  = (this.mes == null) ? "" : dateUtilsService.getMesString(this.mes);
    const anoFmt: string = (this.ano == null) ? "" : this.ano.toString();
    return mesFmt + "/" + anoFmt;
  }
}
