import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ServicoBancario} from "./servico.bancario";

@JacksonType("EscritBanco")
@JsonIdentityInfo()
export class EscritBanco extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  ano: number;

  @DateType()
  dataAlteracao: Date;

  mes: number;

  @JacksonType("ServicoBancario")
  servico: ServicoBancario;

  valor: number;

}
