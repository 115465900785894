import {JacksonType} from "@sonner/jackson-service-v2";
import {Endereco} from "../endereco";
import {ItemReciboLocacao} from "../item.recibo.locacao";

@JacksonType("ReciboLocacaoTO")
export class ReciboLocacaoTO {

  id: number;

  numero: number;

  //Dados prestador

  docPrestador: string;

  nomePrestador: string;

  @JacksonType("Endereco")
  enderecoPrestador: Endereco;

  //Dados tomador
  @JacksonType("Endereco")
  enderecoTomador: Endereco;

  docTomador: string;

  nomeTomador: string;

  emailTomador: string;

  telefoneTomador: string;

  @JacksonType("ItemReciboLocacao")
  itemReciboLocacao: ItemReciboLocacao;

  obs: string;

  senha: string;

  codigo: string;

  motivoCancelamento: string;

  cancelado: boolean;
}
