import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";

import {TNCAgendamento} from "./t.n.c.agendamento";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("TNCAgendamentoExecLog")
@JsonIdentityInfo()
export class TNCAgendamentoExecLog  extends  SonnerBaseEntity{

  id:number;

  @JacksonType("TNCAgendamento")
  agendamento: TNCAgendamento;

  dataExecucao:Date;

  log:string;

  sucesso:boolean=false;

  idsSucesso:string;

  idsErro:string;
}
