import {DateType, EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {TipoEstabelecimentoCartorio} from "../tipo.estabelecimento.cartorio";
import {TipoDocumentoCartorioEnum} from "../../enums/tipo.documento.cartorio.enum";
import {TipoServicoCartorio} from "../tipo.servico.cartorio";

@JacksonType("EscritCartorioTO")
export class EscritCartorioTO {

  id: number;

  @JacksonType("TipoEstabelecimentoCartorio")
  tipoEstabelecimentoCartorioList: TipoEstabelecimentoCartorio[];

  docInicial: number;

  docFinal: number;

  baseCalculo: number;

  receitaBruta: number;

  competenciaEncerrada: boolean;

  @EnumType(TipoDocumentoCartorioEnum)
  tipoDocumento: TipoDocumentoCartorioEnum;

  @JacksonType("TipoServicoCartorio")
  tipoServicoCartorio: TipoServicoCartorio;

  tipoDocumentoFmt: string;

  atividadeFmt: string;

  aliquotaFmt: string;

  quantidade: number;

  valorImposto: number;

  @DateType()
  competencia: Date;
}
