import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("LoginFilter")
export class LoginFilter {

  cep: string;

  documento: string;

  id: number;

  idEstado: number;

  idMunicipio: number;

  novaSenha: string;

  novoUsuario: string;

  senha: string;

  sessionId: string;

  usuario: string;

  captcha: string;
}
