import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoConvenioBoletoEnum} from "../enums/tipo.convenio.boleto.enum";

@JacksonType("ConfigBoleto")
@JsonIdentityInfo()
export class ConfigBoleto extends SonnerBaseEntity {

  id: number;

  agencia: string;

  banco: string;

  carteira: string;

  codigoCedente: number;

  configuracaoCorrente: boolean = false;

  convenio: number;

  inicioBoletos: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  nossoNumConstante1: number;

  nossoNumConstante2: number;

  permiteRecalculo: boolean = false;

  @EnumType(TipoConvenioBoletoEnum)
  tipoConvenio: TipoConvenioBoletoEnum;

  ultimoBoletoGerado: number;

  versao: number;
}
