import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {ContaCosif} from "./conta.cosif";
import {DesifCodigoTributacao} from "./desif.codigo.tributacao";
import {DesifPlanoConta} from "./desif.plano.conta";

@JacksonType("DesifConta")
@JsonIdentityInfo()
export class DesifConta {

  id: number;

  codigo: string;

  @JacksonType("DesifCodigoTributacao")
  codigoTributacao: DesifCodigoTributacao;

  @JacksonType("ContaCosif")
  contaCosif: ContaCosif;

  @JacksonType("DesifConta")
  contaSuperior: DesifConta;

  descricao: string;

  desdobramentoContaMista: string;

  nome: string;

  @JacksonType("DesifPlanoConta")
  planoConta: DesifPlanoConta;
}
