import {DateType, JacksonType} from "@sonner/jackson-service-v2";
import {Atividade} from "../atividade";
import {GrupoAtividade} from "../grupo.atividade";
import {AtividadeHistorico} from "../atividade.historico";
import {OrdemServico} from "../ordem.servico";

@JacksonType("OrdemServicoTO")
export class OrdemServicoTO {

  abertas: number;

  concluidas: number;

  tempoMedio: number;

  @JacksonType("OrdemServico")
  ordemServicoList: OrdemServico[] = [];
}
