import {DateType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("DiaDimensao")
@JsonIdentityInfo()
export class DiaDimensao extends SonnerBaseEntity {

  id: number;

  ano: number;

  @DateType()
  data: Date;

  dia: number;

  diaSemana: string;

  feriado: boolean;

  mes: number;

  nomeMes: string;
}
