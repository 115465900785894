import {DateType, EnumType, JacksonType, JsonSubType} from "@sonner/jackson-service-v2";
import {AtividadeCNAE} from "./atividade.c.n.a.e";
import {AtividadeCTISS} from "./atividade.c.t.i.s.s";
import {DadosEndereco} from "./dados.endereco";
import {DadosPessoa} from "./dados.pessoa";
import {Documento} from "./documento";
import {GuiaAvulsa} from "./guia.avulsa";
import {ItemNotaFiscal} from "./item.nota.fiscal";
import {Municipio} from "./municipio";
import {NotaPremiada} from "./nota.premiada";
import {RegimeISS} from "./regime.i.s.s";
import {Serie} from "./serie";
import {TipoTributacao} from "./tipo.tributacao";
import {ExigibilidadeISSEnum} from "../enums/exigibilidade.iss.enum";
import {TipoRegimeISSEnum} from "../enums/tipo.regime.iss.enum";
import {TipoDocumentoEnum} from "../enums/tipo.documento.enum";
import {NotaEscrit} from "./nota.escrit";

@JacksonType("NotaFiscal")
@JsonSubType({key: 'br.com.sonner.iss.entity.NotaFiscal', type: "Documento"})
export class NotaFiscal extends Documento implements NotaEscrit{

  constructor() {
    super(TipoDocumentoEnum.NF);

    this.dadosTomador = new DadosPessoa();
  }

  aliquota: number;

  @JacksonType("AtividadeCnae")
  atividadeCNAE: AtividadeCNAE;

  @JacksonType("AtividadeCTISS")
  atividadeCTISS: AtividadeCTISS;

  codigoSerie: string;

  condicaoPagamento: string;

  correlacao: string;

  @JacksonType("DadosPessoa")
  dadosPrestador: DadosPessoa;

  @JacksonType("DadosPessoa")
  dadosTomador: DadosPessoa;

  @DateType()
  dataPrestacao: Date;

  descontoCondicional: number;

  descontoImposto: number;

  descontoIncondicional: number;

  emailTomador: string;

  empenho: string;

  @JacksonType("DadosEndereco")
  enderecoPrestador: DadosEndereco;

  @JacksonType("DadosEndereco")
  enderecoTomador: DadosEndereco;

  exibeValorUnitario: boolean = false;

  @EnumType(ExigibilidadeISSEnum)
  exigibilidade: ExigibilidadeISSEnum;

  @JacksonType("GuiaAvulsa")
  guia: GuiaAvulsa;

  inscricaoMunicipalTomador: string;

  @JacksonType("Municipio")
  municipioIncidencia: Municipio;

  @JacksonType("Municipio")
  municipioResponsavel: Municipio;

  nomeFantasiaPrestador: string;

  @JacksonType("NotaFiscal")
  notaSubstituida: NotaFiscal;

  numero: number;

  obs: string;

  outrasDeducoes: number;

  outrasRetencoes: number;

  passaporteTomador: string;

  @JacksonType("RegimeISS")
  regime: RegimeISS;

  retencaoINSS: number;

  retencaoIRRF: number;

  retencaoPIS: number;

  retencaoCOFINS: number;

  retencaoCSLL: number;

  retido: boolean = false;

  @JacksonType("Serie")
  serie: Serie;

  @EnumType(TipoRegimeISSEnum)
  tipoRegimePrestador: TipoRegimeISSEnum;

  @JacksonType("TipoTributacao")
  tipoTributacao: TipoTributacao;

  valorImposto: number;

  valorTotal: number;

  erros: string[];

  @JacksonType("ItemNotaFiscal")
  itens: ItemNotaFiscal[];

  @JacksonType("NotaPremiada")
  notasPremiadas: NotaPremiada[];

  addItem(item: ItemNotaFiscal) {
    item.notaFiscal = this;
    this.itens.push(item);
  }

  getValorLiquido() {
    return this.valorTotal - this.retencaoPIS - this.retencaoCOFINS - this.retencaoIRRF - this.retencaoCSLL - this.retencaoINSS - this.outrasRetencoes - this.outrasDeducoes - this.descontoCondicional - this.descontoIncondicional;
  }
}
