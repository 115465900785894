import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {DesifVersaoEnum} from "../enums/desif.versao.enum";

@JacksonType("DesifServicoVariavel")
@JsonIdentityInfo()
export class DesifServicoVariavel extends SonnerBaseEntity {

  id: number;

  codigo: string;

  descricao: string;

  descricaoComplementarObrigatoria: boolean;

  nome: string;

  @EnumType(DesifVersaoEnum)
  versaoDesif: DesifVersaoEnum;
}
