import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("ContribuinteEncerramentoResumidoTO")
export class ContribuinteEncerramentoResumidoTO {

  contribuinteId: number;

  nome: string;

  documento: string;

  situacao: string;
}
