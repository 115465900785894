import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Logradouro} from "./logradouro";
import {Pessoa} from "./pessoa";
import {TipoEnderecoEnum} from "../enums/tipo.endereco.enum";
import {Municipio} from "./municipio";

@JacksonType("Endereco")
@JsonIdentityInfo()
export class Endereco extends SonnerBaseEntity {

  id: number;

  bairro: string = "";

  cep: string = "";

  complemento: string = "";

  @JacksonType("Logradouro")
  logradouro: Logradouro;

  numero: string = "";

  pais: string = "";

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  principal: boolean = false;

  @EnumType(TipoEnderecoEnum)
  tipoEndereco: TipoEnderecoEnum;


  getMunicipio(): Municipio{
    if(this.logradouro!=null && this.logradouro.municipio!=null){
      return this.logradouro.municipio;
    }
    return null;
  }

  getEnderecoFmt(): string{
    let logra = "";
    if(this.logradouro) {
      logra += this.logradouro.getDisplayValue();
    }
    if(this.numero){
      logra += ", " + this.numero;
    }
    if(this.complemento){
      logra += ", " + this.complemento;
    }
    if(this.bairro){
      logra += " - " + this.bairro;
    }
    return logra.trim();
  }

}
