import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {GuiaAvulsa} from "./guia.avulsa";
import {Pessoa} from "./pessoa";

@JacksonType("ControleTSA")
@JsonIdentityInfo()
export class ControleTSA extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("GuiaAvulsa")
  guiaCobrada: GuiaAvulsa;

  mes: number;
}
