import {Municipio} from "./municipio";
import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";

@JacksonType("ControleNumeracaoGuia")
@JsonIdentityInfo()
export class ControleNumeracaoGuia extends SonnerBaseEntity {

  id: number;

  ano: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  numero: number;
}
